import React, { useState } from 'react'
import Main from '../components/resetPassword/Main'
import DefaultNavbar from '../components/DefaultNavbar'
import DefaultFooter from '../components/DefaultFooter'
import LoadingDiv from '../components/LoadingDiv'
import { useAppData } from '../hooks/useAppData'
import { GApageView } from '../components/renderless/helpers'
import { ErrorHandilingContext } from '../context/ErrorHandilingContext'

export default function ResetPassword({ user }) {
    
    // BEGIN DATA LOADING SECTION
    const [ id, setId ] = useState('notloggedin')
    if  ((user) && (id !== user.uid) ) {
        setId(user.uid)
    }
    const [ isPageLoading, setIsPageLoading ] = React.useState(false)
    const { etgErrorDetected } = React.useContext(ErrorHandilingContext)
    
    // Load data from firestore
    const { profileDocument, plantDocument } = useAppData(id)    
    if (isPageLoading && profileDocument && plantDocument) {
        setIsPageLoading(false)
    }
    
    // END DATA LOADING SECTION

    React.useEffect(() => {                
        GApageView('password-reset')    
    }, [])

    return (
        <> { etgErrorDetected ? null : <>        
        <section className='flex flex-col h-screen justify-between'>

            <div className='relative w-full z-20'>
                <DefaultNavbar user={user} profileData={profileDocument} />
            </div>
            {/* Page Specifc Content */}
            { isPageLoading ? <LoadingDiv /> : 
                <main className='mb-auto my-10 w-10/12 mx-auto'>
                    <Main />
                </main>
            }
            <DefaultFooter/>
            </section>
        </> } </>
        )
    }
    