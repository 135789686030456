import { useEffect, useState } from "react"
import { projectFirestore } from "../firebase/config"
let collectionName = 'growProfiles'


export const useProfileDocument = (id) => {
  const [ profileDocument, setProfileDocumennt ] = useState(null)
  const [ profileDocumentError , setProfileDocumenntError ] = useState(null)

  // realtime document data
  useEffect(() => {
    const ref = projectFirestore.collection(collectionName).doc(id)

    const unsubscribe = ref.onSnapshot(snapshot => {
      // need to make sure the doc exists & has data
      if(snapshot.data()) {
        setProfileDocumennt({...snapshot.data(), id: snapshot.id})
        setProfileDocumenntError(null)
      }
      else {
        setProfileDocumenntError('No such document exists')
      }
    }, err => {
      console.log(err.message)
      setProfileDocumenntError('failed to get document')
    })

    // unsubscribe on unmount
    return () => unsubscribe()

  }, [id])

  return { profileDocument, profileDocumentError }
}