import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ProcessingDiv from '../../../ProcessingDiv';

export default function ImageDisplay({ currentPlantData, isImageLoading, setIsImageLoading }) {
  const sliderRef = useRef(null);
  const [panPosition, setPanPosition] = useState({ x: 50, y: 50 });
  const [currentImageIndex, setCurrentImageIndex] = useState(0); 

  useEffect(() => {
    const initialSlideIndex = currentPlantData.imgArray.findIndex(
      (image) => image.favImg === true
    );
  
    const newIndex = initialSlideIndex !== -1 ? initialSlideIndex : 0;
    
    setCurrentImageIndex(newIndex >= currentPlantData.imgArray.length ? 0 : newIndex);
  }, [currentPlantData.imgArray]);

  const handleNextImage = () => {
    sliderRef.current.slickPrev();
  };

  const handlePreviousImage = () => {
    sliderRef.current.slickNext();
  };

  const handleImageMouseMove = (e) => {
    const { offsetX, offsetY, target } = e;
    const { clientWidth, clientHeight } = target;

    const x = (offsetX / clientWidth) * 100;
    const y = (offsetY / clientHeight) * 100;

    setPanPosition({ x, y });
  };

  const handleImageTouchMove = (e) => {
    const touch = e.touches[0];
    const { clientX, clientY, target } = touch;
    const { clientWidth, clientHeight } = target;

    const x = (clientX / clientWidth) * 100;
    const y = (clientY / clientHeight) * 100;

    setPanPosition({ x, y });
  };


  const transformWrapper = (image, index) => (
    <TransformWrapper
      defaultPositionX={panPosition.x}
      defaultPositionY={panPosition.y}
      options={{
        limitToBounds: false,
        minScale: 0.5,
        maxScale: 3,
        limitToWrapperBounds: true,
        preventDefault: false, // Prevent page scrolling on image zoom
      }}
      pan={{
        disabledOnDoubleClick: false,
      }}
      pinch={{ disabled: false }}
      wheel={{ disabled: false }}
      doubleClick={{ disabled: false }}
      pinchRotate={{ disabled: false }}
      key={index}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <TransformComponent>            
            <div>
              <img
                src={image.imgUrl}
                alt={image.caption}
                onMouseMove={handleImageMouseMove}
                onTouchMove={handleImageTouchMove}                
                style={{
                  width: '100%',
                  height: 'auto'
                }}                
              />
            </div>
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  );

  

  const sliderSettings = {
    dots: false,
    className: '',
    arrows: false,
    fade: true,
    infinite: true,
    lazyLoad: true,     
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: setCurrentImageIndex, // Update the currentImageIndex after slide change
    initialSlide: currentImageIndex, // Set the initial slide to the currentImageIndex
  };

  const displayImage =
    currentPlantData.imgArray && currentPlantData.imgArray.length > currentImageIndex ? (
      <div style={{ width: '100%', height: 'auto', margin: '0 auto' }}>

      <Slider key={currentImageIndex} {...sliderSettings} ref={sliderRef}>
        {currentPlantData.imgArray.map((image, index) => (
          <div key={index} className='w-full h-full'>
            <div className="flex justify-center">
              {transformWrapper(image, index)}
            </div>
          </div>
        ))}
      </Slider>
        </div>
    ) : (
      <img
        className="mx-auto" // Add this class for centering the image horizontally
        style={{ display: 'block' }} // Add this style for centering the image vertically
        src="https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2Fetg_placeHolder.png?alt=media&token=1233cb30-a991-408d-a5d9-e53c4a5b7a47"
        alt="user has not uploaded any images"
      />
    );

    const displayCaption =
    currentPlantData.imgArray && currentImageIndex < currentPlantData.imgArray.length ? (
      <div className="etg-userInput text-black max-h-6 overflow-hidden">
        <p className="whitespace-pre-wrap h-auto">
          {currentPlantData.imgArray[currentImageIndex].caption || 'Upload an image'}
        </p>
      </div>
    ) : null;
  
      
  // display processingDiv when appropriate
  if (isImageLoading) {
    return <ProcessingDiv message='Loading Images' />;
  }
  
  return (
    <section className="">
      {/* Image Carousel */}
      <div className={`flex items-center justify-center ${currentPlantData.imgArray.length > currentImageIndex && currentPlantData.imgArray[currentImageIndex].favImg ? 'bg-sky-400' : 'bg-emerald-800'} slick-list`}>
        {displayImage}
      </div>


      <div className="flex flex-col md:flex-row pb-2 -mt-2">
        <div className="flex flex-col w-full">
          <div className="relative w-full">
            {/* Next Image */}
            {currentPlantData.imgArray &&
              currentPlantData.imgArray.length > 1 && (
                <div
                  className="absolute bottom-2 right-0 text-4xl text-green-400 bg-white rounded-xl p-1 m-2 md:hover:bg-slate-200 active:bg-slate-400"
                  onClick={handleNextImage}
                  style={{ zIndex: 10 }}
                >
                  <FaChevronRight />
                </div>
              )}

            {/* Previous Image */}
            {currentPlantData.imgArray &&
              currentPlantData.imgArray.length > 1 && (
                <div
                  className="absolute bottom-2 left-0 text-4xl text-green-400 bg-white rounded-xl p-1 m-2 md:hover:bg-slate-200 active:bg-slate-400"
                  onClick={handlePreviousImage}
                  style={{ zIndex: 10 }}
                >
                  <FaChevronLeft />
                </div>
              )}
          </div>
          {/* Image Caption */}
          <div className="flex flex-col items-center text-center m-2 py-2">
            <p className="text-black text-xs italic">
              {window.innerWidth < 768
                ? 'Pinch to zoom and pan'
                : 'Double click or use the mouse wheel to zoom'}
            </p>
            <div>{displayCaption} </div>                            
            
          </div>
        </div>
      </div>
    </section>
  );
}
