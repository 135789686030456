import React, { useEffect, useState } from 'react'
import NoteEdit from './NoteEdit'
import NewNote from './NewNote'
import ProcesseingDiv from '../../../../ProcessingDiv'
import { gaEvent } from '../../../../renderless/helpers'
import { timestamp } from '../../../../../firebase/config'
import { GoNote } from 'react-icons/go'
import { BiCut} from 'react-icons/bi'
import { GrPowerCycle, GrScorecard } from 'react-icons/gr'
import { RiGuideLine } from 'react-icons/ri'
import { IoWaterOutline } from 'react-icons/io5'
import { addNewNote } from '../../../../renderless/setData/addNewNote'
import { useUpdatePlantData } from '../../../../../hooks/useUpdatePlantData'
import { processGrowLog } from '../../../helpers/processGrowLog'
import { GiNoseFront } from 'react-icons/gi'
import ImageEditSection from './ImageEditSection'


export default function GrowLog({ 
    appData,
    updateErrorData,
    currentPlantData
    }) {
    const [ editNote, setEditNote ] = useState(false)
    const [ editImg, setEditImg ] = useState(false)
    const [ newNote, setNewNote ] = useState(false)
    const [ proccessedLog, setProccessedLog ] = useState([])
    const [ processingData, setProcessingData ] = useState(false)
    const { updatePlantData, updatePlantDataError } = useUpdatePlantData(appData.profileData)   
    const [ reviewNoteExists, setReviewNoteExists ] = useState(false)
   
    // useEffect to check if a note with the type 'review' exists in the noteLog of the current plant
    useEffect(() => {
        
        currentPlantData.noteLog.forEach(note => {
            if(note.type === 'review') {
                setReviewNoteExists(true)
            }
        })
    }, [currentPlantData.noteLog])


    const deleteNote = async ( index  ) => {
        // setProcessingData(true)
        // get copy of noteLog arr        
        let noteLogCopy = currentPlantData.noteLog
        
        // remove the note from the array
        noteLogCopy.splice(index, 1)
        
        
        // update the array in the plant object
        let tempPlant = Object.assign({}, currentPlantData)
        
        //sort the updated noteLog, this will reorganize the noteLog by date before saving changes
        noteLogCopy.sort(function (a, b) {            
            return new Date(a.date.toDate()) - new Date(b.date.toDate());
        })        
        let posLastNoteLocationPosition = null
        // Find position of last element that contains the correct note type
        noteLogCopy.forEach((note, index) => {
            if (note.type === 'lc-started' || 'lc-transfer' || 'lc-move') {
                posLastNoteLocationPosition = index
            }
        })
        // Using the most recent note containing a location/position, set the currents plant location/position based on movement type
        if((noteLogCopy[posLastNoteLocationPosition].type === 'lc-transfer') || (noteLogCopy[posLastNoteLocationPosition].type === 'lc-move')) {
            tempPlant.location = noteLogCopy[posLastNoteLocationPosition].locationEnd
            tempPlant.position = noteLogCopy[posLastNoteLocationPosition].positionEnd                    
        }

        if(noteLogCopy[posLastNoteLocationPosition].type === 'lc-started') {
            tempPlant.location = noteLogCopy[posLastNoteLocationPosition].locationStart
            tempPlant.position = noteLogCopy[posLastNoteLocationPosition].positionStart
        }



        tempPlant.noteLog = [...noteLogCopy]
        
        // update the plant document in firestore
        await updatePlantData(tempPlant)        
        setEditNote(false)       
        setProcessingData(false)
    }
    
    async function handleAddNewNote( date, comment, type = 'u', rating = false, budSize = false){        
        setProcessingData(true)
        
        // uploadNewNote( date, comment, type)
        const results = addNewNote( appData, currentPlantData, currentPlantData.status, date, comment, type, rating, budSize)
        if(results.error) {
            updateErrorData(results.error, appData.id)
        }
        
        // wind down
        setNewNote(false)
        setProcessingData(false)
    }
   
                    
    const updateSelectedPlantNote = async ( noteIndex, noteDate, noteComment, type, positionStart, positionEnd, locationStart, locationEnd, rating = false, budSize = false ) => {
        setProcessingData(true)        
        
        // Build the noteObj that represents the updated note the user is saving
        let tempDate = new Date(noteDate)
        let tempDateTimestamp = new timestamp(Math.round(tempDate.getTime()/1000), 0)
        const noteObjToUpload = {
            type,
            comment: noteComment,
            date: tempDateTimestamp
        }
        // if rating is passed, add to noteObj
        if(rating && type === 'review') {
            noteObjToUpload.rating = rating
        }
        // if budSize is passed, add to noteObj
        if(budSize && type === 'review') {
            noteObjToUpload.budSize = budSize
        }



        // add location/position start if type === lc started
        if(type === 'lc-started') {
            noteObjToUpload.locationStart = locationStart
            noteObjToUpload.positionStart = positionStart
        }
        // add location/position start, end if type === lc transfer, move 
        if((type === 'lc-transfer') || (type === 'lc-move')) {
            noteObjToUpload.locationStart = locationStart
            noteObjToUpload.positionStart = positionStart
            noteObjToUpload.locationEnd = locationEnd
            noteObjToUpload.positionEnd = positionEnd
        }        
        
        // create clone of the current active plant to prepare changes
        let plantCopy = Object.assign({}, currentPlantData)
        // clone the currently displayed noteLog to prepare changes
        
        let noteLogCopy = plantCopy.noteLog


        //replace the existing note with the updated note
        noteLogCopy[noteIndex] = noteObjToUpload
        
        //sort the updated noteLog, this will reorganize the noteLog by date before saving changes
        noteLogCopy.sort(function (a, b) {            
            return new Date(a.date.toDate()) - new Date(b.date.toDate());
        })
        let posLastNoteLocationPosition = null
        // Find position of last element that contains the correct note type
        noteLogCopy.forEach((note, index) => {            
            if (note.type === 'lc-started' || 'lc-transfer' || 'lc-move') {                
                posLastNoteLocationPosition = index                
            }
        })
        // Using the most recent note containing a location/position, set the currents plant location/position based on movement type
        if((noteLogCopy[posLastNoteLocationPosition].type === 'lc-transfer') || (noteLogCopy[posLastNoteLocationPosition].type === 'lc-move')) {
            plantCopy.location = noteLogCopy[posLastNoteLocationPosition].locationEnd
            plantCopy.position = noteLogCopy[posLastNoteLocationPosition].positionEnd                    
        }

        if(noteLogCopy[posLastNoteLocationPosition].type === 'lc-started') {
            plantCopy.location = noteLogCopy[posLastNoteLocationPosition].locationStart
            plantCopy.position = noteLogCopy[posLastNoteLocationPosition].positionStart
        }
        
        // update the plantCopy with the updated noteLog
        plantCopy.noteLog = [...noteLogCopy]

        // UPDATE PLANT START, TRANSFER & HARVEST DATES 
        // IF lc-started, update plant start date
        if(type === 'lc-started') {
            plantCopy.startDate = tempDateTimestamp
        }
        // IF lc-transfer, update plant transfer date
        if(type === 'lc-transfer') {
            plantCopy.transferDate = tempDateTimestamp
        }
        // IF lc-harvest, update plant harvest date
        if(type === 'lc-harvest') {
            plantCopy.harvestDate = tempDateTimestamp
        }        
                                
        //use find index to first find index position to replace
        setEditNote(false)
       await updatePlantData(plantCopy)

       
    
        setProcessingData(false)
        
    }

    

    const newTrimEvent = () => {
        let date = new Date()
        let comment = 'Plant trimmed'
        handleAddNewNote(date, comment, 'lc-trim')
        gaEvent('Plant Note Action', 'Add Trim Note', `User: ${appData.id}`)
    }
    const newTrainingEvent = () => {
        let date = new Date()
        let comment = 'Plant training'
        handleAddNewNote(date, comment, 'lc-training')
        gaEvent('Plant Note Action', 'Add Training Note', `User: ${appData.id}`)
    }
    const newNutrientEvent = () => {
        let date = new Date()
        let comment = 'Plant nutrients were reset'
        handleAddNewNote(date, comment, 'lc-nutrient')
        gaEvent('Plant Note Action', 'Add Nutrient Reset Note', `User: ${appData.id}`)
    }
    const newWaterEvent = () => {
        let date = new Date()
        let comment = 'Plant watered'
        handleAddNewNote(date, comment, 'lc-water')
        gaEvent('Plant Note Action', 'Add Water Note', `User: ${appData.id}`)
    }
    const newwetTrimEvent = () => {
        let date = new Date()
        let comment = 'Harvest trimmed wet'
        handleAddNewNote(date, comment, 'harvest-wet-trim')
        gaEvent('Plant Note Action', 'Add Wet Trim Note', `User: ${appData.id}`)
    }
    const newDryTrimEvent = () => {
        let date = new Date()
        let comment = 'Harvest trimmed dry'
        handleAddNewNote(date, comment, 'harvest-dry-trim')
        gaEvent('Plant Note Action', 'Add Dry Trim Note', `User: ${appData.id}`)
    }
    

    
    

    React.useEffect(() => {        
        setProcessingData(true)
        const tempProccessedLog  = processGrowLog( setEditNote, setEditImg, currentPlantData, appData.publicView)    
        setProccessedLog([...tempProccessedLog])
        setProcessingData(false)
    }, [currentPlantData, currentPlantData.status, appData, setEditImg, setEditNote ])
    
    
        
    if (processingData) {
        return <ProcesseingDiv message='Processing Growlog Changes' />
    }
    if (updatePlantDataError) {
        updateErrorData(updatePlantDataError, appData.id)
    }
    
       
  return (
    <>
    <div className='sticky top-0 z-10 w-full'>
    <div className='flex flex-col mx-auto pb-4 w-full md:w-8/12 bg-slate-100 rounded-xl'>
        <div>
            <h4 className='text-2xl font-medium pb-4 underline underline-offset-2 uppercase tracking-wider'>Grow Log</h4>
        </div>
        {/* Display Plant Note Actions - if not archived */}
        { ( !appData.publicView && currentPlantData.status !== 'Archived' ) ? 
        <div className='flex flex-col bg-slate-500/40 border-2 border-gray-800 p-2 rounded-xl'>

        
        
            {((editNote === false && editImg === false ) && (newNote === false) && (currentPlantData.status !== 'Archived') && !appData.publicView) && 
                <div className='flex flex-wrap  p-2 justify-evenly py-2'>     
                    <div className='p-2 px-2 border-emerald-800 rouned-t-md border-solid hover:border-b-2' onClick={() => setNewNote('u')}>                        
                        <div className='flex flex-col justify-center items-center'>
                        <GoNote />
                            Note
                        </div>
                    </div>
                    {/* conditional based on harvested or not */}
                    {(currentPlantData.status !== 'Harvested') ? <>
                        <div className='p-2 px-2 border-emerald-800 rouned-t-md border-solid hover:border-b-2' onClick={() => newTrimEvent()}>                            
                            <div className='flex flex-col justify-center items-center'>
                                <BiCut />
                                Trim
                            </div>
                        </div>
                        <div className='p-2 px-2 border-emerald-800 rouned-t-md border-solid hover:border-b-2' onClick={() => newTrainingEvent()}>                            
                            <div className='flex flex-col justify-center items-center'>
                                <RiGuideLine />
                                Training
                            </div>
                        </div>
                        <div className='p-2 px-2 border-emerald-800 rouned-t-md border-solid hover:border-b-2' onClick={() => newNutrientEvent()}>                            
                            <div className='flex flex-col justify-center items-center'>
                                <GrPowerCycle />
                                Nutrient Reset
                            </div>
                        </div>
                        <div className='p-2 px-2 border-emerald-800 rouned-t-md border-solid hover:border-b-2' onClick={() => newWaterEvent()}>                            
                            <div className='flex flex-col justify-center items-center'>
                                <IoWaterOutline />
                                Water
                            </div>
                        </div>
                        <div className='p-2 px-2 border-emerald-800 rouned-t-md border-solid hover:border-b-2' onClick={() => setNewNote('aroma')}>                            
                            <div className='flex flex-col justify-center items-center'>
                                <GiNoseFront />
                                Aroma
                            </div>
                        </div>
                    </> : <>
                        <div className='p-2 px-2 border-emerald-800 rouned-t-md border-solid hover:border-b-2' onClick={() => newwetTrimEvent()}>                            
                            <div className='flex flex-col justify-center items-center'>
                                <BiCut />
                                Wet Trim
                            </div>
                        </div>
                        <div className='p-2 px-2 border-emerald-800 rouned-t-md border-solid hover:border-b-2' onClick={() => newDryTrimEvent()}>                            
                            <div className='flex flex-col justify-center items-center'>
                                <BiCut />
                                Dry Trim
                            </div>
                        </div>
                        { !reviewNoteExists && <>
                        <div className='p-2 px-2 border-emerald-800 rouned-t-md border-solid hover:border-b-2' onClick={() => setNewNote('review')}>                            
                            <div className='flex flex-col justify-center items-center'>
                                <GrScorecard />
                                Review
                            </div>
                        </div>
                         </>}
                    </> }
                </div>
            }
            <div className='flex'>                
                <p className='w-10/12 mx-auto etg-systemText py-2'>
                    detailed notes help you (and the community) understand your plants progress
                </p>
            </div>
        </div> : null }
        
        <div className='flex flex-col w-full h-screen overflow-y-auto'>
            {/* Display Notes */}                       
            {((editNote === false && editImg === false ) && (newNote === false) && (currentPlantData.noteLog.length > 0 )) && proccessedLog}
            {/* Display Note Edit */}
            {(editNote !== false && currentPlantData.noteLog[editNote] !== undefined) && <NoteEdit 
                appData={appData}                                          
                setEditNote={setEditNote} 
                index={editNote}
                note={currentPlantData.noteLog[editNote]}
                updateSelectedPlantNote={updateSelectedPlantNote}
                deleteNote={deleteNote}   
                setProcessingData={setProcessingData}             
            />}
            {/* Display Image Edit */}
            {(editImg !== false) && <ImageEditSection
                imgData={currentPlantData.imgArray[editImg]}
                setEditImg={setEditImg}
                appData={appData} 
                currentPlantData={currentPlantData}
                updatePlantData={updatePlantData}
                index={editImg}
                updateErrorData={updateErrorData}
                setProcessingData={setProcessingData}
            />}

               

            {/* New Note */}
            {(newNote !== false) && <NewNote newNote={newNote} setNewNote={setNewNote} handleAddNewNote={handleAddNewNote} />}
        </div>
            
    </div>
    </div>
    </>
  )
}
