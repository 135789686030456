import React from "react";

export default function AboutContactSection() {
    return (
        <div className='flex flex-col items-center justify-center bg-emerald-800/30 rounded-xl shadow-xl py-6'>
            <h1 className='text-3xl font-bold text-center text-gray-800'>Contact</h1>
            <p className='text-lg text-center text-gray-800 py-3 px-4'>
            Thank you for your interest in EctorGrow! If you have any questions, suggestions, or encounter any issues while using our platform, 
            please use the Support menu option from the navigation bar when logged in to contact our support team. <span className="font-bold">For business inquiries</span>, please 
            email us at <span className="font-bold">Team@EctorGrow.com</span>. We look forward to hearing from you!
            </p>
        </div>);
}
  
  