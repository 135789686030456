import React, { useState } from 'react';
import { useFirestore } from '../../../hooks/useFirestore';
import ReactGA from 'react-ga';
import ETGButton from '../global/ETGButton';
import ETGIconButton from '../global/ETGIconButton';

export default function PositionsAddEdit({ growerProfile, publicView, id }) {
  const [newPosition, setNewPosition] = useState('');
  const { updateDocument, response } = useFirestore('growProfiles');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5; // Number of positions to display per page

  const gaPositionAdd = () => {
    ReactGA.event({
      category: 'user',
      action: 'Add a grow position',
      label: 'AddEditDisplay of growerProfile',
    });
  };

  const handlePositionDelete = async (index) => {
    const updatedPositions = growerProfile.growPositions.filter((_, i) => i !== index);
    // Update the array in Firestore
    await updateDocument(growerProfile.uid, {
      growPositions: updatedPositions,
    });
    if (response.error) {
      console.log('handlePositionDelete Error: ' + response.error);
    }
  };

  const handlePositionSubmit = async () => {
    gaPositionAdd();
    let passedValidation = false;
    if (newPosition !== '') {
      passedValidation = true;
    }
    if (passedValidation) {
      // Add new position to profile
      await updateDocument(id, {
        // all current plus new
        growPositions: [...growerProfile.growPositions, newPosition],
      });
      if (!response.error) {
        // Clear state to clear field
        setNewPosition('');
      }
    } else {
      alert('Position cannot be empty');
    }
  };

  // Determine the positions to display based on the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const positionsToDisplay = growerProfile.growPositions.slice(startIndex, endIndex);

  // Go to the next page
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Go to the previous page
  const previousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <>
      <section className="">
        <div className="justify-between text-center px-1 mx-auto py-3">
          <table className="mx-auto">
            <tbody>
              {positionsToDisplay.map((position, index) => (
                <tr key={`growPosition:` + index}>
                  <td className="p-1 md:p-2 text-white">{position}</td>
                  {!publicView ? (
                    <td className="p-1 md:p-2">
                      <ETGIconButton
                        onClickFunction={() => handlePositionDelete(index + startIndex)}
                        icon="delete"
                        textColor="white"
                      />
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {growerProfile.growPositions.length > pageSize && (
          <div className="flex justify-center mt-4">
            {currentPage > 1 && (
              <button className="mx-1 px-3 py-1 bg-gray-800 rounded-md text-white" onClick={previousPage}>
                Previous
              </button>
            )}
            {endIndex < growerProfile.growPositions.length && (
              <button className="mx-1 px-3 py-1 bg-gray-800 rounded-md text-white" onClick={nextPage}>
                Next
              </button>
            )}
          </div>
        )}

        {!publicView ? (
          <div className="py-4 px-2">
            <input
              className="text-black border-2 border-emerald-800 p-2 rounded-xl"
              value={newPosition}
              label="location"
              type="text"
              placeholder="new position"
              onChange={(e) => {
                setNewPosition(e.target.value);
              }}
            />
            <div className="flex justify-center">
              <ETGButton onClickFunction={handlePositionSubmit} btnText="Submit New Position" textColor="white" />
            </div>
          </div>
        ) : null}
      </section>
    </>
  );
}
