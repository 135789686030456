import { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';
import { useUpdatePlantData } from './useUpdatePlantData';


export const usePlantData = (userUID) => {
  const [plants, setPlants] = useState([]);
  const { updatePlantData } = useUpdatePlantData();
  const today = new Date();


  useEffect(() => {    
    const unsubscribe = projectFirestore.collection('plants')
      .where('plantOwnerUID', '==', userUID)
      .onSnapshot((snapshot) => {
        const newPlants = snapshot.docs.map((doc) => ({              
          ...doc.data()
        }));

        // loop through the newPlants array, if a plant is status harvested and the 
        // harvest date is more than 90 days ago, change the status to archived and update the plant

        newPlants.forEach(plant => {
          if (plant.status === 'Harvested') {
            let differenceInTime = Math.abs(plant.harvestDate.toDate() - today)
            let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
            let tempPlant = plant;                                
            if (differenceInDays > 90) {
              tempPlant.status = 'Archived'
              updatePlantData(tempPlant)
            }
          
            
          }
        })




        setPlants(newPlants);
      });

    return unsubscribe;
  }, [userUID]);

  return plants;
};

export default usePlantData;
