import { projectFirestore } from "../../../firebase/config";


const fetchNutrientSchedulesData = async (usersNutrientScheduleIDs) => {
  if (usersNutrientScheduleIDs.length === 0) {
    return { vegNutrients: [], flowerNutrients: [], error: null };
  }

  try {
    const snapshot = await projectFirestore
      .collection('nutrientSchedules')
      .where('nutrientScheduleID', 'in', usersNutrientScheduleIDs)
      .get();

    const vegNutrients = [];
    const flowerNutrients = [];

    snapshot.docs.forEach((doc) => {
      const nutrient = { ...doc.data(), id: doc.id };

      if (nutrient.phase === 'Vegetative') {
        vegNutrients.push(nutrient);
      } else if (nutrient.phase === 'Flower') {
        flowerNutrients.push(nutrient);
      }
    });
     const data = { vegNutrients, flowerNutrients };
    return { data, error: null };
  } catch (err) {
    
    return { vegNutrients: [], flowerNutrients: [], error: 'Could not fetch Nutrient Schedule Data' };
  }
};

export default fetchNutrientSchedulesData;
