import { projectFirestore, timestamp } from '../../../firebase/config'
import { getGrowProfiles } from '../../renderless/GetData/getGrowProfiles'
import { v4 as uuidv4 } from 'uuid'

const calculateAverage = (total, divisor) => {
  return Math.round(total / divisor)
}

export const generateAdminReport = async () => {
  const today = new Date()
  const reportDetails = {
    id: uuidv4(),
    date: new timestamp(Math.round(today.getTime() / 1000), 0),
    totalUsers: 0,
    totalBugs: 0,
    totalError: 0,
    totalFeatureRequests: 0,
    totalUnsetRequests: 0,
    totalActivePlants: null,
    totalHarvestedPlants: null,
    avgTotalPlantsPerUser: null,
    avgActivePlantsPerUser: null,
    avgImagesPerPlant: null,
  }

  // Get all user profiles to determine total users currently registered
  const userProfiles = await getGrowProfiles()
  reportDetails.totalUsers = userProfiles.length

  // Get all the documents from the plants collection
  const plantsSnapshot = await projectFirestore.collection('plants').get()
  const plantsCollection = plantsSnapshot.docs.map((doc) => doc.data())

  // Load # of active plants and harvested plants
  for (const plant of plantsCollection) {
    if (plant.status === 'Vegetative' || plant.status === 'Flower') {
      reportDetails.totalActivePlants += 1
    }

    if (plant.status === 'Harvested' || plant.status === 'Archived') {
      reportDetails.totalHarvestedPlants += 1
    }

    if (plant.imgArray.length > 0) {
      reportDetails.avgImagesPerPlant += plant.imgArray.length
    }
  }

  // Calculate the average images per plant
  reportDetails.avgImagesPerPlant = Math.round(
    reportDetails.avgImagesPerPlant /
      (reportDetails.totalActivePlants + reportDetails.totalHarvestedPlants)
  )

  // Calculate the total average plants per user
  reportDetails.avgTotalPlantsPerUser = calculateAverage(
    reportDetails.totalActivePlants + reportDetails.totalHarvestedPlants,
    reportDetails.totalUsers
  )

  // Calculate the total average active plants per user
  reportDetails.avgActivePlantsPerUser = calculateAverage(
    reportDetails.totalActivePlants,
    reportDetails.totalUsers
  )

  // Save reportDetails to Firestore in the adminReports collection
  try {
    await projectFirestore.collection('adminReports').doc(reportDetails.id).set(reportDetails)
  } catch (error) {
    console.log('Error saving report to Firestore', error)
  }

  console.log('reportDetails', reportDetails)
  return reportDetails
}
