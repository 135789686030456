import React from 'react'
import DefaultNavbar from '../components/DefaultNavbar'
import DefaultFooter from '../components/DefaultFooter'
import Main from '../components/NotFound/Main'
import { useState } from 'react';
import { GApageView } from '../components/renderless/helpers';
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';

export default function NotFound({ user }) {    
    const { etgErrorDetected } = React.useContext(ErrorHandilingContext)
    // BEGIN DATA LOADING SECTION
    const [ id, setId ] = useState('notloggedin')
    if  ((user) && (id !== user.uid) ) {
        setId(user.uid)
    }
    
    // Load data from firestore
    
    
    // END DATA LOADING SECTION

    React.useEffect(() => {                
        GApageView('not-found-404')    
    }, [])
    
    return (
        <> { etgErrorDetected ? null : <>        
        <section className='flex flex-col h-screen justify-between'>

            <div className='relative w-full z-20'>
                <DefaultNavbar 
                    user={user} 
                    profileData={null} 
                />
            </div>
            {/* Page Specifc Content */}            
                <main className='my-10 w-10/12 mx-auto'>
                    <Main />
                </main>
            
            <DefaultFooter/>
            </section>
        </> } </>
        )
    }
    