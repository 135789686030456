import React from 'react'
import { useErrorHandiling }  from '../hooks/useErrorHandiling'
import ErrorModal from '../components/shared/global/errorHandiling/ErrorModal'

let ErrorHandilingContext
let { Provider } = (ErrorHandilingContext = React.createContext())

let ErrorHandilingProvider = ({ children }) => {
    let { etgErrorDetected, status, errMessage, updateErrorData }  = useErrorHandiling()
    return (
        <Provider value={{ etgErrorDetected, status, errMessage, updateErrorData }}>
            <ErrorModal status={status} etgErrorDectected={etgErrorDetected} errorMsg={errMessage} />
            {children}
        </Provider>
    )
}

export { ErrorHandilingContext, ErrorHandilingProvider }