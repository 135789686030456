import * as React from "react";
import { createPortal } from "react-dom";
import { FaCheck } from 'react-icons/fa'
import ETGButton from '../ETGButton'


const ErrorModal = ({ status, etgErrorDectected, errorMsg='an error has been detected' }) => {  

  function handlePageRefresh() {
    window.location.reload()
  }
  
  
  return etgErrorDectected ? createPortal(
        <section className="flex flex-col w-10/12 m-auto z-50 bg-white rounded-xl p-2 my-4">            
          {/* Title */}
          <div className="pt-8">
            <h2 className="text-red-400 underline underline-offset-2 etg-heading text-center uppercase" >EctorGrow has detected an error!</h2>
          </div>
          {/* Status And Message
           */}
          <div className="flex flex-col md:flex-row justify-evenly pt-8">
            {/* Status Spinner */}
            <div className="flex items-center m-auto py-10">              
            {(status === 'pending') ? <span className="w-24 h-24 border-l-2 border-green-600 rounded-full animate-spin"></span> : 
              <><div className="flex flex-col">
                  <div className="bg-emerald-200 w-fit p-3 rounded-full border-2 border-black/30 drop-shadow-xl m-auto">
                    <div></div><FaCheck size={'4rem'} color='green'/>
                  </div>
                  <p className="etg-systemText p-2">a report has been generated for us to review, thank you for your understanding</p>
              </div></>}
            </div>

            {/* Displayed Error Message */}
            <div className="flex flex-col justify-center items-center text-xl m-auto border-slate-600/50 border-2 border-dotted p-2 md:p-4 rounded-md">
              <p className="etg-sectionTitle text-center">{errorMsg}</p>
              <span className="pt-2 etg-descriptionText">error description, if available...</span>
              {(status === 'pending') ? <p className="etg-systemText">please wait while we generate an error report for EctorGrow to review</p> : 
              null}
            </div>
          </div>

          {/* Refresh Button Page */}
          <div className="flex flex-col w-fit justify-center text-2xl text-black m-auto pt-8">
            <div className="mx-auto">
              <ETGButton type='caution' btnText='Refresh Page' onClickFunction={handlePageRefresh}  />
            </div>            
            <p className="etg-systemText p-2">we apologize for the inconveninece - refresh the page to continue</p>
          </div>


        </section>,        
        document.getElementById('error-modal'),)        
    : null;
};
export default ErrorModal;