import React from 'react';
import DefaultNavbar from '../components/DefaultNavbar';
import DefaultFooter from '../components/DefaultFooter';
import FreeTierCard from '../components/register/FreeTierCard'
import RegisterForm from '../components/register/RegisterForm';
import { GApageView } from '../components/renderless/helpers';
import ProTierCard from '../components/register/ProTierCard';
import MailChimpFormContainer from '../components/newsletter/MailChimpFormContainer';
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';
import EctorGrowExplainer from '../components/home/EctorGrowExplainer';
import FeatureDisplay from '../components/home/FeatureDisplay';

export default function Register() {    
    const { etgErrorDetected } = React.useContext(ErrorHandilingContext)         
 

    

    React.useEffect(() => {                
        GApageView('register')         
        
    }, [])
          
    return (
        <> { etgErrorDetected ? null : <>    
        <section className='flex flex-col h-screen justify-between'>
            <div className='relative w-full z-20'>
                <DefaultNavbar />
            </div>
            <main className='mb-0 w-full mx-auto'>
                <div className='relative p-6 mt-8 text-center text-black bg-emerald-800 drop-shadow-xl'>
                    <div className='w-10/12 mx-auto p-4 rounded-md'>
                        <p className='text-white text-3xl tracking-wider font-bold uppercase'>Ector Grow Registration</p>
                        <p className='text-white text-xl py-4'>ELEVATE YOUR PERSONAL CANNABIS GROW with tools built specifically to help growers of all expereince levels organize, record, share and improve their personal cannabis grow</p>
                    </div>          
                    <div className='flex flex-col justify-center'>
                        <div className='bg-[#2d2d2d] text-white rounded-xl my-4'>
                            <FeatureDisplay />
                        </div>
                        <EctorGrowExplainer />
                    </div>  
                </div>              
                {/* section for three pricing cards */}
                <div className='flex flex-col md:flex-row justify-evenly gap-16 py-40 px-6 bg-etg-hexSVG border-b-8 border-emerald-800'>
                    <FreeTierCard />
                    <ProTierCard />                    
                </div>           
                {/* Registration Form */}
                <div className='flex justify-center pt-20'>
                    {/* Hide registration form in plave of temporary registration close */}
                    {/* <RegisterForm />                     */}


                    <div className='flex flex-col bg-slate-100 rounded-xl p-4 my-20'>
                        <p className='text-4xl text-black my-10'>
                            Registration for EctorGrow is currently closed
                        </p>
                    </div>
                </div>
                <div className='flex flex-col justify-center text-center bg-emerald-800 mt-10 rounded-md'>
                    <MailChimpFormContainer />
                </div>                
            </main>
            <DefaultFooter />
            </section>      
        </> } </>
    );
}
