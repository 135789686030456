import { EmailAuthProvider, getAuth, reauthenticateWithCredential } from 'firebase/auth'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import SaveInProgress from '../../shared/global/SaveInProgress'
import ETGButton from '../../shared/global/ETGButton'
import useMessageSender from '../../../hooks/useMessageSender'


export default function DeleteAccountView({ handleProfileModalToggle, updateErrorData, displayName }) {  
    const [ password, setPassword ] = React.useState('')
    const [ isLoading, setIsLoading ] = React.useState(false)
    const navigate = useNavigate()
    const auth = getAuth()
    const { sendEmail } = useMessageSender()
    
    
    function handlePasswordInputChange  ( passwordInput ) {
        setPassword(passwordInput)
    }

    

    const handleDeleteAccount = async () => {   
        const credential = EmailAuthProvider.credential(auth.currentUser.email, password)
        const userID = auth.currentUser.uid
        const userEmail = auth.currentUser.email

        reauthenticateWithCredential(auth.currentUser, credential).then(() => {

            const messageParams = {
                displayName: 'dfdf',
                email: userEmail
            }


            sendEmail(userEmail, 'accountDeletionEmail', messageParams, 'User Account', userID)
            // delete user
            auth.currentUser.delete().then(() => {            
                // **should redirect user to homepage - signedout


                navigate(0)
            }).catch((error) => {           
    
                if (error.code === 'auth/requires-recent-login') {                      
                    updateErrorData( 'Action requires re-authentication', auth.currentUser.uid)          
                    handleProfileModalToggle()
                } else  {                    
                    updateErrorData('Error Deleting Account - Our Team has been notified')
                    handleProfileModalToggle()                
                }
            })

        }).catch((error) => {
            if (error.code === 'auth/wrong-password') {                                      
                alert('Invalid Password - please try again')
                setIsLoading(false)                
            } else  {
                updateErrorData('Error Authenticating While Deleting Your Account')
                handleProfileModalToggle()                
            }            
        })


        
    }    
    
    

  return (<>
    {/* DisplayName Field */}
    <div className="flex flex-col w-10/12 my-8 rounded-xl self-center text-black">        
        {/* Warning Paragraph */}
        <div className='flex flex-col justify-center text-xl'>
            <h3 className='etg-sectionTitle'>Hey there, {auth.currentUser.displayName}</h3>
            <p className='etg-userText py-2'>
                We're sorry to see you go! We hope you had a good time using our app and found it helpful. 
                We understand that sometimes things change and it's time to move on. We want you to know that we're grateful for the time you spent with us and we'll 
                miss you! If you ever change your mind, we'd love to have you back.
            </p>
            <p className='flex justify-end'>
                Take care and best of luck!
            </p>
            <p className='flex justify-end pb-2'>
                EctorGrow
            </p>

        </div>
        {/* Input section & buttons */}
        <div className='flex flex-col bg-emerald-800/50 rounded-xl py-4 p-2'>
            <label className='w-10/12 md:w-full self-center py-2 text-lg md:text-2xl'>
                Password
            </label>
            <input
                type='password'
                placeholder={'password'}  
                value={password}                              
                className='etg-userText p-2 rounded-lg w-10/12 md:w-full self-center shadow-xl'
                onChange={(e) => handlePasswordInputChange(e.target.value)}
            />     
            <p className='p-2 etg-systemText'>sensitive actions, like permanently deleting your account and data, require your password</p>
        </div>

        {/* Buttons for Deleting/Cancel */}
        <div className='flex flex-col md:flex-row justify-around my-4'>
            {/* conditional save buttons */}
            { isLoading ? <SaveInProgress /> : <><ETGButton btnText='Permanently Delete My Account' type='caution' onClickFunction={handleDeleteAccount} />
                {/* cancel buttons */}
                <ETGButton btnText='Cancel' type='delete' onClickFunction={() => handleProfileModalToggle()} /></>
            }
        </div>
  </div>
  </>
  )
}
