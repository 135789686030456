import React, { useState } from 'react';
import CommentDisplay from './CommentDisplay';
import { getAuth } from 'firebase/auth';
import ProcessingDiv from '../../ProcessingDiv';
import { addComment } from './helpers/addComment';
import { timestamp } from '../../../firebase/config';
import { v4 as uuidV4 } from 'uuid';
import useAdminSupportTask from '../../../hooks/useAdminSupportTask';
import useMessageSender from '../../../hooks/useMessageSender';

const CommentSection = ({ contentID, comments, type, documentID, updateErrorData, getDisplayName, userData }) => {
  const [commentText, setCommentText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = getAuth().currentUser;
  const { addTask } = useAdminSupportTask();
  const { sendEmail } = useMessageSender();


  let displayType = '';
  switch (type) {
    case 'plant':
      displayType = 'plant';
      break;
    case 'nutrient-scheudle':
      displayType = 'nutrientSchedule';
      break;
    case 'user-post':
      displayType = 'userPost';
      break;
    default:
      break;
  }

  const handleAddComment = async () => {
    setIsLoading(true);
    const commentResponse = await addComment(documentID, displayType, commentText);

    if (commentResponse === 'error') {
      updateErrorData('Error adding comment', getAuth().currentUser.uid);
    } else {
      setCommentText('');
      setIsLoading(false);
    }
  };

  const handleFlagCommentReply = async ( commentID, replyID = null, type, flaggedUser) => {
    setIsLoading(true);
    
    // confirm user wants to flag comment
    const confirmFlag = window.confirm('Are you sure you want to flag this comment for review?');


    if (confirmFlag) {
      const today = new Date();
      const dateTimeFlagged = new timestamp(Math.round(today.getTime() / 1000), 0);

      const flagTask = {
        adminNotes: [],
        category: 'Flagged Comment-Reply',
        dateTimeLastUpdated: dateTimeFlagged,                
        dateTimeSubmitted: dateTimeFlagged,
        dateTimeCompleted: null,
        description: `Comment/Reply - from ${type} with ID:${contentID} - posted by ${flaggedUser} - flagged by ${currentUser.uid}`,
        flaggedContent: {
            contentID,
            displayType,
            contentType: type,
            commentID,
            replyID,
            flaggedUser,
            flaggedBy: currentUser.uid,
        },
        priority: 'High',
        status: 'Submitted',
        submittedBy: currentUser.uid,
        taskID: uuidV4(),
        title: 'Flagged Comment/Reply',
    }
      
    try {
      await addTask(flagTask);
      const userEmailData = {
        displayName: getDisplayName(currentUser.uid),
        contentType: 'Comment or Reply',
        taskID: flagTask.taskID,
      }

      await sendEmail(currentUser.email, 'flaggedContentUser', userEmailData, 'Flagged Content Confirmation', currentUser.uid);

      const adminEmailData = {
        displayName: getDisplayName(currentUser.uid),
        contentType: 'Comment or Reply',
        taskID: flagTask.taskID,
        description: flagTask.description,
      }

      await sendEmail('Team@EctorGrow.com', 'flaggedContentAdmin', adminEmailData, 'Flagged Content Notification');
      
    } catch (error) {
      updateErrorData('Error Flagging Content', currentUser.uid);
    }

    }
    setIsLoading(false);
  }

  
  if (isLoading) {
    return <ProcessingDiv message='Saving Changes' />;
  }


  return (
    <>
      <div className='flex flex-col mx-auto pb-4 w-full bg-slate-100 rounded-xl'>
        {/* Section Title */}
        <div className='text-black text-center'>
          <h4 className='text-2xl font-medium pb-4 underline underline-offset-2 uppercase tracking-wider'>Comments</h4>
        </div>

        {/* Display listed comments */}
        <div className="flex flex-col justify-end p-4">
          {comments.length > 0 ? comments.map((comment) => <CommentDisplay 
            contentID={contentID}
            key={comment.commentID} setIsLoading={setIsLoading} 
            comment={comment} updateErrorData={updateErrorData} 
            getDisplayName={getDisplayName}
            userData={userData}
            handleFlagCommentReply={handleFlagCommentReply}
          />) : <div className='text-black m-auto etg-systemText'><p>no comments yet</p></div>}
        </div>

        {/* Add a comment */}
        {currentUser ? (
          <div className="flex flex-col bg-emerald-800/50 rounded-xl m-4 justify-end p-4">
            <label htmlFor="commentLength" className='text-black etg-sectionTitle pb-2'>Add A Comment</label>
            <div className="flex flex-col justify-end">
              <textarea
                className="w-full text-black p-2 bg-slate-200 rounded outline-none focus:ring-2 focus:ring-green-600"
                placeholder="Add a comment..."
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
              <span className='flex justify-end etg-sysmteText text-center text-slate-800'>{commentText.length} | 400</span>              
            </div>

            {/* Button to add comment */}
            <div className="flex justify-end mt-4 space-x-2">
              <button
                className="flex justify-end px-4 py-2 rounded-xl bg-emerald-800 text-white font-medium transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
                onClick={handleAddComment}
              >
                Save Comment
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CommentSection;
