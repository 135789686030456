import React, { useState, useEffect } from 'react'

export default function EctorGrowLabel() {
    const [labelQuantity, setLabelQuantity] = useState(5);
    const [ orderTotal, setOrderTotal ] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);


    // use effect to update the order total whenever the label qunatity changes
    useEffect(() => {
        // default with $15 for Shipping & Processing Fee + the base $10 for 5 labels
        let orderTotal = 15
        // determine the number of additional labels
        const additionalLabels = labelQuantity - 5;
        // add $1.75 for each additional label
        orderTotal += additionalLabels * 1.75;
        // update the order total
        setOrderTotal(orderTotal);


        // setOrderTotal(labelQuantity * 1.75 + 5);
    }, [labelQuantity])

    // Function to update the label quantity with verification
  const updateLabelQuantity = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value >= 5 && value <= 25) {
      setLabelQuantity(value);
    } else {
      // Alert user if the entered value is invalid
      alert('Label quantity should be at least 5 and no more than 25.');
    }
  };

    //   Function to handle user checkout
    const handleCheckout = () => {
        // Set isProcessing to true
        setIsProcessing(true);
        // Create a new order object
        const order = {
            labelQuantity: labelQuantity,
            orderTotal: orderTotal,
        };
    }

        // Generate the payment with S



    
  return (<>
    <section class="text-gray-600 body-font">
        <div class="container mx-auto flex flex-col px-5 pt-24 pb-18 justify-center items-center">
            <div class="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
                <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">EctorGrow Labels</h1>
                <p class="mb-8 leading-relaxed">
                Enjoy Your Cannabis Plant's Journey, the benefits of an EctorGrow growlog and cannabis gifting with that extra Personal Touch! Get unique labels featuring your plant's strain 
                and a unique QR code connecting everyone to its captivating history! Share the love, joy, and harvest (and complete plant history) with your friends and family hassle-free. Also helpful for  
                effortlessly organizing a diverse harvest collection!
                </p>
                {/* mockup of ETG label that instead links user to  */}
                <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600"/>
                {/* area for label quantity input and checkout button */}
                <div class="flex flex-col w-full justify-center items-center">
                    <div class="flex flex-col py-4 items-center text-center">
                        <select
                            value={labelQuantity}
                            onChange={updateLabelQuantity}
                            className="w-full bg-gray-100 bg-opacity-50 rounded focus:ring-2 focus:ring-indigo-200 focus:bg-transparent border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            >
                            <option value="5">5 Labels - $15</option>
                            <option value="6">6 Labels - $16.75</option>
                            <option value="7">7 Labels - $18.50</option>
                            <option value="8">8 Labels - $20.25</option>
                            <option value="9">9 Labels - $22.00</option>
                            <option value="10">10 Labels - $23.75</option>
                            <option value="11">11 Labels - $25.50</option>
                            <option value="12">12 Labels - $27.25</option>
                            <option value="13">13 Labels - $29.00</option>
                            <option value="14">14 Labels - $30.75</option>
                            <option value="15">15 Labels - $32.50</option>
                            <option value="16">16 Labels - $34.25</option>
                            <option value="17">17 Labels - $36.00</option>
                            <option value="18">18 Labels - $37.75</option>
                            <option value="19">19 Labels - $39.50</option>
                            <option value="20">20 Labels - $41.25</option>
                            <option value="21">21 Labels - $43.00</option>
                            <option value="22">22 Labels - $44.75</option>
                            <option value="23">23 Labels - $46.50</option>
                            <option value="24">24 Labels - $48.25</option>
                            <option value="25">25 Labels - $50</option>
                            {/* Add more options up to 25 labels */}
                            </select>
                        <label class="text-sm text-gray-600">Label Quantity</label>
                    </div>
                    <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Checkout ${orderTotal}</button>
                </div>


            <p class="text-sm mt-2 text-gray-500 w-full">$10 for 5 labels - $1.75/additional label - $5 Shipping & Processing</p>
            <p class="text-sm mt-2 text-gray-500 w-full">Average delivery in 5-7 days - US Address Only</p>
            <p class="text-sm mt-2 text-gray-500 w-full">25 labels max/order</p>


            {/* Integration Details */}
            <div class="flex flex-wrap justify-around gap-6 pt-8">
                <div class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none">
                {/* Icon */}
                <div class="ml-4 flex items-center flex-col leading-none">
                    <p class="etg-subHeading">Square</p>
                    <p class="text-xs text-gray-600 mb-1">Payment Processing</p>
                </div>
                </div>
                
                {/* Printify */}
                <div class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none">
                {/* Icon */}
                <div class="ml-4 flex items-center flex-col leading-none">
                    <p class="etg-subHeading">Printify</p>
                    <p class="text-xs text-gray-600 mb-1">Printing & Shipping</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  </>)
}
