import { projectFirestore } from '../../../firebase/config'

export async function retrievePlants(phases, mediums) {
  
  // loadAll is used to load all schedules, regardless of phase and medium
  // this allows the schedules page to display the scheduleID from the URL - and its related schedules


  try {
    let snapshot;


    snapshot = await projectFirestore
    .collection('plants')
    .where('status', 'in', phases)
    .where('isPublic', '==', true)
    .where('medium', 'in', mediums)
    .get();    

    
    const arrayOfAllPlants = [];

    snapshot.docs.forEach((doc) => {
      const nutrient = { ...doc.data() };

      arrayOfAllPlants.push(nutrient);
    });
     const plantData = { arrayOfAllPlants }; 
    return plantData;
  } catch (err) {    
    return [];
  }


  
}

export default retrievePlants;
