import React from 'react'

// Icons
import { MdOutlineAccountCircle } from 'react-icons/md'
import { GiFlowerPot } from 'react-icons/gi'
import { TbRulerMeasure } from 'react-icons/tb'

export default function EctorGrowExplainer() {
  return ( <>
    <section className="bg-slate-200 body-font rounded-xl">
        <div className='flex justify-center px-4 pt-10 '>
            <h3 className='text-4xl text-green-800 text-center'>
                Cannabis Cultivation Made Easy With EctorGrow
            </h3>
        </div>
        
  <div className="container px-5 py-20 justify-center mx-auto flex flex-col">
    <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
      <img alt="feature" 
        className="m-auto rounded-xl h-10/12 shadow-xl border-2 border-slate-800" 
      src="https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FETG_HomeExplainerLog.gif?alt=media&token=6f0cdddb-237f-4f7c-9008-4a021712cbed" />
    </div>
    {/* Getting Started is Easy Title */}
    <div className='flex justify-center'>
      <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">Getting Started With EctorGrow</h1>      
    </div>

    <section className="text-gray-600 body-font">
  <div className="container px-5 py-16 mx-auto">
    <div className="flex flex-wrap -m-4">      
      <div className="p-4 lg:w-1/3 flex flex-col">
        <div className="h-full flex flex-col justify-center items-center bg-gray-100 bg-opacity-75 px-8 pt-10 pb-16 rounded-lg overflow-hidden text-center relative">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1 uppercase">Step 1</h2>
          <div className='rounded-full p-3 bg-emerald-800/50 text-black text-2xl my-4 shadow-xl'>
              {/* Icon */}
              <MdOutlineAccountCircle />
          </div>
            
          <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">Register An Account</h1>
          <p className="leading-relaxed mb-3">Register a free account - this is when you can choose your exclusive EctorGrow Display Name. <span className='etg-systemText'>EctorGrow.com/grows/YOURDISPLAYNAME</span></p>          
          
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        <div className="h-full flex flex-col justify-center items-center bg-gray-100 bg-opacity-75 px-8 pt-10 pb-16 rounded-lg overflow-hidden text-center relative">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1 uppercase">Step 2</h2>
          <div className='rounded-full p-3 bg-emerald-800/50 text-black text-2xl my-4 shadow-xl'>
              {/* Icon */}
              <TbRulerMeasure />
          </div>
            
          <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">Define Your Grow Space</h1>
          <p className="leading-relaxed mb-3">Simply name a Location you plan to grow in, like a 'Veg Grow Tent' or 'Backyard' and the Positions in that location.</p>          
          
        </div>
      </div>
      <div className="p-4 lg:w-1/3">
        <div className="h-full flex flex-col justify-center items-center bg-gray-100 bg-opacity-75 px-8 pt-10 pb-16 rounded-lg overflow-hidden text-center relative">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1 uppercase">Step 3</h2>
          <div className='rounded-full p-3 bg-emerald-800/50 text-black text-2xl my-4 shadow-xl'>
              {/* Icon */}
              <GiFlowerPot />
          </div>
            
          <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">Add Your Plants</h1>
          <p className="leading-relaxed mb-3">Start adding your plants and kick off their growth story using the growlog. Let the EctorGrow community be a part of your journey, where you can display your plants, engage in conversations, seek guidance, and savor the experience.</p>          
          
        </div>
      </div>
    </div>
  </div>
</section>    
  </div>
</section>
  </>)
}
