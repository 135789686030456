import DefaultNavbar from '../components/DefaultNavbar'
import DefaultFooter from '../components/DefaultFooter'
import React, { useState } from 'react';
import { useAppData } from '../hooks/useAppData';
import LoadingDiv from '../components/LoadingDiv';
import ProcessingDiv from '../components/ProcessingDiv';
import { getAuth } from 'firebase/auth';

// styles
import AdminDashboard from '../components/admin/AdminDashboard';
import AdminTaskView from '../components/admin/AdminTaskView';
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';
import AdminReportView from '../components/admin/AdminReportView';
import AdminMaintenance from '../components/admin/AdminMaintenance';
import AdminUserMangementView from '../components/admin/AdminUserMangementView';
import AdminEctorGrowLabelView from '../components/admin/AdminEctorGrowLabelView';

export default function Admin({ user }) {
    const [ adminView, setAdminView ] = useState('adminReport')
    const { etgErrorDetected, updateErrorData } = React.useContext(ErrorHandilingContext)
    let isAdminUser = false
    const currentUser = getAuth().currentUser
    

    // BEGIN DATA LOADING SECTION
    const [ id, setId ] = useState('notloggedin')
    if  ((user) && (id !== user.uid) ) {
        setId(user.uid)
    }
    
    // Load data from firestore
    const { profileDocument, profileDocumentError } = useAppData(id)
    
    // Display while loading data
    if ((!profileDocument) && (user)) {
        return <LoadingDiv />
    } 

    // Display data loading errors
    if ( profileDocumentError ) {
        // if error is because document doesn't exist continue rendering
        // if actual error, display error processing div
        if (profileDocumentError !== 'No such document exists') {            
            updateErrorData('Profile Document Error - Admin', currentUser.uid)
        }
    }
    // END DATA LOADING SECTION

    // MAINTAIN THIS CODE AS PART OF DEV AND ADMIN ACCESS CONTROL
    // while user does not have correct access level, display error message
    if (profileDocument && !isAdminUser) {    
        if (profileDocument.accessTier === 9) {
        isAdminUser = true
        } else {
            return <ProcessingDiv message={'This page is only available to EctorGrow Administrators.'} type='error'   />      
        }
    }

    
    return (
        <> { etgErrorDetected ? null : 
        <section className='flex flex-col h-screen justify-between'>
            <div className='w-full z-20'>
                <DefaultNavbar 
                user={user}
                profileData={profileDocument}  
                />
            </div>
            <main className='flex flex-col my-10 justify-start'>            
                <AdminDashboard setAdminView={setAdminView} />
                { adminView === 'adminTasks' ? <AdminTaskView /> : 
                 adminView === 'adminReport' ? <AdminReportView /> :
                 adminView === 'maintenance' ? <AdminMaintenance /> :
                 adminView === 'userManagement' ? <AdminUserMangementView /> :
                 adminView === 'labelOrderManagement' ? <AdminEctorGrowLabelView />
                 : null }
            </main>
            <DefaultFooter/>
        </section>
} </>
    )
}
 