

export function testEmail( emailInput ) {
    let results = {
        isValid: false,
        currentEmail: ''
    }
    // convert to lower case
    emailInput = emailInput.toLowerCase();
    // set displayed name to user entered text  
    results.currentEmail = emailInput
    
    // check for valid email format
    let isAValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(emailInput);
    if (!isAValidEmail) { 
        results.isValid = false; 
    } else {
        results.isValid = true
    }    

    return results
}
  
  