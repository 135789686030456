import React, { useState } from 'react'
import DatePicker from 'react-date-picker'
import { BiCut } from 'react-icons/bi'
import { IoWaterOutline } from 'react-icons/io5'
import { RiGuideLine } from 'react-icons/ri'
import { GrPowerCycle, GrScorecard } from 'react-icons/gr'
import { TbPlant } from 'react-icons/tb'
import ETGButton from '../../../../shared/global/ETGButton'
import { BsPersonFill } from 'react-icons/bs'
import { GiNoseFront } from 'react-icons/gi'


export default function NoteEdit({ 
    note,
    setEditNote, 
    index, 
    updateSelectedPlantNote, 
    deleteNote, 
    appData }) {
        const [ icon, setIcon ] = useState(<TbPlant />)
        const [ noteTitle, setNoteTitle ] = useState('Editing Your Note')
        const [selectedRating, setSelectedRating] = useState(1);
        const [ budSize, setBudSize ] = useState('Tiny')
    
        const handleRatingChange = (e) => {
            const newRating = parseInt(e.target.value); // Parse the selected value to an integer
            setSelectedRating(newRating); // Update the selectedRating state with the new rating
          };
    let tempDate = note.date.toDate()     
    
       
    const [ newNote, setNewNote ] = useState(note.comment)
    const [ noteDate, setNoteDate ] = useState(tempDate)
    const [ newPositionStart, setNewPositionStart ] = useState(note.positionStart)
    const [ newLocationStart, setNewLocationStart ] = useState(note.locationStart)
    const [ newPositionEnd, setNewPositionEnd ] = useState(note.positionEnd)
    const [ newLocationEnd, setNewLocationEnd ] = useState(note.locationEnd)
    const [ allowEditNoteText, setAllowEditNoteText ] = useState(false)


    let noteCharCount = newNote.length

     // handle comment going over 100 characters
     if (noteCharCount > 100) {    
        setNewNote(newNote.slice(0, -1))        
    }


    React.useEffect(() => {
        switch (note.type) {
            case 'lc-started':
                setNoteTitle('Editing Start Date')
                setIcon(<TbPlant />)
                break;
            case 'lc-transfer':
                setNoteTitle('Editing Transfer Date')
                setIcon(<TbPlant />)
                break;
            case 'lc-water':
                setNoteTitle('Editing Watering Note')
                setIcon(<IoWaterOutline />)
                break;
            case 'lc-harvest':
                setNoteTitle('Editing Harvest Date')
                setIcon(<TbPlant />)
                break;
            case 'harvest-wet-trim':
                setNoteTitle('Editing Wet Trim Date')
                setAllowEditNoteText(true)
                setIcon(<BiCut />)
                break;
            case 'harvest-dry-trim':
                setNoteTitle('Editing Dry Trim Date')
                setAllowEditNoteText(true)
                setIcon(<BiCut />)
                break;
            case 'lc-move':
                setNoteTitle('Editing Plant Movement')
                setIcon(<TbPlant />)
                break;                
            case 'lc-trim':
                setNoteTitle('Editing Plant Trimming Note')
                setIcon(<BiCut />)
                setAllowEditNoteText(true)
                break;
            case 'lc-training':
                setNoteTitle('Editing Plant Training Note')
                setIcon(<RiGuideLine />)
                setAllowEditNoteText(true)
                break;
            case 'lc-nutrient':
                setNoteTitle('Editing Nutrient Reset Note')
                setIcon(<GrPowerCycle />)
                break;
            case 'u':
                // user note
                setNoteTitle('Editing A Note')
                setIcon(<BsPersonFill />)
                setAllowEditNoteText(true)
                break;
            case 'aroma':
                // user note about aroma
                setNoteTitle('Describe the plants aroma')
                setIcon(<GiNoseFront />)
                setAllowEditNoteText(true)
                break;
            case 'review':
                // user review
                setNoteTitle('Review Your Harvest')
                setIcon(<GrScorecard />)
                setAllowEditNoteText(true)
                break;
            default:
                setNoteTitle('Editing Your Note')
                break;
        }
    }, [note.type])

    const locations = appData.profileData.growLocations.map((location, index) => <option key={`location:${index}`}>{location}</option>)
    const positions = appData.profileData.growPositions.map((position, index) => <option key={`position:${index}`}>{position}</option>)
    
    React.useEffect(() => {
        // if the note has a .rating set the selectedRating to that value
        if (note.rating) {
            setSelectedRating(note.rating)
        }
        // if the note has a .budSize set the budSize to that value
        if (note.budSize) {
            setBudSize(note.budSize)
        }
    }, [note.rating, note.budSize])

    

  return (
    <>
        <div className='flex flex-col'>
            <div className='flex flex-wrap justify-around py-4 text-lg'>
                <span className='p-2 bg-emerald-800/50 rounded-full shadow-xl'>
                    {icon}
                </span>
                <p>{noteTitle}</p>
            </div>                       
            { allowEditNoteText && 
                <div className='flex-row py-4'>
                    <textarea
                    className="bg-slate-300 w-full px-4 py-2 rounded-xl border-black"
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                    rows={4} // Set the number of rows you want to display                    
                    />
                    <p className='text-sm pt-4'>{noteCharCount} of 100 characters</p>
                    {note.type === 'review' ? <p className="etg-systemText px-4 pt-2">
                    <span className='font-bold'>congratulations</span>, it's time to review your results with this plant, don't forget to include details like: 
                    taste, aroma, effect and potentcy</p> : null}
                </div>
            }
            {/* EDIT LOCATION/POSITON START IF TYPE IS 'lc-started' || 'lc-transfer' || 'lc-move' */}
            {((note.type === 'lc-started') || (note.type === 'lc-transfer') || (note.type === 'lc-move')) && <>
            {/* START/FROM */}
            <div className='flex flex-row justify-evenly py-3'>
                <div className='text-xs'>
                    {note.type === 'lc-started' ? <p>Started</p> : <p>From</p>}
                    <p>Location: {note.locationStart}</p>  
                    <p>Position: {note.positionStart}</p>  
                </div>   
                <div className='text-xs flex flex-col'>
                    <p>Updated</p>  
                    {/* Location */}
                    <select value={newLocationStart} onChange={(e) => setNewLocationStart(e.target.value)}>
                    {locations}
                    </select>
                    {/* Position */}
                    <select value={newPositionStart} onChange={(e) => setNewPositionStart(e.target.value)}>
                    {positions}
                    </select>                    
                </div>   
            </div>
            {/* EDIT LOCATION/POSITON END IF TYPE IS 'lc-transfer' || 'lc-move' */}            
            {((note.type === 'lc-transfer') || (note.type === 'lc-move')) && <>
                <div className='flex flex-row justify-evenly py-3'>
                    <div className='text-xs'>
                        <p>Ended</p>
                        <p>Location: {note.locationEnd}</p>  
                        <p>Position: {note.positionEnd}</p>  
                    </div>   
                    <div className='text-xs flex flex-col'>
                        <p>Updated</p>  
                        {/* Location */}
                        <select value={newLocationEnd} onChange={(e) => setNewLocationEnd(e.target.value)}>
                        {locations}
                        </select>
                        {/* Position */}
                        <select value={newPositionEnd} onChange={(e) => setNewPositionEnd(e.target.value)}>
                        {positions}
                        </select>                    
                    </div>   
                </div>
            </>}
            {((note.type === 'u') || (note.type === 'lc-harvest'))}
            <div><p className='text-xs pt-4 italic'>*Only showing current Locations/Positions</p></div>
            </>}
            {/* plant rating & bud sizes, if user is reviewing plant */}
            { note.type === 'review' ? <>
                <div className='flex flex-col justify-around py-4 bg-slate-300 rounded-xl mx-4'>
                    {/* Label & Select */}
                    <div className='flex flex-wrap justify-around'>
                        <label className='text-lg'>Harvest Rating:</label>
                        <select
                            className="shadow-xl text-lg p-1 text-center rounded-xl"
                            value={selectedRating} // Bind the selectedRating state to the value of the select element
                            onChange={handleRatingChange} // Call the handleRatingChange function on change
                        >
                            {/* Use a loop to generate options 1 to 10 */}
                            {Array.from({ length: 10 }, (_, index) => index + 1).map((rating) => (
                            <option key={rating} value={rating}>
                                {rating}
                            </option>
                            ))}
                        </select>

                    </div>
                    {/* Rating Descroptions */}
                    <div className='flex flex-col justify-around'>
                        <p className='py-2'>Rating Descriptions:</p>
                        <div className='flex flex-col etg-systemText'> 
                            <p>1 - Would not Recommend</p>
                            <p>3 - Effective but not very enjoyable</p>
                            <p>5 - Average budget to mid shelf </p>
                            <p>7 - Better than average</p>
                            <p>10 - Best of the best</p>
                        </div>                                        
                    </div>

                </div>
                {/* Bud Size */}
                <div className='flex flex-col justify-around py-4'>
                    <label className='text-lg'>Bud Size:</label>
                    <select
                        className="shadow-xl text-lg p-1 text-center rounded-xl bg-slate-300"
                        value={budSize} // Bind the selectedRating state to the value of the select element
                        onChange={(e) => setBudSize(e.target.value)} // Call the handleRatingChange function on change
                    >   
                        <option value='Tiny'>Tiny</option>
                        <option value='Small'>Small</option>
                        <option value='Regular'>Regular</option>
                        <option value='Big'>Big</option>
                        <option value='Jumbo'>Jumbo</option>
                    </select>
                </div>
                    
            </> : null}
            <div className='flex-row pb-2'>
                <p>Date:</p>
                <DatePicker value={noteDate} onChange={(e) => setNoteDate(e)} />
            </div> 
            
        </div>


        {/* EDIT NOTE ACTIONS - when buttons are clicked, conditional rendering is handeled by processing div in NoteLog */}
        <div className='flex flex-wrap justify-center py-4 mb-6'>
            <div>                
                
                <ETGButton
                    onClickFunction={() => updateSelectedPlantNote( index, noteDate, newNote, note.type, newPositionStart, newPositionEnd, newLocationStart, newLocationEnd, selectedRating, budSize )}
                    btnText='Save Changes'
                />                         
            </div>
            
            <ETGButton 
                onClickFunction={() => setEditNote(false)}
                btnText='Cancel Changes'
            />
            {/* DONT ALLOW DELETE ONLY FOR TYPE 'lc-started' || 'lc-transfer' || 'lc-harvest' */}
            {((note.type !== 'lc-started') && (note.type !== 'lc-transfer') && (note.type !== 'lc-harvest')) && <>
                <div>
                    <ETGButton 
                        onClickFunction={() => deleteNote(index)}
                        type='delete'
                        btnText='Delete Note'
                    />                
                </div>                
            </>}
        </div>
    </>
  )
}
