import React from 'react'
import { timestampToDate } from '../renderless/helpers';
import ETGButton from '../shared/global/ETGButton';
import { projectFirestore, projectStorage, timestamp } from '../../firebase/config';
import CommentSection from '../shared/Chat/CommentSection';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { NavLink } from 'react-router-dom';
import { toolbarOptions } from '../../Data/ToolbarOptions';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import Slider from 'react-slick';
import useAdminSupportTask from '../../hooks/useAdminSupportTask';
import { v4 as uuidV4 } from 'uuid';
import useMessageSender from '../../hooks/useMessageSender';




export default function SocialPostViews({ socialPost, currentUser, updateErrorData, getDisplayName, getUserThumbnail, userData, setIsPageLoading, userDisplayNames, setDocumentToLoad }) { 
    const [ commentData, setCommentData ] = React.useState([])
    const [ category, setCategory ] = React.useState(socialPost.category)
    const [ footerDisplay, setFooterDisplay ] = React.useState(null)
    const [ editPost, setEditPost ] = React.useState(false)
    const [ hidePost, setHidePost ] = React.useState(false)
    const [ currentImageIndex, setCurrentImageIndex ] = React.useState(0)
    const [ editorState, setEditorState ] = React.useState(EditorState.createWithContent(convertFromRaw(JSON.parse(socialPost.rawPostText))))
    const [panPosition, setPanPosition] = React.useState({ x: 50, y: 50 });
    const sliderRef = React.useRef(null);

    const { addTask } = useAdminSupportTask()
    const { sendEmail } = useMessageSender()

    React.useEffect(() => {
        const commentQuery = projectFirestore
          .collection('userComments')
          .where('documentID', '==', socialPost.postID)
      
        const unsubscribe = commentQuery.onSnapshot((querySnapshot) => {
          let tempCommentData = [];
          querySnapshot.forEach((doc) => {
            tempCommentData.push(doc.data());
          });
          setCommentData(tempCommentData);
        });
      
        return () => {
          unsubscribe(); // Cleanup function to unsubscribe from the snapshot listener
        };
    }, [socialPost.postID]);

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };  
    
    
      
    const handleSaveChanges = async () => {
        setIsPageLoading(true)
        const contentState = editorState.getCurrentContent();
        const rawPostText = JSON.stringify(convertToRaw(contentState));
        // include additioinal date edited field
        const dateTimeEdited = new Date()
        const updatedPost = {
            ...socialPost,
            rawPostText,
            category,
            dateTimeEdited: new timestamp(Math.round(dateTimeEdited.getTime()/1000), 0),
        }
        try {
            await projectFirestore.collection('socialPosts').doc(updatedPost.postID).set(updatedPost)
        } catch (error) {
            updateErrorData(error, currentUser.uid)
        }
        setIsPageLoading(false)
        setEditPost(false)
                   
    }

    const toggleEditPost = () => {
        if (editPost) {
            setEditPost(false)
            // reset the editor state
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(socialPost.rawPostText))))
        } else {
            setEditPost(true)
        }
    }

    


    const handleDeletePost = async () => {
        setIsPageLoading(true)
        // delete the post
        // first confirm that the user wants to delete the post
        // then delete the post

        // confirm that the user wants to delete the post
        const confirmDelete = window.confirm('Are you sure you want to delete this post?')
        if (confirmDelete) {
            // delete the post
            try {
                // if there is a postImages array, delete the images from firebase storage
                if (socialPost.postImages) {
                    socialPost.postImages.forEach((image) => {
                        const storageRef = projectStorage.ref(`socialPostImages/${socialPost.postID}/${image.imageID}`);
                        storageRef.delete();
                    })
                }


                await projectFirestore.collection('socialPosts').doc(socialPost.postID).delete()
                setHidePost(true)                            
            } catch (error) {
                updateErrorData(error, currentUser.uid)
            }
        }
        setIsPageLoading(false)
    }

    const handleFlagPost = async () => {
        // flag the post
        setIsPageLoading(true)
        // first confirm that the user wants to flag the post
        // then flag the post
        const confirmFlag = window.confirm('Are you sure you want to flag this post? This should be done for offensive content or innapropriate posts only.')
        if (confirmFlag) {
            // flag the post
            const today = new Date()
            const dateTimeFlagged = new timestamp(Math.round(today.getTime()/1000), 0)
            // build AdminSupportTask object
            const flagTask = {
                adminNotes: [],
                category: 'Flagged Social Post',
                dateTimeLastUpdated: dateTimeFlagged,                
                dateTimeSubmitted: dateTimeFlagged,
                dateTimeCompleted: null,
                description: `Social Post - ${socialPost.postID} - ${socialPost.title} - posted by ${socialPost.postedByUID} - flagged by ${currentUser.uid}`,
                flaggedContent: {
                    contentID: socialPost.postID,
                    contentType: 'Social Post',
                    contentOwner: socialPost.postedByUID,
                    flaggedBy: currentUser.uid
                },
                priority: 'High',
                status: 'Submitted',
                submittedBy: currentUser.uid,
                taskID: uuidV4(),
                title: 'Flagged Social Post',
            }

            try {
                await addTask(flagTask)

                // send email to user confirming flagged post
                const userEmailData = {
                    displayName: getDisplayName(currentUser.uid),
                    contentType: 'Social Post',
                    taskID: flagTask.taskID,
                }
                await sendEmail(currentUser.email, 'flaggedContentUser', userEmailData, 'Flagged Content Confirmation', currentUser.uid)

                // send email to admin notifying of flagged post
                const adminEmailData = {
                    displayName: getDisplayName(currentUser.uid),
                    contentType: 'Social Post',
                    taskID: flagTask.taskID,
                    description: flagTask.description,
                }
                await sendEmail('Team@EctorGrow.com', 'flaggedContentAdmin', adminEmailData, 'Flagged Content Notification')

                
            } catch (error) {
                console.log('error', error)
            }

            // const flaggedPost = `Social Post - ${socialPost.postID} - ${socialPost.title} - posted by ${socialPost.postedByUID} - flagged by ${currentUser.uid}`
            // const result = await newSupportRequest('Comment', flaggedPost, currentUser.uid, 'high')            

            // if (result === 'error') {
            //   updateErrorData('Error reporting comment', currentUser.uid)
            // }
            setHidePost(true)
            alert('This post has been flagged - it may temporarily still be visible - but a moderator will review it shortly. Thank you for helping to keep the community safe.')
        }
        setIsPageLoading(false)
    }
    

    

    const handleCommentsClick = () => {
        if (footerDisplay === 'comments') {
            setFooterDisplay(null)
        } else {
            setFooterDisplay('comments')
        }
    }    

    // function to copy the link to the social post
    const handleCopyPostLink = () => {
        const postLink = `${window.location.origin}/community/so${socialPost.postID}`
        navigator.clipboard.writeText(postLink)
        alert('Link copied to clipboard')
    }

    const handleImageMouseMove = (e) => {
    const { offsetX, offsetY, target } = e;
    const { clientWidth, clientHeight } = target;

    const x = (offsetX / clientWidth) * 100;
    const y = (offsetY / clientHeight) * 100;

    setPanPosition({ x, y });
  };

  const handleImageTouchMove = (e) => {
    const touch = e.touches[0];
    const { clientX, clientY, target } = touch;
    const { clientWidth, clientHeight } = target;

    const x = (clientX / clientWidth) * 100;
    const y = (clientY / clientHeight) * 100;

    setPanPosition({ x, y });
  };

    const transformWrapper = (image, index) => (        
    <TransformWrapper
      defaultPositionX={panPosition.x}
      defaultPositionY={panPosition.y}
      options={{
        limitToBounds: false,
        minScale: 0.5,
        maxScale: 3,
        limitToWrapperBounds: true,
        preventDefault: false, // Prevent page scrolling on image zoom
      }}
      pan={{
        disabledOnDoubleClick: false,
      }}
      pinch={{ disabled: false }}
      wheel={{ disabled: false }}
      doubleClick={{ disabled: false }}
      pinchRotate={{ disabled: false }}      
      key={index}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <TransformComponent>
            <img
              src={image.downloadURL}
              alt={image.caption}              
              onMouseMove={handleImageMouseMove}
              onTouchMove={handleImageTouchMove}
              className={`max-w-full max-h-[650px] p-2`}
            />
          </TransformComponent>
          <div className='flex justify-between'>
            
            { socialPost.postImages.length > 1 ? <button className='p-1 bg-slate-200 rounded-xl' onClick={() => sliderRef.current.slickPrev()}>Previous Image</button> : null }
            <p className='px-4 text-xl font-bold'>{image.caption}</p>
            { socialPost.postImages.length > 1 ? <button className='p-1 bg-slate-200 rounded-xl' onClick={() => sliderRef.current.slickNext()}>Next Image</button> : null }
          </div>          
        </React.Fragment>
      )}
    </TransformWrapper>
  );

    //  bug with this funciton, not updating the current image data causing confusion for users
//   const handleDeleteImage = (imageIndex) => {
//     // delete the image from firebase storage
//     try {
//       const storageRef = projectStorage.ref(`socialPostImages/${socialPost.postID}/${socialPost.postImages[imageIndex].imageID}`);
//       storageRef.delete();
//       console.log('image deleted')      
//     } catch (error) {
//       console.log('error', error)
//     }
//     // create a new array with all images except the one at the index passed in
//     const newPostImages = socialPost.postImages.filter((image, index) => index !== imageIndex);
    
//     // update the firestpre document with the new array
//     try {
//       projectFirestore.collection('socialPosts').doc(socialPost.postID).update({
//         postImages: newPostImages
//       })
//       console.log('image removed from firestore')
//     //   update socialPost with the new array
//         socialPost.postImages = newPostImages
//     } catch (error) {
//       console.log('error', error)
//     }
//   };

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    lazyLoad: true,         
    style: { width: '100%', height: '100%' },        
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: setCurrentImageIndex, // Update the currentImageIndex after slide change
    initialSlide: currentImageIndex, // Set the initial slide to the currentImageIndex
  };
  
  
  return (<>
  <div key={socialPost.postID} className={`flex flex-col bg-white ${hidePost ? 'hidden' : null} my-2 border-2 border-solid border-emerald-800 rounded-xl w-full`}>
    <div className="flex flex-col items-center px-4 py-2">
        {/* Description Div */}
        <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="flex mt-4">
                <h2 className="etg-sectionTitle text-2xl break-all" >
                    {socialPost.title}
                </h2>
            </div>
        </div>
        <div className='flex flex-wrap justify-center'>
            {/* category */}
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mt-2">
                {category}
            </span>
            {/* date posted */}
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mt-2">
                {timestampToDate(socialPost.dateTimePosted)}
            </span>
            {/* date edited, if property exists */}
            {socialPost.dateTimeEdited ? <>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mt-2">
                    Edited: {timestampToDate(socialPost.dateTimeEdited)}
                </span>
            </> : <></>}


        </div>
        {/* Slider Div */}
        {/* Display image count if more than 0  */}          
            {/* Image Display Slider */}
            { ( socialPost.postImages && socialPost.postImages.length > 0 ) ? <>
              <div className='flex flex-col bg-emerald-800/50 p-4 rounded-xl w-full shadow-xl max-h-[780px] my-10'>                                  
                <Slider key={currentImageIndex} {...sliderSettings} ref={sliderRef}>
                  {socialPost.postImages.map((image, index) => (
                    <div key={index}>
                      <div className="flex flex-col items-center justify-center">
                        {transformWrapper(image, index)}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              {/* button to delete image */}
              {/* { currentUser && editPost && currentUser.uid === socialPost.postedByUID ? <>
                <div>
                    <button className='mt-2 p-1 bg-red-200 rounded-xl' onClick={() => handleDeleteImage(currentImageIndex)}>Delete Image</button>
                </div>
              </> : null} */}
            </> : null }



    </div>

    {/* Post Text / Buttons and posted by profile */}
    <div className="relative mx-auto w-full">        
        <div className="p-4 mx-4 mb-2">
            <div className="flex justify-center relative rounded-lg overflow-hidden h-2/5">                                

            <span className="absolute top-0 left-0 inline-flex mt-3 ml-3 px-3 py-2 rounded-lg z-10 bg-red-500 text-sm font-medium text-white select-none">
                {socialPost.category}
            </span>
            </div>            


            { editPost ? <>
                <div className="mb-4 text-black">
                <label htmlFor="category" className="block font-medium mb-1">
                    Category
                </label>
                <select
                    id="category"
                    className="w-full border-gray-300 rounded-md p-2"
                    value={category}
                    onChange={handleCategoryChange}
                    required
                >
                    <option value="General">General</option>
                    <option value="Question">Question</option>
                    <option value="Random">Random</option>
                    <option value="Hardware">Hardware</option>
                    <option value="Other">Other</option>
                </select>
                </div>
            <div className="mt-4 text-black bg-slate-300 rounded-xl">
                <Editor
                    editorClassName="bg-gray-300 rounded-md p-2 "
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    toolbar={toolbarOptions}
                    maxLength={10000}
                    mention={{
                        separator: ' ',
                        trigger: '@',
                        suggestions: userDisplayNames,
                    }}
                />
            </div>
            <label htmlFor="postText" className="flex justify-end font-medium mb-1 p-2">
                <span className='etg-descriptionText'>{10000 - editorState.getCurrentContent().getPlainText('').length} of 10000 characters remaining</span>
            </label>
            </> : <>
            <div className="mt-4 text-black bg-slate-300 rounded-xl">            
                <Editor
                    editorClassName="bg-gray-300 rounded-md p-2"
                    toolbarHidden
                    editorState={editorState}
                    readOnly={true} // or set it to false if you want to enable editing
                    mention={{
                        separator: ' ',
                        trigger: '@',
                        suggestions: userDisplayNames,
                    }}
                    />                
            </div>
            </>}
            
            {/* Display buttons for comments, edit/cancel edit delete and save changes */}
            <div className="flex flex-wrap justify-center">
                <div className='flex justify-center mt-8'>                    
                    <ETGButton                    
                        onClickFunction={handleCommentsClick}
                        icon='chat'
                        btnText='Comments'
                    />                                   
                </div>                
                <div className='flex justify-center mt-8'>                    
                    <ETGButton                    
                        onClickFunction={handleCopyPostLink}                        
                        btnText={`Copy Link`}
                    />                                   
                </div>                
            
                {/* Display buttons if socialPost is being viewed by the owner of the social post */}
                {( currentUser && currentUser.uid) === socialPost.postedByUID && <>
                    { editPost ? <>
                    <div className="flex justify-center mt-8">
                        <ETGButton
                            onClickFunction={() => handleSaveChanges()}
                            type='caution'
                            btnText='Save Changes'
                            />
                    </div>
                    <div className="flex justify-center mt-8">
                        <ETGButton
                            onClickFunction={() => handleDeletePost()}
                            type='delete'
                            btnText={`Delete Post`}
                        />
                    </div>
                    </> : <></>}

                    <div className="flex justify-center mt-8">
                        <ETGButton
                            onClickFunction={() => toggleEditPost()}
                            type='edit'
                            btnText={`${editPost ? "Cancel Changes" : "Edit Post"}`}
                        />
                    </div>


            </>}

            {/* Display buttons if socialPost is being viewed by a logged in user who is not the owner */}
            {/* allow the user to flag the socialPost */}
            { currentUser && currentUser.uid !== socialPost.postedByUID && <>            
                <div className="flex justify-center mt-8">
                    <ETGButton
                        onClickFunction={() => handleFlagPost()}
                        type='caution'
                        btnText='Flag Post'
                    />
                </div>
            </>}


            </div>



            {/* Footer for Comments */}
            { footerDisplay === 'comments' ? <>
                <div className={`flex-row text-black px-4 m-4 w-full mx-auto`}>
                    <CommentSection
                        comments={commentData}                
                        type='user-post'
                        documentID={socialPost.postID}
                        updateErrorData={updateErrorData}
                        getDisplayName={getDisplayName}
                        userData={userData}
                        contentID={socialPost.postID}
                    />
            </div> </> : <></>}


            <div className="flex flex-col justify-center items-center mt-4">
                <NavLink to={`/grows/${getDisplayName(socialPost.postedByUID)}`} >
                <div className="rounded-full w-6 h-6 md:w-8 md:h-8 bg-gray-200 m-auto">
                    <img className="rounded-full w-6 h-6 md:w-8 md:h-8" src={getUserThumbnail(socialPost.postedByUID, userData)} alt="Profile" />
                    <span className="absolute top-0 right-0 inline-block w-3 h-3 bg-primary-red rounded-full"></span>
                </div>
                <p className="ml-2 text-gray-800">
                    {getDisplayName(socialPost.postedByUID)}
                </p>
                </NavLink>
            </div>
        </div>        
    </div>

    
</div>
</>)
}
