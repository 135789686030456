import React, { useState } from 'react';
import { getUserThumbnail } from '../shared/global/user/getUserThumbnail';
import SchedulePost from './SchedulePost';
import PlantPost from './PlantPost';
import SocialPostView from './SocialPostView';

export default function CommunityFeedDisplay({
  documentToLoad,
  filteredSchedules,
  filteredPlants,
  filteredSocialPosts,
  currentUser,
  toggleLikeSchedule,
  copySchedule,
  noResultsFound,
  userData,
  updateErrorData,
  setIsPageLoading,
  userDisplayNames,
  setDocumentToLoad
}) {
  
  const [expandedSchedule, setExpandedSchedule] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Define the number of items to display per page
  const totalItems = Math.max(filteredSchedules.length, filteredPlants.length, filteredSocialPosts.length);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  
  const slicedSchedules = filteredSchedules.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  
  const slicedPlants = filteredPlants.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const slicedSocialPosts = filteredSocialPosts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  
  const toggleLowerDiv = (scheduleId) => {
    setExpandedSchedule((prevExpandedSchedule) =>
      prevExpandedSchedule === scheduleId ? null : scheduleId
    );
  };

  const handleProfileClick = (userId) => {
    const user = userData.find((user) => user.uid === userId);
    const url = `/grows/${user.displayName}`;
    window.location.href = url;
  };

  const getDisplayName = (userId) => {
    if (userData) {
      const user = userData.find((user) => user.uid === userId);
      return user.displayName;
    }
  };

  function displayFilteredContent() {    
    const combinedData = [];    

    let i = 0;
    while (i < slicedSchedules.length || i < slicedPlants.length || i < slicedSocialPosts.length) {
      if (i < slicedSchedules.length) {
        const schedule = slicedSchedules[i];
        const isExpanded = expandedSchedule === schedule.nutrientScheduleID;

        // if documentToload is not null and .type is schedule, then combinedData.unshift, else combinedData.push
        if (documentToLoad && documentToLoad.type === 'schedule' && i === 0) {
          combinedData.unshift(
            <SchedulePost
              key={schedule.nutrientScheduleID}
              schedule={schedule}
              currentUser={currentUser}
              toggleLikeSchedule={toggleLikeSchedule}
              copySchedule={copySchedule}
              toggleLowerDiv={toggleLowerDiv}
              isExpanded={isExpanded}
              userData={userData}
              handleProfileClick={handleProfileClick}
              getDisplayName={getDisplayName}
              getUserThumbnail={getUserThumbnail}
            />
          );          
        } else {
          combinedData.push(
            <SchedulePost
              key={schedule.nutrientScheduleID}
              schedule={schedule}
              currentUser={currentUser}
              toggleLikeSchedule={toggleLikeSchedule}
              copySchedule={copySchedule}
              toggleLowerDiv={toggleLowerDiv}
              isExpanded={isExpanded}
              userData={userData}
              handleProfileClick={handleProfileClick}
              getDisplayName={getDisplayName}
              getUserThumbnail={getUserThumbnail}
            />
          );
        }
      }

      if (i < slicedPlants.length) {
        const plant = slicedPlants[i];

        combinedData.push(
          <PlantPost
            key={plant.plantId}
            plant={plant}
            currentUser={currentUser}
            handleProfileClick={handleProfileClick}
            getDisplayName={getDisplayName}
            getUserThumbnail={getUserThumbnail}
            userData={userData}
            updateErrorData={updateErrorData}
          />
        );
      }

      if (i < slicedSocialPosts.length) {
        const socialPost = slicedSocialPosts[i];

        // if documentToload is not null and .type is socialPost, then combinedData.unshift, else combinedData.push
        if (documentToLoad && documentToLoad.type === 'socialPost' && i === 0) {
          combinedData.unshift(
            <SocialPostView
              key={socialPost.postID}
              socialPost={socialPost}
              getDisplayName={getDisplayName}
              getUserThumbnail={getUserThumbnail}
              userData={userData}
              currentUser={currentUser}
              setIsPageLoading={setIsPageLoading}
              userDisplayNames={userDisplayNames}
              setDocumentToLoad={setDocumentToLoad}
            />
          );
        } else  {      
          combinedData.push(
            <SocialPostView
              key={socialPost.postID}
              socialPost={socialPost}
              getDisplayName={getDisplayName}
              getUserThumbnail={getUserThumbnail}
              userData={userData}
              currentUser={currentUser}
              setIsPageLoading={setIsPageLoading}
              userDisplayNames={userDisplayNames}
              setDocumentToLoad={setDocumentToLoad}
            />
          );
        }
      }

      i++;
    }

    
    return combinedData;
  }
  
  return (
    <section className="schedule-container flex flex-col w-11/12 mx-auto">
      {totalPages > 1 && (
        <div className="flex flex-col md:flex-wrap justify-between etg-systemText gap-4 px-6">
          <div className="flex justify-center py-2 items-center">
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
              className="px-2 py-1 rounded bg-gray-200 hover:bg-gray-300 focus:outline-none"
            >
              Previous
            </button>
            <p className="mx-4">
              Page {currentPage} of {totalPages}
            </p>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              className="px-2 py-1 rounded bg-gray-200 hover:bg-gray-300 focus:outline-none"
            >
              Next
            </button>
          </div>
          
        </div>
      )}
      {/* If array is zero - display message about updating filters */}
      {noResultsFound ? (
        <div className="flex flex-col items-center justify-center w-full">
          <p className="etg-generalText">
            No schedules found! Update the filters to find nutrient schedules
          </p>
        </div>
      ) : (
        <div className="schedule-grid overflow-y-auto mt-16">
          {displayFilteredContent()}
        </div>
      )}
      {totalPages > 1 && (
        <div className="flex flex-col justify-between etg-systemText gap-4 px-6">
          <div className="flex justify-center items-center">
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
              className="px-2 py-1 rounded bg-gray-200 hover:bg-gray-300 focus:outline-none"
            >
              Previous
            </button>
            <p className="mx-4">
              Page {currentPage} of {totalPages}
            </p>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              className="px-2 py-1 rounded bg-gray-200 hover:bg-gray-300 focus:outline-none"
            >
              Next
            </button>
          </div>         
        </div>
      )}
    </section>
  );
}
