import React from 'react';
import DatePicker from 'react-date-picker';
import useStrainNameSuggest from '../../../hooks/useStrainNameSuggest';

export default function AddPlantModalInputs({
    strain, setStrain,
    startDate, setStartDate,    
    location, setLocation,
    position, setPosition,
    medium, setMedium,
    vegNutrients, setVegNutrients,
    flowerNutrients, setFlowerNutrients,
    locations,
    positions,
    vegScheduleOptions,
    flowerScheduleOptions, 
    getNutrientScheduleObj
}) {
    const {
        inputValue: strainInput,
        suggestions: strainSuggestions,
        handleInputChange,
        handleSuggestionClick,
    } = useStrainNameSuggest();

    // Updated handleInputChange to also update the strain state
    const handleStrainInputChange = (value) => {
        handleInputChange(value); // original logic
        setStrain(value); // update strain state
    };

    // Updated handleSuggestionClick to also update the strain state
    const handleStrainSuggestionClick = (suggestion) => {
        handleSuggestionClick(suggestion); // original logic
        setStrain(suggestion); // update strain state
    };

    function handleVegNutrients(selectedNutrientName) {
        const nutrientScheduleID = getNutrientScheduleObj(selectedNutrientName);    
        setVegNutrients([selectedNutrientName, nutrientScheduleID]);
    }

    function handleFlowerNutrients(selectedNutrientName) {
        const nutrientScheduleID = getNutrientScheduleObj(selectedNutrientName);        
        setFlowerNutrients([selectedNutrientName, nutrientScheduleID]);
    }

    const renderStrainSuggestions = () => {
        if (strainSuggestions.length === 0) {
            return null;
        }

        return (
            <div className='font-bold bg-slate-200 p-1 rounded-xl mx-auto mb-4'>
                <h4 className='text-emerald-800 text-lg'>Strain Name Suggestions:</h4>
                <ul className="suggestions-dropdown">
                    {strainSuggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            className='text-emerald-600 text-base list-disc list-inside'
                            onClick={() => handleStrainSuggestionClick(suggestion)}
                        >
                            {suggestion}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <>
            <div className="bg-slate-800/30 rounded-xl">
                <div className="m-2 py-4 px-2">
                    <div className='flex flex-col justify-center items-center py-3'>
                        <div className='flex flex-col mx-auto'>
                            <label className='etg-inputLabel text-black text-lg p-1'>
                                Strain: {strain}
                            </label>  
                            <div className="input-container">
                                <input 
                                    type="text" 
                                    className='text-textInput text-black bg-slate-200 p-2 mb-4 m-1 rounded-xl tracking-wide text-base md:text-lg'
                                    placeholder='Strain Name'
                                    value={strainInput} 
                                    onChange={(e) => handleStrainInputChange(e.target.value)}
                                />
                                {renderStrainSuggestions()}
                            </div>
                        </div>

                        <div className='text-black'>
                            <label className='etg-inputLabel text-black text-lg p-1'>
                                Start Date: 
                            </label>
                            <DatePicker onChange={(e) => setStartDate(e)} value={startDate} />
                        </div>

                        <div className='flex flex-col w-full md:w-6/12 mx-auto py-4 text-black'>
                            <div className='flex flex-col'>
                                <label className='etg-inputLabel text-black text-lg p-1'>
                                    Location: {location}
                                </label>
                                <select className='etg-selectInput p-2 rounded-xl' value={location} onChange={(e) => setLocation(e.target.value)}>                                    
                                    {locations}
                                </select>                            
                            </div>

                            <div className='flex flex-col'>
                                <label className='etg-inputLabel text-black text-lg p-1'>
                                    Position: {position}
                                </label>
                                <select className='etg-selectInput p-2 rounded-xl' value={position} onChange={(e) => setPosition(e.target.value)}>
                                    {positions}    
                                </select>
                            </div>

                            <div className='flex flex-col'>
                                <label className='etg-inputLabel text-black text-lg p-1'>
                                    Medium: {medium}
                                </label>                                    
                                <select className='etg-selectInput p-2 rounded-xl' value={medium} onChange={(e) => setMedium(e.target.value)}>
                                    <option value="Hydro">Hydro</option>
                                    <option value="Soil">Soil</option>
                                    <option value="Coco">Coco</option>
                                    <option value="Peat Moss">Peat Moss</option>
                                    <option value="Rockwool">Rockwool</option>
                                </select>
                            </div>
                        </div>

                        <div className='flex flex-col text-black'>
                            <label className='etg-inputLabel text-black text-lg p-1'>
                                Veg Nutrient Schedule: {vegNutrients[0]}
                            </label>
                            <select className='etg-selectInput p-2 rounded-xl' value={vegNutrients[0]} onChange={(e) => handleVegNutrients(e.target.value)}>                                    
                                <option value='none'>none</option>                      
                                {vegScheduleOptions}
                            </select>
                        </div>

                        <div className='flex flex-col text-black'>
                            <label className='etg-inputLabel text-black text-lg p-1'>
                                Flower Nutrient Schedule: {flowerNutrients[0]}
                            </label>
                            <select className='etg-selectInput p-2 rounded-xl' value={flowerNutrients[0]} onChange={(e) => handleFlowerNutrients(e.target.value)}>                                    
                                <option value='none'>none</option>                                     
                                {flowerScheduleOptions}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
