import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
    return (      
          <div className="max-w-3xl mx-auto bg-slate-700 shadow-xl rounded-2xl p-8 text-center">
            <h3 className="text-3xl font-bold mb-4">Content Not Found</h3>
            <p className="text-lg mb-6">
              Looks like we've had an issue - the content you were looking for could not be found.
            </p>
            <div className="flex flex-wrap justify-center space-x-4">
              <Link to="/grows" className="text-blue-500 hover:underline">
                <div className="flex flex-col items-center">
                  <span className="text-2xl mr-2">🌱</span>
                  <span className="font-bold">Grows</span>
                  <p>mange your plants</p>
                </div>
              </Link>
              <Link to="/profile" className="text-blue-500 hover:underline">
                <div className="flex flex-col items-center">
                  <span className="text-2xl mr-2">👤</span>
                  <span className="font-bold">Profile</span>
                    <p>manage your account</p>
                </div>
              </Link>
              <Link to="/community" className="text-blue-500 hover:underline">
                <div className="flex flex-col items-center">
                  <span className="text-2xl mr-2">🌐</span>
                  <span className="font-bold">Community</span>
                <p>connect with others</p>
                </div>
              </Link>
            </div>
          </div>
        
      
    );
  }
  