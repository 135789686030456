// js function to update order document with a new note, addded to the array of existing notes
// each note contains the message, passed as a parm, and the current date

import { projectFirestore } from '../../../firebase/config';

export const addOrderNote = async (orderID, notesArray) => {
    const orderRef = projectFirestore.collection('growLabelOrders').doc(orderID);   
    
    try {
        await orderRef.update({
        notes: notesArray,
        });
    } catch (err) {
        console.error(err);
    }
}