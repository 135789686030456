import React, { useState, useEffect } from 'react';
import DefaultNavbar from '../components/DefaultNavbar';
import DefaultFooter from '../components/DefaultFooter';
import { useAppData } from '../hooks/useAppData';
import LoadingDiv from '../components/LoadingDiv';
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';
import { GApageView } from '../components/renderless/helpers';
import tempLabelImage from '../img/etgLogoWhiteBG.svg'
import EctorGrowLabelSampleSlider from '../components/ectorGrowLabels/EctorGrowLabelSampleSlider';
import MailChimpFormContainer from '../components/newsletter/MailChimpFormContainer';
import DefaultSlider from '../components/shared/Marketing/DefaultSlider';


export default function EctorGrowLabels({ user }) {
  // Error Handling and Modal Management
  const { etgErrorDetected } = React.useContext(ErrorHandilingContext);
  const [isPageLoading, setIsPageLoading] = React.useState(false);


  
  // BEGIN DATA LOADING SECTION
  const [id, setId] = useState('notloggedin');
  if (user && id !== user.uid) {
    setId(user.uid);
  }

  // Load data from Firestore
  const { profileDocument } = useAppData(id);

  // temp fix, this may break if isPageLoading reset by page
  if (isPageLoading && profileDocument) {
    setIsPageLoading(false);
  }

  useEffect(() => {
    GApageView('EctorGrowLabels');
  }, []);  
  

  return (
    <>
      {etgErrorDetected ? null : (
        <section className='flex flex-col h-screen justify-between'>
          {/* Navbar */}
          <div className='w-full z-20'>
            <DefaultNavbar user={user} profileData={profileDocument} />
          </div>
          {/* Page Modals */}
          <div className='flex flex-col' id='app-modal' />
          {/* Page Specific Content */}
          {isPageLoading ? (
            <LoadingDiv />
          ) : (
            <>
              <main className='my-10 w-10/12 mx-auto'>
                {/* Page Title */}
                <div className="flex flex-col pb-8">
                    <div className="p-4 lg:p-12 rounded-2xl overflow-hidden bg-gradient-to-b from-white to-slate-200">
                        <div className="flex items-center text-emerald-600">
                            <p className="text-sm font-bold uppercase">
                                Introducing
                            </p>                            
                        </div>

                        <h2 className="bg-white rounded-xl text-6xl text-center px-2 py-4 text-slate-800 self-center">
                            EctorGrow Labels
                        </h2>

                        <p className="mt-4 text-xl text-slate-600 text-center tracking-wide">
                            Elevate your cannabis sharing experience and strengthen your in-person community connections with EctorGrow Labels. 
                        </p>

                        <p className="mt-4 text-xl text-slate-600 text-center tracking-wide">
                            Also great for organizing your previous harvests and keeping track of your favorite strains!
                            EctorGrow Labels are the perfect way to instantly connect your physical grow to its digital history and grow log, with EctorGrow.
                        </p>

                    
                        <div className="mt-12 flex justify-center items-center hover:scale-125 transform ease-in-out duration-150 transition-transform">
                            {/* SVG for ETG Labels */}   
                            <img src={tempLabelImage} alt="EctorGrow Labels" />
                        </div>
                    </div>
                </div>
                {/* Brief Intro and Summary */}
                <section className='flex flex-col py-8 gap-10'>
                    {/* ETG Explainer */}
                    <div className="flex flex-col bg-gradient-to-b from-white to-slate-200 rounded-xl my-4">
                  <h2 className="bg-white rounded-xl text-4xl text-center px-2 py-4 text-gray-700 self-center">New to EctorGrow?</h2>
                    <div className="p-4 text-xl tracking-wide">                        
                        <p className="mb-4 text-gray-800 text-center">
                          EctorGrow is a free, easy-to-use web application that helps you track and improve your cannabis cultivation journey - connect with other growers in our online community - and share your journey with your in-person community. 
                        </p>
                        <DefaultSlider textColor='text-white' />
                        <h4 className='pt-4 text-2xl text-center text-gray-800 underline underline-offset-2'>With EctorGrow, you can:</h4>
                        <ul className="list-disc list-inside mb-4 text-gray-800 text-lg px-4">
                        <li>Effortlessly monitor your plant's journey, from germination to harvest.</li>
                        <li>Receive tailored advice and insights for optimal growth.</li>
                        <li>Connect with a community of passionate growers, sharing tips and stories.</li>
                        </ul>
                        <p className="mb-4 text-gray-800 text-center">Ready to elevate your cultivation experience? Introducing EctorGrow Labels – the perfect way to encapsulate your harvest's story and share it with those who matter most!</p>
                        {/* EctorGrow Newsletter */}                        
                        <div className='bg-emerald-800 rounded-xl justify-center items-center text-center'>
                          <MailChimpFormContainer />
                        </div>
                    </div>
                </div>


                {/* ETG Label Explainer */}
                <div className="flex flex-col bg-gradient-to-b from-white to-slate-200 rounded-xl my-4">
                    <h2 className="bg-white rounded-xl text-4xl text-center px-2 py-4 text-gray-700 self-center">What are EctorGrow Labels and How They Work</h2>
                    {/* img of EctorGrow Labels */}
                    <div className="flex flex-col md:flex-row">
                      <img 
                        className="lg:w-1/2 md:w-1/2 object-cover object-center rounded-2xl md:mt-0 mt-12 p-4 w-full h-auto drop-shadow-xl" 
                        src='https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FAbout%2FEctorGrow%20Labels.jpeg?alt=media&token=5492b2d7-e89e-48ab-84bb-0c461f962ee4' 
                        alt="EctorGrow Labels" 
                      />
                    </div>
                    <div className="p-4 text-xl tracking-wide m-2">
                        <p className="mb-4 text-gray-800 text-center py-2">Introducing EctorGrow Labels – the perfect companion for sharing your cannabis cultivation journey with your in-person community!</p>
                        <p className="mb-4 text-gray-800 text-center py-2">With EctorGrow Labels, you can now bring the rich history of your plant's growth to life. Whether you're labeling your jars, bags, or other containers, these physical labels feature a unique QR code linking your physcial harvest to its digital history and grow log.</p>
                        <ul className="list-disc list-inside mb-4 text-gray-800">
                        <li>Effortlessly share your plant's entire history with friends and fellow enthusiasts.</li>
                        <li>Create an interactive experience – anyone scanning the QR code can explore your plant's journey.</li>
                        <li>Make your harvest gifts even more special by attaching an EctorGrow Label. Your loved ones can appreciate your hard work while enjoying the fruits of your labor.</li>
                        </ul>
                        <p className="mb-4 text-gray-800 text-center py-2">Elevate your cannabis sharing experience and strengthen your in-person community connections with EctorGrow Labels. Order yours today and let your plant's story unfold!</p>
                    </div>
                </div>
                {/* Label Size and Color Options */}
                <div className="flex flex-col bg-gradient-to-b from-white to-slate-200 rounded-xl my-4">
                  <h2 className="bg-white rounded-xl text-4xl text-center px-2 py-4 text-gray-700 self-center">Personalizing Your Labels</h2>
                  <div className="p-4 text-xl tracking-wide m-2">
                    <p className="mb-4 text-gray-800">Choose from three vibrant color options – Green, Purple, and Orange. We offer two convenient label sizes to fit your needs:</p>
                    <ul className="list-disc list-inside mb-4 text-gray-800 py-4">
                      <li><strong>Regular:</strong> These 3"x4" labels are perfect for large harvest jars or bags, and ideal for organizing previous harvests.</li>
                      <ul className="list-disc list-inside pl-6 mb-2 text-gray-800">
                        <li>Optionally, Display your unique EctorGrow display name.</li>
                        <li>Optionally, show the harvest date or your chosen custom text.</li>
                      </ul>
                      <li><strong>Mini Labels:</strong> Order a 4"x6" sheet of 6 mini labels – great for subtly labeling your harvest or personalized gifts for sharing with friends and family members.</li>
                        <ul className="list-disc list-inside pl-6 mb-2 text-gray-800">
                          <li>Mini labels come on a single clear sheet containing all of the mini labels - you can cut them to your preferred size for labeling your harvest</li>
                        </ul>
                    </ul>
                    <p className="mb-4 text-gray-800">Elevate your cannabis sharing experience and strengthen your in-person community connections with EctorGrow Labels. Order yours today and let your plant's story unfold!</p>
                  </div>

                  {/* Custom Options Slider */}
                  <div className='flex flex-col bg-slate-300 p-4 justify-center items-center rounded-xl w-full'>
                    <h2 className='text-center text-4xl py-4 text-black'>EctorGrow Label Options and Examples</h2>
                    <div className="max-w-full mx-auto">
                        <EctorGrowLabelSampleSlider />
                    </div>
                </div>

                </div>

                {/* EctorGrow Label Order Process */}                
                <div className="flex flex-col bg-gradient-to-b from-white to-slate-200 rounded-xl my-4">                
                  <div className="flex flex-col w-full">
                    <div className="w-full md:pr-10 md:py-6">
                      <div className="flex-grow pl-4 text-xl tracking-wide m-2">
                        <h2 className="bg-white rounded-xl text-4xl text-center px-2 py-4 text-gray-700 self-center">Order Process</h2>
                        <p className="leading-relaxed mb-4 text-gray-800">Ordering EctorGrow Labels is a breeze! Follow these simple steps to get your personalized labels:</p>
                        <ol className="list-decimal list-inside text-gray-800 px-4">
                          <li>Customize labels in the Plant footer, add to cart, and generate your order.</li>
                          <li>Complete payment securely through checkout. We'll email you the order details and a Square link.</li>
                          <li>After payment, EctorGrow reviews your order, creates the artwork, and sends the information to our partners for production and shipping. Labels typically arrive in 7-10 business days.</li>
                        </ol>
                        <p className="leading-relaxed mt-4 text-gray-800">
                          <span className="font-bold">Once delivered:</span> You're ready to apply the labels to your jars, bags, or other containers, making it easy for you to share your unique journey with your 
                          loved ones, adding an extra layer of joy to your cannabis experience! Absolutely no setup required, it's that easy!
                        </p>
                        <p className="mb-4 mt-8 text-gray-800">EctorGrow Labels start at just $12.99, including shipping! Order your EctorGrow Labels today and add a personal touch to your cannabis sharing experience!</p>
                      </div>
                    </div>
                    <img className="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12 p-4 w-full h-auto" src={tempLabelImage} alt="step" />
                  </div>
                </div>






                </section>
                {/* Detailed Description */}
                {/* Recap Summary & FAQ */}
                <section className="flex flex-col bg-gradient-to-b from-white to-slate-200 rounded-xl py-10">
                  <div>
                    <h2 className="bg-white rounded-xl text-4xl text-center px-2 py-4 text-gray-700 self-center">EctorGrow Labels F.A.Q.</h2>
                  </div>
                  <div className="space-y-4 p-10">
                    {/* FAQ ITEM */}
                    <details className="group border-s-4 border-green-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden" >
                      <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                        <h2 className="text-lg font-medium text-gray-900">Ectorgrow Labels: Delivery Time</h2>
                        <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-45" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd"/>
                          </svg>
                        </span>
                      </summary>
                      <p className="mt-4 leading-relaxed text-gray-700">
                        Orders are typically shipped using USPS (United States Postal Service) and typically arrive in 7-10 business days from when the payment is completed.
                      </p>
                    </details>
                    {/* FAQ ITEM */}
                    <details className="group border-s-4 border-green-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden" >
                      <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                        <h2 className="text-lg font-medium text-gray-900">Our Printing Partner</h2>
                        <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-45" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd"/>
                          </svg>
                        </span>
                      </summary>
                      <p className="mt-4 leading-relaxed text-gray-700">
                        We have partnered with StickersandPosters.com for the production and shipping of EctorGrow Labels.
                      </p>
                    </details>
                    {/* FAQ ITEM */}
                    <details className="group border-s-4 border-green-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden" >
                      <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                        <h2 className="text-lg font-medium text-gray-900">Our Payment Partner</h2>
                        <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-45" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd"/>
                          </svg>
                        </span>
                      </summary>
                      <p className="mt-4 leading-relaxed text-gray-700">
                        We have integrated EctorGrow with Square for secure payment processing. EctorGrow generates the order and provides you with a secure Square link to complete the payment.
                      </p>
                    </details>
                    {/* FAQ ITEM */}
                    <details className="group border-s-4 border-green-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden" >
                      <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                        <h2 className="text-lg font-medium text-gray-900">Shipping Cost and Details</h2>
                        <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-45" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd"/>
                          </svg>
                        </span>
                      </summary>
                      <p className="mt-4 leading-relaxed text-gray-700">
                        Every order includes free shipping! All orders are shipped using protective packaging to ensure your labels arrive in pristine condition. Ready for almost 
                        any surface, including glass jars, plastic bags, and almost anything you'd want to store your harvest in.
                      </p>
                    </details>
                    {/* FAQ ITEM */}
                    <details className="group border-s-4 border-green-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden" >
                      <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                        <h2 className="text-lg font-medium text-gray-900">Material and Care Instructions</h2>
                        <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-45" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd"/>
                          </svg>
                        </span>
                      </summary>
                      <p className="mt-4 leading-relaxed text-gray-700">
                        EctorGrow labels are printed on a clear, durable material. If you apply these labels correctly, to a clean, dry surface, they will likely outlast your harvest, but will be good for 
                        years to come.
                      </p>
                    </details>

    

    

                  </div>

                </section>

                
              </main>
            </>
          )}
          <DefaultFooter />
        </section>
      )}
    </>
  );
}
