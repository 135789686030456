import ReplyDisplay from './ReplyDisplay';
import { getAuth } from 'firebase/auth';
import { deleteComment } from './helpers/deleteComment';
import { projectFirestore } from '../../../firebase/config';
import { deleteReplyToComment } from './helpers/deleteReplyToComment';
import { getUserThumbnail } from '../global/user/getUserThumbnail';
import React, { useEffect, useState } from 'react';
import CommentHeader from './CommentHeader';
import CommentText from './CommentText';
import CommentActions from './CommentActions';
import AddReply from './AddReply';
import { commentTsToDate } from './helpers/commentTsToDate';

export default function CommentDisplay({
  contentID,
  comment,  
  updateErrorData,
  setIsLoading,
  getDisplayName,
  userData,
  handleFlagCommentReply
}) {

  const [addReply, setAddReply] = useState(false);  
  const [ replies, setReplies ] = useState([]); 
  const [showReplies, setShowReplies] = useState(false);
  const [ replyTo, setReplyTo ] = useState(false);
  const currentUser = getAuth().currentUser;
  

  // function that navigates to the user's profile page
  const handleProfileClick = (userId) => {
    // use the id to find the user's displayName
    const user = userData.find((user) => user.uid === userId);
    // build the URL
    const url = `/grows/${user.displayName}`;
    // navigate to the URL
    window.location.href = url;
  };

  const toggleReplies = () => {    
    // if addReply is true, set to false
    if (addReply) {
      setAddReply(false);      
    }
    // set showReplies to the opposite of what it currently is
    setShowReplies(!showReplies);
  };

  const handleDeleteReply = async (relpyID ) => {
    // have the user confirm with a prompt before deleting a comment
    const confirmDelete = window.confirm('Are you sure you want to delete this reply?');
    if (!confirmDelete) {
      return;
    }
    setIsLoading(true);
    // delete the comment from the database
    const result = await deleteReplyToComment(comment.commentID, relpyID);
    if (result === 'error') {
      updateErrorData('Error deleting reply', getAuth().currentUser.uid);
    }
    setIsLoading(false);
  };

  const handleLikeToggle = async () => {
    setIsLoading(true);
    const hasLiked = comment.likes.includes(currentUser.uid);
    let updatedComment = { ...comment };

    if (hasLiked) {
      updatedComment.likes = updatedComment.likes.filter((like) => like !== currentUser.uid);
    } else {
      updatedComment.likes.push(currentUser.uid);
    }

    try {
      await projectFirestore.collection('userComments').doc(comment.commentID).update(updatedComment);
    } catch (error) {
      updateErrorData('Error liking comment', currentUser.uid);
    }

    setIsLoading(false);
  };

  const handleDeleteComment = async () => {
    setIsLoading(true);
    const confirmDelete = window.confirm('Are you sure you want to delete this comment?');

    if (!confirmDelete) {
      return;
    }

    const result = await deleteComment(comment);    
    if (result === 'error') {
      updateErrorData('Error deleting comment', currentUser.uid);
    }

    setIsLoading(false);
  };

  

  
  

  // recursive function to organize replies into a nested array
  const organizeCommentsWithReplies = (comments) => {    
    // Create an object to map comment IDs to their replies
    const commentRepliesMap = {};

    // Initialize the root comments array
    const rootComments = [];
    let tempReplies = [];

    for (const reply of comments) {    
      // If there's no respondingToID, it's a root comment
      if (reply.respondingTo === comment.commentID) {        
        rootComments.push(reply);
      } else {        
        tempReplies.push(reply);        
      }

      // if the tempReplies array has any replies, add them to the by finding the index of the reply.respondingTo field that matches the rootComments.commentID
      if (tempReplies.length > 0) {        
        const index = rootComments.findIndex((rootComment) => rootComment.replyID === reply.respondingTo);        
        if (index > -1) {          
          rootComments[index].replies = tempReplies;
          tempReplies = [];
        }
      }
    }

    // Recursively organize the comments
    const organize = (comment) => {
      if (comment.replyID && commentRepliesMap[comment.replyID]) {
        comment.replies = commentRepliesMap[comment.replyID].map(organize);
      }
      return comment;
    };

    const organizedReplies = rootComments.map(organize);    
    return organizedReplies
  };
  
  useEffect(() => {
  if (showReplies) {
    const fetchData = async () => {
      const commentRef = projectFirestore.collection('userComments').doc(comment.commentID);
      const repliesCollectionRef = commentRef.collection('replies');
      const querySnapshot = await repliesCollectionRef.get();
      const repliesData = querySnapshot.docs.map((doc) => doc.data());
      const organizedReplies = organizeCommentsWithReplies(repliesData);      
      setReplies(organizedReplies);
    };

    fetchData();
  }
}, [showReplies, comment.commentID]);


  if (userData === null) {
    return null;
  }

  
  return (
    <div className="mt-2 grid grid-cols-12 bg-white text-black p-4 rounded-xl">
      <div className="col-span-12 flex">
        <div className="mx-2 flex h-16 w-16 items-center justify-center overflow-hidden rounded-full flex-shrink-0">
          <img
            className='hover:cursor-pointer'
            onClick={() => handleProfileClick(comment.userID)} 
            src={getUserThumbnail(comment.userID, userData)} 
            alt="user thumbnail" 
          />
        </div>
        <div className="flex flex-col ml-4 my-2 w-full justify-center">
          <CommentHeader 
            dateTime={commentTsToDate(comment.date)}
            displayName={getDisplayName(comment.userID)}            
            handleProfileClick={handleProfileClick}
            userID={comment.userID}
          />        
          <CommentText text={comment.text} />
          <CommentActions
            setAddReply={setAddReply}
            setReplyTo={setReplyTo}
            onDelete={() => handleDeleteComment(comment)}
            onFlag={() => handleFlagCommentReply(comment.commentID, null, 'comment', comment.userID)}
            onLike={handleLikeToggle}            
            currentUser={currentUser}
            comment={comment}
            toggleReplies={toggleReplies}   
            showReplies={showReplies}         
          />
        </div>
      </div>      

      {showReplies &&
        !addReply &&
        organizeCommentsWithReplies(replies).map((reply, index) => {
          const replyUserType = currentUser === null ? 'publicVisitor' : currentUser.uid === reply.userID ? 'owner' : 'registeredVisitor';

          return (
            <div className="col-span-12" key={reply.replyID + index}>
              
              <ReplyDisplay                
                reply={reply}
                comment={comment}
                getDisplayName={getDisplayName}
                getUserThumbnail={getUserThumbnail}
                commentTsToDate={commentTsToDate}
                handleDeleteReply={handleDeleteReply}
                currentUser={currentUser}
                updateErrorData={updateErrorData}
                setIsLoading={setIsLoading}
                userData={userData}
                replyUserType={replyUserType}
                setAddReply={setAddReply}
                setReplyTo={setReplyTo}
                handleProfileClick={handleProfileClick}     
                handleFlagCommentReply={handleFlagCommentReply}                           
              />
            </div>
          );
        })}

      {addReply && (
        <div className="col-span-12 mt-4">
          <AddReply
            replyTo={replyTo}            
            setIsLoading={setIsLoading}
            updateErrorData={updateErrorData}
            getAuth={getAuth}
            comment={comment}
            setAddReply={setAddReply}            
          />
        </div>
      )}
    </div>
  );
}