// THIS COMPONENT IS SHARED BETWEEN THE PROFILE PAGE - AND THE PUBLIC VIEW OF THE 
// PLANT CARD FOOTER

import React from 'react'
import WeeklyScheduleDisplay from './WeeklyScheduleDisplay'

export default function VegNutrientDisplay({ activeNutrientObj, setNutrientModal }) {        

    if (activeNutrientObj.emptyObj) {
        return <div></div>
    }


    const displayNutrients = () => {
        let returnValue = []        
        // loop through nutrients to create nutrient display 
        for (let index = 0; index < activeNutrientObj.nutrients.length; index++) {
            returnValue.push(<WeeklyScheduleDisplay 
                nutrientIndex={index}
                activeNutrientObj={activeNutrientObj}
                key={`nutrientWeekDisplayIndex${index}`}
            />)   
        }
        return returnValue
    }    


  return (<>
  <section className='text-black mx-auto'>
    {(setNutrientModal) && <div className='flex justify-end' onClick={() => setNutrientModal('EDIT')}>edit</div>}
    <p className='text-xl underline underline-offset-2 py-2'>{activeNutrientObj.scheduleName}</p>
    <p className='text-xs text-gray-800 italic pb-2'>{activeNutrientObj.phase} phase</p>
    <p className=''>{activeNutrientObj.medium} | {activeNutrientObj.containerSize} gal(s) </p>
    <p className='text-xs text-gray-800 italic pb-2'>growth medium | container size</p>
    <p className='pb-2'>{activeNutrientObj.numberOfNutrients} nutrients | {activeNutrientObj.numberOfWeeks} weeks </p>
    
    <div className='flex flex-col overflow-auto mx-auto'>
        {displayNutrients()}
    </div>
  </section>
  </>)
}