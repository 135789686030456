import React, { useState, useEffect } from 'react';
import DefaultNavbar from '../components/DefaultNavbar';
import DefaultFooter from '../components/DefaultFooter';
import { useAppData } from '../hooks/useAppData';
import LoadingDiv from '../components/LoadingDiv';
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';
import { GApageView } from '../components/renderless/helpers';
import { getAuth } from 'firebase/auth';
import { projectFirestore } from '../firebase/config';
import { useParams } from 'react-router-dom';
import useMessageSender from '../hooks/useMessageSender';
import ETGButton from '../components/shared/global/ETGButton';


export default function OrderStatus({ user }) {
  // Error Handling and Modal Management
  const { etgErrorDetected } = React.useContext(ErrorHandilingContext);
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const [ orderID, setOrderID ] = useState('');
  const [ orderData, setOrderData ] = useState(null);
  const [ orderStep, setOrderStep ] = useState('Created'); // Created || Paid || Processing || Shipping || Delivered
  const [ displayMode, setDisplayMode ] = useState('orderLookup'); //   orderLookup || orderFound || orderNotFound
  const [ emailMessage, setEmailMessage ] = useState(null);
  const [ sendingEmail, setSendingEmail ] = useState(false);

  
  
  
  // BEGIN DATA LOADING SECTION
  const [id, setId] = useState('notloggedin');
  if (user && id !== user.uid) {
    setId(user.uid);
  }
  const currentUser = getAuth().currentUser;
  const { sendEmail, messageSent } = useMessageSender()

    const params = useParams();
    const etgOrderIDToLoad = params.etgOrderIDToLoad;

  // Load data from Firestore
  const { profileDocument } = useAppData(id);

  // temp fix, this may break if isPageLoading reset by page
  if (isPageLoading && profileDocument) {
    setIsPageLoading(false);
  }

  useEffect(() => {
    GApageView('orderStatus');
  }, []);

  // const orderStep = "Processing";

  useEffect(() => {

    let tempOrderStep = 'Created';
    if (orderData) {
        tempOrderStep = orderData.orderStatus
    }
    setOrderStep(tempOrderStep);
  }, [orderData]);

  

    async function handleOrderLookup(orderIDParam) {
        setIsPageLoading(true);
        try {                
          // Retrieve orderData by matching the orderID to the collection documents .etgOrderID property
          const querySnapshot = await projectFirestore.collection('growLabelOrders').where('squareOrderID', '==', orderIDParam).get();
      
          // Check if a matching order was found
          if (!querySnapshot.empty) {
            // If a matching order was found, extract the first document from the querySnapshot
            const orderData = querySnapshot.docs[0].data();
            let tempOrderData = {...orderData, paymentLinkResponse: JSON.parse(orderData.paymentLinkResponse)};            
            // setOrderData to the order data retrieved from the database

            setOrderData(tempOrderData);
      
            // setDisplayMode to orderFound since a matching order was found
            setDisplayMode('orderFound');
          } else {
            // If no matching order was found, setOrderData to null
            setOrderData(null);
      
            // setDisplayMode to orderNotFound since no matching order was found
            setDisplayMode('orderNotFound');
          }
        } catch (error) {
          console.error('Error fetching order data:', error);
          // Handle the error as needed
        }
        setIsPageLoading(false);
      }
      

    // useEffect that runs when component mounts
    // check for any params that were passed in the URL and save as etgOrderIDToLoad
    // if etgOrderIDToLoad is not null, call handleOrderLookup passing in etgOrderIDToLoad
    // if etgOrderIDToLoad is null, setDisplayMode to orderLookup
    useEffect(() => {
        // check for any params that were passed in the URL and save as etgOrderIDToLoad        
        
        // if etgOrderIDToLoad is not null, call handleOrderLookup passing in etgOrderIDToLoad
        if (etgOrderIDToLoad) {
            setOrderID(etgOrderIDToLoad);
            handleOrderLookup(etgOrderIDToLoad);
        } else {
            // if etgOrderIDToLoad is null, setDisplayMode to orderLookup
            setDisplayMode('orderLookup');
        }
    }, [etgOrderIDToLoad]);

    function convertToMoney(valueInCents) {
        const valueInDollars = valueInCents / 100
        return valueInDollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
    }


    const handleCompletePayment = () => {
      // open the orderGenerated.long_url in a new tab
      window.open(orderData.paymentLinkResponse.payment_link.long_url, '_blank');
  }

  const handleSendUserEmail = async () => {
    setSendingEmail(true);

    const messageParams = {
      orderID: orderData.squareOrderID,
      orderDate: orderData.dateTimeOrdered,
      userMessage: emailMessage
    }

    await sendEmail('Team@EctorGrow.com', 'orderUserEmail', messageParams, 'ETGLabelsOrder', null, orderData.squareOrderID )
    setEmailMessage(null);
    if(messageSent) {
      console.log('message Sent Called')
      alert('Your message has been sent succesfully!')
    }
    setSendingEmail(false);
 
  }

  function formatOrderDateTime(inputDate) {
    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = new Date(inputDate).toLocaleDateString('en-US', options);
    return formattedDate;
  }
  
  
  return (
    <>
      {etgErrorDetected ? null : (
        <section className='flex flex-col h-screen justify-between'>
          {/* Navbar */}
          <div className='w-full z-20'>
            <DefaultNavbar user={user} profileData={profileDocument} />
          </div>
          {/* Page Modals */}
          <div className='flex flex-col' id='app-modal' />
          {/* Page Specific Content */}
          {isPageLoading ? (
            <LoadingDiv />
          ) : (
            <>
              <main className='flex flex-col my-10'>
                { displayMode === 'orderLookup' ? <>
                {/* Order Lookup Component */}
                <div className="flex flex-col self-center p-8 bg-emerald-500 rounded-xl text-white">
                    <div className="text-center mb-4">
                        <h3 className='text-4xl font-bold  pb-4'>Order Lookup</h3>
                        <p className='p-2 tracking-wide'>
                          Enter your EctorGrow Order ID to check the status of your order for EctorGrow Labels
                        </p>
                        <p className='p-2 tracking-wide'>
                          This can be found in your order confirmation email or by going to the EctorGrow Labels section of each plant
                          in your grows page.

                        </p>
                    </div>


                  {/* form allowing user to enter an EctorGrow OrderID */}                    
                    <div className='flex flex-col justify-center text-black py-4'>
                        <label className='py-4 text-white text-xl self-center font-medium' htmlFor="orderID">EctorGrow Order ID</label>
                        <input 
                          type="text"
                          onChange={(e) => setOrderID(e.target.value)} name="orderID" id="orderID"
                          className='p-2 rounded-xl w-8/12 self-center'
                        />                            
                    </div>                    
                    <div className='flex justify-center'>
                      <ETGButton textColor='white' btnText='Search for Order' onClickFunction={() => handleOrderLookup(orderID)} />                    
                    </div>
                </div>                
                </> : null}


                { displayMode === 'orderFound' ? <>
                {/* Order Display Component */}
                <div className="flex flex-col m-10 p-8 bg-emerald-500 rounded-xl text-white">
                    {/* Step Indicator */}
                    <div className="text-4xl items-center text-center font-bold mb-4">
                        <h3>EctorGrow Label Order Lookup</h3>
                    </div>
                    <div className="flex flex-col md:flex-row items-center text-center justify-center mb-8">
                      <p className='text-3xl font-bold px-10 text-white pt-4'> Current Order Status: 
                      {orderStep === "Created" && (
                        // add conditional rendering, to either show Created or Paid if paid is true
                        orderData.orderStatus === 'Created' && orderData.paymentUpdate && orderData.paymentUpdate.data.object.payment.status === 'COMPLETED' ? <>
                          <span className=" text-white animate-pulse px-2 underline underline-offset-2">Payment Completed </span>    
                        </> : <>
                          <span className=" text-white animate-pulse px-2 underline underline-offset-2">Created</span>
                    </> )}
                      {orderStep === "Processing" && (
                          <span className=" text-white animate-pulse px-2 underline underline-offset-2">Processing</span>
                      )}
                      {orderStep === "In Transit" && (
                          <span className=" text-white animate-pulse px-2 underline underline-offset-2">In Transit</span>
                      )}
                      {orderStep === "Delivered" && (
                          <span className=" text-white animate-pulse px-2 underline underline-offset-2">Delivered</span>
                      )}
                      {orderStep === "Cancelled" && (
                          <span className=" text-white animate-pulse px-2 underline underline-offset-2">Cancelled</span>
                      )}
                      </p>
                  </div>


                    {/* Order Details */}
                    <div className="flex flex-col bg-gradient-to-b from-white to-slate-200 p-8 rounded-lg shadow-lg text-black break-words">
                        <h2 className="text-3xl font-bold text-center mb-4">Order Details</h2>
                        <div className="mb-4">
                          <span className="font-bold">Description:</span> {orderData.orderDescription}
                        </div>
                        <div className="mb-4">
                          <span className="font-bold">Order Total:</span> { convertToMoney(orderData.paymentLinkResponse.related_resources.orders[0].total_money.amount)}
                        </div>
                        <div className="mb-4">
                          <span className="font-bold">Order Date:</span> {formatOrderDateTime(orderData.dateTimeOrdered)}
                        </div>                        
                        <div className="mb-4">
                          <span className="font-bold">Order ID:</span> {orderData.squareOrderID}
                        </div>                        
                        {/* if order is delayed */}
                        { orderData.delay ? <>
                          <div className="my-4 text-center">
                            <p className='py-1'>We are sorry but your order has been delayed.</p>
                            <p className='py-1'>It was delayed due to {orderData.delay}. An email has been sent with additional information.</p>
                          </div>
                        </> : null}

                        {/* if order is cancelled */}
                        { orderData.orderStatus === 'Cancelled' ? <>
                          <div className="border-2 border-red-700  rounded-xl text-xl my-4 text-center">
                            <p className='py-1'>We are sorry but your order has been cancelled.</p>
                            <p className='py-2'>It was cancelled due to, <span className='underline underline-offset-2'>{orderData.cancellationReason}</span>. Again, we are sorry for any inconveninence, an email has been sent with additional information including information about your refund.</p>
                            <p className='py-1'>The EctorGrow Team</p>
                          </div>
                        </> : null}

                    </div>


                    {/* Next Steps Section */}
                    {/* Created - Awaiting Payment */}
                    { orderStep === 'Created' ? <>
                    <div className="flex flex-col bg-gradient-to-b from-white to-slate-200 p-8 rounded-lg shadow-lg text-black mt-8">
                     {orderData.orderStatus === 'Created' && orderData.paymentUpdate && orderData.paymentUpdate.data.object.payment.status === 'COMPLETED' ? 
                     <>
                      <h2 className="text-3xl text-center font-bold mb-4">Payment Completed</h2>
                      <div className="text-center mb-4">
                        <p>
                          You're payment has been recieved and you should be recieivng your order soon, typically 7-10 days after payment!
                        </p>
                      </div>
                        </> :  <>
                      
                      
                      <h2 className="text-3xl text-center font-bold mb-4">Awaiting Payment</h2>
                      <div className='flex flex-wrap justify-center gap-4 p-4'>
                        <div className="mb-4">
                          <p><span className="font-bold">Description:</span> {orderData.orderDescription}</p>
                        </div>
                        <div className="mb-4">
                          <p><span className="font-bold">Total:</span> { convertToMoney(orderData.paymentLinkResponse.related_resources.orders[0].total_money.amount)}</p>
                        </div>
                        </div>
                        <div className='flex flex-col items-center p-4'>
                          <button 
                            onClick={handleCompletePayment}
                            className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'
                            >
                            Complete Payment
                          </button>
                          <p>payments are completed using Square's Online Checkout - once payment is completed your order status should be updated a few moments later</p>
                        </div>
                     </> }

                    </div>
                    </> : null}

                    {/* Paid - includes ETG production and SandP production while awaiting shipping */}
                    { orderStep === 'Processing' ? <>
                    <div className="bg-gradient-to-b from-white to-slate-200 p-8 rounded-lg shadow-lg text-black mt-8">
                      <h2 className="text-3xl text-center font-bold mb-4">We Are Processing Your Order</h2>
                      <div className="mb-4 text-center">                        
                        <p>We are double checking the order, generating the artwork and sending your order to our production partner. 
                          When the order has been shipped, you'll get an email containing the tracking information and any additional details.
                        </p>
                      </div>
                    </div>
                    </> : null}

                  {/* Shipping */}
                  { orderStep === 'In Transit' ? <>
                    <div className="bg-gradient-to-b from-white to-slate-200 p-8 rounded-lg shadow-lg text-black mt-8">
                      <h2 className="text-3xl text-center font-bold mb-4">Your Order is on the Way</h2>
                      {/* if the orderData has a carrier number display info, otherwise null */}
                      <div className='flex flex-col gap-4 py-6 justify-center items-center'>
                        { orderData.carrier ? <><p>Delivery Service: {orderData.carrier}</p></> : null}
                        { orderData.trackingNumber ? <><p>Delivery Service: {orderData.trackingNumber}</p></> : null}
                      </div>
                      <div className="mb-4 text-center">
                        <p className='pb-2'>Your order has been shipped and should be with you soon!</p>
                        <p className='p-4'>
                          Get ready to label your jars, bags, or whatever containers you're using. Your labels are ready to take you and your loved ones directly to all
                          the hard work you put into growing this plant.
                        </p>                        
                      </div>
                    </div>
                </> : null} 

                {/* Delivered */}
                { orderStep === 'Delivered' ? <>
                    <div className="bg-gradient-to-b from-white to-slate-200 p-8 rounded-lg shadow-lg text-black mt-8">
                      <h2 className="text-3xl text-center font-bold mb-4">Your Order Has Been Delivered</h2>
                      <div className="mb-4 text-center">                        
                        <p>
                          Its time to label your jars, bags, or whatever containers you're using. Your labels are ready to take you and your loved ones directly to all
                          the hard work you put into growing this plant.
                        </p>
                        <p className='py-2'>Again, congratulations on your harvest and thank you for your order!</p>
                        <p className='etg-systemText pb-10'>the EctorGrow team</p>
                          <p>
                            If you have ANY isssues with your label, please send us a message here and we'll get back to you as soon as possible.
                        </p>
                        { messageSent ? <p className='text-green-500 py-4'>Message Sent! Thank you</p> : null}
                    </div>


                    {/* Section to email team at EctorGrow */}
                    { currentUser && currentUser.uid === orderData.orderedByUID ? 
                    <>
                    { emailMessage === null   ? <>
                    <div className="flex flex-col mb-4 justify-center items-center">
                      <ETGButton onClickFunction={() => setEmailMessage('')} btnText='Send Us an Email your Grow Labels' />                      
                    </div>
                    </> : <>
                      <h3>Your Message:</h3>
                      <textarea
                        className="w-full mb-4 bg-slate-200 rounded-xl p-2"
                        rows="5"
                        value={emailMessage}
                        onChange={(e) => setEmailMessage(e.target.value)}
                        />
                      { sendingEmail ? <p className='self-center text-xl text-green-500 py-4 m-auto'>Sending Email...</p> : <>
                        <ETGButton onClickFunction={handleSendUserEmail} btnText='Send Message' />                      
                      </>}
                    </>}
                  </> : null}
                </div></> : null}


                </div>                                
                </> : null}

                {/* Display Message that no order was found */}
                { displayMode === 'orderNotFound' ? <>
                {/* Display Message that no order was found */}
                <div className="p-8 bg-emerald-500 rounded-xl text-white">
                  <div className="flex flex-col justify-center text-center mb-4">
                    <h3 className='text-3xl font-bold  pb-4'>Order Not Found</h3>
                  </div>
                  <div className='flex flex-col justify-center items-center'>
                    <p className='text-center py-2'>
                      Sorry, we could not find an order matching the Order ID you entered, <span className='font-bold'>{orderID}</span>. Please try again.
                    </p>
                  </div>
                    {/* button to try again */}
                  <div className='flex flex-col justify-center items-center'>
                    <ETGButton onClickFunction={() => setDisplayMode('orderLookup')} textColor='white' btnText='Try Again' />                    
                  </div>
                </div>
                </> : null}      
                                    
              </main>
            </>
          )}
          <DefaultFooter />
        </section>
      )}
    </>
  );
}
