import React from 'react'
import tierData from '../../Data/TierData'

export default function ProTierCard() {

    
    const greenCheck = <div className=''>
        <i className='material-icons text-emerlad-800 text-2xl pr-2'>check</i>
    </div>

    return (
        <>
        <div className="shadow-lg rounded-2xl w-10/12 mx-auto bg-slate-800 h-full dark:bg-gray-800 p-4 border-2 border-gray-400">
            <div className='flex w-full justify-end'>
                <p className='absolute -mt-14 md:-ml-10 uppercase border-2 bg-emerald-600 border-emerald-800 rounded-lg md:text-2xl p-2 px-4 font-medium'>
                    Pro
                </p>
            </div>
            <div className='flex flex-col pt-2'>                
            <p className="text-gray-400 dark:text-gray-100 text-center text-2xl pt-4">
                    For perpetual harvests and advanced growers
                </p>
            </div>
            <div className='flex flex-col justify-center text-white text-xl tracking-wider font-medium bg-emerald-800/80 my-8 p-4 rounded-xl'>
                <ul className="text-md font-light text-white w-full mt-6 mb-6">
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        Up to {tierData[1].activePlantLimit} active plants
                    </li>
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        {tierData[1].imgLimit} images or videos, per plant
                    </li>
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        No plant limit
                    </li>  
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        Enhanced Growlog with detailed tracking
                    </li>
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        {tierData[1].nutrientLimit} custom Nutrient Schedules
                    </li>                                      
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        Cannabis Cultivation Information & Support
                    </li>                                      
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        Community - learn from and share with the EctorGrow Community
                    </li>                                      
                </ul>                                
            </div>
            <div className='flex flex-col justify-center items-center'>
            <p className="text-white text-3xl font-bold">
                    TBD
                    <span className="text-white text-base">
                       / year 
                    </span>
            </p>                   
            <p className='etg-systemText text-white text-center'>
                coming soon...
            </p>
            </div>
        </div>
        </>
      )
    }
    
