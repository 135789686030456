import React from "react";
import EctorGrowLabelPreviousOrders from "../grows/plantCard/Footer/EctorGrowLabels/EctorGrowLabelPreviousOrders";

export default function ETGOrdersAndAccountLevelSubscription({ previousOrderData, displayName }) {    

    return (
    <section className='flex flex-col p-4 my-10 border-2 rounded-xl m-2 border-slate-600 text-black'>
        <h3 className='etg-subHeading text-black'>EctorGrow Label Orders & Account Subscription Level</h3>

        {/* EctorGrow Label Previous Orders */}
        <div className='flex flex-col py-16 m-4 h-fit overflow-x-auto'>
            <h3 className='text-2xl self-center text-center'>Previous Label Orders for {displayName}</h3>
            <EctorGrowLabelPreviousOrders previousOrderData={previousOrderData} />
            <div className='flex flex-wrap self-center text-center'>
                Order Status Progression: Created > Paid > Processing > Shipped > Delivered
            </div>
        </div>
        { previousOrderData && previousOrderData.length > 0 ? <>
        {/* Area For Previous Orders */}
        </> : null}



        {/* Account Subscription Levels */}
        <div className="flex flex-col justify-center py-20">
        <h3 className='text-2xl self-center text-center'>Account Subscription Level</h3>
            <p className='tracking-widest py-8 uppercase text-center text-black self-center'>Coming Soon...</p>
        </div>           
    </section>);
}

