import { timestampToDate } from "../helpers"

// Return the number of days since the plant has had nutrients reset
export function calcDaysSinceLastNutrientReset ( activePlantObject ) {
    let dateOfLasReset = null
    let activePlantObjectClone = Object.assign({}, activePlantObject)    
    
    // sort oldest to most recent
    activePlantObjectClone.noteLog.sort(function (a, b) {            
        return new Date(a.date.toDate()) - new Date(b.date.toDate());
    })  

    // loop through every event - if the event type matches 'lc-nutrient', 
    // set the dateOfLasReset to that notes date
    activePlantObjectClone.noteLog.forEach(note => {
        if (note.type === 'lc-nutrient') {
            dateOfLasReset = timestampToDate(note.date)
        }         
    });

    // if no nutrient notes found - set return to text string
    if (dateOfLasReset === null) {
        dateOfLasReset = `no nutrient resets for ${activePlantObject.strain}`
    }

    return dateOfLasReset
}