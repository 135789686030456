import React, { useState } from 'react';
import { AiFillLike, AiOutlineLike } from 'react-icons/ai';
import ETGButton from '../shared/global/ETGButton';
import FlowerNutrientDisplay from '../grows/plantCard/Footer/Nutrients/FlowerNutrientDisplay';
import VegNutrientDisplay from '../grows/plantCard/Footer/Nutrients/VegNutrientDisplay';
import CommentSection from '../shared/Chat/CommentSection';
import { projectFirestore } from '../../firebase/config';
import { NavLink } from 'react-router-dom';

export default function SchedulePost({ schedule, currentUser, toggleLikeSchedule, copySchedule, toggleLowerDiv, isExpanded, userData, handleProfileClick, getDisplayName, getUserThumbnail, updateErrorData }) {
  const [showComments, setShowComments] = useState(false);
  const [commentData, setCommentData] = useState([])

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  React.useEffect(() => {
    // set up a live query to listen for any documents with the plantId field matching the current plant id
    const commentQuery = projectFirestore.collection('userComments').where('documentID', '==', schedule.nutrientScheduleID)
    // show results of query in console
    commentQuery.onSnapshot((querySnapshot) => {
        let tempCommentData = []
        querySnapshot.forEach((doc) => {            
            tempCommentData.push(doc.data())
        });        
        setCommentData(tempCommentData)        
    });
    }, [schedule.nutrientScheduleID]) 

    // calculate the number of comments and number of replies for each comment
    // const calculateCommentCount = (comments) => {
    //   let commentCount = 0;
    //   let replyCount = 0;
    //   comments.forEach((comment) => {
    //       commentCount += 1;
    //       if (comment.replies) {
    //           replyCount += comment.replies.length
    //       }
    //   })
    //   return { commentCount, replyCount }
    // }    

  return (
    <>
      <section className='flex flex-col'>
        <div
          className={`schedule-card ${
            schedule.phase === 'Vegetative' ? 'bg-emerald-200' : 'bg-emerald-400'
          } border-2 border-black rounded-xl p-2 my-4 ${isExpanded ? 'expanded' : ''}`}
          key={schedule.nutrientScheduleID}
        >
          <div className='schedule-header'>
            <h2 className='text-2xl font-bold text-center text-slate-900'>{schedule.scheduleName}</h2>

            <div className='flex flex-col md:flex-row justify-around items-center w-full py-4'>            
              {/* Schedule Details */}
              <div className='schedule-description text-slate-800 text-center py-2'>
                <p className='flex justify-center'>
                  {schedule.phase} | {schedule.medium} | {schedule.containerSize} | {schedule.numberOfWeeks}
                </p>
                <p className='flex justify-center etg-systemText'>phase | medium | size (gallons) | weeks</p>
              </div>

              {/* Schedule Description */}
              <div className='flex w-8/12 border-2 border-slate-800  rounded-xl'>
                <p className='flex p-2 text-slate-800 text-center'>{schedule.scheduleDescription}</p>
              </div>
            </div>

            {/* Action Buttons */}
            <div className='flex flex-col md:flex-row items-center justify-center w-full'>
              <div className='schedule-details text-slate-800 text-center'>
                {/* Schedule Created Details & Link/Button */}
                <div className='flex flex-wrap justify-center cursor-pointer bg-slate-100 shadow-xl rounded-2xl p-2 my-4'>
      {schedule.createdByUID === 'abandoned' ? (
        <p className='hover:cursor-auto'>User no longer exists</p>

      ) : (
        <>
          
            <NavLink to={`/grows/${getDisplayName(schedule.createdByUID)}`}>
              <div className='flex flex-col justify-center items-center'>
                <p>Created By: {getDisplayName(schedule.createdByUID)}</p>
                <img
                  src={getUserThumbnail(schedule.createdByUID, userData)}
                  alt='user thumbnail'
                  className='w-10 h-10 rounded-xl m-1 border-2 border-emerald-800 p-1'
                />
              </div>
            </NavLink>          
        </>
      )}
    </div>
              </div>                            
              {/* Hide/Show Nutrients Button */}
              <div className='flex flex-row items-center justify-center w-full'>
                <ETGButton
                  btnText={isExpanded ? 'Hide Nutrients' : 'Show Nutrients'}
                  onClickFunction={() => toggleLowerDiv(schedule.nutrientScheduleID)}
                />
              </div>
              {/* Comments Button */}
              <div className='flex items-center justify-center w-full'>
                <ETGButton 
                  btnText='Comments'
                  onClickFunction={toggleComments} 
                />
              </div>
              {/* Copy Schedule Button */}
              {currentUser ? (
                schedule.createdByUID !== currentUser.uid ? (
                  <>
                    <div className='flex flex-row items-center justify-center w-full'>
                      <ETGButton btnText='Copy Schedule' type='caution' onClickFunction={() => copySchedule(schedule)} />
                    </div>
                  </>
                ) : null
              ) : null}
              {/* Likes Button */}
              <div className='flex flex-row items-center justify-center w-full'>
                {currentUser ? (
                  <>
                    <div
                      className='flex flex-col items-center justify-center text-center'
                      onClick={() => toggleLikeSchedule(schedule.nutrientScheduleID, schedule)}
                    >
                      <button className='flex flex-col text-green-800 hover:text-green-900 hover:font-medium items-center'>
                        {schedule.likes.includes(currentUser.uid) ? <AiFillLike /> : <AiOutlineLike />}
                      </button>
                      <span className='text-gray-800'>{schedule.likes.length} likes</span>
                      <span className='etg-systemText'>click to like/unlike</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex flex-col items-center justify-center text-center'>
                      <span className='text-green-800 hover:text-green-900 hover:font-medium items-center'>
                        <AiFillLike />
                      </span>
                      <span className='text-gray-800'>{schedule.likes.length} likes</span>
                    </div>
                  </>
                )}
              </div>              
            </div>
          </div>

          {isExpanded && (
            <div className='schedule-nutrients py-2'>
              {schedule.phase === 'Vegetative' && <VegNutrientDisplay activeNutrientObj={schedule} />}
              {schedule.phase === 'Flower' && <FlowerNutrientDisplay activeNutrientObj={schedule} />}
            </div>
          )}

          {showComments && (
            <div className='schedule-comments py-2'>
              {/* Render the comments section here */}
              <CommentSection
                comments={commentData}                
                type='nutrient-scheudle'
                documentID={schedule.nutrientScheduleID}
                updateErrorData={updateErrorData}
                getDisplayName={getDisplayName}
                userData={userData}
                contentID={schedule.nutrientScheduleID}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
}
