// Last Updated Tier Values 8/31/21

const tierData = [
    // Free User - Same as Default
    {
        tierLevel: 1,
        activePlantLimit: 8,
        totalPlantLimit: 100,
        imgLimit: 40,
        nutrientLimit: 1

    },
    // Pro User
    {
        tierLevel: 2,
        activePlantLimit: 20,
        totalPlantLimit: 10000, //intended as no limits
        imgLimit: 75,
        nutrientLimit: 4
    },
    // Admin User
    {
        tierLevel: 9,
        activePlantLimit: 1000, //intended as no limits
        totalPlantLimit: 5000, //intended as no limits
        imgLimit: 100,
        nutrientLimit: 3
    }
]

export const returnAccountLimits = ( userTier ) => {
    let returnData = null
    
    switch (userTier) {
        case 1:
            returnData = tierData[0]
            break;
        case 2:
            returnData = tierData[1]
            break;
        case 9:
            returnData = tierData[2]
            break;
    
        default:
            returnData = tierData[0]
            break;
    }

    return returnData
}



export default tierData