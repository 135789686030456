import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const DefaultSlider = ({ textColor }) => {



    



  const imgArray = [
    {
    imgURL:
        'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FMarketingSliderImages%2FEctorGrow-Labels.png?alt=media&token=48832aeb-73c5-4b85-af59-aacbcdd0293e',
    caption: 'Labels make it easy for you to share your grow with others',
    },
    {
      imgURL:
        'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FMarketingSliderImages%2FAddNewPlant.png?alt=media&token=c49f580a-7bef-4c38-a29c-446287b840ed',
      caption: 'Add a New Plant to your Grow',
    },
    {
      imgURL:
        'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FMarketingSliderImages%2FEctorGrow-Community.png?alt=media&token=7edd534d-d580-4b20-9e6d-39fe14521e61',
      caption: 'Community makes it easy to connect with other growers',
    },
    {
      imgURL:
        'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FMarketingSliderImages%2FEctorGrow-Growlog.png?alt=media&token=3e11a5fe-3906-416e-a52e-4f88116eaa33',
      caption: 'Growlog makes it easy to track your plants\' growth',
    },
    {
      imgURL:
        'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FMarketingSliderImages%2FEctorGrow-Labels2.jpeg?alt=media&token=a6174d3b-b193-4862-915b-adcc2ffa2569',
      caption: 'EctorGrow Labels come in two sizes, three colors, and are customizable',
    },
    {
      imgURL:
        'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FMarketingSliderImages%2FEctorGrow-Nutrients.png?alt=media&token=0da5bc5f-7689-4a76-8a6e-ee147593ef6c',
      caption: 'EctorGrow makes it easy to track your nutrient schedule and feeding times',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="w-full max-w-screen-xl mx-auto my-6 p-8 bg-emerald-800/60 rounded-xl    ">
      <Slider {...settings}>
        {imgArray.map((img, index) => (
          <div key={index}>
            <img src={img.imgURL} alt={img.caption} className="w-full h-auto rounded-xl" />
            <p className={`text-center ${textColor} pt-2 text-xl`}>{img.caption}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default DefaultSlider;
