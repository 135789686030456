import React from 'react'

export default function WeeklyScheduleInputs({ updateNutrients, weekIndex, nutrients, indexOfNutrient }) {        
    return (<>
    <section className='flex flex-col border-x-emerald-800/20 border-2'>
        <div className='px-4 text-center'> 
            <p className='text-xs text-gray-800 py-1'> {`Week ${(weekIndex + 1)}`}</p>
        </div>
        <div className='py-2 px-2'>
            <input 
                type='number' 
                step='0.01'
                min={0}
                value={nutrients[indexOfNutrient].weeklySchedule[weekIndex]}
                onChange={(e) => updateNutrients([3, e.target.value, weekIndex])}
            />            
        </div>
    </section>
</>)
}
