import React from 'react';
import QuestionImage from '../../img/decorative-question-mark.png'

export default function AccountFeaturesandProPricing() {
    const [ expandedProDetails, setExpandedProDetails ] = React.useState(false);


    return <section className=" bg-gray-200 text-gray-600 tracking-wider text-center body-font rounded-xl -mt-2">
        <div className="container px-5 py-20 mx-auto border-t-4 border-emerald-800">
            
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-center mb-4 text-gray-900">Pro User Features</h1>            
            <div className="flex flex-wrap justify-between">
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Enhanced Grow Logs</h2>
                    <p className="leading-relaxed text-base mb-4">
                    Every user can document their plant's growth progress, but Pro users gain access to purpose-built tools that make it a breeze to add extensive notes to their growlog. With options for quick manual entries or even automated data input, Pro users can maintain an exceptionally detailed record of their plants' development.
                    </p>

                </div>
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Videos, Notifications & More</h2>
                    <p className="leading-relaxed text-base mb-4">
                    Pro users can take it up a notch by uploading videos of their plants. Alongside this, they benefit from enhanced notification features to stay informed about their plant's growth and stay connected to the thriving social community. Pro users also enjoy increased limits for images, active plants, and their total plant count.
                    </p>

                </div>
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">For Outdoor Grows</h2>
                    <p className="leading-relaxed text-base mb-4">
                        Add the zipcode your plant is growing in and EctorGrow will automatically create daily records of your plants light schedule &amp; humidity. Ector Grow
                        will also send you a warning if severe weather is forcasted in the next 48 hours so you can take any needed precautions to <span className="font-bold">protect your plants</span>.
                    </p>
                </div>                
            </div>            
            <div className="flex justify-center">
            <button
                onClick={() => setExpandedProDetails(!expandedProDetails)}
                className="flex items-center justify-between w-full px-6 py-3 text-left bg-white rounded-lg border border-gray-300 shadow-sm focus:outline-none"
            >
                <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font">
                    Why a paid tier?
                </h2>
                <span className="transition-transform">
                    {expandedProDetails ? '▲' : '▼'}
                </span>
            </button>


            </div>

            {/* new section for explaing why of pricing and estimated pricing */}
            { expandedProDetails ? <>
            <section className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 pt-20 pb-10 md:flex-row flex-col items-center">
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img className="object-cover object-center rounded m-auto" alt="hero" src={QuestionImage} />
                    </div>
                    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Why a paid tier?
                            {/* <br className="hidden lg:inline-block">readymade gluten */}
                        </h1>
                        <h2 className="text-xs text-indigo-500 tracking-widest font-medium my-1">and what will it cost?</h2>
                        <p className="mb-8 leading-relaxed">
                        EctorGrow is designed to be user-friendly and accessible to all, but we understand that some users aspire to elevate their growing experience with more advanced features and greater detail. By subscribing to our Pro tier, you not only unlock these advanced capabilities but also contribute directly to the ongoing development and maintenance of EctorGrow. Your support fuels the evolution of our platform, ensuring we can continually enhance it for all our users.
                        </p>
                        <p className="mb-8 leading-relaxed">
                            Our Goal is to start the pro tier at just <span className="font-bold">a few dollars a month</span>, with an optional discount, if paid annually. We are still working on the details and will update this section as we get closer to the Pro tier launch.
                        </p>                        
                    </div>
                </div>
            </section>
            </> : null }
            <p className='flex justify-end text-sm'>*pro tier coming soon</p>
        </div>
    </section>;
}
