import React from 'react'
import { daysSince } from '../../renderless/helpers'
import infoData from '../../../Data/InfoSupportData.json'
import CategoryArticleDisplay from '../plantCard/Footer/CategoryArticleDisplay'
import LoadingDiv from '../../LoadingDiv'
import { MdOutlineWaterDamage } from 'react-icons/md'
import { TbTemperature } from 'react-icons/tb'
import { BsLightbulb } from 'react-icons/bs'
import { RiGuideLine } from 'react-icons/ri'
import { GrPowerCycle } from 'react-icons/gr'



export default function PlantInfoView({ currentPhase, currentPlantData}) {
    const [ detailType, setDetailType ] = React.useState('Humidity')


  let dayInPhase = daysSince(currentPlantData)
    let currentPlantInfo = {
        humidity: 0,
        temp: 0, 
        lightSchedule: 'loading',
        currentPhase: 'loading',
        training: 'loading',
        nutrients: 'loading',
        
    }
    
    if (currentPhase === 'Vegetative') {
        if (dayInPhase > 30) {
            dayInPhase = 30
        }
        if (dayInPhase !== 1) {
            currentPlantInfo = infoData.Vegetative[(dayInPhase - 1)]              
        } else {
            currentPlantInfo = infoData.Vegetative[dayInPhase]                                
        }
    }
    if (currentPhase === 'Flower') {
        if (dayInPhase > 70) {
            dayInPhase = 70
        }
        currentPlantInfo = infoData.Flower[(dayInPhase - 1)]                
    }
    if (currentPhase === 'Harvested') {
        if (dayInPhase > 30) {            
            dayInPhase = 30
        }
        currentPlantInfo = infoData.Harvested[(dayInPhase - 1)]                
    }
        
    
    const displaySupportArticles = () => {
        let returnArray = []

        for (let categoryIndex = 0; categoryIndex < currentPlantInfo.support.length; categoryIndex++) {
            const category = currentPlantInfo.support[categoryIndex]
            
            for (let linkIndex = 0; linkIndex < category.links.length; linkIndex++) {
                const link = category.links[linkIndex]
                returnArray.push(<CategoryArticleDisplay 
                    title={category.category}
                    link={link.link}
                    source={link.source}
                    index={linkIndex}
                    key={`categoryDisplay${categoryIndex}.${linkIndex}`}  
                />)
                
            }
            
        }

        return returnArray
    }

    let detail = <p>details about humidity</p>
    // switch statement to determine which info to display
    switch (detailType) {
        case 'Humidity':
            detail = <><p className='p-1'>Humidity plays a crucial role in the successful cultivation of cannabis, particularly in confined spaces such as a closet. Maintaining the appropriate humidity levels can significantly impact the growth, health, and overall yield of the plants. Cannabis plants thrive in a specific range of humidity, and deviating from this range can lead to various issues.</p>
            <p className='p-1'>In a confined space like a closet, controlling humidity becomes even more critical as the limited airflow can easily trap moisture and create an environment prone to mold and mildew. High humidity levels can encourage the growth of these harmful pathogens, which can quickly spread and damage the plants. On the other hand, low humidity levels can cause the plants to lose moisture rapidly, leading to dehydration, stunted growth, and even leaf curling.</p>
            <p className='p-1'>To ensure optimal humidity in a closet or confined space, it is essential to monitor and regulate it carefully. Investing in a digital hygrometer can help you accurately measure the humidity levels. During the vegetative stage, when the plants are actively growing, aim for a humidity range of 40-70%. This higher range helps promote lush foliage and vigorous growth. However, as the plants transition into the flowering stage, it's recommended to lower the humidity to around 40-50% to prevent mold issues.</p>
            <p className='p-1'>To maintain the appropriate humidity levels, consider using dehumidifiers or humidifiers depending on your specific needs. Dehumidifiers are ideal for reducing excessive moisture, while humidifiers can add moisture to the air if it's too dry. Additionally, proper ventilation and airflow within the closet are crucial to prevent stagnant air and ensure fresh air exchange.</p>
            <p className='p-1'>By carefully managing humidity levels in a confined space like a closet, cannabis growers can create an environment that promotes healthy plant growth, minimizes the risk of mold and mildew, and maximizes overall yield. Regular monitoring, adjusting humidity levels based on plant stage, and implementing appropriate equipment are key steps to achieve successful cannabis cultivation in such limited spaces.</p>
            </>
            break;
        case 'Temperature':
            detail = <><p className='p-1'>Temperature control is equally crucial when growing cannabis in confined spaces like a closet. Maintaining the right temperature range directly impacts the overall health, growth, and quality of the plants. In a confined environment, temperature fluctuations can have detrimental effects on the plants.</p>
            <p className='p-1'>Temperature control is equally crucial when growing cannabis in confined spaces like a closet. Maintaining the right temperature range directly impacts the overall health, growth, and quality of the plants. In a confined environment, temperature fluctuations can have detrimental effects on the plants.</p>
            <p className='p-1'>In a closet or confined space, it is important to consider the heat generated by grow lights and other equipment. Proper ventilation and airflow are crucial to dissipate excess heat and maintain a stable temperature. Additionally, utilizing cooling systems such as fans, exhaust fans, or air conditioning can help regulate the temperature effectively.</p>
            <p className='p-1'>Regular monitoring of temperature using a thermometer is essential to identify any fluctuations and make adjustments promptly. By maintaining an optimal temperature range and ensuring a suitable climate in a confined space, growers can provide a favorable environment for cannabis plants to thrive, resulting in healthy growth, improved yields, and high-quality buds.</p>
            </>
            break;
        case 'Light':
            detail = <><p className='p-1'>Proper lighting is paramount when growing cannabis in confined spaces like a closet. Choosing the right type of lights and ensuring appropriate light intensity and duration directly impacts the plant's growth, development, and overall yield.</p>            
            <p className='p-1'>Selecting high-quality grow lights specifically designed for cannabis cultivation is essential. Full-spectrum LED lights are a popular choice as they provide the necessary light spectrum for both vegetative and flowering stages. These lights offer energy efficiency, emit less heat, and can be placed closer to the plants without causing damage.</p>
            <p className='p-1'>Light intensity is crucial for optimal photosynthesis and plant growth. During the vegetative stage, aim for light intensities between 400-600 μmol/m²/s. As the plants transition into the flowering stage, increase the intensity to 600-1000 μmol/m²/s to promote bud development. It's important to monitor the distance between the plants and the lights to avoid light burn or light stress.</p>
            <p className='p-1'>Maintaining an appropriate light cycle is equally important. During the vegetative stage, provide 18-24 hours of light per day to encourage growth. In the flowering stage, reduce the light cycle to 12 hours of light and 12 hours of uninterrupted darkness to trigger bud formation.</p>
            <p className='p-1'>Proper light distribution and coverage within the confined space can be achieved by using reflective materials on the walls and optimizing the positioning of the lights. Regularly check and clean the lights to ensure maximum efficiency.</p>
            <p className='p-1'>By carefully selecting and managing the lighting setup in a confined space, cannabis growers can provide the necessary light spectrum, intensity, and duration for optimal plant growth, development, and higher yields.</p>
            </>
            break;
        case 'Training':
            detail = <><p className='p-1'>Training techniques are valuable for shaping and maximizing the potential of cannabis plants during cultivation. Two commonly used methods are Low Stress Training (LST) and High Stress Training (HST), each with its own benefits and considerations.</p>
            <p className='p-1'>LST involves gently bending and manipulating the branches of the plant to create a more horizontal and open canopy. This technique helps ensure that light reaches more areas of the plant, promoting even growth and increased bud production. By using soft ties or plant training clips, growers can gently bend the branches and secure them in a desired position. LST is typically performed during the vegetative stage when the plant is more flexible. This method allows for better light penetration and improved airflow, reducing the risk of mold and mildew. LST is a low-risk training technique that can significantly increase yields and improve overall plant health.</p>
            <p className='p-1'>On the other hand, HST, or High Stress Training, involves more aggressive methods of plant manipulation. Techniques like topping, fimming, and super cropping fall under this category. Topping involves removing the main apical bud to encourage lateral growth and the development of multiple main colas. Fimming is a similar technique where a portion of the apical bud is pinched to stimulate bushier growth. Super cropping involves strategically bending or crushing certain branches to manipulate the plant's shape and encourage more lateral growth. These methods can be performed during the vegetative stage and require careful consideration and precision to minimize stress and damage to the plant.</p>
            <p className='p-1'>HST techniques can produce more drastic changes to the plant's structure and increase yields, but they carry a higher risk of stress and potential damage. It is important to allow sufficient recovery time for the plant after performing HST techniques to minimize setbacks in growth. Additionally, HST techniques may not be suitable for all cannabis strains or grow setups, so it is essential to research and understand the specific needs and characteristics of the chosen strain before applying these techniques.</p>
            <p className='p-1'>Both LST and HST techniques offer growers opportunities to optimize their cannabis plants' structure, enhance light exposure, and ultimately maximize yields. The choice between LST and HST depends on the grower's experience, strain characteristics, and desired outcomes. Regardless of the chosen technique, it is crucial to approach training with care, patience, and an understanding of the plant's response to ensure successful results.</p>            
            </>
            break;
        case 'Nutrients':
            detail = <><p className='p-1'>
            Proper nutrient management is essential for successful cannabis cultivation throughout all stages of growth. Cannabis plants have specific nutrient requirements that change as they progress from the vegetative stage to the flowering stage. Providing the right balance and variety of nutrients ensures healthy plant development, optimal yields, and high-quality buds.</p>
            <p className='p-1'>During the vegetative stage, cannabis plants primarily require higher levels of nitrogen (N), which promotes vigorous foliage growth. Nitrogen-rich fertilizers, such as those with a higher N-P-K (Nitrogen-Phosphorus-Potassium) ratio, are commonly used during this phase. However, it's important to maintain a balanced nutrient profile with adequate levels of phosphorus (P) and potassium (K) as well.</p>
            <p className='p-1'>As the plants transition into the flowering stage, their nutrient needs shift. During this phase, cannabis plants require higher levels of phosphorus and potassium, which promote bud development, density, and resin production. Fertilizers with a higher P-K ratio are typically used at this stage to meet these requirements. Additionally, micronutrients like calcium, magnesium, and trace elements are crucial throughout the entire growth cycle, as they support overall plant health, enzyme functions, and nutrient uptake.</p>
            <p className='p-1'>It is important to closely monitor nutrient levels to avoid deficiencies or excesses, as both can negatively impact plant health and productivity. Nutrient deficiencies can manifest as yellowing or discolored leaves, stunted growth, and reduced yields. Conversely, nutrient excesses can cause nutrient lockouts, nutrient burn, or toxicity symptoms, which can lead to plant stress or damage.</p>
            <p className='p-1'>Different nutrient formulations, such as liquid concentrates or dry fertilizers, are available in the market. Following the manufacturer's guidelines and adjusting nutrient concentrations based on the plant's response and growth stage is crucial for achieving optimal results.</p>
            <p className='p-1'>In addition to selecting the right nutrients, maintaining a proper pH level in the root zone is essential. Cannabis plants thrive in slightly acidic soil with a pH range of 6.0-6.5. Monitoring and adjusting the pH of the nutrient solution or growing medium helps ensure optimal nutrient uptake and availability.</p>
            <p className='p-1'>In summary, providing the right balance and variety of nutrients is vital for successful cannabis cultivation. By understanding the specific nutrient requirements of each growth stage and monitoring nutrient levels, growers can promote healthy growth, maximize yields, and produce high-quality cannabis flowers.</p>
            </>
            break;
        default:
            detail = <><p className='p-1'>Humidity plays a crucial role in the successful cultivation of cannabis, particularly in confined spaces such as a closet. Maintaining the appropriate humidity levels can significantly impact the growth, health, and overall yield of the plants. Cannabis plants thrive in a specific range of humidity, and deviating from this range can lead to various issues.</p>
            <p className='p-1'>In a confined space like a closet, controlling humidity becomes even more critical as the limited airflow can easily trap moisture and create an environment prone to mold and mildew. High humidity levels can encourage the growth of these harmful pathogens, which can quickly spread and damage the plants. On the other hand, low humidity levels can cause the plants to lose moisture rapidly, leading to dehydration, stunted growth, and even leaf curling.</p>
            <p className='p-1'>To ensure optimal humidity in a closet or confined space, it is essential to monitor and regulate it carefully. Investing in a digital hygrometer can help you accurately measure the humidity levels. During the vegetative stage, when the plants are actively growing, aim for a humidity range of 40-70%. This higher range helps promote lush foliage and vigorous growth. However, as the plants transition into the flowering stage, it's recommended to lower the humidity to around 40-50% to prevent mold issues.</p>
            <p className='p-1'>To maintain the appropriate humidity levels, consider using dehumidifiers or humidifiers depending on your specific needs. Dehumidifiers are ideal for reducing excessive moisture, while humidifiers can add moisture to the air if it's too dry. Additionally, proper ventilation and airflow within the closet are crucial to prevent stagnant air and ensure fresh air exchange.</p>
            <p className='p-1'>By carefully managing humidity levels in a confined space like a closet, cannabis growers can create an environment that promotes healthy plant growth, minimizes the risk of mold and mildew, and maximizes overall yield. Regular monitoring, adjusting humidity levels based on plant stage, and implementing appropriate equipment are key steps to achieve successful cannabis cultivation in such limited spaces.</p>
            </>
            break;
    }


    if (currentPlantInfo === undefined) {
        return <LoadingDiv />
    }

    
    

  return (<>
    <section className='flex flex-col text-black justify-center'>        
            <div className='flex justify-center'>
              <h4 className='etg-sectionTitle pb-4'>
                Currently in day {dayInPhase} of {currentPhase}
              </h4>
            </div>    
            
            <div className='flex flex-col md:flex-row justify-around my-4'> 

                {/* General Info Graphic */}
                <div className='flex flex-col'>
                    {/* General Info Section */}
                    <div className='mx-auto md:px-4'>
                        <div>
                            <h3 className='font-medium underline text-lg pb-3 text-center'>Recommended Conditions</h3>  
                            <p className='etg-systemText text-center'>click to learn more</p>
                        </div>
                        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
                            {/* Humidity */}
                            <div className={`flex justify-between ${detailType === 'Humidity' ? 'bg-blue-500' : 'bg-blue-100'} border-gray-200 border p-4 rounded-lg`} onClick={() => setDetailType('Humidity')}>
                                <div className='flex text-2xl items-center text-green-200'>
                                    <MdOutlineWaterDamage />
                                </div>
                                <div class="flex-grow text-center">
                                    <h2 class="text-gray-900 title-font font-medium">{(currentPlantInfo.humidity) && currentPlantInfo.humidity}</h2>
                                    <p class="text-gray-700">Humidity</p>
                                </div>
                            </div>
                            {/* Temperature */}
                            <div className={`flex justify-between ${detailType === 'Temperature' ? 'bg-green-500' : 'bg-green-100'} border-gray-200 border p-4 rounded-lg`} onClick={() => setDetailType('Temperature')}>
                                <div className='flex text-2xl items-center text-green-200'>
                                    <TbTemperature />
                                </div>
                                <div class="flex-grow text-center">
                                    <h2 class="text-gray-900 title-font font-medium">{(currentPlantInfo.temp) && currentPlantInfo.temp}</h2>
                                    <p class="text-gray-700">Temperature</p>
                                </div>
                            </div>
                            {/* Light Schedule */}
                            <div className={`flex justify-between ${detailType === 'Light' ? 'bg-blue-500' : 'bg-blue-100'} border-gray-200 border p-4 rounded-lg`} onClick={() => setDetailType('Light')}>
                                <div className='flex text-2xl items-center text-green-200'>
                                    <BsLightbulb />
                                </div>
                                <div class="flex-grow text-center">
                                    <h2 class="text-gray-900 title-font font-medium">{(currentPlantInfo.lightSchedule) && currentPlantInfo.lightSchedule}</h2>
                                    <p class="text-gray-700">Light Schedule</p>
                                </div>
                            </div>
                            {((currentPhase !== 'Harvested') && (currentPhase !== 'Archived')) && <>
                                {/* Training */}
                                <div className={`flex justify-between ${detailType === 'Training' ? 'bg-green-500' : 'bg-green-100'} border-gray-200 border p-4 rounded-lg`} onClick={() => setDetailType('Training')}>
                                    <div className='flex text-2xl items-center text-green-200'>
                                        <RiGuideLine />
                                    </div>
                                    <div class="flex-grow text-center">
                                        <h2 class="text-gray-900 title-font font-medium">{(currentPlantInfo.training) && currentPlantInfo.training}</h2>
                                        <p class="text-gray-700">Training</p>
                                    </div>
                                </div>
                                {/* Nutrients */}
                                <div className={`flex justify-between ${detailType === 'Nutrients' ? 'bg-blue-500' : 'bg-blue-100'} border-gray-200 border p-4 rounded-lg`} onClick={() => setDetailType('Nutrients')}>
                                    <div className='flex text-2xl items-center text-green-200'>
                                        <GrPowerCycle />
                                    </div>
                                    <div class="flex-grow text-center">
                                        <h2 class="text-gray-900 title-font font-medium">{(currentPlantInfo.nutrients) && currentPlantInfo.nutrients}</h2>
                                        <p class="text-gray-700">Nutrients</p>
                                    </div>
                                </div>                        
                            </>}                    
                        </div>
                    </div>
                </div>

                {/* General Info Details */}
                <div className='flex flex-col w-10/12 mx-auto'>
                    <p className='font-medium underline text-lg pb-3 text-center'>{detailType} Details</p>
                    <div className='max-h-80 overflow-auto break-words bg-slate-200 rounded-md p-2'>
                        <p className='font-light'>{detail}</p>
                    </div>
                </div>

            </div>
                
            <div className='flex flex-col md:flex-row justify-around text-center bg-blue-200 rounded-xl py-2'> 
                {/* Links for genetics */}
                <div className='flex flex-col justify-center'>
                    <p className='font-medium underline text-lg pb-3'>Species and Genetic Information</p>
                    <div className='flex flex-col max-h-20 overflow-auto rounded-md'>
                        <a href='https://www.wikileaf.com/strains/' target="_blank" rel='noreferrer'>
                            <span className='border-b-2 border-black/20 md:hover:border-black/70 text-sm font-light italic'>
                                WikiLeaf.com
                            </span>
                        </a>
                        <a href='https://weedmaps.com/strains' target="_blank" rel='noreferrer'>
                            <span className='border-b-2 border-black/20 md:hover:border-black/70 text-sm font-light italic'>
                                WeedMaps.com
                            </span>
                        </a>                            
                    </div>
                </div>

                {/* Support Article External Links */}
                <div className='flex flex-col justify-center items-center'>
                    <p className='font-medium underline text-lg pb-3'>Support Articles</p>    
                    <div className='w-64 max-h-40 overflow-auto break-words bg-slate-200/80 rounded-md p-2'>                    
                    {(currentPhase !== 'Archived') && displaySupportArticles()}                                                          
                        
                    </div>
                    <p className='text-xs pb-2'>*links open to external resources</p>                                    
                </div>
            </div>    
            {/* Footer Disclaimer */}
            <div className='font-medium italic text-sm text-center pt-6'>
                <p className='py-2'>Support Articles provides additional advice and information based on the current age and phase of your plant.</p>
                <p className='pb-2'>Have a suggestion or think we made a mistake? Let us know by submitting a support request.</p>
            </div>
    </section>
  </>)
}
