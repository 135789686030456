import React, { useState } from 'react';
import { Card, CardBody, CardFooter, Checkbox, NavLink } from '@material-tailwind/react';
import PasswordChecklist from 'react-password-checklist';
import DatePicker from 'react-date-picker';
import ProcessingDiv from '../ProcessingDiv';
import { useSignup } from '../../hooks/useSignup';
import { gaEvent } from '../renderless/helpers';
import { loadDisplayNames } from '../renderless/GetData/loadDisplayNames';
import { testDesiredUsername } from '../renderless/TestInput/testDesiredDisplayName';
import { testPassword } from '../renderless/TestInput/testPassword';

export default function RegisterForm() {
  const [desiredDisplayNameAvailable, setDesiredDisplayNameAvailable] = useState(false);
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [registerError, setRegisterError] = useState(null);
  const [dob, setDob] = useState(null);
  const [tosChecked, setTosChecked] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [userDisplayNames, setUserDisplayNames] = useState([]);
  const { signup, error } = useSignup();  

  // Validate password
  const handlePasswordInput = (passwordInput) => {
    const testResults = testPassword(passwordInput);
    setPassword(testResults.newPassword.toString());
    setValidPassword(testResults.isValid);
  };

  React.useEffect(() => {
    loadDisplayNames(false).then((result) => {
      setUserDisplayNames(result);
    });
  }, []);

  const handleTestDisplayName = (desiredDisplayName) => {
    let testResults = testDesiredUsername(desiredDisplayName, userDisplayNames);
    setDisplayName(testResults.currentDisplayName);
    setDesiredDisplayNameAvailable(testResults.isNameAvailable);
  };

  // Handle submission of the registration form
  const handleSubmit = (e) => {
    e.preventDefault();

  // Check if any required fields are empty
  if (
    displayName === '' ||
    firstName === '' ||
    lastName === '' ||
    email === '' ||
    password === '' ||
    dob === null ||
    !tosChecked
  ) {
    alert('Please complete all required fields.');
    return;
  }
    setIsProcessing(true);

    // Check if dob is older than 18
    let validAge = false;
    // Calculate date of birth
    let birthDate = dob;
    // Get difference from current date;
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (calculatedAge >= 18) {
      validAge = true;
    }

    // If password is NOT valid, clear password and DOB field and notify user via register error
    // If password is valid, but age is not over 18, clear fields and update register error
    // If password is valid, and age is valid, use signup hook to create account
    if (validPassword) {
      if (validAge === false) {
        setEmail('');
        setDisplayName('');
        setFirstName('');
        setLastName('');
        setPassword('');
        setDob(null);
        setRegisterError('Must be 18+ to register');
      } else {
        signup(email, password, displayName, firstName, lastName);
        gaEvent('User Action', 'New Account Registered', 'New User');
      }
    } else {
      setPassword('');
      setDob(null);
      setRegisterError('Password is not valid');
    }

    // Notify user of error if occurred during signupHook
    if (error) {
      setRegisterError(error);
    }

    setIsProcessing(false);
  };

  return (
    <>
      {isProcessing ? <ProcessingDiv message='Registering Your Account...' /> : (
        <>
          <div className="flex flex-col px-2 pt-4">
            <Card>
              <form>
                <div className="relative p-4 text-center rounded-2xl -mx-16">
                  <p className="uppercase bg-emerald-800 rounded-lg text-2xl p-4 font-medium">
                    Ector Grow Registration
                  </p>
                </div>

                <CardBody className="border-2 rounded-md border-emerald-600/80 bg-slate-200">
                  <div className="mb-4 px-4">
                    <input
                      type="text"
                      className="w-full p-2 rounded-md border-green-800/50 shadow-xl border-2 text-black"
                      placeholder="Display Name"
                      onChange={(e) => handleTestDisplayName(e.target.value)}
                      value={displayName}
                    />
                    {/* MESSAGE ABOUT DISPLAY NAME BEING PUBLICLY AVAILABLE */}
                    {displayName !== '' && (
                      <p className="text-xs py-1 italic text-black">
                        Display Name will be publicly visible and must contain 3-10 characters
                      </p>
                    )}
                    {/* SUCCESS MESSAGE _ USERNAME AVAILABLE */}
                    {displayName !== '' && desiredDisplayNameAvailable && (
                      <p className="text-xs py-1 font-medium italic text-black">
                        Yes! {displayName} is available!
                      </p>
                    )}
                    {/* FAIL MESSAGE _ USERNAME UNAVAILABLE */}
                    {displayName !== '' && !desiredDisplayNameAvailable && (
                      <p className="text-xs py-1 font-medium italic text-black">
                        Keep trying, {displayName} is unavailable...
                      </p>
                    )}
                  </div>
                  <div className="mb-4 px-4">
                    <input
                      type="text"
                      className="w-full p-2 rounded-md border-green-800/50 shadow-xl border-2 text-black"
                      placeholder="First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 px-4">
                    <input
                      type="text"
                      className="w-full p-2 rounded-md border-green-800/50 shadow-xl border-2 text-black"
                      placeholder="Last Name"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 px-4">
                    <input
                      type="email"
                      className="w-full p-2 rounded-md border-green-800/50 shadow-xl border-2 text-black"
                      placeholder="Email Address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 px-4">
                    <input
                      type="password"
                      className="w-full p-2 rounded-md border-green-800/50 shadow-xl border-2 text-black"
                      placeholder="Password"
                      onChange={(e) => handlePasswordInput(e.target.value)}
                    />
                  </div>
                  {/* Centered 'footer' fields */}
                  <div className="flex flex-col">
                    <div className="flex flex-col md:flex-row mx-auto pt-2">
                      <div className="text-black">
                        <PasswordChecklist
                          rules={['minLength', 'specialChar', 'number', 'capital']}
                          minLength={6}
                          placeholder="password"
                          value={password}
                        />
                      </div>
                    </div>
                    <div className="p-4 mb-2 mx-auto">
                      <p className="etg-subHeading text-black text-center">Date of Birth</p>
                      <p className="etg-systemText">
                        Users must be 18 years or older to use EctorGrow.com
                      </p>
                    </div>
                    <div className="mb-4 px-4 mx-auto text-black">
                      <div className="flex items-center">
                        <DatePicker
                          onChange={(e) => setDob(e)}
                          value={dob}
                          maxDate={new Date()} // Added maxDate prop
                          yearPlaceholder="YYYY" // Added yearPlaceholder prop
                          monthPlaceholder="MM" // Added monthPlaceholder prop
                          dayPlaceholder="DD" // Added dayPlaceholder prop
                        />
                        <label className="pl-2">Date of Birth</label>
                      </div>
                    </div>
                    <div className="flex flex-row justify-center">
                      <div>
                        <NavLink href="/termsofservice" rel="noreferrer" ripple="light">
                          <p className="text-slate-600 text-xs mx-auto">Terms of Service</p>
                        </NavLink>
                      </div>
                      <div>
                        <NavLink href="/privacy" rel="noreferrer" ripple="light">
                          <p className="text-slate-600 text-xs mx-auto">Privacy Policy</p>
                        </NavLink>
                      </div>
                    </div>
                    <div className="px-4 py-2 mx-auto">
                      <Checkbox
                        color="green"
                        text="I have read the EctorGrow.com Terms of Service and Privacy Policy"
                        checkbox={tosChecked.toString()}
                        id="unknown"
                        onChange={() => setTosChecked((prev) => !prev)}
                      />
                    </div>
                  </div>
                </CardBody>
                {registerError && (
                  <div className="mx-auto text-center">
                    <p className="text-red-800">Registration Error: {registerError}</p>
                  </div>
                )}
                <CardFooter>
                  <div className="flex flex-col justify-center items-center">
                    {/* <ETGButton btnText="Register Your Account" onClickFunction={(e) => handleSubmit(e)} /> */}
                    <button className="p-3 bg-emerald-400 md:hover:bg-emerald-600 active:scale-75 active:bg-emerald-800 rounded-xl text-black uppercase tracking-wider" onClick={(e) => handleSubmit(e)}> Register Your Account </button>
                    <p>To register you must be 18+ years old and agree to the Terms of Service and Privacy Policy</p>
                  </div>

                  <div className="flex justify-center">
                    <NavLink href="/login" rel="noreferrer" ripple="light">
                      <p className="text-slate-800 text-xs italic mx-auto">Already Have An Account? Log in</p>
                    </NavLink>
                  </div>
                </CardFooter>
              </form>
            </Card>
          </div>
        </>
      )}
    </>
  );
}
