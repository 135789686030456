import React, { useState } from "react";
import ETGButton from "../../../shared/global/ETGButton";
import { projectFirestore } from "../../../../firebase/config";

export default function PlantFooterSocialView({ currentPlantData, appData }) {
  const [plantCategory, setPlantCategory] = useState(currentPlantData.plantCategory);
  const [socialTitle, setSocialTitle] = useState('');
  const [isPublic, setIsPublic] = useState(currentPlantData.isPublic);
  

  const categoryDescriptions = {
    General: "General: Includes generally every plant, this is also the default",
    Help: "Help: Issues with your plant? Select this so other users cand find your plant and offer some advice",
    Controversial: "Controversial: Non-traditional light schedules, or other controversial growing methods",
    Showoff: "Showoff: Show off a large harvest, or any plant that made you proud",
    Mother: "Mother: Any plants that are being used to clone from",
    Specialty: "Specialty: Bonsai, Solo Cup Challenge, or any other specialty grows",
  };

  const handleCategoryChange = (event) => {
    setPlantCategory(event.target.value);
  };


  const saveSocialTitle = () => {
    // Update the socialTitle field in Firestore
    const plantRef = projectFirestore.collection("plants").doc(currentPlantData.plantId);
    plantRef
      .update({ socialTitle: socialTitle })
      .then(() => {
        alert('Social Title Updated')        
      })
      .catch((error) => {
        console.error("Error updating social title:", error);
      });
  };

  const handleSocialTitleChange = (inputValue) => {
    // verify that the social title is less than 40 characters

    if (inputValue.length <= 40) {
      setSocialTitle(inputValue);
    } else {
      alert("Social Title must be less than 40 characters");
      // reset the social title to the passed value, up to 40 characters
      setSocialTitle(inputValue.substring(0, 40));      
    }
  };


  const saveCategory = () => {
    const plantRef = projectFirestore.collection("plants").doc(currentPlantData.plantId);

    // Update the plantCategory field in Firestore
    plantRef
      .update({
        plantCategory: plantCategory,
      })
      .then(() => {
        console.log("Plant category updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating plant category:", error);
      });
  };

  const handleIsPublicChange = (event) => {
    setIsPublic(event.target.value === "true");
  };

  const saveIsPublic = () => {
    const plantRef = projectFirestore.collection("plants").doc(currentPlantData.plantId);

    // Update the isPublic field in Firestore
    plantRef
      .update({
        isPublic: isPublic,
      })
      .then(() => {
        console.log("Plant public status updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating plant public status:", error);
      });
  };

  

  return ( 
    <div className="flex-row text-black px-4 m-4">
      {/* Display temporary title */}
      <div className="flex-row p-2 my-1 rounded-xl">
        <h3 className="etg-sectionTitle">Social Title</h3>
        {currentPlantData.socialTitle && <>
        <p className="etg-systemText outline-1 outline-dotted">
          {currentPlantData.socialTitle }
        </p>
        <span className="flex etg-systemText justify-center pt-2 italic">current social title</span>
        </>
        }
        <input 
          className="rounded-lg p-2 text-lg mt-2 bg-slate-200 w-full"
          type="text"
          placeholder="Add New Social Title"
          value={socialTitle}
          onChange={(e) => handleSocialTitleChange(e.target.value)}
        />
        <span className="flex justify-end etg-systemText pb-2">{ socialTitle.length } of 40 characters</span>
        <p className="etg-systemText">
          set a temporary title for this plant to be displayed on the Community page - use this to get feedback on your
          plant or to share something specific about it - save with empty characters to remove the social title
        </p>
        {/* Save Button */}
        { ( socialTitle.length > 0 || ( currentPlantData.socialTitle && socialTitle !== currentPlantData.socialTitle ) ) ? <>
          <div className="flex justify-center">
            <ETGButton btnText="Save Social Title" onClickFunction={saveSocialTitle} />
          </div>
        </> : null}
      </div>
      {/* Display Social Count Section */}
      <div className="flex-row bg-slate-200 p-2 rounded-xl">
        <h3 className="etg-sectionTitle">Social Count</h3>
        <p className="justify-center text-center m-2"> Coming Soon</p>
        <p className="etg-systemText">
          see how many times your plant has been viewed by other visitors
        </p>
      </div>

      {/* Form with Option to select a social plant category */}
      <div className="flex-row p-2">
        <h3 className="etg-sectionTitle">Plant Category</h3>
        <select className="rounded-lg p-2 text-lg mt-2" value={plantCategory} onChange={handleCategoryChange}>
          <option value="General">General</option>
          <option value="Help">Help</option>
          <option value="Controversial">Controversial</option>
          <option value="Showoff">Showoff</option>
          <option value="Mother">Mother</option>
          <option value="Specialty">Specialty</option>
        </select>
        {plantCategory !== currentPlantData.plantCategory && (
          <>
          <div className="flex justify-center">

            <ETGButton btnText="Save Change in Category" onClickFunction={saveCategory} />            
          </div>
          </>
        )}
        <p className="etg-systemText p-1">{categoryDescriptions[plantCategory]}</p>
      </div>

      {/* Display A Link to this plant directly */}
      <div className="flex-row items-center bg-slate-200 p-2 rounded-xl">
        <h3 className="etg-sectionTitle">Direct Plant Link</h3>
        <p className="flex justify-center items-center m-2 overflow-hidden">
          <span className="text-center whitespace-normal break-all">
            EctorGrow.com/grows/{appData.profileData.displayName}/{currentPlantData.plantId}
          </span>
        </p>
   
        <p className="justify-center text-center m-2 etg-systemText">Share this link with your friends or family to show off your plant</p>
        {/* button to copy link to clipboard */}
        <div className="flex justify-center">
          <ETGButton
            btnText="Copy Link"
            onClickFunction={() => {
              navigator.clipboard.writeText(
                `EctorGrow.com/grows/${appData.profileData.displayName}/${currentPlantData.plantId}`
              );
              alert("Link Copied to Clipboard");
            }}
          />

        </div>
        <p className="etg-systemText py-2">
          Direct Plant Links are currently available to all users - eventually this feature will be available only to
          Pro Users
        </p>

      </div>

      

      {/* Display radio buttons to select the isPublic property of the plant */}
      <div className="flex flex-col items-center justify-center p-2">
        <h3 className="etg-sectionTitle">Public</h3>
        <div className="p-1">
          <label className="p-1">
            <input
              type="radio"
              className="m-1 self-center"
              value="true"
              checked={isPublic}
              onChange={handleIsPublicChange}
            />
            Public  
          </label>
          <label className="p-1">
            <input
              type="radio"
              className="m-1 self-center"
              value="false"
              checked={!isPublic}
              onChange={handleIsPublicChange}
            />
            Private
          </label>
        </div>
        {isPublic !== currentPlantData.isPublic && (
          <>
            <div className="flex justify-center">
              <ETGButton btnText="Save changes" onClickFunction={saveIsPublic} />
            </div>
            
          </>
        )}
        <p className="etg-systemText">
          Public Plants are visible on the Community page
        </p>
      </div>
    </div>
  );
}