import { getAuth } from "firebase/auth";
import { projectFirestore } from "../../../../firebase/config";

export async function deleteReplyToComment(commentID, replyID) {
  const userID = getAuth().currentUser.uid;
  let result = null;

  try {
    const commentRef = projectFirestore.collection('userComments').doc(commentID);
    const replyRef = commentRef.collection('replies').doc(replyID);

    const replyDoc = await replyRef.get();

    if (replyDoc.exists) {
      const replyData = replyDoc.data();

      if (replyData.userID === userID) {
        // If the user is the owner of the reply, delete it
        await replyRef.delete();
        result = 'success';
      } else {
        result = 'error'; // User is not the owner of the reply
      }
    } else {
      result = 'error'; // Reply with the given ID does not exist
    }
  } catch (error) {
    result = 'error'; // An error occurred during deletion
  }

  return result;
}
