import React from 'react';
import Header from '../plantCard/Header';
import ImageDisplay from './ImageDisplay/ImageDisplay'
import PlantFooter from './Footer/PlantFooter';
import { daysSince } from '../../renderless/helpers';
import { timestamp } from '../../../firebase/config';
import { projectStorage } from '../../../firebase/config'
import { gaEvent } from '../../renderless/helpers';
import { useUpdatePlantData } from '../../../hooks/useUpdatePlantData';
import { returnAccountLimits } from '../../../Data/TierData';
import { useKeyPress } from '../../../hooks/useKeyPress';




export default function PlantCard({ 
    currentPlantData, currentPhase,
    appData, setAppData,
    updateErrorData,      
    appDisplayControl, setAppDisplayControl,
    nutrientVisibility, setNutrientVisibility, 
    setGrowsModal,
    userPlantData, nutrientScheduleData, currentUser }) {                            
        const [ showFullImgModal, setShowFullImgModal ] = React.useState(false)
        const { updatePlantData, updatePlantDataError } = useUpdatePlantData(appData.profileData)
        const [ isImageLoading, setIsImageLoading ] = React.useState(null)
        const hiddenImageUpload = React.useRef(null)
        const userTierLimits = returnAccountLimits(appData.profileData.accessTier)

        // console.log('currentPlantData', currentPlantData)

        // function to resize image
        // const resizeFile = (file) => 
        // new Promise((resolve) => {
        //     Resizer.imageFileResizer(file,2500,2500,"JPEG",85,0,
        //     (uri) => {
        //         resolve(uri);
        //     },"file");
        // })
        
      
    
        // function to handle adding a new image
        const handleAddPhoto = async () => {
            //check if able to add more images, per account capacity
            if (currentPlantData.imgArray.length >= userTierLimits.imgLimit) {
                alert("You have reached the image capacity of your account")
            } else {
                //prompt user for input
                hiddenImageUpload.current.click()            
            }
        }

    // function to upload a new image
const uploadNewImage = async (e) => {
    setIsImageLoading(true)
    let newPlant = currentPlantData;
  
    const today = new Date()
    const todayTimestamp = new timestamp(Math.round(today.getTime()/1000), 0)
  
    // Create a new image object
    let newImageObject = {
      caption: `Day ${daysSince(currentPlantData)} of ${currentPhase}`,
      date: todayTimestamp,
      displayType: 'img',
      favImg: false,
    };
  
    if (currentPlantData.status === 'Harvested') {
      newImageObject.caption = `${daysSince(currentPlantData)} days after harvest`;
    }
  
    // Upload the original image to the Firestore bucket/folder
    let uploadedImageBeforeCompression = e.target.files[0];
    const originalImageName = `${todayTimestamp}.${uploadedImageBeforeCompression.name}`;
    const originalUploadPath = `plantImages/${appData.profileData.uid}/${newPlant.plantId}/${originalImageName}`;
    const originalImg = await projectStorage.ref(originalUploadPath).put(uploadedImageBeforeCompression);
    const originalImgUrl = await originalImg.ref.getDownloadURL();
  
    // Resize the image
    // const resizedImage = await resizeFile(uploadedImageBeforeCompression, {
    //   maxWidth: 1500,
    //   maxHeight: 1500,
    //   compressFormat: 'JPEG',
    //   quality: 60,
    //   rotation: 0,
    //   outputType: 'file',
    // });
  
    // Append 'resized' to the end of the title of the image
    // const resizedImageName = `${todayTimestamp}.${resizedImage.name}_resized`;
  
    // Save the resized image to the Firestore bucket/folder
    // const resizedUploadPath = `plantImages/${appData.profileData.uid}/${newPlant.plantId}/${resizedImageName}`;
    // const resizedImg = await projectStorage.ref(resizedUploadPath).put(resizedImage);
    // const resizedImgUrl = await resizedImg.ref.getDownloadURL();
  
    // Determine the dimensions of the uploaded image before compression
    const uploadedImageElement = document.createElement('img');
    uploadedImageElement.src = originalImgUrl;
    uploadedImageElement.onload = () => {
      const fullHeight = `${uploadedImageElement.height}px`;
      const fullWidth = `${uploadedImageElement.width}px`;
  
      // Determine the dimensions of the resized image
      // const resizedImageElement = document.createElement('img');
      // resizedImageElement.src = resizedImgUrl;
      // resizedImageElement.onload = () => {
      //   const resizedHeight = `${resizedImageElement.height}px`;
      //   const resizedWidth = `${resizedImageElement.width}px`;
  
        // Update the new image object with the necessary fields
        newImageObject = {
          ...newImageObject,
          fullHeight,
          fullWidth,
          imgUrl: originalImgUrl          
        };        
  
        // Update the image array in the plant document
        newPlant.imgArray.unshift(newImageObject);
  
        // Loop through the imgArray and remove unnecessary fields
        newPlant.imgArray.forEach((img) => {
          delete img.imgArrayIndex;
        //   delete img.displayType;
        });
  
        // Update firestore document
         updatePlantData(newPlant);
        if (updatePlantDataError) {
          updateErrorData(updatePlantDataError);
        }        
  
        gaEvent('Plant Image Action', 'Add New Image', `User: ${appData.id}`);
        setIsImageLoading(false)
      };
    };
  
  

    

        // testing closing Main Img modal with key press
    const closeModal = useKeyPress('Escape')
    // if statement to close modal, if it is open
    if (closeModal.keyPressed && showFullImgModal) {
        setShowFullImgModal(false)
    }    
    
  return (
      <>
        
         {/* Plant Display */}
         <div className={`bg-[#2d2d2d] py-8 flex justify-center items-center ${appDisplayControl.cardVisibility}`}>
            {/* Plant Card */}
            <div className="w-full px-2 md:w-9/12 justify-center rounded-xl">
                <div className="bg-white rounded-xl shadow-xl">
                    <Header 
                        currentPhase={currentPhase} 
                        currentPlantData={currentPlantData}                                                
                        handleAddPhoto={handleAddPhoto}
                        setGrowsModal={setGrowsModal}
                        appData={appData}
                        hiddenImageUpload={hiddenImageUpload}
                        uploadNewImage={uploadNewImage}
                    />
                    <ImageDisplay
                        isImageLoading={isImageLoading} setIsImageLoading={setIsImageLoading}
                        currentPlantData={currentPlantData}                                 
                    />
                    <PlantFooter
                      currentUser={currentUser}
                        currentPlantData={currentPlantData} currentPhase={currentPhase}
                        userPlantData={userPlantData}                     
                        appData={appData} setAppData={setAppData}
                        updateErrorData={updateErrorData}                        
                        nutrientVisibility={nutrientVisibility} setNutrientVisibility={setNutrientVisibility}                        
                        setGrowsModal={setGrowsModal}
                        setShowFullImgModal={setShowFullImgModal}
                        nutrientScheduleData={nutrientScheduleData}
                    />
                </div>
            </div>
        </div> 
      </>
  )
}
