import React from 'react';
import ETGButton from '../shared/global/ETGButton';
import { generateAdminReport } from './adminScripts/generateAdminReport';
import { useCollection } from '../../hooks/useCollection';
import { timestampToDate } from '../renderless/helpers';

export default function AdminReportView() {
  const [generatingReport, setGeneratingReport] = React.useState(false);

  function handleGenerateReport() {
    setGeneratingReport(true);
    generateAdminReport();
    setGeneratingReport(false);
  }

  const adminReports = useCollection('adminReports', null, ['date', 'desc']);

  return (
    <>
      <section className='flex flex-col py-16 bg-slate-200 my-10 mx-4 rounded-xl text-black'>
        <div className='flex flex-col justify-center items-center'>
          <h1 className='etg-heading'>Dashboard</h1>
          <p className='text-xl'>Welcome to the dashboard</p>
        </div>

        {/* Display recent Admin Report Stats - possibly select through list to display older stats */}
        <div className='flex flex-col w-full justify-center items-center'>
          <div className='flex flex-row bg-slate-100 rounded-xl p-4 m-4'>
            {/* button to generate new report */}
            {generatingReport ? <p>Generating Report...</p> : ( 
              <div>
                <ETGButton
                  onClickFunction={() => handleGenerateReport()}
                  btnText='Generate New Report'
                />
              </div>
            )}
          </div>

          <div className='flex flex-col max-w-full overflow-x-auto bg-slate-100 rounded-xl p-4 m-4'>
            {/* section to display the details of the previous reports */}
            <h2 className='etg-heading'>Previous Reports</h2>

            <div className='grid grid-cols-1 md:grid-cols-12 gap-4'>
              {/* Table headers */}
              <div className='font-bold text-center col-span-1 collapse md:visible'>Report ID</div>
              <div className='font-bold text-center col-span-1 collapse md:visible'>Report Date</div>
              <div className='font-bold text-center col-span-1 collapse md:visible'>Total Users</div>
              <div className='font-bold text-center col-span-1 collapse md:visible'>Total Bugs</div>
              <div className='font-bold text-center col-span-1 collapse md:visible'>Total Errors</div>
              <div className='font-bold text-center col-span-1 collapse md:visible'>Total Feature Requests</div>
              <div className='font-bold text-center col-span-1 collapse md:visible'>Total Unset Requests</div>
              <div className='font-bold text-center col-span-1 collapse md:visible'>Total Active Plants</div>
              <div className='font-bold text-center col-span-1 collapse md:visible'>Total Harvested Plants</div>
              <div className='font-bold text-center col-span-1 collapse md:visible'>Avg. Total Plants/User</div>
              <div className='font-bold text-center col-span-1 collapse md:visible'>Avg. Active Plants/User</div>
              <div className='font-bold text-center col-span-1 collapse md:visible'>Avg. Images/Plant</div>

              {/* Table data */}
              {adminReports.documents &&
                adminReports.documents.length > 0 &&
                adminReports.documents.map((report, index) => (
                  <React.Fragment key={index}>
                    <div className='text-center col-span-1'>{report.id} <span className='visible md:invisible etg-systemText'> - report id</span></div>
                    <div className='text-center col-span-1'>{timestampToDate(report.date)} <span className='visible md:invisible etg-systemText'>- date</span> </div>
                    <div className='text-center col-span-1'>{report.totalUsers} <span className='visible md:invisible etg-systemText'>- total users</span></div>
                    <div className='text-center col-span-1'>{report.totalBugs} <span className='visible md:invisible etg-systemText'>- total bugs</span></div>
                    <div className='text-center col-span-1'>{report.totalError} <span className='visible md:invisible etg-systemText'>- total errors</span></div>
                    <div className='text-center col-span-1'>{report.totalFeatureRequests} <span className='visible md:invisible etg-systemText'>- total feature requests</span></div>
                    <div className='text-center col-span-1'>{report.totalUnsetRequests} <span className='visible md:invisible etg-systemText'>- total unset requests</span></div>
                    <div className='text-center col-span-1'>{report.totalActivePlants} <span className='visible md:invisible etg-systemText'>- total active plants</span></div>
                    <div className='text-center col-span-1'>{report.totalHarvestedPlants} <span className='visible md:invisible etg-systemText'>- total harvested plants</span></div>
                    <div className='text-center col-span-1'>{report.avgTotalPlantsPerUser} <span className='visible md:invisible etg-systemText'>- avg total plants/user</span></div>
                    <div className='text-center col-span-1'>{report.avgActivePlantsPerUser} <span className='visible md:invisible etg-systemText'>- avg active plants/user</span></div>
                    <div className='text-center col-span-1'>{report.avgImagesPerPlant} <span className='visible md:invisible etg-systemText'>- avg images/plant</span></div>
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </section>

      
    </>
  );
}
