import React from 'react'
import Note from '../plantCard/Footer/GrowLog/Note'
import ImageLogDisplay from '../plantCard/Footer/GrowLog/ImageLogDisplay'
// take the notelog and imgArray and process them into a growlog, sorted by date
// each element should have a displayType of either note or img



export function processGrowLog ( setEditNote, setEditImg, currentPlantData, publicView ) {
    // combine and sort the arrays by date - data only
    let dataArray = []
    let growLog = []  
    

    // combine both arrays data
    currentPlantData.noteLog.forEach(( note, noteLogIndex) => {
        note.displayType = 'note'
        note.noteLogIndex = noteLogIndex
        dataArray.push(note)    
    })
    currentPlantData.imgArray.forEach(( img, imgArrayIndex) => {
        img.displayType = 'img'
        img.imgArrayIndex = imgArrayIndex
        dataArray.push(img)        
    })

    // sort by date    
    // dataArray.sort(function (a, b) {            
    //     return new Date(a.date.toDate()) - new Date(b.date.toDate());
    // })

    // sort by date - reverse order
    dataArray.sort(function(a, b) {
        return new Date(b.date.toDate()) - new Date(a.date.toDate());
      });
      

    // map through sorted array, building components to display
    dataArray.forEach((growLogElement, elementIndex) => {        
    // if type note
    if (growLogElement.displayType === 'note') {                    
        growLog.push(<Note 
        prevElement={dataArray[elementIndex - 1]}
        note={growLogElement}         
        setEditNote={setEditNote}
        key={elementIndex} 
        currentPlantData={currentPlantData}        
        publicView={publicView}
        />)
    }
    
    // if type img
    if (growLogElement.displayType === 'img') {               
        growLog.push(<ImageLogDisplay 
            currentPlantData={currentPlantData}
            prevElement={dataArray[elementIndex - 1]}
            imgData={growLogElement}            
            key={elementIndex}
            setEditImg={setEditImg}            
            publicView={publicView}
        />)
    }  
})
    return growLog
}