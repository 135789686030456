import React from 'react'
import { IoChevronForwardCircleOutline, IoChevronBackCircleOutline } from 'react-icons/io5'
import DashbpardPlantsView from './DashboardPlantsView'
import DashboardHeader from './DashboardHeader'
import DashboardGrowSpaceView from './DashboardGrowSpaceView'
import InfoBubbleDisplay from './InfoBubbleDisplay'
import SocialStats from './Headers/SocialStats'

export default function DashboardMain({ currentUser, appData, setAppData, currentPhase, setCurrentPhase, setGrowsModal, 
    handleNewPlantSelected, currentPlantData, setActiveNutrientObj, nutrientScheduleData }) {
    const [ dashboardPosition, setDashboardPosition ] = React.useState(0)
    const [ title, setTitle ] = React.useState('Plants') 
 
    // useEffect to change dashboardDisplay based on dashboardPosition
    React.useEffect(() => {        
    // switch statement to change dashboardDisplay based on dashboardPosition, default is plants, grows, nutrients & stats being the other options
        switch (dashboardPosition) {
            case 0:                
                setTitle('Plants')                
                break;
            case 1:                
                setTitle('Grow Space')                
                break;
            case 2:                
                setTitle('Results')                
                break;
            default:
                setTitle('Plants')                
                break;
        }
    }, [dashboardPosition])

    function handleMovePositionForward () {
        // if dashboard position is less than 2, increment by 1, else set to 0
        if (dashboardPosition < 2) {        
            setDashboardPosition(dashboardPosition + 1)
        } else {
            setDashboardPosition(0)
        }
    }

    function handleMovePostionBackward() {
        // if dashboard position is greater than 0, decrement by 1, else set to 2
        if (dashboardPosition > 0) {
            setDashboardPosition(dashboardPosition - 1)
        }        
        else {
            setDashboardPosition(2)            
        }

    }
    
  return ( <>
    <section className='flex flex-col w-10/12 bg-white text-black rounded-xl mx-auto mb-4'>
        {/* Header - first/upper section that extends above the section */}
        <DashboardHeader appData={appData} setAppData={setAppData} />

        {/* Display the dashboard, based on switch  */}
        <div className='flex flex-col -ml-4 -mr-4 h-[420px] overflow-auto justify-center bg-emerald-700 shadow-xl rounded-xl'>
            {/* Plants View */}
            { title === 'Plants' ? <DashbpardPlantsView appData={appData} setGrowsModal={setGrowsModal}
                currentPhase={currentPhase} setCurrentPhase={setCurrentPhase}
                handleNewPlantSelected={handleNewPlantSelected} currentPlantData={currentPlantData} />
            : null }

            {/* Grow Space View */}
            { title === 'Grow Space' ? <DashboardGrowSpaceView appData={appData}
                setActiveNutrientObj={setActiveNutrientObj} setGrowsModal={setGrowsModal} nutrientScheduleData={nutrientScheduleData} 
            /> : null }

            {/* Results View */}
            { title === 'Results' ? 
                <SocialStats appData={appData} />
             : null }
        </div>

        {/* Lower section for title and toggle */}
        <div className='flex flex-col'>
            {/* Info Bubble Display - only visble to owner */}
            { !appData.publicView ? <>
            <div className='flex flex-col border-b-2 py-2 border-b-slate-300 justify-between bg-emerald-700'>
                <InfoBubbleDisplay appData={appData} />                    
            </div>
            </> : null}

            <div className='flex flex-row justify-between bg-slate-200 rounded-b-xl px-4'>
  {/* back button */}
  <button
    onClick={handleMovePostionBackward}
    className='bg-gray-200 md:hover:bg-emerald-400 active:scale-75 md:active:bg-emerald-700 rounded-full h-8 w-8 flex justify-center items-center my-auto'
  >
    <IoChevronBackCircleOutline />
  </button>
  {/* title in the center */}
  <div className='flex justify-center items-center my-auto text-center p-2 flex-1'>
    <h2 className='text-2xl font-bold'>{title} - {appData.profileData.displayName}</h2>
  </div>
  {/* forward button */}
  <button
    onClick={handleMovePositionForward}
    className='bg-gray-200 md:hover:bg-emerald-400 active:scale-75 md:active:bg-emerald-700 rounded-full h-8 w-8 flex justify-center items-center my-auto'
  >
    <IoChevronForwardCircleOutline />
  </button>
</div>

        </div>
    </section>
</>)
}
