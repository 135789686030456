import React, { useState, useEffect } from 'react';
import { BsTrash, BsReply, BsFlag } from 'react-icons/bs';
import { projectFirestore } from '../../../firebase/config';

export default function CommentActions({ onDelete, setAddReply, setReplyTo, onFlag, currentUser, comment, toggleReplies, showReplies }) {  
  const [hasReplies, setHasReplies] = useState(false);

  useEffect(() => {
    const fetchReplies = async () => {
      try {
        const commentRef = projectFirestore.collection('userComments').doc(comment.commentID);
        const repliesCollectionRef = commentRef.collection('replies');
        const querySnapshot = await repliesCollectionRef.get();

        if (!querySnapshot.empty) {
          setHasReplies(true);
        } else {
          setHasReplies(false);
        }
      } catch (error) {
        console.error('Error fetching replies:', error);
      }
    };

    fetchReplies();
  }, [comment.commentID]);

  const handleAddReply = () => {
    setAddReply(true);
    setReplyTo(comment.commentID)
  }

  return (
    <div className="flex flex-wrap justify-end mt-1 gap-2 items-center">
      {/* Check that the user is logged in, and they did not post this comment */}
      {currentUser && currentUser.uid !== comment.userID ? (
        <>          
          {/* Reply Button */}
          <button
            className="px-4 py-2 rounded-xl bg-emerald-400 text-white font-medium flex items-center transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
            onClick={() => handleAddReply()}
          >
            <BsReply /> <span className="ml-2">Reply</span>
          </button>
          </>) : null } 
          {/* if comment has replies, display button to toggleReplies */}
          {hasReplies && (
            <button
              className="px-4 py-2 rounded-xl bg-sky-500 text-white font-medium flex items-center transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
              onClick={toggleReplies}
            >
              {showReplies ? 'Hide Replies' : 'Show Replies'}
            </button>
          )}

        {currentUser && currentUser.uid !== comment.userID ? (
          <>
          {/* Flag Button */}
          <button
            className="px-4 py-2 rounded-xl bg-amber-400 text-white font-medium flex items-center transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
            onClick={onFlag}
          >
            <BsFlag /> <span className="ml-2">Report</span>
          </button>
        </>
      ) : null}


      {/* Delete Button */}
      {/* only show if is owner of comment */}
      { currentUser && currentUser.uid === comment.userID ? <>
      <button
        className="px-4 py-2 rounded-xl bg-red-400 text-white font-delete flex items-center transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
        onClick={onDelete}
        >
        <BsTrash /> <span className="ml-2">Delete</span>
      </button>
        </> : null}
    </div>
  );
}
