import { projectFirestore } from "../../../firebase/config";

export const removeOrderDelay = async (orderID) => {
  const orderRef = projectFirestore.collection("growLabelOrders").doc(orderID);

  try {
    const orderSnapshot = await orderRef.get();
    if (orderSnapshot.exists) {
      const orderData = orderSnapshot.data();
      delete orderData.delay; // Remove the 'delay' property

      await orderRef.set(orderData); // Set the modified data back to the document
    }
  } catch (err) {
    console.error(err);
  }
};
