import React, { useRef, useEffect, useState } from 'react';
import { useCollection } from '../../hooks/useCollection';
import LoadingDiv from '../LoadingDiv';
import RequestCard from './RequestCard';

export default function AdminTaskView() {
  // Load All Current Support Requests
  const supportRequestsCollection = useCollection('adminSupportTask');
  console.log('supportRequestsCollection', supportRequestsCollection);

  // Initialize Admin Stats
  const numBugs = useRef(0);
  const numOpenRequests = useRef(0); 
  const numUnsetRequests = useRef(0);
  const numArchivedRequests = useRef(0);
  const numComment = useRef(0);

  useEffect(() => {
    calcSupportStats(
      supportRequestsCollection,
      numBugs,
      numOpenRequests,      
      numUnsetRequests,
      numArchivedRequests,
      numComment
    );
  }, [supportRequestsCollection]);

  // Set Options & Select Lists
  const categoryOptions = ['Bug', 'Feedback', 'Other', 'Flagged Social Post', 'Flagged Plant', 'Flagged Comment-Reply'];
  const statusOptions = ['Submitted', 'In Progress', 'Complete', 'NP-Complete'];
  const priorityOptions = ['unset', 'Low', 'Medium', 'High', 'Critical'];

  const [selectedCategory, setSelectedCategory] = useState(null); // State to store selected category
  const [selectedStatus, setSelectedStatus] = useState(null); // State to store selected status
  const [selectedPriority, setSelectedPriority] = useState(null); // State to store selected priority

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
  };

  const handlePriorityClick = (priority) => {
    setSelectedPriority(priority);
  };
  // Function to get the count of tasks for a specific category
  const getCategoryCount = (category) => {
    if (supportRequestsCollection.documents) {
      return supportRequestsCollection.documents.filter((request) => {
        // Check if the request's category matches the selected category
        const isCategoryMatch = request.category === category;
  
        // Check if the request status is not 'Complete' or 'NP-Complete'
        const isNotComplete = request.status !== 'Complete' && request.status !== 'NP-Complete';
  
        // If the category is 'Flagged Social Post' or 'Feedback', further filter by status
        if (category === 'Flagged Social Post' || category === 'Feedback') {
          return isCategoryMatch && isNotComplete;
        }
  
        // For other categories, only match on category and status
        return isCategoryMatch && isNotComplete;
      }).length;
    }
    return 0;
  };

  // Function to get the count of tasks for a specific status
  const getStatusCount = (status) => {
    if (supportRequestsCollection.documents) {
      return supportRequestsCollection.documents.filter((request) => {
        return request.status === status;
      }).length;
    }
    return 0;
  };

  // Function to get the count of tasks for a specific priority
  const getPriorityCount = (priority) => {
    if (supportRequestsCollection.documents) {
      return supportRequestsCollection.documents.filter((request) => {
        return request.priority === priority;
      }).length;
    }
    return 0;
  };
  
  
  

  // Create list items for category options
  const categoryOptionsFilterItems = categoryOptions.map((category, index) => (
    <div
      key={`category${index}`}
      onClick={() => handleCategoryClick(category)}
      className={`cursor-pointer ${
        selectedCategory === category ? 'text-blue-500 font-semibold' : 'text-black'
      }`}
    >
      {category} ({getCategoryCount(category)})
    </div>
  ));
    
    

  const categoryOptionsListItems = categoryOptions.map((category, index) => (
    <option key={`category${index}`}> {category}</option>
  ));

  const statusOptionsListItems = statusOptions.map((status, index) => (
    <option key={`status${index}`}> {status}</option>
  ));
  const priorityOptionsListItems = priorityOptions.map((priority, index) => (
    <option key={`priority${index}`}> {priority}</option>
  ));


// Filter the supportRequestsCollection based on the selected category, status, and priority
const filteredRequests = (supportRequestsCollection.documents || []).filter((request) => {
  // Check if the request's category matches the selected category
  const isCategoryMatch = selectedCategory === 'Completed'
    ? request.status === 'Complete' || request.status === 'NP-Complete'
    : request.category === selectedCategory;

  // Check if the request status matches the selected status
  const isStatusMatch = !selectedStatus || request.status === selectedStatus;

  // Check if the request priority matches the selected priority
  const isPriorityMatch = !selectedPriority || request.priority === selectedPriority;

  // Return true if all conditions are met
  return isCategoryMatch && isStatusMatch && isPriorityMatch;
});

console.log('filteredRequests', filteredRequests);





  // If loading support requests, display loading div, else calculate initial values
  // If loading support requests, display loading div, else calculate initial values
if (!supportRequestsCollection?.documents) {
    console.log('supportRequestsCollection', supportRequestsCollection);
    return <LoadingDiv />;
  }
  

  return (
    <>
      <section className='flex flex-col justify-center w-10/12 m-auto bg-gray-100 rounded-2xl my-10'>
        {/* View Title */}
        <div className='flex m-auto p-4 my-4'>
          <h3 className='text-black text-2xl'>Support Requests</h3>
        </div>

        {/* Support Request Stats */}
        <div className='flex flex-wrap w-10/12 justify-center text-black gap-4 space-evenly mx-auto p-4 border-2 border-emerald-800 rounded-md'>
          {categoryOptionsFilterItems}
        </div>
        <div className="flex flex-wrap w-10/12 justify-center text-black gap-4 space-evenly mx-auto p-4 border-2 border-emerald-800 rounded-md">
        {statusOptions.map((status, index) => (
          <div
            key={`status${index}`}
            onClick={() => handleStatusClick(status)}
            className={`cursor-pointer ${
              selectedStatus === status ? 'text-blue-500 font-semibold' : 'text-black'
            }`}
          >
            {status} ({getStatusCount(status)})
          </div>
        ))}
      </div>

      <div className="flex flex-wrap w-10/12 justify-center text-black gap-4 space-evenly mx-auto p-4 border-2 border-emerald-800 rounded-md">
        {priorityOptions.map((priority, index) => (
          <div
            key={`priority${index}`}
            onClick={() => handlePriorityClick(priority)}
            className={`cursor-pointer ${
              selectedPriority === priority ? 'text-blue-500 font-semibold' : 'text-black'
            }`}
          >
            {priority} ({getPriorityCount(priority)})
          </div>
        ))}
      </div>

        {/* List of Support Requests */}
        <div className='text-black text-center py-2'>
          {filteredRequests.length > 0 ? (
            filteredRequests.map((request, index) => (
              <RequestCard
                request={request}                
                categoryOptionsListItems={categoryOptionsListItems}
                statusOptionsListItems={statusOptionsListItems}
                priorityOptionsListItems={priorityOptionsListItems}
                key={index}
              />
            ))
          ) : (
            <div>
              <p>No Current Requests</p>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

function calcSupportStats(
  supportRequestsCollection,
  numBugs,
  numOpenRequests,  
  numUnsetRequests,
  numArchivedRequests,
  numComment
) {
  let tempNumOpenBugs = 0;
  let tempOpenFeatureRequests = 0;
  let tempUnsetRequests = 0;
  let tempArchivedRequests = 0;
  let tempCommentRequests = 0;  
  if (supportRequestsCollection.documents) {
    // Load # of reported bugs
    for (let index = 0; index < supportRequestsCollection.documents.length; index++) {
      const request = supportRequestsCollection.documents[index];
      // determine # of open bugs
      if (request.category === 'Bug' && request.status !== 'Complete' && request.status !== 'NP-Complete') {
        tempNumOpenBugs += 1;
      }
      // determine open comment requests
      if (request.category === 'Comment' && request.status !== 'Complete' && request.status !== 'NP-Complete') {
        tempCommentRequests += 1;
      }
      // determine open feature requests
      if (
        request.category === 'Feature Suggestion' &&
        request.status !== 'Complete' &&
        request.status !== 'NP-Complete'
      ) {
        tempOpenFeatureRequests += 1;
      }      
      // determine # of unset requests
      if (request.priority === 'unset') {
        tempUnsetRequests += 1;
      }
      // determine # of archived requests
      if (request.priority === 'Complete' || request.status === 'NP-Complete') {
        tempArchivedRequests += 1;
      }
    }
  }
  // set references
  numBugs.current = tempNumOpenBugs;
  numOpenRequests.current = tempOpenFeatureRequests;  
  numUnsetRequests.current = tempUnsetRequests;
  numArchivedRequests.current = tempArchivedRequests;
  numComment.current = tempCommentRequests;
}
