import { projectFirestore } from "../../../firebase/config";


// returns an array with nested arrays with user displayName with their UID

export async function loadDisplayNames( withUID = false ) {
    let tempList = []
    
    const snapshot = await projectFirestore.collection('growProfiles').get()                      
    let result = snapshot.docs.map(doc => doc.data())
    
    if (withUID) {
        for (let index = 0; index < result.length; index++) {
            const displayName = result[index].displayName;
            const id = result[index].uid
    
            tempList.push([displayName, id])            
            
            // when the temp list array length matches the length of 
            // current user displayNames -> add the tempList to userDisplayNames
            if (tempList.length === result.length) {      
                
                return tempList
            }                   
        }                
    } else {
        for (let index = 0; index < result.length; index++) {
            const displayName = result[index].displayName;            
    
            tempList.push(displayName)            
            
            // when the temp list array length matches the length of 
            // current user displayNames -> add the tempList to userDisplayNames
            if (tempList.length === result.length) {      
                
                return tempList
            }                   
        }                
        
    }
    
}