import DefaultNavbar from '../components/DefaultNavbar';
import DefaultFooter from '../components/DefaultFooter';
import React, { useState } from 'react';
import { useAppData } from '../hooks/useAppData';
import LoadingDiv from '../components/LoadingDiv';
import ProfileModal from '../components/profile/Modals/ProfileModal';
import SocialInfoSettings  from '../components/profile/SocialInfoSettings';
import PreferencesSettings from '../components/profile/PreferencesSettings';
import ETGOrdersAndAccountLevelSubscription from '../components/profile/ETGOrdersAndAccountLevelSubscription'
import AccountSettings from '../components/profile/AccountSettings'
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';
import { GApageView } from '../components/renderless/helpers';
import useGrowLabelOrdersListener from '../hooks/useGrowLabelOrderListener';





export default function Dev({ user }) {   
    // Error Handiling and Modal Management    
    const [ profileModal, setProfileModal ] = useState(false)
    const { etgErrorDetected, updateErrorData } = React.useContext(ErrorHandilingContext)
    // here temporarily for nutrient schedules
    
    const [ isPageLoading, setIsPageLoading ] = useState(true)

    
    
    // BEGIN DATA LOADING SECTION
    const [id, setId] = useState('notloggedin')
    if ((user) && (id !== user.uid)) {
        setId(user.uid)
    }
    // useHook to load previous orders
    const previousOrderData = useGrowLabelOrdersListener(null, id)

    // Load data from firestore
    const { profileDocument } = useAppData(id)    
    if (isPageLoading && profileDocument) {
        setIsPageLoading(false)
    }    
    // END DATA LOADING SECTION


    function handleProfileModalToggle() {
        setProfileModal(!profileModal)
    }

    


    React.useEffect(() => {                
        GApageView('profile')    
    }, [])
        
    return (
        <>  { etgErrorDetected ? null : 
            <section className='flex flex-col h-screen justify-between'>
                {/* Navbar */}
                <div className="w-full z-20">
                    <DefaultNavbar user={user} profileData={profileDocument} />
                </div> 
                {/* Page Modals */}                            
                <div className='flex flex-col' id='profile-modal'>
                    { profileModal ? <ProfileModal profileModal={profileModal} updateErrorData={updateErrorData} handleProfileModalToggle={handleProfileModalToggle} profileDocument={profileDocument} /> : null}
                </div>                              
                
                {/* Page Specific Content */}
                { isPageLoading ? <LoadingDiv /> : 
                
                <main className='w-10/12 mx-auto bg-white rounded-md my-4'>
                    {/* check for multiple conditions  */}
                    { profileModal ? null : <>
                        

                        {/* Social Info / Settings */}
                        <SocialInfoSettings profileDocument={profileDocument} updateErrorData={updateErrorData} handleProfileModalToggle={handleProfileModalToggle} user={user} setProfileModal={setProfileModal} />

                        {/* Preferences & Settings */}
                        { <PreferencesSettings updateErrorData={updateErrorData} profileDocument={profileDocument} />}

                        {/* ETG Orders - Account Level &  Subscription Settings */}
                        <ETGOrdersAndAccountLevelSubscription previousOrderData={previousOrderData} displayName={profileDocument.displayName}    />

                        {/* Account Settings */}
                        <AccountSettings setProfileModal={setProfileModal} updateErrorData={updateErrorData} profileDocument={profileDocument} user={user} />                        
                                
                    </>} 
                </main>
                }
                
                {/* Footer Display */}
                <DefaultFooter />                                
            </section>
}   </>
    );
}


