export function commentTsToDate(timestamp) {
    let tempDate = timestamp.toDate();
  
    // Format date in dd/mm/yy format
    const dateOptions = { day: 'numeric', month: 'numeric', year: '2-digit' };
    const date = tempDate.toLocaleDateString("en-US", dateOptions);
  
    // Format time in ##:##am pattern
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const time = tempDate.toLocaleTimeString("en-US", timeOptions);
  
    return { date, time };
  }
  