// CommentHeader.js
import React from 'react';

export default function CommentHeader({ displayName, handleProfileClick, userID, dateTime }) {

  return (
    <div className="flex flex-col md:flex-row w-full items-center justify-between">
      <h2 onClick={() => handleProfileClick(userID)} className="text-lg font-medium hover:cursor-pointer">{displayName}</h2>      
      <p>{dateTime.date} <span className='etg-systemText'>@ {dateTime.time}</span></p>
    </div>
  );
}