import { getAuth } from 'firebase/auth';
import { projectFirestore, timestamp } from '../../../../firebase/config';
import { v4 as uuidv4 } from 'uuid';

export async function addComment(documentID, type, text) {
  const userID = getAuth().currentUser.uid;
  let result = null;
  let date = new Date();

  try {
    // Use uuid to get a unique id for the comment
    const commentID = uuidv4();
    const comment = {
      date: new timestamp(Math.round(date.getTime() / 1000), 0),
      likes: [userID],
      documentID,
      commentID,
      type,
      text,
      userID,      
    };

    // Add the comment to the "userComments" collection
    await projectFirestore.collection('userComments').doc(commentID).set(comment);

    result = 'success';
  } catch (error) {
    result = 'error';
  }

  return result;
}
