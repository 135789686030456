import { projectFirestore, timestamp } from "../../../firebase/config"
import { gaEvent } from "../helpers"



export async function addNewNote( appData, currentPlantData, currentPhase, date, comment, type = 'u', rating = false, budSize = false) {    
        const plantCollectionRef = projectFirestore.collection('plants')
    
        
        let updatedPlantData = Object.assign({}, appData.plantData)
        let plantCopy = Object.assign({}, currentPlantData)
        //convert note date to timestamp
        let temmpDate = new Date(date)
        
        let noteDate = new timestamp(Math.round(temmpDate.getTime()/1000), 0)       
    
        
        //build note object
        let noteObj = {
            comment,
            date: noteDate, 
            type
        }        
        // if rating is true, add rating to note object
        if(rating) {
            noteObj.rating = rating
        }
        // if budSize is true, add budSize to note object
        if(budSize) {
            noteObj.budSize = budSize
        }
        
        // //add new note to notelog array        
        plantCopy.noteLog = [ ...plantCopy.noteLog, noteObj]      
        
        //sort the updated noteLog, this will reorganize the noteLog by date before saving changes
        plantCopy.noteLog.sort(function (a, b) {            
            return new Date(a.date.toDate()) - new Date(b.date.toDate());
        })        
        let posLastNoteLocationPosition = null
        // Find position of last element that contains the correct note type
        plantCopy.noteLog.forEach((note, index) => {
            if (note.type === 'lc-started' || 'lc-transfer' || 'lc-move') {
                posLastNoteLocationPosition = index
            }
        })
        // Using the most recent note containing a location/position, set the currents plant location/position based on movement type
        if((plantCopy.noteLog[posLastNoteLocationPosition].type === 'lc-transfer') || (plantCopy.noteLog[posLastNoteLocationPosition].type === 'lc-move')) {
            plantCopy.location = plantCopy.noteLog[posLastNoteLocationPosition].locationEnd
            plantCopy.position = plantCopy.noteLog[posLastNoteLocationPosition].positionEnd                    
        }

        if(plantCopy.noteLog[posLastNoteLocationPosition].type === 'lc-started') {
            plantCopy.location = plantCopy.noteLog[posLastNoteLocationPosition].locationStart
            plantCopy.position = plantCopy.noteLog[posLastNoteLocationPosition].positionStart
        }
        
        // //re create plantdata object        
        let plantIndex = null
        
        //use find index to first find index position to replace
        if(currentPhase === 'Harvested') {
            plantIndex = updatedPlantData.harvestedPlants.findIndex((plant) => plant.plantId === currentPlantData.plantId)        
            //remove object from array with existing plantId
            updatedPlantData.harvestedPlants.splice(plantIndex, 1, plantCopy)    
        } else {            
            plantIndex = updatedPlantData.activePlants.findIndex((plant) => plant.plantId === currentPlantData.plantId)        
            //remove object from array with existing plantId
            updatedPlantData.activePlants.splice(plantIndex, 1, plantCopy)    
        }   
        
        let results = { plantCopy}
        
        //save new plant object to DB
        try {
            await plantCollectionRef.doc(currentPlantData.plantId).update(plantCopy).then(() => {
                results.success = true
            })
            
        } catch (error) {
            results.error = error
        }
        
        gaEvent('Plant Note Action', 'Add New Note', `User: ${appData.id}`)        


        
        return results
    }  