import React, { useState, useEffect } from 'react';
import { useFirestore } from '../../hooks/useFirestore';

export default function PreferencesSettings({ updateErrorData, profileDocument }) {
    // State for nutrient reset email toggle and number of days
    const [nutrientResetEmails, setNutrientResetEmails] = useState(profileDocument.wantsNutrientResetEmail || false);
    const [resetEmailDays, setResetEmailDays] = useState(profileDocument.resetEmailNumDays || 7);
    const [changesToSave, setChangesToSave] = useState(false);
    const { updateDocument, response } = useFirestore('growProfiles', profileDocument.uid)
    // console.log('profileDocument', profileDocument)

    // useEffect to check if there are changes to save
    useEffect(() => {
        if (profileDocument.wantsNutrientResetEmail !== nutrientResetEmails || profileDocument.resetEmailNumDays !== resetEmailDays) {
            setChangesToSave(true);
        } else {
            setChangesToSave(false);
        }
    }, [nutrientResetEmails, resetEmailDays, profileDocument.wantsNutrientResetEmail, profileDocument.resetEmailNumDays]);

    
    // function to update the user's profile document in the 'growerProfiles' collection
    async function handleSaveChanges() {
        // clone profile document, update and upload new version
        let clonedProfileDocument = Object.assign({}, profileDocument)
        clonedProfileDocument.wantsNutrientResetEmail = nutrientResetEmails
        clonedProfileDocument.resetEmailNumDays = resetEmailDays

        await updateDocument( profileDocument.uid, clonedProfileDocument)
        if (response.error) {
            console.log(`Error with updating nutrient reset emails.` + response.error)
        }
        setChangesToSave(false)        
    }
    
    

    return (
        <section className='flex flex-col bg-slate-600 p-4 rounded-xl -mx-6 mt-10 h-fit'>
            <h3 className='etg-subHeading'>EctorGrow Preferences & Settings</h3>

            {/* Section for Plant Nutrient Reset Emails */}
            <div className='flex flex-col text-white m-4'>
                <h3 className='text-xl'>Plant 'Nutrient Reset' Emails</h3>
                <p className='m-1 mb-4 text-sm italic'>
                    An email will be sent to your registered email if a plant has gone more than the desired 
                    number of days without a nutrient reset.
                </p>
                <label className='text-lg'>
                    Enable EctorGrow to email you about Nutrient Resets:
                    <input
                        type="checkbox"
                        className='text-black p-1 m-1'
                        checked={nutrientResetEmails}
                        onChange={(e) => setNutrientResetEmails(e.target.checked)}
                    />
                </label>

                {nutrientResetEmails && (
                   <> <label className='text-lg py-2'>
                        How many days since the last 'Nutrient Reset' should we wait before emailing?:
                        <input
                            type="number"
                            min="6"
                            max="14"
                            className='text-black p-1 m-1 rounded-xl'
                            value={resetEmailDays}
                            onChange={(e) => setResetEmailDays(Math.min(Math.max(e.target.value, 6), 14))}
                        /> 
                    </label>
                <p className='m-1 mb-4 text-sm italic'>You can select between (6-14 days)</p>                    
                </>
                )}


                {/* Button to save changes */}
                {changesToSave ? <> 
                <div className='flex flex-row justify-end'>
                    <button
                        className={`etg-btn ${changesToSave ? 'etg-btn-primary' : 'etg-btn-disabled'}`}
                        disabled={!changesToSave}
                        onClick={() => handleSaveChanges()}
                        
                        >
                        Save Changes
                    </button>                

                </div>
                </> : null }
            </div>

            {/* Other settings can be added here */}
        </section>
    );
}
