import React from 'react';
import { BsTrash, BsFlag } from 'react-icons/bs';
import CommentHeader from './CommentHeader';
import CommentText from './CommentText';

export default function ReplyDisplayForReplies({
  reply,
  comment,
  getDisplayName,
  getUserThumbnail,
  commentTsToDate,
  handleDeleteReply,
  currentUser,
  userData,
  handleProfileClick,
  handleFlagCommentReply
  
}) {
  


  return (
    <div key={reply.replyID} className="mt-2 grid grid-cols-12 bg-sky-500 text-black p-4 rounded-xl">
      <div className="col-span-12 flex">
        <div className="mx-2 flex h-16 w-16 items-center justify-center overflow-hidden rounded-full flex-shrink-0">
          <img 
            className='hover:cursor-pointer'
            onClick={() => handleProfileClick(reply.userID)}q
            src={getUserThumbnail(reply.userID, userData)}
            alt="user thumbnail" />
        </div>
        <div className="flex flex-col ml-4 p-2 w-full justify-center">
          <CommentHeader 
            displayName={getDisplayName(reply.userID)} 
            dateTime={commentTsToDate(reply.date)}
            handleProfileClick={handleProfileClick}
            userID={reply.userID}
            />
          <CommentText text={reply.text} />
        </div>
      </div>
      
      <div className="col-span-12 flex flex-wrap justify-end mt-1 gap-2 items-center">        

        { (currentUser && currentUser.uid === reply.userID) && (
          <button
            className="px-4 py-2 rounded-xl bg-red-400 text-white font-delete flex items-center transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
            onClick={() => handleDeleteReply(reply.replyID)}
          >
            <BsTrash /> <span className="ml-2">Delete</span>
          </button>
        )}

        { (currentUser && currentUser.uid !== reply.userID) && (
          <button
            className="px-4 py-2 rounded-xl bg-amber-400 text-white font-medium flex items-center transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
            onClick={() => handleFlagCommentReply(comment.commentID, reply.replyID, 'reply-reply', reply.userID)}
          >
            <BsFlag />
            <span className="ml-2">Report</span>
          </button>
        )}
      </div>

    </div>
  );
}
