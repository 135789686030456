import React, { useState } from 'react'
import { AdminStats } from './AdminStats'
import { getGrowProfiles } from '../renderless/GetData/getGrowProfiles'


export default function AdminDashboard({ setAdminView }) {
    const [ numRegisteredUsers, setNumRegisteredUsers ] = useState(0)
    
    getGrowProfiles().then(result => {    
        // numRegisteredUsers.current = result.length 
        setNumRegisteredUsers(result.length)                            
    })    
    
   
    
  return (<>
    <section className='flex flex-col py-16 bg-slate-200 my-10 mx-4 rounded-xl text-black'>
        {/* Page Title */}
        <div className='flex flex-col justify-center text-center'>
            <h2 className='text-2xl'>Ector Grow Administrator Dashboard</h2>
        </div>

        {/* Section for Admin Stats */}
        {numRegisteredUsers > 0 ? <AdminStats numRegisteredUsers={numRegisteredUsers} /> :
        <p className='m-auto'>loading number of users...</p> }       

        {/* Section for selecting potential views */}
        <section className=''> 
            <div className='flex flex-col justify-center text-center'>
                <h2 className='text-2xl'>Select a View</h2>
            </div>
            <div className='flex flex-wrap gap-4 justify-center text-center'>

                <div>
                    <button 
                      className='bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded-full my-2'
                      onClick={() => setAdminView('adminReport')}
                    >Admin Report</button>
                </div>
                <div>
                    <button 
                      className='bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded-full my-2'
                      onClick={() => setAdminView('adminTasks')}
                    >Admin Tasks</button>
                </div>
                <div>
                    <button 
                      className='bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded-full my-2'
                      onClick={() => setAdminView('maintenance')}
                    >Maintenance</button>
                </div>
                <div>
                    <button 
                      className='bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded-full my-2'
                      onClick={() => setAdminView('userManagement')}
                  >User Management</button>
                </div>
                <div>
                    <button
                      className='bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded-full my-2'
                      onClick={() => setAdminView('labelOrderManagement')}
                    >Label Order Management</button>
                </div>
            </div>            
        </section>


    </section>
  </>)
}
