import React, { useState } from 'react'
import { timestampToDate } from '../../../../renderless/helpers';
import NoteDateDayOfDisplay from './NoteDateDayOfDisplay';
import NoteDateDisplay from './NoteDateDisplay';
import { BsPersonFill } from 'react-icons/bs'
import { TbPlant } from 'react-icons/tb'
import { GrPowerCycle, GrScorecard } from 'react-icons/gr'
import { RiGuideLine } from 'react-icons/ri'
import { BiCut } from 'react-icons/bi'
import { IoWaterOutline } from 'react-icons/io5'
import { FiArchive } from 'react-icons/fi'
import { GiNoseFront } from 'react-icons/gi'
import ETGIconBtn from '../../../../shared/global/ETGIconBtn';


export default function Note({ note, setEditNote, currentPlantData, publicView, prevElement }) {
  
  const [ dateDisplayToggle, setDateDisplayToggle ] = useState
  (true)
  const [ isSameDateAsPrev, setIsSameDateAsPrev ] = useState(false)
  let noteDate = timestampToDate(note.date)
  let noteDateFormatted = new Date(noteDate)
  let startDate = timestampToDate(currentPlantData.startDate)
  startDate = new Date(startDate)
  let transferDate = timestampToDate(currentPlantData.transferDate)
  transferDate = new Date(transferDate)
  let harvestDate = timestampToDate(currentPlantData.harvestDate)
  harvestDate = new Date(harvestDate)

  let defaultDate = new Date('04/20/2020')  
  let prevElementDate = null
  if (prevElement) {
    prevElementDate = timestampToDate(prevElement.date)
    
    if (prevElementDate === noteDate && !isSameDateAsPrev) {
      setIsSameDateAsPrev(true)
    }
    
  }
  
  const dayOf = () => {
    // determine phase the plant was in on date of note
    let phase
    let day


    // deteremine if Vegetative
    if (
      (noteDateFormatted > startDate || noteDateFormatted.getTime() === startDate.getTime()) && 
      (noteDateFormatted < transferDate || transferDate.getTime() === defaultDate.getTime()) ) {
      let differenceInTime = Math.abs(noteDateFormatted - startDate)
      let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))
      if (differenceInDays === 0) {
        differenceInDays = 1
      }
      day = differenceInDays
      phase = 'Vegetative'
    } else if (
      (noteDateFormatted > transferDate || noteDateFormatted.getTime() === transferDate.getTime()) && 
      ((noteDateFormatted < harvestDate || noteDateFormatted.getTime() === harvestDate.getTime()) || harvestDate.getTime() === defaultDate.getTime()) ){
      let differenceInTime = Math.abs(noteDateFormatted - transferDate)
      let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))
      if (differenceInDays === 0) {
        differenceInDays = 1
      }
      day = differenceInDays
      phase = 'Flower'      
    } else {
      let differenceInTime = Math.abs(noteDateFormatted - harvestDate)
      let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))
      if (differenceInDays === 0) {
        differenceInDays = 1
      }
      day = differenceInDays
      phase = 'Harvest'

    } 

    let dayOfDisplay = <div>
      <p className=''>
      Day {day} 
      </p>
      <p className='text-xs'>
        {phase}
      </p>
      
    </div>

    return dayOfDisplay

  }
  
  
    const iconDisplay = ( type ) => {
      let returnSymbol = <TbPlant />
      switch (type) {
        case 'lc-trim':
          // when trimming a plant in both veg and flower
          returnSymbol = <BiCut />
          break;
        case 'harvest-wet-trim':
          // when trimming a plant while harvested
          returnSymbol = <BiCut />
          break;
        case 'harvest-dry-trim':
          // when trimming a plant while harvested
          returnSymbol = <BiCut />
          break;
        case 'lc-training':
          // when training a plant
          returnSymbol = <RiGuideLine />
          break;
        case 'lc-nutrient':
          // when adding nutrients
          returnSymbol = <GrPowerCycle />
          break;
        case 'lc-water':
          // when watering a plant
          returnSymbol = <IoWaterOutline />
          break;
        case 'lc-auto':
          // when a plant is automatically created          
          returnSymbol = <TbPlant />
          break;
        case 'lc-started':
            // when starting a plant
            returnSymbol = <TbPlant />
            break;
        case 'lc-transfer':
            // when transferring a plant from veg to flower
            returnSymbol = <TbPlant />
            break;
        case 'lc-harvest':
          // when harvesting a plant
            returnSymbol = <TbPlant />
            break;
        case 'lc-archived':
          // when archiving a plant
            returnSymbol = <FiArchive />
            break;
        case 'lc-move':
            // when changing the location/position of a plant
            returnSymbol = <TbPlant />
            break;
        case 'u':
          // user note
          returnSymbol = <BsPersonFill />
          break;
        case 'aroma':
          // user note
          returnSymbol = <GiNoseFront />
          break;
        case 'review':
          // user note
          returnSymbol = <GrScorecard />
          break;
        default:          
          break;
      }
      return returnSymbol
    }

    const toggleDateDisplay = ( ) => {     
      if (dateDisplayToggle) {
        setDateDisplayToggle(false)
      } else {
        setDateDisplayToggle(true)
      }
    }       
        
  return (
    <>
    <div className={`text-black m-1 py-1 px-4 flex flex-col ${!prevElement || prevElementDate !== noteDate ? 'border-t-2' : ''} border-emerald-700 shadow-xl`}>


      {/* Display Date - toggle bettween date or day of phase - IF same date as prevElement */}
        { !prevElement || prevElementDate !== noteDate ? 
        <div className='px-2 m-2'>              
            { dateDisplayToggle ? <NoteDateDisplay toggleDateDisplay={toggleDateDisplay} noteDate={noteDate} /> :
            <NoteDateDayOfDisplay toggleDateDisplay={toggleDateDisplay} dayOf={dayOf}  />}
        </div> 
            : null }

        <div className='flex flex-col md:flex-row justify-between'>
          {/* section for date and icot to display type */}
          <div className='flex flex-col'>
            <div className='flex justify-around items-center  p-2'>
              {/* space for an svg to indicate the note type to user */}
              {iconDisplay(note.type)}
              { ( note.rating && note.budSize ) ? <>
              <div>
                <p className='px-2 bg-emerald-800/30 rounded-xl shadow-xl  text-md'>{note.rating}/10</p>
              </div>
              <div>
                <p className='px-2 bg-emerald-800/30 rounded-xl shadow-xl  text-md'>{note.budSize}</p>
              </div>
              </> : null }
            </div>            

          </div>


          {/* content of the note */}
          <div className='flex flex-col w-full bg-emerald-800/30 rounded-xl p-1 justify-between'>
              {/* Display Note Content */}
              <p className='p-2'>
                {note.comment}                    
              </p>
              
              {/* Display Starting Location/Position - for notes that contain such data */}
              <div className='flex flex-row pt-2 justify-evenly'>
                {((note.type === 'lc-started') || (note.type === 'lc-transfer') || (note.type === 'lc-move')) && <div className=''>
                  <div className='mx-auto p-1 text-xs'>
                      {note.type === 'lc-started' ? <p>Started</p> : <p>From:</p>}
                      <p>Location: {note.locationStart}</p>  
                      <p>Position: {note.positionStart}</p>  
                  </div>   
                </div>}
                {/* Display Ending Location/Position */}
                {((note.type === 'lc-transfer') || (note.type === 'lc-move')) && <div className=''>
                  <div className='mx-auto p-1 text-xs'>
                      <p>To:</p>
                      <p>Location: {note.locationEnd}</p>  
                      <p>Position: {note.positionEnd}</p>  
                  </div>
                </div>}              
              </div>
              
              {/* Button To Edit Note - don't allow if archived */}
              <div className='flex jusitfy-center m-auto rounded-full' >
                {(currentPlantData.status !== 'Archived' && !publicView &&                   
                  <ETGIconBtn onClickFunction={() => setEditNote(note.noteLogIndex)} type='edit' size='sm' />
                  )}
              </div>
          </div>                       
        </div>     
    </div>
    </>
  )
}
