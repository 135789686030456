

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';



const imageSlides = [
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FEctorGrowLabelSamples%2FEctorGrowLabels_Mini_Green.png?alt=media&token=b054e4aa-5171-4ad7-862f-59adc27d3aea',
    description: 'EctorGrow Labels, Mini - Green',
    sizeDetails: 'A Single 4x6 sheet of 6 mini labels (customize and cut to size once delivered), great for gifting and smaller contianers',
  },
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FEctorGrowLabelSamples%2FEctorGrowLabels_Mini_Orange.png?alt=media&token=388845fd-b54d-4b63-b25a-4c2c04e2dc35',
    description: 'EctorGrow Labels, Mini - Orange',
    sizeDetails: 'A Single 4x6 sheet of 6 mini labels (customize and cut to size once delivered), great for gifting and smaller contianers',
  },
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FEctorGrowLabelSamples%2FEctorGrowLabels_Mini_Purple.png?alt=media&token=1aae44bb-4ff1-41b3-bb0f-1da3699f2995',
    description: 'EctorGrow Labels, Mini - Purple',
    sizeDetails: 'A Single 4x6 sheet of 6 mini labels (customize and cut to size once delivered), great for gifting and smaller contianers',
  },
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FEctorGrowLabelSamples%2FEctorGrowLabels_Regular_Green_StrainDisplayNameandHarvestDate.png?alt=media&token=158f8fde-0119-420e-bb2d-7802639e4217',
    description: 'EctorGrow Label, Green, with the Strain, your Display Name, and Harvest Date being displayed',
    sizeDetails: 'individual 3x4 labels - great for organizing your grow and bigger containers',
  },
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FEctorGrowLabelSamples%2FEctorGrowLabels_Regular_Strain.png?alt=media&token=d49be5a4-4670-4d53-b47f-7173b126d331',
    description: 'EctorGrow Label, Orange, with the Strain being displayed',
    sizeDetails: 'individual 3x4 labels - great for organizing your grow and bigger containers',
  },
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FEctorGrowLabelSamples%2FEctorGrow_Regular_Purple_StrainandDisplayName.png?alt=media&token=606a2129-13aa-45d5-b1a7-33459fe30a70',
    description: 'EctorGrow Label, Purple, with the Strain and your Display Name being displayed',
    sizeDetails: 'individual 3x4 labels - great for organizing your grow and bigger containers',
  },
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FEctorGrowLabelSamples%2FEctorGrowLabels_Regular_CustomText.png?alt=media&token=26b8444b-b61c-470e-ae9d-55127cfd9a4c',
    description: 'Add custom text to your EctorGrow Labels, up to 25 characters',
    sizeDetails: 'individual 3x4 labels - great for organizing your grow and bigger containers',
  },
];



const EctorGrowLabelSampleSlider = () => {
  const sliderRef = React.useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800, // Adjust the speed (in milliseconds)
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true, // Adjust the slider height based on content
  };

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="w-full flex flex-col relative">
      <div className="">
        <Slider {...settings} ref={sliderRef}>
          {imageSlides.map((slide, index) => (
            <div className='flex flex-col items-center' key={index}>
              <div className='w-full max-w-xs mx-auto'>
                <img
                  className='mx-auto rounded-lg object-contain'
                  src={slide.url}
                  alt={slide.description}
                />
              </div>
              <p className='text-2xl text-black p-1 w-3/5 mx-auto py-2 text-center'>
                Label Description: <span className='text-slate-800 italic'>{slide.description}</span>
              </p>
              <p className='etg-systemText px-10 text-center'>Actual Size: {slide.sizeDetails}</p>
            </div>
          ))}
        </Slider>
      </div>
      <div className="absolute bottom-0 left-0 w-full flex justify-between items-center px-4 py-8">
        <button
          onClick={handlePrevClick}
          className="bg-white rounded-full p-1 text-gray-600 hover:text-black transition"
        >
          <AiOutlineLeft />
        </button>
        <button
          onClick={handleNextClick}
          className="bg-white rounded-full p-1 text-gray-600 hover:text-black transition"
        >
          <AiOutlineRight />
        </button>
      </div>
    </div>
  );
};

export default EctorGrowLabelSampleSlider;