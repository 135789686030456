import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import { useFirestore } from "../../../hooks/useFirestore";
import AddNutrientScheduleSection from "./AddNutrientScheduleSection";
import LoadingDiv from '../../LoadingDiv'
import EditNutrienScheduletView from "./EditNutrientScheduleView";
import ETGButton from "../../shared/global/ETGButton";
import { v4 as uuidv4 } from 'uuid';
import { projectFirestore, timestamp } from "../../../firebase/config";
import fetchNutrientSchedulesData from "../../renderless/GetData/fetchNutrientSchedulesData";
import { getAuth } from "firebase/auth";


function  NutrientScheduleView({ growsModal, setGrowsModal, growerProfile, activeNutrientObj, 
  setActiveNutrientObj, updateErrorData, setNutrientScheduleData }) {
  // App/Component control
  const [ addNutrientProgress, setAddNutrientProgress ] = useState('stepA')
  const [ isLoading, setIsLoading ] = useState(false)
  // Schedule Object Data
  const [ scheduleName, setScheduleName ] = useState('')
  const [ scheduleDescription, setScheduleDescription ] = useState('')
  const [ medium, setMedium ] = useState('Hydro')
  const [ containerSize, setContainerSize ] = useState(1)
  const [ schedulePhase, setSchedulePhase ] = useState('Vegetative')
  const [ numberOfNutrients, setNumberOfNutrients ] = useState(1)
  const [ numberOfWeeks, setNumberOfWeeks ] = useState(3)
  const [ nutrients, setNutrients ] = useState([])
  const [ indexToUpdate, setIndexToUpdate ] = useState(null)
  const [ sharePublicStatus, setSharePublicStatus ] = useState(true)

  const currentUser = getAuth().currentUser
  const { updateDocument, response } = useFirestore('growProfiles')
  const nutrientScheduleCollectionRef = projectFirestore.collection('nutrientSchedules')


// Veg week boundary high
if ( (schedulePhase === 'Vegetative') && (numberOfWeeks > 6)) {
  setNumberOfWeeks(6)
}
// Flower week boundary low
if ( (schedulePhase === 'Flower') && (numberOfWeeks < 8)) {
  setNumberOfWeeks(8)
}  

function handleToggleSharePublicly () {  
  // set status as the opposite of current status
  setSharePublicStatus(!sharePublicStatus)  
}


if (growsModal === 'edit_nutrient' && addNutrientProgress === 'stepA') {  
  setScheduleName(activeNutrientObj.scheduleName)
  setScheduleDescription(activeNutrientObj.scheduleDescription)
  setMedium(activeNutrientObj.medium)
  setContainerSize(activeNutrientObj.containerSize)
  setSchedulePhase(activeNutrientObj.phase)
  setNumberOfNutrients(activeNutrientObj.numberOfNutrients)
  setNumberOfWeeks(activeNutrientObj.numberOfWeeks)
  setNutrients(activeNutrientObj.nutrients)
  setSharePublicStatus(activeNutrientObj.sharePublicly)
  setAddNutrientProgress('stepB')
}

const handleDeleteNutrient = async () => {
  setIsLoading(true)
  let clonedGrowerProfile = Object.assign({}, growerProfile)
  // clone and update the nutrientSchedule to have the createdByUID be updated to 'abandoned'
  let clonedNutrientSchedule = Object.assign({}, activeNutrientObj)
  const date = new Date()
  clonedNutrientSchedule.createdByUID = 'abandoned'
  clonedNutrientSchedule.dateModified = new timestamp(Math.round(date.getTime()/1000), 0)
  clonedNutrientSchedule.sharePublicly = true
  
  // update the nutrientSchedule in the nutrientSchedules collection
  try {
    await nutrientScheduleCollectionRef.doc(clonedNutrientSchedule.nutrientScheduleID).set(clonedNutrientSchedule).then(() => {
      console.log('nutrientScheduleObject updated in the nutrientSchedules collection')
    })
  } catch (error) {
    updateErrorData('Error Saving Nutrient Schedule', growerProfile.uid)
  }
  // update the growerProfile to remove the nutrientScheduleID from the vegNutrients or flowerNutrients array    
  // clone grower profile, update clone to include new nutrient schedule, in the correct phase (veg or flower)
  if ( schedulePhase === 'Vegetative' ) {
    clonedGrowerProfile.vegNutrients.splice([indexToUpdate], 1)
  } else {    
    clonedGrowerProfile.flowerNutrients.splice([indexToUpdate], 1)
  }

  await updateDocument(growerProfile.uid, clonedGrowerProfile)
  if(response.error) {
    updateErrorData('Error Saving Grower Profile', growerProfile.uid)
    
  }
  
  //close Modal
  setGrowsModal(false)
}

const handleSaveUpdatedNutrients = async () => {
  setIsLoading(true)

  // check the current user is the creator of the schedule - if not, do not allow update
  if (activeNutrientObj.createdByUID !== currentUser.uid) {
    alert('You are not the creator of this schedule, you cannot update it but you can copy it and edit your own version')
    setIsLoading(false)
    return
  }

  
  const date = new Date()
  const timestampFromDateModified = new timestamp(Math.round(date.getTime()/1000), 0)
  let nutrientScheduleObject = {
    nutrientScheduleID: activeNutrientObj.nutrientScheduleID,
    createdByUID: activeNutrientObj.createdByUID,
    contributors: activeNutrientObj.contributors,
    dateCreated: activeNutrientObj.dateCreated,
    dateModified: timestampFromDateModified,
    likes: activeNutrientObj.likes,
    copiedBy: activeNutrientObj.copiedBy,
    phase: schedulePhase,
    scheduleDescription,
    medium,
    scheduleName,
    sharePublicly: sharePublicStatus,
    containerSize,
    numberOfNutrients,
    numberOfWeeks,
    nutrients
  }
  
  // add object to firestore nutrientSchedules collection
  try {
    await projectFirestore.collection('nutrientSchedules').doc(nutrientScheduleObject.nutrientScheduleID).set(nutrientScheduleObject).then(() => {
      // console.log('nutrientScheduleObject updated in the nutrientSchedules collection')
    })
  } catch (error) {    
    updateErrorData('Error Saving Nutrient Schedule', growerProfile.uid)
  }

  // update data for nutrient schedule in growerProfile
  let tempResult = fetchNutrientSchedulesData([...growerProfile.vegNutrients, ...growerProfile.flowerNutrients])
      tempResult.then(result => {
        if (result.error) {
          updateErrorData(result.error, growerProfile.uid)
        } else {
          setNutrientScheduleData(result.data)
        }
      })


  setActiveNutrientObj(nutrientScheduleObject)
  setIsLoading(false)
  setGrowsModal(false)
  
}

const handleSaveNewNutrients =  async () => {
  setIsLoading(true)  
  const date = new Date()
  const timestampFromDate = new timestamp(Math.round(date.getTime()/1000), 0)
  let nutrientScheduleObject = {
    nutrientScheduleID: uuidv4(),
    createdByUID: growerProfile.uid,
    contributors: [growerProfile.uid],
    dateCreated: timestampFromDate,
    dateModified: timestampFromDate, 
    likes: [],
    copiedBy: [],
    phase: schedulePhase,
    scheduleDescription,
    medium,
    scheduleName,
    sharePublicly: sharePublicStatus,
    containerSize,
    numberOfNutrients,
    numberOfWeeks,
    nutrients
  }  
  
  // add object to firestore nutrientSchedules collection
  try {    
    await projectFirestore.collection('nutrientSchedules').doc(nutrientScheduleObject.nutrientScheduleID).set(nutrientScheduleObject).then(() => {
      console.log('nutrientScheduleObject added to nutrientSchedules collection')
    })

  } catch (error) {           
    updateErrorData('Error Saving Nutrient Schedule', growerProfile.uid)
  }
  // add id to the growerProfiles vegNutrients or flowerNutrients array
  let clonedGrowerProfile = Object.assign({}, growerProfile)
  if ( schedulePhase === 'Vegetative' ) {
    clonedGrowerProfile.vegNutrients.push(nutrientScheduleObject.nutrientScheduleID)
  } else {
    clonedGrowerProfile.flowerNutrients.push(nutrientScheduleObject.nutrientScheduleID)
  }
  // update growerProfile in firestore
  await updateDocument(growerProfile.uid, clonedGrowerProfile)
  if(response.error) {
    console.log("handle error: " + response.error)
  }
  
  
//close Modal
  setActiveNutrientObj(nutrientScheduleObject)
  setIsLoading(false)
  setGrowsModal(false)
}

const handleCompleteStepA = () => {
  if (addNutrientProgress === 'stepA' && scheduleName.length > 0) {
    let tempArr = []      
    for (let nutrientIndex = 0; nutrientIndex < numberOfNutrients; nutrientIndex++) {
      let nutrientObject = {
        nutrientName: '',
        // setting default which is first option in select field
        unitOfMeasure: 'scoop(s)',
        weeklySchedule: []
      }                 
      tempArr.push(nutrientObject)      
      for (let weeklyIndex = 0; weeklyIndex < (parseInt(numberOfWeeks)); weeklyIndex++) {
        tempArr[nutrientIndex].weeklySchedule[weeklyIndex] = '0'
      }
    }      
    setNutrients( tempArr )
    setAddNutrientProgress('stepB')      
  } else {
    alert('A Schedule Name would be nice ;)')
  }
}

if (indexToUpdate === null && activeNutrientObj && activeNutrientObj !== undefined) {  
  let index = null    
  if (activeNutrientObj.phase === 'Vegetative') {    
    index = growerProfile.vegNutrients.findIndex((schedule) => {      
      if (schedule === activeNutrientObj.nutrientScheduleID) {
        return true
      } else {
        return false
      }
    } )

  } else if (activeNutrientObj.phase === 'Flower') {
    index = growerProfile.flowerNutrients.findIndex((schedule) => {      
      if (schedule === activeNutrientObj.nutrientScheduleID) {
        return true
      } else {
        return false
      }
    } )
  }  
  setIndexToUpdate(index)
}




if ( isLoading ) {
  <LoadingDiv />
}
// call to lock body while modal is open
// useLockBodyScroll()

return (
  <div className="flex flex-col overflow-auto p-5 bg-slate-200 rounded-xl">

        {/* Display either the add or edit component */}
        {(growsModal === 'add_nutrient') && <AddNutrientScheduleSection
          addNutrientProgress={addNutrientProgress} setAddNutrientProgress={setAddNutrientProgress} 
          scheduleName={scheduleName} setScheduleName={setScheduleName}
          medium={medium} setMedium={setMedium}
          containerSize={containerSize} setContainerSize={setContainerSize}
          schedulePhase={schedulePhase} setSchedulePhase={setSchedulePhase}
          numberOfNutrients={numberOfNutrients} setNumberOfNutrients={setNumberOfNutrients}
          numberOfWeeks={numberOfWeeks} setNumberOfWeeks={setNumberOfWeeks} 
          nutrients={nutrients} setNutrients={setNutrients}
          growerProfile={growerProfile} activeNutrientObj={activeNutrientObj}
          handleToggleSharePublicly={handleToggleSharePublicly} sharePublicStatus={sharePublicStatus}
          scheduleDescription={scheduleDescription} setScheduleDescription={setScheduleDescription}
        />}
        {(growsModal === 'edit_nutrient') && <EditNutrienScheduletView
          addNutrientProgress={addNutrientProgress} setAddNutrientProgress={setAddNutrientProgress} 
          scheduleName={scheduleName} setScheduleName={setScheduleName}
          medium={medium} setMedium={setMedium}
          containerSize={containerSize} setContainerSize={setContainerSize}
          schedulePhase={schedulePhase} setSchedulePhase={setSchedulePhase}
          numberOfNutrients={numberOfNutrients} setNumberOfNutrients={setNumberOfNutrients}
          numberOfWeeks={numberOfWeeks} setNumberOfWeeks={setNumberOfWeeks} 
          nutrients={nutrients} setNutrients={setNutrients}
          growerProfile={growerProfile} activeNutrientObj={activeNutrientObj}
          handleToggleSharePublicly={handleToggleSharePublicly} sharePublicStatus={sharePublicStatus}
          scheduleDescription={scheduleDescription} setScheduleDescription={setScheduleDescription}
        />} 


        <div className="flex justify-evenly pt-6 gap-4 mx-auto">
          {(addNutrientProgress === 'stepA') && <>
            <ETGButton
              onClickFunction={() => handleCompleteStepA()}
              btnText='Proceed With Defining Your Schedule'
            />            
          </>}

          {((addNutrientProgress === 'stepB') && (growsModal === 'add_nutrient')) && <>
            <Button
              color="lightBlue"
              buttonType="outline"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={handleSaveNewNutrients}>
                Save New Nutrient Schedule
            </Button>
          </>}
          {((addNutrientProgress === 'stepB') && (growsModal === 'edit_nutrient')) && <>
            <ETGButton
              onClickFunction={handleSaveUpdatedNutrients}
              btnText='Save Updated Nutrient Schedule'
            /> 
            <ETGButton
              onClickFunction={handleDeleteNutrient}
              btnText='Delete Nutrient Schedule'
              type='caution'
            />            
          </>}
        </div>   
        {growsModal === 'edit_nutrient' ?
        <div>
          <p className="etg-systemText">
            plants & other users using this Nutrient Schedule will continue to use it, if you delete it.
          </p>
        </div>                
        : null}
        <div className="flex flex-row mx-auto py-4">
          <ETGButton
            onClickFunction={() => setGrowsModal(false)}
            btnText='Cancel'
            icon='cancel'
            type='delete'
          />          

        </div>    
    </div>
  )}

export default NutrientScheduleView;