import React, { useEffect } from 'react';
import GrowLog from './GrowLog/GrowLog'
import { useState } from 'react';
import ETGButton from '../../../shared/global/ETGButton';
import CommentSection from '../../../shared/Chat/CommentSection';
import { projectFirestore } from '../../../../firebase/config';
import PlantFooterNutrientview from './PlantFooterNutrientView';
import PlantFooterSocialView from './PlantFooterSocialView';
import EctorGrowLabel from './EctorGrowLabels/EctorGrowLabel';
import PlantFooterFlagView from './PlantFooterFlagView';
import { getGrowProfiles } from '../../../renderless/GetData/getGrowProfiles';



export default function PlantFooter({ 
    currentPlantData, currentPhase,
    appData, setAppData, 
    updateErrorData,
    userPlantData, mainImgSetter, mainImg,
    setShowFullImgModal, nutrientScheduleData, currentUser }) { 

    const [ nutrientPhase, setNutrientPhase ] = useState(currentPhase)
    const [ activeNutrientObj, setActiveNutrientObj ] = useState(null)
    const [ commentData, setCommentData ] = useState([])
    const [ footerDisplay, setFooterDisplay ] = useState(null)    
    const [ userData, setUserData ] = useState(null)
    useEffect(() => {
        const fetchUserData = async () => {
          const data = await getGrowProfiles();
          setUserData(data);
        };
    
        fetchUserData();
      }, []);

      const getDisplayName = (userId) => {
        if (userData) {
          const user = userData.find((user) => user.uid === userId);
          return user.displayName;
        }
      };
    

    const handlePhaseChange = () => {
        if (nutrientPhase === 'Vegetative') {
            setNutrientPhase('Flower')
            let flowerSchedule = appData.profileData.flowerNutrients.filter(schedule => schedule.scheduleName === currentPlantData.flowerNutrients)            
            setActiveNutrientObj(flowerSchedule[0])
        } else {
            setNutrientPhase('Vegetative')
            let vegSchedule = appData.profileData.vegNutrients.filter(schedule => schedule.scheduleName === currentPlantData.vegNutrients)
            setActiveNutrientObj(vegSchedule[0])
        }
    }

    // function to find and return the schedule object of the nutrient by matching the ID
    const findVegNutrientSchedule = (nutrientId) => {        
        let nutrientSchedule = {}
        nutrientSchedule = nutrientScheduleData.vegNutrients.filter(schedule => schedule.id === nutrientId)        
        return nutrientSchedule
    }
    // function to find and return the schedule object of the nutrient by matching the ID
    const findFlowerNutrientSchedule = (nutrientId) => {
        let nutrientSchedule = {}        
        nutrientSchedule = nutrientScheduleData.flowerNutrients.filter(schedule => schedule.id === nutrientId)
        return nutrientSchedule
    }


    const updateNutrientObject =  () => {   
                                                               
        if ((currentPlantData.vegNutrients !== 'none' ) && nutrientPhase === 'Vegetative' ) {        
                            
            setActiveNutrientObj( findVegNutrientSchedule(currentPlantData.vegNutrients)[0] )            
        } else if ((currentPlantData.flowerNutrients !== 'none' ) && nutrientPhase === 'Flower' ) {            
            setActiveNutrientObj( findFlowerNutrientSchedule(currentPlantData.flowerNutrients)[0] )            
        } else {                        
            setActiveNutrientObj({
                scheduleName: 'NO SCHEDULES CREATED YET',
                emptyObj: true
            })                
            
        }
    }    

    
    // 
    useEffect(() => {
        if (
          !activeNutrientObj &&
          nutrientScheduleData &&
          ((currentPhase === 'Vegetative' && currentPlantData.vegNutrients !== '' && currentPlantData.vegNutrients !== 'none') ||
            (currentPhase === 'Flower' && currentPlantData.flowerNutrients !== '' && currentPlantData.flowerNutrients !== 'none'))
        ) {          
          updateNutrientObject();
        }
      }, [activeNutrientObj, nutrientScheduleData, currentPhase, currentPlantData]);
      
    
      

    // if statement - if currentPhase changes and nutrientPhase is not the same as currentPhase - update nutrients
    if (currentPhase === 'Flower' && nutrientPhase !== 'Flower' && (currentPlantData.vegNutrients === '' || currentPlantData.vegNutrients === 'none') ) {                            
        setNutrientPhase('Flower')
        let flowerSchedule = appData.profileData.flowerNutrients.filter(schedule => schedule.scheduleName === currentPlantData.flowerNutrients)            
        setActiveNutrientObj(flowerSchedule[0])        
    }

    // if statement - if currentPhase changes and nutrientPhase is not the same as currentPhase - update nutrients
    if (currentPhase === 'Vegetative' && nutrientPhase !== 'Vegetative' && (currentPlantData.flowerNutrients === '' || currentPlantData.flowerNutrients === 'none') ) {  
        setNutrientPhase('Vegetative')
        let vegSchedule = appData.profileData.vegNutrients.filter(schedule => schedule.scheduleName === currentPlantData.vegNutrients)
        setActiveNutrientObj(vegSchedule[0])        
    }
    
    // if statement - if activeNutrientObj. emptyObj === true but activePlantdata.phase has a current phase schedule - update nutrients again
    if ( activeNutrientObj && activeNutrientObj.emptyObj && activeNutrientObj.scheduleName !== 'NO SCHEDULES CREATED YET') {
        if (currentPhase === 'Vegetative' && ( currentPlantData.vegNutrients !== '' && currentPlantData.vegNutrients !== 'none') ) {            
            updateNutrientObject()
        } else {
            // do nothing            
            setActiveNutrientObj({
                scheduleName: 'NO SCHEDULES CREATED YET',
                emptyObj: true
            })     
        }
        
        if (currentPhase === 'Flower' && (currentPlantData.flowerNutrients !== '' && currentPlantData.flowerNutrients !== 'none' ) ) {            
            updateNutrientObject()
        } else {
            // do nothing            
            setActiveNutrientObj({
                scheduleName: 'NO SCHEDULES CREATED YET',
                emptyObj: true
            })     
        }
    }

    const handleSocialClick = () => {
        if (footerDisplay === 'social') {
            setFooterDisplay(null)
        } else {
            setFooterDisplay('social')
        }
    }

    const handleNutrientClick = () => {
        if (footerDisplay === 'nutrients') {
            setFooterDisplay(null)
        } else {
            setFooterDisplay('nutrients')
        }   
    }

    const handleGrowLogClick = () => {
        if (footerDisplay === 'growLog') {
            setFooterDisplay(null)
        } else {
            setFooterDisplay('growLog')
        }
    }

    const handleCommentsClick = () => {
        if (footerDisplay === 'comments') {
            setFooterDisplay(null)
        } else {
            setFooterDisplay('comments')
        }
    }

    const handleETGLabelClick = () => {
        if (footerDisplay === 'etgLabel') {
            setFooterDisplay(null)
        } else {
            setFooterDisplay('etgLabel')
        }
    }

    const handleFlagPlant = () => {
        // display footer about flaggina a plant
        if (footerDisplay === 'flagPlant') {
            setFooterDisplay(null)
        } else {
            setFooterDisplay('flagPlant')
        }            
    }


    useEffect(() => {
    // set up a live query to listen for any documents with the plantId field matching the current plant id
    const commentQuery = projectFirestore.collection('userComments').where('documentID', '==', currentPlantData.plantId)
    // show results of query in console
    commentQuery.onSnapshot((querySnapshot) => {
        let tempCommentData = []
        querySnapshot.forEach((doc) => {            
            tempCommentData.push(doc.data())
        });        
        setCommentData(tempCommentData)        
    });
    }, [currentPlantData.plantId]) 




  return (
      <>
      <section className='py-2'>

      
        {/* <!-- Availale Actions --> */}
        <div className="bg-gray-500/40 border-2 border-gray-800 rounded-xl m-2 p-2 flex flex-wrap justify-evenly justify-items-center text-black">            
            {/* Plant Log Button */}
            <div className=''>                
            <ETGButton                    
                onClickFunction={handleGrowLogClick}
                icon='description'
                btnText='Grow Log'
                />
            </div>
           
            {((currentPhase !== 'Harvested') && (currentPhase !== 'Archived')) && <> 
                <div className=''>                    
                <ETGButton
                    onClickFunction={handleNutrientClick}
                    icon='list'
                    btnText='Nutrients'
                />            
                </div>
            </>}            
            <div className=''>                
            <ETGButton                    
                onClickFunction={handleCommentsClick}
                icon='chat'
                btnText='Comments'
            />
            </div>
            {!appData.publicView ? <>            
                { ( currentUser && (currentUser.uid === appData.profileData.uid)  ) ? <>
                <div className=''>                
            <ETGButton                    
                onClickFunction={handleSocialClick}                
                btnText='Social Settings'
                />
            </div>
            <div className=''>                
            <ETGButton                    
                onClickFunction={handleETGLabelClick}                
                btnText='EctorGrow Labels'
                />
            </div>
            </> : null}
            </> : <>
            { ( currentUser && currentUser.uid !== currentPlantData.plantOwnerUID ) ? <> 
                {/* button to flag plant */}
                <div className=''>
                    <ETGButton
                        onClickFunction={handleFlagPlant}
                        icon='flag'
                        btnText='Flag Plant'
                        />
                </div>
            </> : null}
            </>}            
                                                     
        </div>        
            
            {/* Display of Nutrients */}
            {footerDisplay === 'nutrients' ?
            <PlantFooterNutrientview
                currentPlantData={currentPlantData}
                appData={appData} currentPhase={currentPhase}
                updateErrorData={updateErrorData}
                findFlowerNutrientSchedule={findFlowerNutrientSchedule}
                findVegNutrientSchedule={findVegNutrientSchedule}
                activeNutrientObj={activeNutrientObj}
                handlePhaseChange={handlePhaseChange}
                nutrientPhase={nutrientPhase}
            /> : <></>}


            {/* Display of Note Log */}
            { footerDisplay === 'growLog' ? <>
            <div className={`flex-row text-black px-4 m-4 w-full mx-auto`}>
                <div className='text-center'>
                    {/* <!-- Notes --> */}                                 
                    <div className='flex flex-row'>                        
                        <GrowLog 
                            appData={appData} setAppData={setAppData}
                            userPlantData={userPlantData}
                            updateErrorData={updateErrorData}
                            currentPlantData={currentPlantData} currentPhase={currentPhase}                                                        
                            mainImgSetter={mainImgSetter}
                            mainImg={mainImg}                            
                            setShowFullImgModal={setShowFullImgModal}
                            />
                    </div>                    
                </div>
            </div> </> : <></>}     


            {/* Display of Comment Section */}
            { footerDisplay === 'comments' ? <>
            <CommentSection
                comments={commentData}                
                type='plant'
                documentID={currentPlantData.plantId}
                updateErrorData={updateErrorData}
                getDisplayName={getDisplayName}
                userData={userData}
                contentID={currentPlantData.plantId}
                /> </> : <></>}


            {/* Display of Social Settings */}
            { footerDisplay === 'social' ? <PlantFooterSocialView
                currentPlantData={currentPlantData}
                appData={appData}
                updateErrorData={updateErrorData}
            />: <></>}


            {/* Display of View to Flag a plant */}
            { footerDisplay === 'flagPlant' ? <PlantFooterFlagView
                currentUser={currentUser}
                currentPlantData={currentPlantData}            
                updateErrorData={updateErrorData}
                getDisplayName={getDisplayName}
            />: <></>}


            {/* EctorGrow Labels */}
            { footerDisplay === 'etgLabel' ? <EctorGrowLabel 
                updateErrorData={updateErrorData} 
                currentPlantData={currentPlantData} 
                displayName={appData.profileData.displayName} 
                currentUser={currentUser}
            /> : null}

            </section>
      </>
  )

    
}
