import React from 'react'

export default function SaveInProgress({ message = 'Saving Changes'}) {
    
  
  return (<> 
    <section className=''>
        <div 
            className='flex flex-col justify-center items-center uppercase tracking-widest text-center rounded-xl'
        >
          <div className='absolute border-t-2 border-emerald-200 animate-spin w-10 h-10 rounded-full'></div>
          <p className='absolute animate-pulse'>{message}</p>          
        </div>
    </section>
  </>)
}
