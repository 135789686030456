import React from 'react';
import { daysOfFlower, daysOfVeg, daysSince, timestampToDate } from '../../renderless/helpers'
import ETGIconBtn from '../../shared/global/ETGIconBtn';

export default function Header({ currentPhase, currentPlantData, handleAddPhoto, 
    setGrowsModal, appData, hiddenImageUpload, uploadNewImage }) {    
    
    // function to return boolean if the noteLog has a note with the type lc-archived
    const noteWasArchived = currentPlantData.noteLog.filter(note => note.type === 'lc-archived').length > 0 ? true : false    

        
    

    return ( <>
        {/* Begin Header Section  */}
        <div className="relative md:-mx-4 px-4 py-3 bg-gradient-to-b from-green-300 to-emerald-500 rounded-t-xl md:rounded-xl shadow-xl mt-16 z-40">        

             {/* Add a hidden div with the ref hiddenImageUpload */}
            <div className="hidden">
                <input
                type="file"
                ref={hiddenImageUpload}
                onChange={uploadNewImage}
                accept="image/jpeg, image/png, image/gif"
                />
            </div>

            <div className='flex justify-between'>
                {/* <!-- Strain Name --> */}

                <div className="flex flex-col justify-start font-bold text-slate-900 px-4">                    
                        <h3 className='text-3xl underline underline-offset-4'>{currentPlantData.strain}</h3>
                    <div className='flex'>
                        {/* <!-- Location and Position --> */}
                        {((currentPhase === 'Vegetative') || (currentPhase === 'Flower')) && <>
                            <div className="py-1 text-sm font-medium text-gray-900">
                                {currentPlantData.location} | {currentPlantData.position}
                            </div>                                    
                        </>}
                        {((currentPhase === 'Harvested') || (currentPhase === 'Archived')) && !noteWasArchived && 
                            <div className="py-1 text-sm font-medium text-gray-900">
                                {currentPlantData.harvestYield} g | {(currentPlantData.harvestYield/28).toFixed(2)} oz
                            </div>  
                        }                        
                    </div>
                    {/*If Vegetative or Flower = Display days in current phase */}
                    {((currentPhase === 'Vegetative') || (currentPhase === 'Flower')) && <>
                        <div className="flex flex-col py-1 text-sm font-medium text-gray-900"> 
                            {/* <!-- Days Since Start of Current Phase --> */}
                            <div className="">
                                Day                                                                       
                                <span className="ml-1">{daysSince(currentPlantData)}</span>
                                    of {currentPhase}
                            </div>
                            {/* Days of Vegetative Phase */}
                            {currentPhase === 'Flower' && <>
                                <div className="">                                    
                                    {daysOfVeg(currentPlantData)}                                    
                                </div>
                            </>}
                        </div>                
                    </>}
                </div>                           

                {/* <!-- Plant Age --> */}
                {/* If Harvested or Archived - display yield totals */}
                {/* only displat if either archived or harvested but not if noteWasArchived is true */}     

                    {/* Action buttons to hover over top right of section */}
                    <div className='flex flex-wrap justify-end text-black p-1'>
                        {/* Info */}
                        {currentPhase !== 'Archived' ? <>
                        <div className='flex flex-col items-center justify-end p-2'>
                            <ETGIconBtn type='info' onClickFunction={() => setGrowsModal('info')} />
                            <span className='text-xs'>info</span>
                        </div> </> : null}
                        {/* only display if appData.publicView is false */}
                        {!appData.publicView ? <>
                            <div className='flex flex-col items-center justify-end p-2'>
                                <ETGIconBtn type='actions' onClickFunction={() => setGrowsModal('edit-plant')} />
                                <span className='text-xs'>actions</span>
                            </div>
                            <div className='flex flex-col items-center justify-end p-2'>
                                <ETGIconBtn type='addImage' onClickFunction={handleAddPhoto} />
                                <span className='text-xs'>add image</span>
                            </div>                                                                      
                        </> : null }
                </div>

            </div>

            <div className="flex flex-col md:flex-row pl-2 md:py-2 justify-evenly">
                
                {/* If Harvested or Archived - output the number of days of veg and flower */}
                {((currentPhase === 'Harvested') || (currentPhase === 'Archived')) &&  <>
                    <div className='mx-auto text-black self-end border-green-800 border-b-2 px-2'>
                        {daysOfVeg(currentPlantData)}
                        
                    </div>                 
                    <div className='mx-auto text-black self-end border-emerald-900 border-b-2 px-2'>
                        {daysOfFlower(currentPlantData)}
                    </div>                 
                </>}
                {/* only displat if either archived or harvested but not if noteWasArchived is true */}
                {((currentPhase === 'Harvested') || (currentPhase === 'Archived')) && !noteWasArchived &&  <>
                    <div className='mx-auto text-black self-end border-green-800 border-b-2 px-2'>
                        <p>Harvested: {`${timestampToDate(currentPlantData.harvestDate)}`}</p>
                    </div>                                 
                </>}                        
            </div>
            
        </div>
    </>)
}
    