
import DefaultNavbar from '../components/DefaultNavbar';
import DefaultFooter from '../components/DefaultFooter';
import React, { useState, useEffect } from 'react';
import { useAppData } from '../hooks/useAppData';
import { GApageView } from '../components/renderless/helpers';
import HomeHeader from '../components/home/HomeHeader';
import FeatureDisplay from '../components/home/FeatureDisplay';
import RegisterForm from '../components/register/RegisterForm'
import FreeTierCard from '../components/register/FreeTierCard';
import ProTierCard from '../components/register/ProTierCard';
import MailChimpFormContainer from '../components/newsletter/MailChimpFormContainer';
import { projectFirestore } from '../firebase/config';
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';
import { getAuth } from 'firebase/auth';
import EctorGrowExplainer from '../components/home/EctorGrowExplainer';
import AccountFeaturesandProPricing from '../components/home/AccountFeaturesandProPricing';
import DefaultSlider from '../components/shared/Marketing/DefaultSlider';
import AccountFeaturesandFree from '../components/home/AccountFeaturesFree';





export default function Home() {
    const [betaSignupProgress, setBetaSignupProgress] = useState(0)

    
    const [ isPageLoading, setIsPageLoading ] = React.useState(false)
    const { etgErrorDetected } = React.useContext(ErrorHandilingContext)
    const user = getAuth().currentUser



    // BEGIN DATA LOADING SECTION
    const [id, setId] = useState('notloggedin')
    if ((user) && (id !== user.uid)) {
        setId(user.uid)
    }

    // Load data from firestore
    const { profileDocument, plantDocument } = useAppData(id)    
    if (isPageLoading && profileDocument && plantDocument) {
        setIsPageLoading(false)
    }
    // END DATA LOADING SECTION

    // useEffect to check if user is logged in, if they are, use react router to go to /grows
    useEffect(() => {
        if (user) {
            window.location.href = '/grows'
        }
    }, [user])


    

    // load current number of user profiles for progress bar
    const getGrowProfiles = async () =>  {
        const snapshot = await projectFirestore.collection('growProfiles').get()               
        return snapshot.docs.map(doc => doc.data());
    }

    if (betaSignupProgress === 0) {
        getGrowProfiles().then(result => {
            let currentLength = result.length            
            let currentProgress = Math.round((currentLength / 420) * 100)                      
            setBetaSignupProgress(currentProgress)
        })
    }

    React.useEffect(() => {                
        GApageView('home')    
    }, [])



    return (
        <> { etgErrorDetected ? null : <>        
            <section className='flex flex-col h-screen justify-between'>
                <div className="w-full z-20 bg-[#2d2d2d]">
                    <DefaultNavbar user={user} profileData={profileDocument} />
                </div>
                <main className='mb-0 w-full mx-auto my-10'>
                    <HomeHeader />
                    <div className='relative p-8 -mt-5 text-center my-10 text-black bg-emerald-800 drop-shadow-xl'>                                               
                        <p className="text-2xl md:text-3xl text-white tracking-widest uppercase">
                            Your Partner in Every Step of Your <span className='underline'>Personal Cannabis Cultivation</span> Journey
                        </p>

                        {/* Image Slider */}
                        {/* <DefaultSlider textColor='text-white' /> */}
                    </div>
                    {/* Feature Cards */}
                    <FeatureDisplay />
                    {/* <EctorGrowExplainer /> */}
                    {/* MailChimp Signup Form */}
                    <div className='flex flex-col -mt-2 justify-center text-center drop-shadow-xl'>
                        <div className='bg-emerald-800'>
                            <MailChimpFormContainer />
                        </div>
                    </div>
                        <div className='bg-etg-tentBGVertical bg-cover bg-no-repeat rounded-b-xl lg:bg-etg-tentBG'>
                        
                            <div className='flex flex-col md:flex-row justify-evenly gap-16 py-40 -my-8 md:px-20'>
                                <FreeTierCard />
                                <ProTierCard />
                            </div>
                        </div>
                        <AccountFeaturesandFree />
                        <AccountFeaturesandProPricing />
                    
                    <div className='flex justify-center overflow-hidden py-10 mt-10'>
                        <RegisterForm />                    
                    </div>
                    <div className='flex flex-col justify-center text-center'>
                        <div className='bg-emerald-800'>
                            <p className='pt-4 pb-2 text-3xl underline'>Not Ready To Register?</p>
                            <MailChimpFormContainer />
                        </div>
                    </div>                    

                </main>
                <DefaultFooter />
            </section>
        </> } </>
    );
}

