import React from 'react'
import { Input } from '@material-tailwind/react'
import WeeklyScheduleInputs from './WeeklyScheduleInputs'



export default function AddNutrientScheduleNutrients({ 
    nutrients, setNutrients,
    numberOfWeeks, numberOfNutrients,
    indexOfNutrient }) {    
    
    const updateNutrients = async ( valueArr ) => {
        // pass array as params
            // value 1 = name
            // value 2 = unit of measurement
            // value 3+ will be the weeks of the schedule and the actual amounts to update
            // final object should have format
            let nutrientsClone = JSON.parse(JSON.stringify(nutrients))
            let nutrientObject = nutrientsClone[indexOfNutrient]
            
            if (valueArr[0] === 1) {
                // Update the name of the current Nutrient                                
                nutrientObject.nutrientName = valueArr[1]
                nutrientsClone[indexOfNutrient] = nutrientObject
            }
            if (valueArr[0] === 2) {
                // update the unit of measurement                                
                nutrientObject.unitOfMeasure = valueArr[1]
                nutrientsClone[indexOfNutrient] = nutrientObject
                
            }
            if (valueArr[0] === 3) {
                // Update the week nutrients, for the given week... 
                // value for weekly position index will be param 3 of valueArr                                 
                nutrientObject.weeklySchedule[valueArr[2]] = valueArr[1]
                nutrientsClone[indexOfNutrient] = nutrientObject

            }
            setNutrients([...nutrientsClone])
    }    

    const displayScheduleWeeklyInput = () => {
        let returnValue = []        
        for (let index = 0; index < numberOfWeeks; index++) { 
            // console.log('called: ', index)
            returnValue.push(<WeeklyScheduleInputs 
                updateNutrients={updateNutrients} 
                weekIndex={index}
                nutrients={nutrients}
                indexOfNutrient={indexOfNutrient}
                key={`scheduleInput${index}`}
            />)
        }        
        return returnValue
    }

  return (<>
    <div className='flex flex-col h-full px-3 py-2' key={indexOfNutrient}>
        <div>
            <p className='text-2xl text-center underline-offset-2 underline'>Nutrient #{(indexOfNutrient + 1)}</p>
        </div>    
        <div className='flex flex-col md:flex-row justify-evenly'>
            <div className='flex flex-col mx-auto'>
                <p className='text-xl text-center py-2'>Nutrient Name: {nutrients[indexOfNutrient].nutrientName}</p>
                <Input
                    type="text"
                    color="green"
                    size="small"
                    outline={false}
                    placeholder={nutrients[indexOfNutrient].nutrientName}
                    onChange={(e) => updateNutrients([1, e.target.value])}
                />
            </div>
            <div className='flex flex-col items-center mx-auto'>
                <p className='text-xl px-2 text-center py-2'>Unit of Measurement: {nutrients[indexOfNutrient].unitOfMeasure}</p>                
                {/* <select value={nutrients[indexOfNutrient].unitOfMeasure} onChange={(e) => updateNutrients([2, e.target.value])}> */}
                {/* { nutrients[indexOfNutrient].unitOfMeasure === '' ?
                
                } */}
                <select value={nutrients[indexOfNutrient].unitOfMeasure} onChange={(e) => updateNutrients([2, e.target.value])}>
                    <option value='scoop(s)'>scoop(s)</option>
                    <option value='ml(s)'>ml(s)</option>
                    <option value='gram(s)'>gram(s)</option>
                    <option value='oz(s)'>oz(s)</option>
                    <option value='cup(s)'>cup(s)</option>
                    <option value='other'>other</option>
                </select>                
            </div>
            <div>
                </div>
        </div>
    </div>
                

        {/* This section will repeat for the number of weeks from userInput in
        stepA */}
        {/* index being passed through as third param in updateNutrientsArray, third param only for weekly nutrient input */}
        {/* use a nutrientWeek variable for index */}
        <div className='flex flex-row overflow-auto pb-4'>            
            {displayScheduleWeeklyInput()}     
        </div>   
    </>)
  }
