import React from 'react'
import MailChimpFormContainer from '../newsletter/MailChimpFormContainer'
import ETGButton from '../shared/global/ETGButton'

export default function CommuntiyHeader({ currentUser }) {

    function handleLearnMoreClick() {
    window.location.href = '/register';
  }

  return (<>
    <div className="flex flex-col items-center justify-center w-full">          
          {/* <h3 className="text-4xl font-bold text-center text-slate-900 py-10">Engage with the EctorGrow Communtiy</h3>           */}
          <h3 className="text-4xl font-bold text-center text-slate-900 py-10">EctorGrow Communtiy</h3>          
            {/* Explanation of Content Types */}
            {/* <div className='flex flex-wrap'>
              <p className="text-xl text-center outline-1 outline-emerald-800 shadow-lg outline-double rounded-xl m-1 py-4 px-2 etg-systemText">
                <span className='etg-sectionTitle'>Plants</span> shared by our community members are displayed here for everyone to see, troubleshoot when needed, learn from and enjoy.
              </p>
              <p className="text-xl text-center outline-1 outline-emerald-800 shadow-lg outline-double rounded-xl m-1 py-4 px-2 etg-systemText">
                <span className='etg-sectionTitle'>Social Posts</span> allow you to express yourself, ask questions, share your growing experiences, or introduce yourself to the community. It's a fantastic way to interact with others.
              </p>              
              <p className="text-xl text-center outline-1 outline-emerald-800 shadow-lg outline-double rounded-xl m-1 py-4 px-2 etg-systemText">
                <span className='etg-sectionTitle'>Nutrient Schedules</span> can be created on the Grow Space Dashboard, found on the /grows page, and shared with the community. You can also find schedules you like and make customizable copies.
              </p>
            </div> */}
          {currentUser ? null : (
            <>
              <div className="flex flex-col w-full items-center border-2 border-slate-800 rounded-xl p-2 py-8">
                <p className="etg-generalText px-4 py-2">
                  Welcome to EctorGrow, the ultimate destination for cannabis cultivation enthusiasts. Register now and
                  embark on a journey to elevate your cannabis growing experience. By logging your own grow, you gain
                  valuable insights into your plants' progress, allowing you to fine-tune your techniques and maximize
                  yields.
                </p>
                <p className="etg-generalText px-4 py-2">
                  Connect with a vibrant community of like-minded growers, where knowledge sharing and collaboration
                  thrive. Together, we strive to unlock the full potential of cannabis cultivation and empower
                  individuals to enhance their skills. Join EctorGrow today and cultivate your passion to new heights.
                </p>
                <div className="flex flex-wrap py-2">
                  <ETGButton btnText="Learn More" onClickFunction={handleLearnMoreClick} />
                </div>
                <div className="pt-5">
                  <p className="etg-generalText">
                    Already have an account? <a href="/login" className="text-emerald-800">Log In</a>
                  </p>
                </div>
                <div className="rounded-xl justify-center w-full bg-emerald-800 shadow-xl">
                  <MailChimpFormContainer />
                </div>
              </div>
            </>
          )}
        </div>
    </>)
}
