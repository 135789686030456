export const toolbarOptions = {
  // options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'emoji', 'image', 'remove', 'history'],
  options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline'],
    // bold: { icon: 'bold', className: undefined },
    // italic: { icon: 'italic', className: undefined },
    // underline: { icon: 'underline', className: undefined }    
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontSize: {
    icon: 'fontSize',
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    // unordered: { icon: 'unordered', className: undefined },
    // ordered: { icon: 'ordered', className: undefined },
    // indent: { icon: 'indent', className: undefined },
    // outdent: { icon: 'outdent', className: undefined },
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
    // left: { icon: 'left', className: undefined },
    // center: { icon: 'center', className: undefined },
    // right: { icon: 'right', className: undefined },
    // justify: { icon: 'justify', className: undefined },
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_blank',
    options: ['link', 'unlink'],
    // link: { icon: 'link', className: undefined },
    // unlink: { icon: 'unlink', className: undefined },
    linkCallback: undefined
  },
  emoji: {
    // icon: 'emoji',
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    emojis: [
      '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
      '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
      '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
      '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
      '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
      '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
      '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
      '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
      '✅', '❎', '💯',
    ],
  },
  image: {
    // icon: 'image',
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: false,
    alignmentEnabled: true,
    uploadEnabled: false,
    uploadCallback: undefined,
    previewImage: true,
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  },
  remove: { 
    // icon: 'eraser', 
    className: undefined, component: undefined },
  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['undo', 'redo'],
    // undo: { icon: 'undo', className: undefined },
    // redo: { icon: 'redo', className: undefined },
  },
}