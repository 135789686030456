import React from 'react';
import { timestampToDate } from '../../../../renderless/helpers';
import NoteDateDisplay from './NoteDateDisplay';
import NoteDateDayOfDisplay from './NoteDateDayOfDisplay';
import ETGIconBtn from '../../../../shared/global/ETGIconBtn';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

export default function ImageLogDisplay({
  currentPlantData,
  imgData,
  setEditImg,    
  publicView, 
  prevElement,
}) {
  const [dateDisplayToggle, setDateDisplayToggle] = React.useState(true);
  const [isSameDateAsPrev, setIsSameDateAsPrev] = React.useState(false);
  const imgDate = timestampToDate(imgData.date);
  const imgDateFormatted = new Date(imgDate);
  let startDate = timestampToDate(currentPlantData.startDate);
  startDate = new Date(startDate);
  let transferDate = timestampToDate(currentPlantData.transferDate);
  transferDate = new Date(transferDate);
  let harvestDate = timestampToDate(currentPlantData.harvestDate);
  harvestDate = new Date(harvestDate);
  const defaultDate = new Date('04/20/2020');
  
  let prevElementDate = null
  if (prevElement) {
    prevElementDate = timestampToDate(prevElement.date)
    
    if (prevElementDate === imgDate && !isSameDateAsPrev) {
      setIsSameDateAsPrev(true)
    }
  }

  const dayOf = () => {
    let phase;
    let day;

    if (
      (imgDateFormatted > startDate || imgDateFormatted.getTime() === startDate.getTime()) &&
      (imgDateFormatted < transferDate || transferDate.getTime() === defaultDate.getTime())
    ) {
      const differenceInTime = Math.abs(imgDateFormatted - startDate);
      let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
      if (differenceInDays === 0) {
        differenceInDays = 1;
      }
      day = differenceInDays;
      phase = 'Vegetative';
    } else if (
      (imgDateFormatted > transferDate || imgDateFormatted.getTime() === transferDate.getTime()) &&
      ((imgDateFormatted < harvestDate || imgDateFormatted.getTime() === harvestDate.getTime()) ||
        harvestDate.getTime() === defaultDate.getTime())
    ) {
      const differenceInTime = Math.abs(imgDateFormatted - transferDate);
      let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
      if (differenceInDays === 0) {
        differenceInDays = 1;
      }
      day = differenceInDays;
      phase = 'Flower';
    } else {
      const differenceInTime = Math.abs(imgDateFormatted - harvestDate);
      let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
      if (differenceInDays === 0) {
        differenceInDays = 1;
      }
      day = differenceInDays;
      phase = 'Harvest';
    }

    return (
      <div>
        <p className="">Day {day}</p>
        <p className="text-xs">{phase}</p>
      </div>
    );
  };

  const toggleDateDisplay = () => {
    setDateDisplayToggle(!dateDisplayToggle);
  };  

  return (
    <div className={`text-black m-1 py-1 px-4 flex flex-col ${!prevElement || prevElementDate !== imgDate ? 'border-t-2' : ''} border-emerald-700 shadow-xl`}>
      {!prevElement || prevElementDate !== imgDate ? 
        <div className="px-2 m-2">
          {dateDisplayToggle ? (
            <NoteDateDisplay toggleDateDisplay={toggleDateDisplay} noteDate={imgDate} />) : 
            ( <NoteDateDayOfDisplay toggleDateDisplay={toggleDateDisplay} dayOf={dayOf} />
          )}
        </div>
      : null }
      <div className="flex flex-col md:flex-row">                  
          <div className="flex flex-col justify-between">
            <div className="px-2">
              <TransformWrapper>
                <TransformComponent>
                  <img
                    src={imgData.imgUrl}
                    alt="plant"
                    className="w-full h-auto object-cover rounded-lg shadow-xl cursor-pointer"                    
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="etg-descriptionText">
              {window.innerWidth < 768
                ? 'Pinch to zoom and pan'
                : 'Double click or use the mouse wheel to zoom'}
            </p>
            </div>
            <div className="flex flex-row mt-4 bg-emerald-800/50 rounded-xl p-1 justify-between">
              <div className="flex flex-wrap justify-center rounded-xl p-2">
                <p>{imgData.caption}</p>
              </div>
              <div className="flex justify-center m-auto rounded-full">
                {currentPlantData.status !== 'Archived' && !publicView && (
                  <ETGIconBtn onClickFunction={() => setEditImg(imgData.imgArrayIndex)} type="edit" />
                )}
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}
