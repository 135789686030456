 

export function testPassword( passwordInput ) {
    let results = {
        isValid: false,
        newPassword: ''
    }
    
    // set displayed name to user entered text  
    results.newPassword = passwordInput
    
    // check for valid email format
    // let isAValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(emailInput);
    let isValidPassword = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/.test(passwordInput);
    if (!isValidPassword) { 
        results.isValid = false; 
    } else {
        results.isValid = true
    }    

    return results
}
  
  