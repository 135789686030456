import React, { useEffect } from 'react'
import DefaultNavbar from '../components/DefaultNavbar'
import DefaultFooter from '../components/DefaultFooter'
import { useState } from 'react'
import { useAppData } from '../hooks/useAppData'
import { GApageView, timestampToDate } from '../components/renderless/helpers'
import { getAuth } from 'firebase/auth'
import { ErrorHandilingContext } from '../context/ErrorHandilingContext'
import CommunityFilterDashboardSection from '../components/community/CommunityFilterDashboardSection'
import { retrieveSchedules } from '../components/schedules/helpers/retrieveSchedules'
import { projectFirestore, timestamp } from '../firebase/config'
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom'
import { getGrowProfiles } from '../components/renderless/GetData/getGrowProfiles'
import CommuntiyHeader from '../components/community/CommuntiyHeader'
import { retrievePlants } from '../components/schedules/helpers/retrievePlants'
import CommunityFeedDisplay from '../components/community/CommunityFeedDisplay'
import ProcessingDiv from '../components/ProcessingDiv'
import ETGButton from '../components/shared/global/ETGButton'
import AddSocialPost from '../components/community/AddSocialPost'
import { loadDisplayNames } from '../components/renderless/GetData/loadDisplayNames'
import retrievePosts from '../components/schedules/helpers/retrievePosts'






export default function Schedules({ user }) {
    const [ isPageLoading, setIsPageLoading ] = React.useState(true)
    const { etgErrorDetected, updateErrorData } = React.useContext(ErrorHandilingContext)
    const [ noResultsFound, setNoResultsFound ] = useState(false)
    const [ userData, setUserData ] = useState(null);
    const [ phases, setPhases ] = useState(['Vegetative', 'Flower', 'Harvested', 'Archived'])
    const [ mediums, setMediums ] = useState(['Soil', 'Hydro', 'Coco', 'Peat Moss', 'Rockwool'])
    const [ contentTypes, setContentTypes ] = useState(['Plants', 'Social Posts' ])
    const [ ageCheckPass, setAgeCheckPass ] = useState(false)
    const [ filteredSchedules, setFilteredSchedules ] = useState([])
    const [ filteredPlants, setFilteredPlants ] = useState([])
    const [ filteredSocialPosts, setFilteredSocialPosts ] = useState([])
    const [ plantCategories, setPlantCategories ] = useState(['General', 'Help', 'Controversial', 'Showoff', 'Mother', 'Specialty'])
    const [ userDisplayNames, setUserDisplayNames ] = useState([])
    const [ postBeginDate, setPostBeginDate ] = useState(new Date('January 1, 2021'))
    const [ postEndDate, setPostEndDate ] = useState(new Date())
    const [ socialPostCategories, setSocialPostCategories ] = useState(['General', 'Question', 'Random', 'Hardware', 'Other'])
    const [ addPost, setAddPost ] = useState(false)
    const [ documentToLoad, setDocumentToLoad ] = useState(null)
    const [ viewingMySocialFeed, setViewingMySocialFeed ] = useState(false)
    
    // BEGIN DATA LOADING SECTION
    const [ id, setId ] = useState('notloggedin')
    if  ((user) && (id !== user.uid) ) {
        setId(user.uid)
    }

    // recieve the params from the url, if any passed, save as documentToLoad
    const { documentIDToLoad } = useParams()  
    // let loadSpecificDocument = false
    // remove the first two characters from the documentToLoad, which will be the first two characters of the document type, if remaining characters are not equal to 36 characters, then the documentToLoad is not a valid document ID
    if (documentIDToLoad && !documentToLoad) {
      const docTypeToLoad = documentIDToLoad.slice(0, 2);      
      const tempDocumentID = documentIDToLoad.slice(2, documentIDToLoad.length);
      // verify that the documentIDToLoad is a valid document ID
      if (tempDocumentID.length !== 36) {
        console.log('invalid documentIDToLoad')        
      } else {
        // if the documentIDToLoad is valid, set the documentToLoad state to the, post type and documentIDToLoad        
        if (docTypeToLoad === 'sc') {        
          setDocumentToLoad({ type: 'schedule', IDToLoad: tempDocumentID });
          // make sure that the contentTypes state includes nutrient schedules
          if (!contentTypes.includes('Nutrient Schedules')) {
            setContentTypes([...contentTypes, 'Nutrient Schedules']);
          }
          
        } 
        
        if (docTypeToLoad === 'so') {
          setDocumentToLoad({ type: 'socialPost', IDToLoad: tempDocumentID});
        }
      } 
    }


    
    // if (documentToLoad && documentToLoad.length === 36) {
    //   console.log('called')
    //   loadSpecificDocument = true
    // }
    

    const currentUser = getAuth().currentUser    
    // Load data from firestore
    const { profileDocument } = useAppData(id)    
    if (isPageLoading && profileDocument) {
        setIsPageLoading(false)
    }

    
    // Set the userDisplayNames state to an array of objects with three keys, text, value and url
    useEffect(() => {
        GApageView('about')   
        let tempUserDisplayNames = [] 
        loadDisplayNames(false).then((result) => {
          // for every element in result, build a new object with three keys, text, value and url
          // then set the state of the displayNames to the new array of objects
          result.forEach((element) => {
            const newElement = {
              text: element.toUpperCase(),
              value: element,
              url: `/grows/${element}`,
            };
            tempUserDisplayNames.push(newElement);
            }
          );

          setUserDisplayNames(tempUserDisplayNames);
        });      

        // set end date to today
        setPostEndDate(new Date())


        // set begin date to 30 days ago
        const thirtyDaysAgo = new Date()
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
        setPostBeginDate(thirtyDaysAgo)        
    }, [])    

    // function that like a nutrient schedule - update the schedules like array by adding the currentUser.uid to the array
    const toggleLikeSchedule = async (scheduleId, scheduleToUpdate) => {
        setIsPageLoading(true)
        // check if the user is logged in
        if (currentUser) {
            // check if the user has already liked the schedule
            if (scheduleToUpdate.likes.includes(currentUser.uid)) {
                // if the user has already liked the schedule, remove the like
                const index = scheduleToUpdate.likes.indexOf(currentUser.uid)
                scheduleToUpdate.likes.splice(index, 1)
            } else {
                // if the user has not liked the schedule, add the like
                scheduleToUpdate.likes.push(currentUser.uid)
            }
            try {
                // update the schedule document in the firestore collection, nutrientSchedules
                await projectFirestore.collection('nutrientSchedules').doc(scheduleId).update({
                    likes: scheduleToUpdate.likes
                })
                
            } catch (error) {
                if (currentUser) {
                    updateErrorData(error, currentUser.uid)
                } else {
                    updateErrorData(error, 'notloggedin')                                    
                }
            }
        } else {
            // if the user is not logged in, alert them to log in
            alert('Please log in to like a schedule')
        }
        setIsPageLoading(false)
    }

    // function that copies the nutrient schedule to the users profile which has several steps   
    const copySchedule = async (scheduleToCopy) => {
        setIsPageLoading(true);

        const confirmed = window.confirm('Confirm you want a copy of this schedule in your Grow Space?');
      if (confirmed) {        
        try {
          const batch = projectFirestore.batch();
        // update existing schedule with the currentUser.uid added to the copiedBy array
          const scheduleRef = projectFirestore.collection('nutrientSchedules').doc(scheduleToCopy.nutrientScheduleID);
          batch.update(scheduleRef, {
            copiedBy: [...scheduleToCopy.copiedBy, currentUser.uid]
          });
          
        
          //   create a new schedule document with the currentUser.uid added to the contributors array        
          let date = new Date();
          const timestampFromDate = new timestamp(Math.round(date.getTime()/1000), 0)
        
  
          // Create a new schedule document with the currentUser.uid added to the contributors array
            const newSchedule = {
                ...scheduleToCopy,
                nutrientScheduleID: uuidv4(),
                contributors: [...scheduleToCopy.contributors, currentUser.uid],
                dateCreated: timestampFromDate,
                dateModified: timestampFromDate,
                likes: [],
                copiedBy: [],
                scheduleName: `${scheduleToCopy.scheduleName} - Copy`,
                createdByUID: currentUser.uid
            };
  
  
          const newScheduleRef = projectFirestore.collection('nutrientSchedules').doc(newSchedule.nutrientScheduleID);
          batch.set(newScheduleRef, newSchedule);
      
          const profileRef = projectFirestore.collection('growProfiles').doc(currentUser.uid);
          if (newSchedule.phase === 'Vegetative') {
            batch.update(profileRef, {
              vegNutrients: [...profileDocument.vegNutrients, newSchedule.nutrientScheduleID]
            });
          } else if (newSchedule.phase === 'Flower') {
            batch.update(profileRef, {
              flowerNutrients: [...profileDocument.flowerNutrients, newSchedule.nutrientScheduleID]
            });
          }
      
          await batch.commit();
  
        } catch (error) {
          console.log('An error occurred during batch update:', error);
          updateErrorData("Error Copying Schedule", currentUser.uid)
        }
      }
      
        setIsPageLoading(false);        
      };

    // load the user data from the firestore collection, growProfiles
    React.useEffect(() => {
      const usersData = getGrowProfiles()        
      usersData.then((data) => {          
        setUserData(data);
      });      
    }, []);

    // function that updates contentTypes to just include 'Social Posts' uploaded by the logged in user
    const viewMySocialFeed = () => {
      setContentTypes(['Social Posts'])
      setViewingMySocialFeed(true)
    }

    const handleResetCommunnityFeed = () => {
      setContentTypes(['Social Posts', 'Plants', 'Nutrient Schedules'])
      setViewingMySocialFeed(false)
    }

    // useEffect that runs when filteredSchedules, filteredPlants, and filteredSocialPosts are updated
    // if all three are empty, set noResultsFound to true, otherwise set to false
    useEffect(() => {
      if (filteredSchedules.length === 0 && filteredPlants.length === 0 && filteredSocialPosts.length === 0) {
        setNoResultsFound(true)
      } else {
        setNoResultsFound(false)
      }
    }, [filteredSchedules, filteredPlants, filteredSocialPosts])

  
    
    useEffect(() => {      
      const fetchData = async () => {
        setIsPageLoading(true);
    
        try {
          let retrievedSchedules;
          let retrievedPlants;
          let retrievedPosts;
    
          if (documentToLoad === null) {
            // load all schedules, plants, and posts
            if (contentTypes.includes('Nutrient Schedules')) {              
              retrievedSchedules = await retrieveSchedules(phases, mediums);
            }            
            if (contentTypes.includes('Social Posts')) {
              retrievedPosts = await retrievePosts(postBeginDate, postEndDate);
            }
          } else {
            // load contentTypes based on user filters
            if (contentTypes.includes('Social Posts')) {
              retrievedPosts = await retrievePosts(postBeginDate, postEndDate, true);
            }
    
            if (contentTypes.includes('Nutrient Schedules')) {
              retrievedSchedules = await retrieveSchedules(phases, mediums, true);
            }            
          }
          // plants aren't loaded directly on the community page
          if (contentTypes.includes('Plants')) {
            retrievedPlants = await retrievePlants(phases, mediums);
          }
          
          // Filter Data Section
          const socialPosts = retrievedPosts || {};
          const nutrientSchedules = retrievedSchedules || {};
          const plantData = retrievedPlants || {};          
          
          // FILTER SOCIAL POSTS
          if (contentTypes.includes('Social Posts')) {            
            if ( documentToLoad && documentToLoad.type === 'socialPost') {              
              const socialPostToLoad = socialPosts.find(post => post.postID === documentToLoad.IDToLoad);                            
              setPostBeginDate(timestampToDate(socialPostToLoad.dateTimePosted))
              const filteredSocialPosts = socialPosts.filter(post => post.category === socialPostToLoad.category && post.postID !== socialPostToLoad.postID);
              const filteredSocialPostsWithSocialPostToLoadFirst = [socialPostToLoad, ...filteredSocialPosts];
              setFilteredSocialPosts(filteredSocialPostsWithSocialPostToLoadFirst);
            } else {
              const filteredSocialPosts = socialPosts ? socialPosts.filter(post => socialPostCategories.includes(post.category)) : [];
              setFilteredSocialPosts(filteredSocialPosts);              
            }


          } else {
            setFilteredSocialPosts([]);
          }
          
          // FILTER PLANTS
          if (contentTypes.includes('Plants')) {
            const filteredPlantsByMedium = plantData.arrayOfAllPlants ? plantData.arrayOfAllPlants.filter(plant => mediums.includes(plant.medium)) : [];
            const filteredPlants = filteredPlantsByMedium.filter(plant => plantCategories.includes(plant.plantCategory));
            setFilteredPlants(filteredPlants);
          } else {
            setFilteredPlants([]);
          }
          
          if (contentTypes.includes('Nutrient Schedules')) {
            // FILTER SCHEDULES                        
            if (documentToLoad && documentToLoad.type === 'schedule') {
              const scheduleToLoad = nutrientSchedules.find(schedule => schedule.nutrientScheduleID === documentToLoad.IDToLoad);            
              const filteredSchedules = nutrientSchedules.filter(schedule => schedule.medium === scheduleToLoad.medium && schedule.nutrientScheduleID !== scheduleToLoad.nutrientScheduleID);
              const filteredSchedulesWithScheduleToLoadFirst = [scheduleToLoad, ...filteredSchedules];
              setFilteredSchedules(filteredSchedulesWithScheduleToLoadFirst);
            } else {
              setFilteredSchedules(nutrientSchedules);
              }  
            } else {
              setFilteredSchedules([]);
            }

        } catch (err) {
          console.log('Error:', err);
          updateErrorData(err, currentUser.uid || 'notloggedin');
        } 
    
        setIsPageLoading(false);
      } 
    
      fetchData();
    }, [phases, mediums, plantCategories, updateErrorData, contentTypes, socialPostCategories, postBeginDate, postEndDate, documentToLoad, currentUser]);
    
    // If user = Scenario 2 (when determining view) since there is no user, verify age
  if (!currentUser && !ageCheckPass) {
    return <ProcessingDiv message="Please Verify You Are 18+ Years Old" type='ageVerification' processRing={false} controlBoolean={setAgeCheckPass} />    
  }        
        return (
            <>
              {etgErrorDetected ? null : (
                <>
                  <section className='flex flex-col h-screen justify-between'>
                    {/* NavBar */}
                    <div className='relative w-full z-20'>
                      <DefaultNavbar user={user} profileData={profileDocument} />
                    </div>
                    {/* Page Specific Content */}                    
                      <main className='flex flex-col w-11/12 mx-2 my-10 justify-center self-center bg-slate-100 py-6 rounded-xl px-4'>
                        { addPost ? null : <>

                          {/* Page Welcome & Header Info */}
                          <CommuntiyHeader
                              currentUser={currentUser}
                              />

                        <div className='flex flex-wrap justify-center'>
                          {currentUser && !addPost ? (
                          // Button to Display the New Social Post Section instead of the community feed display
                            <div className='flex justify-center py-4'>
                              <ETGButton
                                btnText="Create New Social Post"
                                onClickFunction={() => setAddPost(true)}
                              />
                            </div>
                            ) : null}
                          
                          
                            { ( currentUser && !addPost ) ? !viewingMySocialFeed ? <>
                            {/* // Button to Display the users social feed instead of the community feed display */}
                            <div className='flex justify-center py-4'>
                              <ETGButton
                                btnText="View My Social Feed"
                                onClickFunction={() => viewMySocialFeed()}
                                />
                            </div>                          
                            </> : <> 
                            {/* // Button to Display the community feed display instead of the users social feed */}
                            <div className='flex justify-center py-4'>
                              <ETGButton
                                btnText="Reset Community Feed"
                                onClickFunction={() => handleResetCommunnityFeed()}
                                />
                            </div>
                            </> : null }
                          </div>
                          {/* Options for user to filter what they are viewing */}
                          <CommunityFilterDashboardSection
                            phases={phases}
                            mediums={mediums}
                            setPhases={setPhases}
                            setMediums={setMediums}                          
                            updateErrorData={updateErrorData}
                            plantCategories={plantCategories} setPlantCategories={setPlantCategories}
                            filteredPlants={filteredPlants}
                            contentTypes={contentTypes} setContentTypes={setContentTypes}
                            postBeginDate={postBeginDate} setPostBeginDate={setPostBeginDate}
                            postEndDate={postEndDate} setPostEndDate={setPostEndDate}  
                            socialPostCategories={socialPostCategories} setSocialPostCategories={setSocialPostCategories}
                            isPageLoading={isPageLoading}         
                            />

                        </>}


                          {/* Community Feed Display / Add Social Post */}
                          { isPageLoading ? <ProcessingDiv message='Loading Feed' /> : addPost ? <AddSocialPost
                            userDisplayNames={userDisplayNames} setAddPost={setAddPost} currentUser={currentUser} updateErrorData={updateErrorData}
                            setDocumentToLoad={setDocumentToLoad}
                          /> : <>
                        <div className='overflow-y-auto bg-slate-300 outline-2 outline-slate-400 outline-dotted p-1 mt-4 -mx-2 h-screen'>
                          {/* If noResultsFound is true, display a message to the user */}
                          {noResultsFound ? <div className='flex justify-center text-2xl text-red-800 p-2 text-center uppercase tracking-wider'>No Results Found - Try Again By Updating the Filters</div> : <>
                            <CommunityFeedDisplay
                              documentToLoad={documentToLoad}
                              filteredSchedules={filteredSchedules}
                              filteredPlants={filteredPlants}
                              filteredSocialPosts={filteredSocialPosts}
                              currentUser={currentUser}
                              toggleLikeSchedule={toggleLikeSchedule}
                              copySchedule={copySchedule}
                              noResultsFound={noResultsFound}
                              updateErrorData={updateErrorData}
                              userData={userData}
                              setIsPageLoading={setIsPageLoading}
                              userDisplayNames={userDisplayNames}
                              setDocumentToLoad={setDocumentToLoad}
                              />
                            </>}
                        </div>
                            </>}
                      </main>                    
                    <DefaultFooter />
                  </section>
                </>
              )}
            </>
          );
          }

