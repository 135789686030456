import { useEffect, useState } from 'react';
import { projectFirestore } from '../firebase/config';

// Make sure to initialize Firebase in your application before using this hook.

const useGrowLabelOrdersListener = (plantID = null, userID = null) => {
    const [growLabelOrders, setGrowLabelOrders] = useState([]);
  useEffect(() => {    

    
    let query = projectFirestore.collection('growLabelOrders');

    if (plantID && !userID) {
      // Create a listener for documents where labelsForPlantID matches the plantID
      query = query.where('labelsForPlantID', '==', plantID);
    } else if (!plantID && userID) {
      // Create a listener for documents where orderedByUID matches the userID
      query = query.where('orderedByUID', '==', userID);
    } else if (plantID && userID) {
      // Create a listener for documents where labelsForPlantID matches the plantID
      // and orderedByUID matches the userID
      query = query.where('labelsForPlantID', '==', plantID);
    } else {
      // If both plantID and userID are null, return early as there's nothing to listen to
      return;
    }

    // Set up the listener
    const unsubscribe = query.onSnapshot((snapshot) => {
        let growLabelOrders = [];
      // Process the snapshot data here
      // For example, you can update state or dispatch actions with the data
      snapshot.forEach((doc) => {       
        doc.data().paymentLinkResponse = JSON.parse(doc.data().paymentLinkResponse);   
        growLabelOrders.push( {...doc.data(), paymentLinkResponse: JSON.parse(doc.data().paymentLinkResponse)} );

      });
        setGrowLabelOrders(growLabelOrders);
    });

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [plantID, userID]);

  return growLabelOrders;
};

export default useGrowLabelOrdersListener;
