import React from 'react'
import { timestamp } from '../../../firebase/config'
import ETGButton from '../../shared/global/ETGButton'
import { useUpdatePlantData } from '../../../hooks/useUpdatePlantData'

export default function FlowerToVegView({ appData, currentPlantData,
    setCurrentPhase, setGrowsModal, setIsProccesing, updateErrorData, handleNewPlantSelected }) {

        const { updatePlantData, updatePlantDataError } = useUpdatePlantData(appData.profileData)


    const handleFlowerToVeg = async () => {    
        setIsProccesing(true)
        let tempDate = new Date('April 20, 2020')

        let tempTransferResetDate = new timestamp(Math.round(tempDate.getTime()/1000), 0)


        let newPlant = {
            strain: currentPlantData.strain, 
            status: 'Vegetative', 
            location: currentPlantData.location,
            position: currentPlantData.position,
            medium: currentPlantData.medium,
            harvestYield: currentPlantData.harvestYield,
            startDate: currentPlantData.startDate,
            transferDate: tempTransferResetDate,
            harvestDate: currentPlantData.harvestDate,
            noteLog: currentPlantData.noteLog,
            plantId: currentPlantData.plantId,
            imgArray: currentPlantData.imgArray,
            vegNutrients: currentPlantData.vegNutrients,
            flowerNutrients: currentPlantData.flowerNutrients,
            plantOwnerUID: currentPlantData.plantOwnerUID
        }
        
        // remove note type 'lc-transfer'
        let noteIndexToRemove = newPlant.noteLog.findIndex((note) => note.type === 'lc-transfer')
        let noteToRemove = newPlant.noteLog.splice(noteIndexToRemove, 1)
                
        // update location & postion to match removed note starting locaiton/position
        newPlant.location = noteToRemove[0].locationStart
        newPlant.position = noteToRemove[0].positionStart

        // update plant data in firebase
        await updatePlantData(newPlant)
        if (updatePlantDataError) {
            updateErrorData(updatePlantDataError, appData.id)            
        } else {
            handleNewPlantSelected(newPlant)
        }
        setCurrentPhase("Vegetative")           
        setGrowsModal(false)
        setIsProccesing(false)     
                   
    }
  return ( <>
    <section>
        <div className="flex flex-col justify-evenly">                    
            <div className='flex flex-row pt-4 space-evenly mx-auto'>
                <div className="px-2">                        
                    <ETGButton
                    onClickFunction={handleFlowerToVeg}
                    icon='save'
                    btnText='Move Back to Veg'
                    type='caution'
                    />
                </div>
                <div className="px-2">                                                
                    <ETGButton
                        onClickFunction={() => setGrowsModal(false)}
                        icon='close'
                        btnText='Cancel'
                        type='delete'
                        />
                </div>
            </div>
        </div>
    </section>
    </>)
}
