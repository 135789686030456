import { getAuth } from 'firebase/auth';
import { projectFirestore, timestamp } from '../../../../firebase/config';
import { v4 as uuidv4 } from 'uuid';

export async function addReplyFunc(commentID, replyText, respondingTo = null) {
  const userID = getAuth().currentUser.uid;
  let result = null;
  let date = new Date();
  const replyID = uuidv4();

  try {
    const reply = {
      date: new timestamp(Math.round(date.getTime() / 1000), 0),
      likes: [userID],
      text: replyText,
      userID,
      replyID,
      respondingTo, // Set respondingTo to the parent comment or reply ID, or null if it's a top-level comment
    };
    
    const commentRef = projectFirestore.collection('userComments').doc(commentID);
    const repliesCollectionRef = commentRef.collection('replies');

    // Add the reply document to the replies sub-collection
    await repliesCollectionRef.doc(replyID).set(reply);

    result = 'success';
  } catch (error) {
    result = 'error';
  }

  return result;
}
