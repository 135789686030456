import DefaultNavbar from '../components/DefaultNavbar'
import Main from '../components/TOS/Main'
import DefaultFooter from '../components/DefaultFooter'
import React, { useState } from 'react';
import { useAppData } from '../hooks/useAppData';
import LoadingDiv from '../components/LoadingDiv';
import { GApageView } from '../components/renderless/helpers';
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';

export default function TOS({ user }) {
    const [ isPageLoading, setIsPageLoading ] = React.useState(false)
    const { etgErrorDetected } = React.useContext(ErrorHandilingContext)
    
    // BEGIN DATA LOADING SECTION
    const [ id, setId ] = useState('notloggedin')
    if  ((user) && (id !== user.uid) ) {
        setId(user.uid)
    }
    
    // Load data from firestore
    const { profileDocument, plantDocument } = useAppData(id)    
    if (isPageLoading && profileDocument && plantDocument) {
        setIsPageLoading(false)
    }

    
    // END DATA LOADING SECTION
    React.useEffect(() => {                
        GApageView('terms-of-service')    
    }, [])
    
    return (
        <> { etgErrorDetected ? null : <>        
        <section className='flex flex-col h-screen justify-between'>

            <div className='relative w-full z-20'>
                <DefaultNavbar 
                    user={user} 
                    profileData={profileDocument} 
                />
            </div>
            {/* Page Specifc Content */}
            { isPageLoading ? <LoadingDiv /> : 
                <main className='my-10 w-10/12 mx-auto'>
                    <Main />
                </main>
            }
            <DefaultFooter/>
            </section>
        </> } </>
        )
    }
    