export function growsPlantDataProcessing ( plantData ) { 
    let newPlantData = {
        activePlants: [],
        harvestedPlants: [],
        archivedPlants: []
    }    


    // loop through the plantData array - if the element.status is 'Deted', skip it, otherwise add it to the newPlantData array
    // in the correct phase array

    plantData.forEach(plant => {            
        if (plant.stauts === 'Deleted' ) {
            // do nothing
        } else if (plant.status === 'Vegetative') {
            newPlantData.activePlants.push(plant)            
        } else if (plant.status === 'Flower') {
            newPlantData.activePlants.push(plant)                        
        } else if (plant.status === 'Harvested') {
            newPlantData.harvestedPlants.push(plant)
        } else if (plant.status === 'Archived') {
            newPlantData.archivedPlants.push(plant)
        }
    })
    return newPlantData
}