import React, {useState, useEffect} from 'react';
import { gaEvent } from '../renderless/helpers';

export default function CustomForm ({ status, message, onValidated }) {

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');



    const handleSubmit = () => {        
        gaEvent('newsletter', 'newsletter signup', 'mailchimp newsletter signup')
        email &&
        firstName &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
            MERGE1: firstName
        });
    }

    useEffect(() => {
        if(status === "success") clearFields();
      }, [status])
    
      const clearFields = () => {
        setFirstName('');
        setEmail('');
      }


    return (
      <form className="pt-2 pb-4">
        <h3 className='text-3xl tracking-wide font-medium py-2 px-6 uppercase'>
          {status === "success" 
            ? "Success!" 
            : "Join Our Newsletter"
          }
        </h3>

        {/* Display MailChimp Response Messages */}
        {status === "sending" && (
          <div className="text-2xl text-white tracking-wide font-medium py-2 px-6 uppercase">
            subscribing...
          </div>
        )}
        {status === "error" && (
          <div 
            className="text-2xl border-2 border-dashed rounded-xl border-red-800 bg-red-800/40 tracking-wide font-medium py-2 px-6 uppercase mx-6"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className='text-2xl text-white tracking-wide font-medium py-2 px-6 uppercase'
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

        {status !== "success" ? ( <>
          {/* INPUTS FOR FIRST NAME AND EMAIL ADDRESS FOR MAILCHIMP SUBSCRIPTION */}
          <div className='flex flex-col md:flex-row gap-2 justify-evenly text-black py-2'>            
            <div className=''>
              <input
                className='border-2 rounded-xl p-2'
                label="First Name"
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                value={firstName}
                placeholder="first name"            
                />          
                <p className='py-2 text-white font-light text-md tracking-wider'>first name</p>
            </div>
            <div>
              <input
                className='border-2 rounded-xl p-2'
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                value={email}
                placeholder="your@email.com"
                />
                <p className='py-2 text-white font-light text-md tracking-wider'>email address</p>    
            </div>            
          </div>

          {/* BUTTON TO CONFIRM SUBSCRIPTION */}
          <div onClick={() => handleSubmit()} className=''>            
            <p className='flex w-fit justify-center p-2 px-4 border-2 rounded-xl border-emerald-400/50 md:hover:bg-emerald-400 mx-auto'>
                <i className='material-icons pr-2'>mail_outline</i> Confirm Newsletter Subscription
            </p>               
          </div>
          </>
        ) : null}

      </form>
    );
};


// following this tutorial
// https://dev.to/gedalyakrycer/create-an-email-list-with-react-mailchimp-965