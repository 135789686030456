import React from "react";
import ETGButton from "../../../shared/global/ETGButton";
import { timestamp } from "../../../../firebase/config";
import { v4 as uuidv4 } from "uuid";
import useAdminSupportTask from "../../../../hooks/useAdminSupportTask";
import useMessageSender from "../../../../hooks/useMessageSender";

export default function PlantFooterFlagView({ currentPlantData, currentUser, updateErrorData, getDisplayName }) {
    const { addTask } = useAdminSupportTask()
    const { sendEmail, messageSent } = useMessageSender() 

  


    const handleFlagPlant = async () => {
        // confirm user would like to flag the plant
        const confirmFlag = window.confirm("Are you sure you would like to flag this plant?");
        if (confirmFlag) {
            // create the AdminTask being generated
            const today = new Date();
            const dateTimeFlagged = new timestamp(Math.round(today.getTime()/1000), 0);

            const flagTask = {
                adminNotes: [],
                category: "Flagged Plant",
                dateTimeLastUpdated: dateTimeFlagged,
                dateTimeSubmitted: dateTimeFlagged,
                dateTimeCompleted: null,
                description: `Plant ID: ${currentPlantData.plantId} flagged by ${currentUser.uid}`,
                flaggedContent: {
                    contentID: currentPlantData.plantId,
                    contentType: "Plant",
                    flaggedBy: currentUser.uid,
                    contentOwner: currentPlantData.plantOwnerUID,
                }, 
                priority: "High",
                status: "Submitted",
                taskID: uuidv4(), 
                title: `Flagged Plant`,
            }
            try {
                await addTask(flagTask)

                // send email to user confirming plant was flagged
                const userEmailData = {
                    displayName: getDisplayName(currentUser.uid),
                    contentType: "Plant",
                    taskID: flagTask.taskID,
                }

                await sendEmail(currentUser.email, 'flaggedContentUser', userEmailData, 'Flagged Content Confirmation', currentUser.uid)

                // send email to admin confirming plant was flagged
                const adminEmailData = {
                    displayName: getDisplayName(currentUser.uid),
                    contentType: "Plant",
                    taskID: flagTask.taskID,
                    description: flagTask.description,
                }

                await sendEmail('Team@EctorGrow.com', 'flaggedContentAdmin', adminEmailData, 'Flagged Content Confirmation')
                
            } catch (error) {
                updateErrorData(error.message, currentUser.uid)                
            }

            
        }


    }  

  

  

  return ( 
    <div className="flex-row justify-center items-center text-center text-black px-4 m-4">
        {/* Display temporary title */}
        <h3 className="etg-sectionTitle">Flag this plant?</h3>      


        {/* Explainer For When and Why To potentially flag a plant */}
        { !messageSent ? <>
            
            <div className="flex-row p-2 my-1 rounded-xl">
                <h4 className="etg-sectionTitle">When and Why To Flag a Plant</h4>
                <div className="flex flex-col outline-1 outline-dotted p-4 mx-8 rounded-xl">
                    <p className="">
                        Plants should be flagged if they are not in compliance with the EctorGrow terms of service or community guidelines.
                    </p>
                    <p className="pt-2">
                        Examples of plants that should be flagged include (but are not limited to) the following:
                    </p>
                    <ul className="text-sm pt-4 gap-2 italic list-disc mx-4">
                        <li>
                            Plants that are not cannabis plants
                        </li>
                        <li>
                            Language or images that are not appropriate for the EctorGrow Community or unrelated to growing cannabis
                        </li>
                        <li>
                            Language including any reference or mention of selling cannabis plants or cannabis products
                        </li>
                    </ul>
                    <p className="pt-2">
                        If you need to flag a comment or reply to a comment, please use the flag button on the comment or reply directly
                    </p>
                    <p className="etg-systemText pt-4">
                        For reference, our Terms of Service can be found here - <a href="https://www.ectorcountygrow.com/terms-of-service" target="_blank" rel="noreferrer" className="etg-systemText underline">Terms of Service</a>
                    </p>
                </div>

            </div>

            {/* Button to Flag Plant */}
            <div className="flex-row justify-center items-center p-2 my-1 rounded-xl">
                <ETGButton
                    btnText="Flag Plant"
                    onClickFunction={handleFlagPlant}
                />
            </div>
            </> : <>
                {/* message that plant has been flagged */}
                <div className="flex-row p-2 my-1 rounded-xl">                    
                    <div className="flex flex-col outline-1 outline-dotted p-4 mx-8 rounded-xl">
                        <p className="">
                            This plant has been flagged and will be reviewed by the EctorGrow team.  Thank you for helping us keep the EctorGrow community safe and enjoyable for everyone!
                        </p>
                    </div>
                </div>
            </>}


    </div>
  );
}