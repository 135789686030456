import React from "react";
import './App.css';
// page imports
import About from './pages/About'
import TOS from "./pages/TOS"
import Privacy from "./pages/Privacy";
import Login from "./pages/Login"
import Register from "./pages/Register";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Community from './pages/Community'
import ResetPassword from "./pages/ResetPassword";
import Profile from "./pages/Profile";
import Admin from './pages/Admin'
import Dev from './pages/Dev'
import LoadingDiv from "./components/LoadingDiv";
import Grows from "./pages/Grows";
import OrderStatus from "./pages/OrderStatus";
import EctorGrowLabels from "./pages/EctorGrowLabels";

import ReactGA from 'react-ga4';



// Import Material-Tailwind Stylesheet
import "@material-tailwind/react/tailwind.css";
import { useAuthContext } from "./hooks/useAuthContext";
import { ErrorHandilingProvider } from "./context/ErrorHandilingContext";
import { Routes, Route, Navigate } from "react-router-dom";
import Feedback from "./pages/Feedback";


// Initialize Google Analytics
ReactGA.initialize([{ trackingId: "G-HSCZJRW5CY" }])




function App() {
  const { authIsReady, user } = useAuthContext()
  // if until authContext is loaded
  if (!authIsReady) {
    return <LoadingDiv />
  } 
      
  return (
    <ErrorHandilingProvider>
      <div className="App"> 
        {authIsReady && (           
        
          <Routes>
            {/* General Linked Pages */}
            <Route path="/" element={<Home user={user} />} />        
            <Route path="about" element={<About user={user} />} />
            <Route path="resetpassword" element={<ResetPassword  user={user}/>} />                               
            <Route path="ectorgrowlabels" element={<EctorGrowLabels  user={user}/>} />    
            <Route path="feedback/" element={!user ? <Navigate to="/login" /> : <Feedback user={user} />} 
            />                           
            {/* Misc. Site Links */}          
            <Route path="termsofservice" element={<TOS user={user} />} />
            <Route path="privacy" element={<Privacy user={user} />} />

            {/* Pages that have multiple param options they may be passed */}          
            <Route 
              path="grows/" 
              element={!user ? <Navigate to="/login" /> : <Grows user={user} />} 
            />
            <Route 
              path="grows/:displayName" 
              element={<Grows user={user} />} 
            />
            <Route 
              path="grows/:displayName/:plantIDToLoad" 
              element={<Grows user={user} />} 
            />

            {/* Pages that have a single param option they may be passed */}
            <Route
              path="community/"
              element={<Community user={user} />}
            />
            <Route
              path="community/:documentIDToLoad"
              element={<Community user={user} />}
            />
            <Route
              path="order/"
              element={<OrderStatus user={user} />}
            />
            <Route
              path="order/:etgOrderIDToLoad"
              element={<OrderStatus user={user} />}
            />            
            {/* Pages that redirect - based on user login status */}
            <Route 
              path="profile/" 
              element={!user ? <Navigate to="/login" /> : <Profile user={user} />} 
            />            
            <Route 
              path="login" 
              element={user ? <Navigate to={`/grows/`} /> : <Login />} 
            />    
            <Route 
              path="register" 
              element={user ? <Navigate to={`/grows/`} /> : <Register />} 
            />            
            <Route 
              path="admin/" 
              element={user ? <Admin user={user} /> : <Navigate to='/' />} 
            />
            <Route 
              path="dev/" 
              element={user ? <Dev user={user} /> : <Navigate to='/' />} 
            />          
            <Route 
              path="dev/:id" 
              element={<Dev user={user} />} 
            />          
            {/* 404 - Page Not Found */}
            <Route path='*' element={<NotFound user={user} />} />
          </Routes>              
        )}                 
    </div>
  </ErrorHandilingProvider>
  );
}

export default App;
