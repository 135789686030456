import React from 'react'
import ETGButton from '../../shared/global/ETGButton'
import { useFirestore } from '../../../hooks/useFirestore'
import { testAboutMeInput } from '../helpers/testAboutMeInput'
import { useKeyPress } from '../../../hooks/useKeyPress'
import SaveInProgress from '../../shared/global/SaveInProgress'

export default function EditNameView({ handleProfileModalToggle, profileDocument }) {        
    const [ isLoading, setIsLoading ] = React.useState(false)
    const [ firstName, setFirstName ] = React.useState(profileDocument.firstName)        
    const [ lastName, SetLastName ] = React.useState(profileDocument.lastName)        
    const { updateDocument, response } = useFirestore('growProfiles')
    
    const { keyPressed } = useKeyPress('Escape')
    if (keyPressed) {
        handleProfileModalToggle()
    }
        

    const handleFirstNameInputChange = ( nameInput ) => {                    
        const results = testAboutMeInput( nameInput )
        // if passes validation update firstname - if fails, clear firstname
        if (results.validationPassed) { 
            setFirstName(nameInput)        
        } else { 
            alert('Prohibited Input Detected')
            setFirstName(results.nameInput)
            setFirstName('')
        }
    }

    function handleLastNameInputChange ( nameInput ) {
        const results = testAboutMeInput( nameInput )
        // if passes validation update firstname - if fails, clear firstname
        if (results.validationPassed) { 
            SetLastName(nameInput)        
        } else { 
            alert('Prohibited Input Detected')
            SetLastName(results.nameInput)
            SetLastName('')
        }
    }

    const handleSaveName = async () => {    
        setIsLoading(true)    
        // clone and update document
        let clonedProfile = Object.assign({}, profileDocument)        
        clonedProfile.firstName = firstName
        clonedProfile.lastName = lastName
        
        // save document
        updateDocument(profileDocument.uid, clonedProfile)        
        // make error if response is not null otherwise close modal        
        
    if (response.error) {
            // throw error
        } else {
            handleProfileModalToggle()
        }

    setIsLoading(false)
    }    
    
    

  return (<>
    {/* DisplayName Field */}
    <div className="flex flex-col w-10/12 bg-gray-300 rounded-xl self-center text-black">        
        {/* First Name */}
        <div className="flex flex-col w-full justify-center text-black my-8 p-2 pt-4">            
            <label className='etg-sectionTitle text-center pb-2'>First Name</label>
            <input
                type='text'                
                placeholder={profileDocument.firstName}  
                value={firstName}                              
                className='etg-userText p-2 rounded-lg w-10/12 md:w-full self-center shadow-xl'
                onChange={(e) => handleFirstNameInputChange(e.target.value)}
            />            
        </div>

        {/* Last Name */}
        <div className="flex flex-col bg-gray-300 w-full justify-center text-black my-8 p-2 pt-4">            
            <label className='etg-sectionTitle text-center pb-2'>Last Name</label>
            <input
                type='text'                
                placeholder={profileDocument.lastName}  
                value={lastName}
                className='etg-userText p-2 rounded-lg w-10/12 md:w-full self-center shadow-xl'
                onChange={(e) => handleLastNameInputChange(e.target.value)}
            />            
        </div>
        {/* Buttons for Save/Cancel */}
        <div className='flex flex-col md:flex-row justify-around my-4'>
            {/* conditional save buttons */}
            { isLoading ? <SaveInProgress /> : <>
            <ETGButton btnText='Save Name' onClickFunction={handleSaveName} />
            {/* cancel buttons */}
            <ETGButton btnText='Cancel' type='delete' onClickFunction={() => handleProfileModalToggle()} />
            </>}            
        </div>
  </div>
  </>
  )
}
