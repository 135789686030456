import ScheduleDisplay from './ScheduleDisplay';
import VegNutrientDisplay from './Nutrients/VegNutrientDisplay';
import FlowerNutrientDisplay from './Nutrients/FlowerNutrientDisplay';
import { NavLink } from 'react-router-dom';
import ETGButton from '../../../shared/global/ETGButton'


export default function PlantFooterNutrientview({ currentPlantData, appData, currentPhase, updateErrorData, 
    findFlowerNutrientSchedule, findVegNutrientSchedule, activeNutrientObj, handlePhaseChange, nutrientPhase }) {
        return <div className={`flex-row text-black px-4 m-4`}>
            <div className='text-center'>
                <div className="flex flex-col items-center text-black">
                    {/* display private version of schedule */}
                    {((currentPlantData.vegNutrients !== 'none' || currentPlantData.flowerNutrients !== 'none') && !appData.publicView) && <>
                        <ScheduleDisplay
                            appData={appData}
                            currentPlantData={currentPlantData}
                            currentPhase={currentPhase}
                            updateErrorData={updateErrorData}
                            findVegNutrientSchedule={findVegNutrientSchedule}
                            findFlowerNutrientSchedule={findFlowerNutrientSchedule} />
                    </>}


                    {/* Display public version of Vegetative schedule */}
                    {((activeNutrientObj && currentPlantData.vegNutrients !== '' && currentPlantData.vegNutrients !== 'none') && nutrientPhase === 'Vegetative' && appData.publicView) && <>
                        <div className='flex w-full overflow-auto border-emerald-300 border-2 bg-emerald-300/10 rounded-xl'>
                            <VegNutrientDisplay
                                activeNutrientObj={activeNutrientObj} />
                        </div>
                        {(currentPlantData.flowerNutrients !== '' && currentPlantData.flowerNutrients !== 'none') && <>
                            <div className='flex text-center mx-auto mt-2'>
                                <ETGButton
                                    onClickFunction={handlePhaseChange}
                                    btnText='See Flower Schedule' />
                                <NavLink to={`/community/sc${activeNutrientObj.nutrientScheduleID}`} className=''>
                                    <ETGButton
                                        type='inner-link'
                                        btnText='View On Community page' />
                                </NavLink>

                            </div>
                        </>}
                    </>}
                    {/* If No Veg Schedule selected for plant */}
                    {((currentPlantData.vegNutrients === '' || currentPlantData.vegNutrients === 'none') &&
                        (currentPlantData.flowerNutrients !== '' && currentPlantData.flowerNutrients !== 'none') &&
                        currentPhase === 'Vegetative') && <>
                            <div className='py-4 italic text-slate-500'>
                                <p>No nutrient schedules have been selected for this plants <span className='font-medium'>current phase</span> of growth</p>
                            </div>
                        </>}
                    {/* Display public version of  Flower schedule */}
                    {((activeNutrientObj && currentPlantData.flowerNutrients !== '' && currentPlantData.flowerNutrients !== 'none') && nutrientPhase === 'Flower' && appData.publicView) && <>
                        <div className='flex w-full overflow-auto border-emerald-200 border-2 bg-emerald-200/10 rounded-xl'>
                            <FlowerNutrientDisplay
                                activeNutrientObj={activeNutrientObj} />
                        </div>
                        {(currentPlantData.vegNutrients !== '' && currentPlantData.vegNutrients !== 'none') && <>
                            <div className='flex text-center mx-auto mt-2'>
                                <ETGButton
                                    onClickFunction={handlePhaseChange}
                                    btnText='See Vegetative Schedule' />
                                <NavLink to={`/community/sc${activeNutrientObj.nutrientScheduleID}`} className=''>
                                    <ETGButton
                                        type='inner-link'
                                        btnText='View On Community page' />
                                </NavLink>
                            </div>
                        </>}
                    </>}
                    {/* If No Veg Schedule selected for plant */}
                    {((currentPlantData.flowerNutrients === '' || currentPlantData.flowerNutrients === 'none') &&
                        (currentPlantData.vegNutrients !== '' && currentPlantData.vegNutrients !== 'none') &&
                        currentPhase === 'Flower') && <>
                            <div className='py-4 italic text-slate-500'>
                                <p>No nutrient schedules have been selected for this plants <span className='font-medium'>current phase</span> of growth</p>
                            </div>
                        </>}



                    {/* If No Schedule selected for plant */}
                    {(currentPlantData.vegNutrients === '' || currentPlantData.vegNutrients === 'none') && ((currentPlantData.flowerNutrients === '' || currentPlantData.flowerNutrients === 'none')) && <>
                        <div className='py-4 italic text-slate-500'>
                            <p>No Nutrient Schedules have been selected for this plant yet</p>
                        </div>
                    </>}
                </div>
            </div>
        </div>;
    }