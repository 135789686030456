export const emailTemplates = {
    // Order Created - displayName - orderDescription - paymentLink - orderID
    orderCreated: {
      subject: "EctorGrow Labels Order Created",
      text: `
        <p>Dear {displayName},</p>
        <p>We know how much passionate growers love collecting and sharing their harvests and we truly think EctorGrow labels are the perfect way.</p>
        <p>To complete your order for {orderDescription}, please proceed with the payment using our secure Square Checkout system:</p>
        <p><a href="{paymentLink}">Square Checkout Payment Link</a></p>
        <p>Thank you for trusting us with your grow, and this order. You'll soon have the easiest way to seamlessly link your plant's harvest to its digital history.</p>
        <p>If you have any questions or need assistance with the payment process, don't hesitate to reach out to our support team at <a href="mailto:Team@EctorGrow.com">Team@EctorGrow.com</a>.</p>
        <p>Thank you for choosing EctorGrow.com. We look forward to being a part of your cannabis growing journey.</p>
        <p>Happy Growing,</p>
        <p>The EctorGrow Team</p>
        <p style="font-size: 80%;">Order ID: <a href="https://EctorGrow.com/order/{orderID}" target="_blank" rel="noopener noreferrer">{orderID}</a></p>
      `
    },
    // Order Update - displayName - orderID - orderDate - emailMessage - orderID
    orderUpdate: {
      subject: "EctorGrow Labels Order Update",
      text: `
      <p>Dear {displayName},</p>
      <p>We are writing to inform you about an update to your order, {orderID}, which was placed on {orderDate}.</p>
      <p>{emailMessage}</p>
      <p>If you have an additional questions or concerns please reply to this email or contact us at <a href="mailto:Team@EctorGrow.com">Team@EctorGrow.com</a>.</p>
      <p>Happy Growing,</p>
      <p>The EctorGrow Team</p>
      <p style="font-size: 80%;">Order ID: <a href="https://EctorGrow.com/order/{orderID}" target="_blank" rel="noopener noreferrer">{orderID}</a></p>
    `
    },
    // Order Processing - displayName - orderID
    orderProcessing: {
      subject: "EctorGrow Labels Order Processing",
      text: `
      <p>Dear {displayName},</p>
      <p>Great news! Your payment for your EctorGrow Labels order has been succesfully confirmed. Thank you for your prompt payment!</p>
      <p>We've started processing your order and you can expect it to arrive at your door in 7-10 business days from payment.</p>
      <p>If you have any questions or need further assistance, feel free to reach out to our support team at <a href="mailto:Team@EctorGrow.com">Team@EctorGrow.com</a>.</p>
      <p>Happy Growing!</p>      
      <p>The EctorGrow Team</p>
      <p style="font-size: 80%;">Order ID: <a href="https://EctorGrow.com/order/{orderID}" target="_blank" rel="noopener noreferrer">{orderID}</a></p>
    `
    },
    // In Transit
    inTransit: {
      subject: "EctorGrow Labels Order In Transit",
      text: `
      <p>Dear {displayName},</p>
      <p>Exciting news! Your EctorGrow Labels order is now on its way to you!</p>
      <p>We are pleased to provide you with the {carrier} tracking number: {trackingNumber}. You can use this tracking number to follow the status of your delivery and know exactly when it will arrive.</p>
      <p>We truly appreciate your business, and we're confident that EctorGrow Labels will enhance your cannabis growing experience. If you have any questions or need assistance, don't hesitate to reach out to our support team at <a href="mailto:Team@EctorGrow.com">Team@EctorGrow.com</a>. We're here to help!</p>
      <p>Happy Growing!</p>
      <p>Best regards,</p>      
      <p> The EctorGrow Team</p>
      <p style="font-size: 80%;">Order ID: <a href="https://EctorGrow.com/order/{orderID}" target="_blank" rel="noopener noreferrer">{orderID}</a></p>
    `
    },
    // Order Delivered
    orderDelivered: {
      subject: "EctorGrow Labels Order Delivered",
      text: `
      <p>Dear {displayName},</p>
      <p>Hooray! Your EctorGrow Labels order has been successfully delivered!</p>
      <p>Now that your labels have arrived, you can start labeling your jars, bags, or any other storage containers being used for your harvest. There is no setup required, scan the QR-code and you'll be directly linked to your plants on EctorGrow.com</p>
      <p>We hope this innovative feature enhances your cannabis cultivation experience and simplifies sharing your journey with friends and family.</p>
      <p>Thank you again for choosing EctorGrow. We appreciate being a part of your cannabis growing journey.</p>
      <p>If you have any questions or need additional guidance, feel free to contact our support team at <a href="mailto:Team@EctorGrow.com">Team@EctorGrow.com</a>.</p>
      <p>Happy Growing and Labeling!</p>
      <p>Best regards,</p>      
      <p> The EctorGrow Team</p>
      <p style="font-size: 80%;">Order ID: <a href="https://EctorGrow.com/order/{orderID}" target="_blank" rel="noopener noreferrer">{orderID}</a></p>
    `
    },
    // Order Delayed - displayName, delayReason, delayNumDays, orderID
    orderDelayed: {
      subject: "EctorGrow Labels Order Delayed",
      text: `
      <p>Dear {displayName},</p>
      <p>We hope this email finds you well. We are writing to inform you that there has been a slight delay in processing your EctorGrow Labels order, and we sincerely apologize for any inconvenience this may cause.</p>
      <p>The delay was caused by {delayReason}, and we want to assure you that our team is working diligently to resolve this issue and expedite your order as quickly as possible.</p>
      <p>Rest assured, your EctorGrow Labels are on their way to you, and we expect the delay to take approximately {delayNumDays} more business days.</p>
      <p>We truly appreciate your patience and understanding during this time.</p>
      <p>If you have any questions or concerns regarding your order, please don't hesitate to contact our support team at <a href="mailto:Team@EctorGrow.com">Team@EctorGrow.com</a>.</p>      
      <p>Best regards,</p>      
      <p>The EctorGrow Team</p>
      <p style="font-size: 80%;">Order ID: <a href="https://EctorGrow.com/order/{orderID}" target="_blank" rel="noopener noreferrer">{orderID}</a></p>
    `
    },
    // Order Cancelled - params: displayName, reasonForCancellation, refundType, refundAmount ::: displayName, reason, refundType (partial or full), refundAmount
    orderCancelled: {
      subject: "EctorGrow Labels Order Cancelled",
      text: `
      <p>Dear {displayName},</p>
      <p>We regret to inform you that your EctorGrow Labels order has been canceled due to {cancellationReason}. We apologize for any inconvenience this may have caused.</p>
      <p>Based on the circumstances, you will receive a {refundType} refund for a total of {refundAmount}. Rest assured, we are working diligently to process the refund as swiftly as possible.</p>
      <p>If you have any questions or require further clarification regarding the cancellation or refund process, please don't hesitate to reach out to our support team at  <a href="mailto:Team@EctorGrow.com">Team@EctorGrow.com</a>.</p>
      <p>Once again, we sincerely apologize for this inconvenience and hope to serve you better in the future.</p>
      <p>Thank you for your understanding.</p>
      <p>Best regards,</p>      
      <p>The EctorGrow Team</p>
      <p style="font-size: 80%;">Order ID: <a href="https://EctorGrow.com/order/{orderID}" target="_blank" rel="noopener noreferrer">{orderID}</a></p>
    `
    },
    // Order Message From User To EctorGrow - orderID, orderDate, userMessage
    orderUserEmail: {
      subject: "Order Message From User",
      text: `
      <p>Dear EctorGrow Team,</p>
      <p>This message is for {orderID} created on {orderDate}.</p>
      <p>{userMessage}</p>      
    `
    },    
    // New User Registration - displayName, email
    newUserRegistrationEmail: {
      subject: "Welcome to EctorGrow!",
      text: `
        <p>Welcome to EctorGrow, {displayName}!</p>
        <p>Thank you for registering with the email address {email}.</p>
        <p>You can start setting up your grow at <a href="https://EctorGrow.com/grows/{displayName}">EctorGrow.com/grows/{displayName}</a></p>
        <p>Get ready for your cannabis growing journey!</p>
        <p>Step 1: Registration -> Complete!</p>
        <p>Step 2: Define your grow </p>
        <p>Step 3: Adding your plants and growing </p>
        <p>Thank you for choosing EctorGrow.com. We're excited to be a part of your cannabis cultivation adventure and appreciate your trust in us.</p>
        <p>Best regards,<br>EctorGrow Team</p>
        `
    },
    // User Account Deletion - displayName, email
    accountDeletionEmail: {
      subject: "Account Deletion Confirmation - EctorGrow",
      text: `
        <p>Hi {displayName},</p>
        <p>We're sorry to see you go, but we've received a request to delete your EctorGrow account associated with the email address {email}.</p>
        <p>Your account and all associated data will be permanently deleted. If you did not request this action, please contact our support team immediately.</p>
        <p>If you initiated this request, please be aware that this action is irreversible, and you will lose access to your account and all your grow data.</p>
        <p>Thank you for being a part of EctorGrow.com. We appreciate your past support, and if you ever decide to return, we'll be here to assist you.</p>
        <p>Best regards,<br>EctorGrow Team</p>
      `
    },
    // Feedback Submission TO USER - displayName, feedbackMessage, feedbackID (taskID)
    feedbackSubmissionUser: {
      subject: "EctorGrow Feedback",
      text: `
      <p>Dear {displayName},</p>
      <p>We have received your message: {feedbackMessage}</p>
      <p>Thank you for taking the time to share your feedback with us. We truly appreciate your input and will use it to improve our services and your experience with EctorGrow.com.</p>
      <p>Thank you for being a valued member of our community. If you ever need to reference this feedback wtih EctorGrow - the reference ID is: {feedbackID}</p>
      <p>Best regards, <br>EctorGrow Team</p>
    ` 
  },
    // Feedback Submission TO ECTORGROW - displayName, feedbackMessage, feedbackID (taskID)
    feedbackSubmissionAdmin: {
      subject: "EctorGrow Feedback",
      text: `
      <p>Dear EctorGrow Team,</p>
      <p>This message from {displayName} was just recieved.</p>
      <p>{feedbackMessage}</p>
      <p>Feedback ID: {feedbackID}</p>
      <p>Best regards, <br>EctorGrow Team</p>
    `
    },
    // Flagged Content By User - displayName, contentType, taskID
    flaggedContentUser: {
      subject: "Flagged Content",
      text: `
      <p>Dear {displayName},</p>
      <p>We have received your request to flag a {contentType} posted by another user.</p>
      <p>Thank you for taking the time to help us keep our community safe and respectful. 
      We take content moderation seriously, and our team is currently reviewing the flagged {contentType} 
      to ensure compliance with our community guidelines and policies.</p>
      <p>We kindly request your patience as we carefully assess the situation. We will conduct a thorough review and if apprpriate, send
       you a follow-up email with our findings and any necessary actions or explanations.</p>
      <p>Our goal is to foster a positive and respectful environment for all EctorGrow.com users, and your cooperation is greatly appreciated in this regard.</p>
      <p>If you need to reference this flagged content with EctorGrow - the reference ID is: {taskID}</p>
      <p>Thank you for being a valued member of our community.</p>
      <p>Best regards, <br>EctorGrow Team</p>
    `
    },
    // Flagged Content Alert To Admin - displayName, contentType, description, taskID
    flaggedContentAdmin: {
      subject: "Flagged Content",
      text: `
      <p>Dear EctorGrow Team,</p>
      <p>This message from {displayName} was just recieved.</p>
      <p>{contentType} flagged for review.</p>
      <p>Description: {description}</p>
      <p>Flagged Content ID: {taskID}</p>    
      <p>Best regards, <br>EctorGrow Team</p>
    `
    },
  



    // Flagged Comment, Post, etc; -- NOT IMPLEMENTED YET
    flaggedContent: {
      subject: "Flagged Content",
      text: `
      <p>Dear {name},</p>
      <p>We hope this email finds you well. We are writing to inform you that a plant, social post, or comment you recently shared on EctorGrow.com has been flagged by our community members for potentially inappropriate content.</p>
      <p>Please be assured that we take content moderation seriously, and our team is currently reviewing the flagged item to ensure compliance with our community guidelines and policies.</p>
      <p>We kindly request your patience as we carefully assess the situation. We will conduct a thorough review and send you a follow-up email with our findings and any necessary actions or explanations.</p>
      <p>Our goal is to foster a positive and respectful environment for all EctorGrow.com users, and your cooperation is greatly appreciated in this regard.</p>
      <p>If you have any questions or concerns, please don't hesitate to contact our support team at <a href="mailto:Team@EctorGrow.com">Team@EctorGrow.com</a>.</p>
      <p>Thank you for your understanding.</p>
      <p>Best regards,</p>      
      <p> The EctorGrow Team</p>
    `
    },
    // Response to Support Request -- NOT IMPLEMENTED YET
    supportResponse: {
      subject: "Support Request",
      text: `
      <p>Dear {name},</p>
      <p>Thank you for reaching out to EctorGrow.com's support team. We have received your support request, and we're here to assist you.</p>
      <p>Our team is currently reviewing your inquiry about [brief description of the support request]. Rest assured that we take your concern seriously and will do our best to provide you with a timely and satisfactory resolution.</p>
      <p>Please give us a little time to investigate the matter thoroughly. We will be in touch with a detailed response as soon as possible.</p>
      <p>In the meantime, if you have any additional information or questions related to your request, feel free to reply to this email.</p>
      <p>We genuinely appreciate your patience and understanding. Our primary goal is to ensure your satisfaction and a seamless experience with EctorGrow.com.</p>
      <p>Thank you for being a valued member of our community.</p>
      <p>Best regards,</p>
      <p>[Your Name]</p>
      <p>EctorGrow.com Support Team</p>
    `
    },
    // Plant has gone more than 7 days since last nutrient reset -- NOT IMPLEMENTED YET
    nutrientReset: {
      subject: "Nutrient Reset",
      text: `
      <p>Hi {name},</p>
      <p>Just a quick heads-up! Your {plantName} in {position} has gone at least seven days without a nutrient reset. To ensure your plant receives the proper nutrients and boosts its quality and yield, we recommend performing a nutrient reset soon. Don't forget to follow our guidelines based on the current growth phase.</p>
      <p>For any questions or support regarding nutrient resets, feel free to contact us at <a href="mailto:Team@EctorGrow.com">Team@EctorGrow.com</a>.</p>
      <p>Thank you for your dedication to nurturing your plants. Happy growing!</p>
      <p>Best regards,</p>      
      <p> The EctorGrow Team</p>
    `
    }
  };