import React from 'react'
import tierData from '../../Data/TierData'

export default function FreeTierCard() {

    const greenCheck = <div className=''>
        <i className='material-icons text-emerlad-800 text-2xl pr-2'>check</i>
    </div>
    
    return (
        <>
        <div className="shadow-lg rounded-2xl w-10/12 mx-auto bg-slate-800 h-full p-4 border-2 border-gray-400">
            <div className='flex w-full justify-end'>
            <p className='absolute -mt-14 md:-ml-10 uppercase border-2 bg-emerald-600 border-emerald-800 rounded-lg md:text-2xl p-2 px-4 font-medium'>
                    Beginner
                </p>
            </div>
            <div className='flex flex-col pt-2'>                
                <p className="text-gray-400 dark:text-gray-100 text-center text-2xl pt-4">
                    Micro growers or those just getting started
                </p>
            </div>
            <div className='flex flex-col justify-center text-white text-xl tracking-wider font-medium bg-emerald-800/50 my-8 p-4 rounded-xl'>
                <ul className="text-md font-light text-white w-full mt-6 mb-6">
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        Up to {tierData[0].activePlantLimit} active plants
                    </li>
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        {tierData[0].imgLimit} images, per plant
                    </li>
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        {tierData[0].totalPlantLimit} plant limit - including all phases of plant growth
                    </li>  
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        Basic Growlog
                    </li>
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        {tierData[0].nutrientLimit} custom Nutrient Schedule
                    </li>                                      
                    <li className="mb-3 flex items-center ">
                        {greenCheck}                        
                        Cannabis Cultivation Information & Support
                    </li>                                      
                    <li className="mb-3 flex items-center ">
                        {greenCheck}
                        Community - learn from and share with the EctorGrow Community
                    </li>                                      
                </ul>  
            </div>
            <div className='flex flex-col justify-center items-center'>
                <p className="text-white text-3xl font-bold">
                    Free                    
                </p>                        
            </div>
        </div>
        </>
      )
    }
    