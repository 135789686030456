import React from 'react'

export default function ETGButton({ 
    type, 
    onClickFunction, 
    icon = null, 
    btnText = 'click me',
    textColor = 'black',
    disable = false
    }) {

        
    // types
    // general (default) -> emerald color
    let buttonStyling = 'border-emerald-800 bg-emerald-700/50 hover:bg-emerald-700/80 md:active:scale-75 md:active:bg-emerald-800'
    // caution -> yellowish amber color, used as a caution button
    if (type === 'caution') {
        buttonStyling = 'border-amber-800 bg-amber-400/80 hover:bg-amber-700/80 md:active:scale-75 md:active:bg-amber-800'
    }
    // delete -> red color, used when deleting something    
    if (type === 'delete') {
        buttonStyling = 'border-red-800 bg-red-600/50 hover:bg-red-700/80 md:active:scale-75 md:active:bg-red-800'
    }
    // social -> blue color, used with social interactions
    if (type === 'social') {
        buttonStyling = 'border-sky-800 bg-sky-600/50 hover:bg-sky-700/80 md:active:scale-75 md:active:bg-sky-800'
    }
    // inner-link -> blue color, used to link to other pages within the app, such as plants or nutrient schedules
    if (type === 'inner-link') {
        buttonStyling = 'border-sky-800 bg-sky-600/50 hover:bg-sky-700/80 md:active:scale-75 md:active:bg-sky-800'
    }
        
    



    // disabeled override
    if (disable) {
        buttonStyling = 'border-slate-400 bg-slate-600/50 hover:bg-slate-700/80 hover:text-white'
    }

    


  return (
    <>
        <div className={`flex justify-center md:justify-between drop-shadow-xl px-2 text-${textColor}`} onClick={ onClickFunction !== undefined ?
         () => onClickFunction() : () => null} >            
            { disable ? <button disabled={true} className={`border-2 rounded-xl px-2 my-2 ${buttonStyling}`}>
                            <p className='flex justify-items-center gap-1 p-2'>                    
                                {(icon) && <i className='material-icons'>{icon}</i>} {btnText}
                            </p>
                        </button> : 
                        <button className={`border-2 rounded-xl px-2 my-2 ${buttonStyling}`}>
                            <p className='flex justify-items-center gap-1 p-2'>                    
                                {(icon) && <i className='material-icons'>{icon}</i>} {btnText}
                            </p>
                        </button>                
            }
        </div>
    
    </>
  )
}
