// This array is used to prohibit certain words or 
// phrases from being used in a displayName

// Used during Registrating Form and if 
// a user is editing an existing profile

const prohibitedNameList = [
    'fregoso',    
    'ector',    
    'ectorgrow', 
    'ector_grow',
    'sale',
    'sex',  
    '69',
    'dick',
    'pussy',
    'vagina',
    'pussy'      
]


export default prohibitedNameList