import * as React from "react";
import { createPortal } from "react-dom";
import ETGIconBtn from "../../shared/global/ETGIconBtn";
import LoadingDiv from '../../LoadingDiv'
import EditDisplayNameView from "./EditDisplayNameView";
import EditAboutMeView from "./EditAboutMeView";
import EditNameView from "./EditNameView";
import EditEmailView from "./EditEmailView";
import { useKeyPress } from "../../../hooks/useKeyPress";
import EditPasswordView from "./EditPasswordView";
import DeleteAccountView from "./DeleteAccountView";




const ProfileModal = ({ profileModal, handleProfileModalToggle, profileDocument, updateErrorData }) => {  
    
    const { keyPressed } = useKeyPress('Escape')
    let modalDisplay = <LoadingDiv />        

    let title = 'ProfileModal'
    switch (profileModal) {
        case 'editAboutMe':
            title = 'Editing About Me'
            modalDisplay = <EditAboutMeView 
                handleProfileModalToggle={handleProfileModalToggle}  
                profileDocument={profileDocument} 
                updateErrorData={updateErrorData}
            />
            break;
        case 'deleteAccount':
            title = 'Are You Sure You Want To Delete Your Account'
            modalDisplay = <DeleteAccountView 
                handleProfileModalToggle={handleProfileModalToggle}
                updateErrorData={updateErrorData}
                displayName={profileDocument.displayName}
            />
            break;
        case 'editName':
            title = 'Editing Your Name'
            modalDisplay = <EditNameView 
                handleProfileModalToggle={handleProfileModalToggle}
                profileDocument={profileDocument}
                updateErrorData={updateErrorData} />
            break
        case 'editEmail':
            title = 'Edit Your Email'
            modalDisplay = <EditEmailView 
                handleProfileModalToggle={handleProfileModalToggle} 
                updateErrorData={updateErrorData}
            />
            break
        case 'passwordChange':
            modalDisplay = <EditPasswordView 
                handleProfileModalToggle={handleProfileModalToggle} 
                updateErrorData={updateErrorData}
            />
            title = 'Password Change'
            break        
        case 'editDisplayName':
            title = `Edit Your Current Display Name: ${profileDocument.displayName}`
            modalDisplay = <EditDisplayNameView 
                handleProfileModalToggle={handleProfileModalToggle} 
                profileDocument={profileDocument} 
                updateErrorData={updateErrorData}
            />
            break
        default:
            break;
    }


  if (keyPressed) {
    handleProfileModalToggle()
  }
  
  
  return profileModal ? createPortal(
        <section className="flex flex-col w-10/12 m-auto z-50 bg-white rounded-xl p-2 my-4">            
        {/* Close Btn */}
        <div className="flex justify-end m-2">
            <ETGIconBtn type='close' onClickFunction={handleProfileModalToggle} />
        </div>
          {/* Title */}
          <div className="pt-8 mx-4">
            <h2 className="text-black underline underline-offset-2 etg-heading text-center" >{title}</h2>
          </div>

          {/* Display Modal Content */}
          {modalDisplay}                    


        </section>,        
        document.getElementById('profile-modal'),)        
    : null;
};
export default ProfileModal;