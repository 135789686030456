import React from "react";

    export function AdminStats({ numRegisteredUsers }) {
      return (<><div className='flex flex-row gap-4 space-evenly mx-auto py-4'>                            
        <div className='flex flex-col mx-auto text-center rounded-2xl'>
            <div className='flex mx-auto bg-gray-100 rounded-xl -mb-3 z-20 p-2'>
                <p className="uppercase tracking-wide">User Stats</p>
            </div>
            <div className='border-4 p-4 rounded-xl border-shadow-xl border-teal-700'>
                <p>{numRegisteredUsers} Registered Users</p>
            </div>
        </div>
    </div>
    </>);
    }
  
  