import React, { useState } from 'react'
import DatePicker from 'react-date-picker'
import ETGIconBtn from '../../../../shared/global/ETGIconBtn'
import { timestamp } from '../../../../../firebase/config'
import firebase from 'firebase/compat/app'


export default function ImageEditSection({ 
    imgData,
    setEditImg, 
    appData,
    currentPlantData,
    updatePlantData,
    index,
    updateErrorData, setProcessingData
     }) {    
    
        
    let tempDate = imgData.date.toDate()              
    const [ imgDate, setImgDate ] = useState(tempDate)
    const [ newCaption, setNewCaption ] = useState(imgData.caption)
    const [ favImg, setFavImg ] = useState(imgData.favImg)
        
    function dateToFirestoreTimestamp(date) {
        const milliseconds = date.getTime();
        const seconds = Math.floor(milliseconds / 1000);
        const nanoseconds = (milliseconds - seconds * 1000) * 1000000;
        return new timestamp(seconds, nanoseconds);
      }
      
      const storageRef = firebase.storage()
      

        // function to delete the image from firestore
        const deleteImage = () => {
            setProcessingData(true);
          
            // Get reference to the original image in storage
            const originalImgRef = storageRef.refFromURL(imgData.imgUrl);
          
            // Delete the original image from storage
            originalImgRef
              .delete()
              .then(() => {
                // Get reference to the resized image in storage
                const resizedImgRef = storageRef.refFromURL(imgData.imgUrlResized);
          
                // Delete the resized image from storage
                return resizedImgRef.delete();
              })
              .then(() => {
                // Clone the currentPlantData object
                let newPlantData = { ...currentPlantData };
          
                // Remove the image object from the imgArray
                newPlantData.imgArray.splice(index, 1);
          
                // Loop through the imgArray and remove unnecessary fields
                newPlantData.imgArray.forEach((img) => {
                  delete img.imgArrayIndex;
                  delete img.displayType;
                });
          
                // Update the plant data in Firestore
                updatePlantData(newPlantData);
          
                // Close the edit image section
                setEditImg(false);
              })
              .catch((error) => {
                console.log('Error deleting image:', error);
                updateErrorData(error.code, appData.id);
              })
              .finally(() => {
                setProcessingData(false);
              });
          };
          
        // function to update the image caption, date, and fav status in firestore
        const updateImage = () => {        
            setProcessingData(true)         
            let tempDateTimestamp = dateToFirestoreTimestamp(imgDate)                           
            // build new image object
            let newImgObj = {
                caption: newCaption,
                date: tempDateTimestamp,
                displayType: imgData.displayType,
                favImg: favImg,
                fullHeight: imgData.fullHeight,
                fullWidth: imgData.fullWidth,
                imgUrl: imgData.imgUrl                
            }

            // clone the currentPlantData object
            let newPlantData = {...currentPlantData}
            // if user is updating the fav image, update the favImg property in the plant data
            if (favImg) {
                // update each item in the imgArray to favImg = false
                let tempImgArray = currentPlantData.imgArray.map((imgObj) => {
                    return {...imgObj, favImg: false}
                })
                // update the newPlantData object with the new imgArray
                newPlantData.imgArray = tempImgArray
                
            }

            // update the image object in the plant data
            newPlantData.imgArray[index] = newImgObj       
            // loop through the imgarray, if the fields imgArrayIndex or displayType exist as fields, remove them, even if their value is 0
            newPlantData.imgArray.forEach((img) => {
                if (img.imgArrayIndex || img.imgArrayIndex === 0) {
                    delete img.imgArrayIndex
                }
                if (img.displayType) {
                    delete img.displayType
                }                
            })     
            // update the plant data in firestore
            updatePlantData(newPlantData)
            // close the edit image section
            setEditImg(false)
            setProcessingData(false)
        }
        
    

        let newCaptionCount = newCaption.length
        // if newCaptionCount is greater than 80, remove the extra characters
        if (newCaptionCount > 80) {            
            setNewCaption(newCaption.slice(0, 80))
        }
    





  return (
    <>
        <div className='flex flex-col'>
            {/* Title */}
            <div className='py-4 text-lg'>
                <h2 className='etg-sectionTitle'>Editing an Image</h2>
            </div>
            {/* Image */}
            <div className='flex justify-center'>
                <img src={imgData.imgUrl} alt='plant' className='w-1/2 rounded-xl' />
            </div>
            {/* Caption */}
            <div className='flex flex-col pt-2'>
                <label>Caption:</label>
                <textarea 
                    className='border-2 border-emerald-800 rounded-md p-2 etg-userInput' 
                    value={newCaption}
                    onChange={(e) => setNewCaption(e.target.value)} 
                />
                <p className='text-right'>{newCaptionCount}/80</p>
            </div>
            {/* Date & Fav Image  */}            
            <div className='flex justify-around bg-emerald-800/30 rounded-xl p-2'>
                {/* date */}
                <div>
                    <p>Date:</p>
                    <DatePicker value={imgDate} onChange={(e) => setImgDate(e)} />
                </div>
                {/* fav image toggle */}
                <div className='flex flex-col'>
                    <p>Favorite Image:</p>
                    <div className='flex justify-center'>
                        <input
                            type='checkbox'
                            checked={favImg}
                            onChange={() => setFavImg(!favImg)}
                        />
                    </div>
                </div>

            </div>
            
            
        </div>


        {/* EDIT NOTE ACTIONS - when buttons are clicked, conditional rendering is handeled by processing div in NoteLog */}
        <div className='flex justify-between py-4 mb-6'>
            <div 
                className='p-2 hover:border-emerald-800 rouned-t-md border-solid border-b-2'                 
                >                                                   
                <ETGIconBtn type='save' onClickFunction={updateImage} />
                save
            </div>            
            <div className='p-2 hover:border-emerald-800 rouned-t-md border-solid border-b-2'>                
                <ETGIconBtn type='delete' onClickFunction={deleteImage}  />              
                delete
            </div>
            <div className='p-2 hover:border-emerald-800 rouned-t-md border-solid border-b-2'>                
                <ETGIconBtn type='cancel' onClickFunction={() => setEditImg(false)} />            
                cancel
            </div>
        </div>
    </>
  )
}
