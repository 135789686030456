import React from 'react';
import ETGButton from '../shared/global/ETGButton';
import { NavLink } from 'react-router-dom';
import EctorGrowLogo from '../../img/etgLogoWhiteBG.svg'

export default function LoginForm({
  email,
  setEmail,
  password,
  setPassword,
  handleUserLogin, errorMessage
}) {
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };  

  return (
    <>
      <div className="w-full items-center">
        <div className="flex flex-col items-center">
          <div className="bg-gray-200 rounded-xl shadow-sm sm:rounded-lg overflow-hidden w-full p-8 sm:max-w-md">
            <div className="flex flex-col items-center justify-center">
              {/* EctorGrow Logo */}
              <div className="flex items-center justify-center w-6/12 overflow-hidden hover:scale-125 transition-all">
                <img src={EctorGrowLogo} alt="EctorGrow Logo" className='p-2' />
              </div>
              <h2 className='text-3xl uppercase tracking-wider font-lighter text-emerald-800 pt-2'>Login</h2>
            </div>

            <form className="mt-4">              
              <div>
                <label
                  className="block text-sm font-medium text-gray-800 "
                  htmlFor="email"
                >
                  Email
                </label>

                <input
                  className="p-2 text-gray-800 rounded-md bg-gray-100 border-2 dark:border-gray-700 dark:hover:border-gray-700 dark:hover:focus:border-gray-700 focus:border-gray-300 hover:border-gray-300 hover:focus:border-gray-300 focus:ring-0 text-sm mt-1 block w-full"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  required="required"
                  autoFocus="autofocus"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>

              <div className="mt-4">
                <label
                  className="block text-sm font-medium text-gray-800 "
                  htmlFor="password"
                >
                  Password
                </label>

                <input
                  className="p-2 text-gray-800 rounded-md bg-gray-100 border-2 dark:border-gray-700 dark:hover:border-gray-700 dark:hover:focus:border-gray-700 focus:border-gray-300 hover:border-gray-300 hover:focus:border-gray-300 focus:ring-0 text-sm mt-1 block w-full"
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Your password"
                  required="required"
                  autoComplete="current-password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>              
              <div className="my-4 flex items-center justify-end">
                <ETGButton btnText="Login" onClickFunction={handleUserLogin} />
              </div>
            </form>

            <div className="flex flex-col border-2 border-gray-600 rounded-lg text-black py-4 mx-auto">
                {/* Display Error Message */}
                { errorMessage ? <>
                    <div className=''>
                        <p className='text-red-500 text-center'>
                        <span>Error</span> {errorMessage}
                        </p>

                    </div>
                </> : null }
                <div className="flex justify-center py-1 hover:scale-125 transition-all">
                    <NavLink to="/register" rel="noreferrer" ripple="light">
                    <p>Register An Account</p>
                    </NavLink>
                </div>
                <div className="flex justify-center py-1 hover:scale-125 transition-all">
                    <NavLink to={`/resetpassword`} rel="noreferrer" ripple="light">
                    <p>Forgot Password?</p>
                    </NavLink>
                </div>
            </div>
          </div>
        </div>        
      </div>
    </>
  );
}
