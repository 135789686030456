

export function determineOldestNutrient( currentActivePlants, flowerPlants, vegetativePlants  ) {
    let plantWithOldestNutrient = null
    let daysSinceNutrientReset = 0
    let today = new Date()

    // If no active plants, display message
    if (currentActivePlants <= 0) {
        return 'No Active Plants Currently'
    }

    // If there are plants in flower, loop through to compare dates of nutrient reset
    if (flowerPlants.length > 0) {
        flowerPlants.forEach((plant) => {
            // only check for nutrient reset if a nutrient schedule exists
            if (plant.nutrientSchedule !== 'none') {
                let filteredNotes = plant.noteLog.filter(note => note.type === 'lc-nutrient')
                if (filteredNotes.length > 0) {
                    let oldestNote = filteredNotes[(filteredNotes.length - 1)]
                    let differenceInTime = Math.abs(oldestNote.date.toDate() - today)
                    let daysSince = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))
                    // update current oldest plant if more days than current 
                    if (daysSince > daysSinceNutrientReset) {
                        plantWithOldestNutrient = plant
                        daysSinceNutrientReset = daysSince
                    }
                }
            }
        })
    }
    // If there are plants in vegetative, loop through to compare dates of nutrient reset
    if (vegetativePlants.length > 0) {
        vegetativePlants.forEach((plant) => {
            // only check for nutrient reset if a nutrient schedule exists
            if (plant.nutrientSchedule !== 'none') {
                let filteredNotes = plant.noteLog.filter(note => note.type === 'lc-nutrient')
                if (filteredNotes.length > 0) {
                    let oldestNote = filteredNotes[(filteredNotes.length - 1)]
                    let differenceInTime = Math.abs(oldestNote.date.toDate() - today)
                    let daysSince = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))
                    // update current oldest plant if more days than current 
                    if (daysSince > daysSinceNutrientReset) {
                        plantWithOldestNutrient = plant
                        daysSinceNutrientReset = daysSince
                    }
                }
            }
        })
    }

    if (plantWithOldestNutrient !== null) {
        return `${plantWithOldestNutrient.strain}, reset ${daysSinceNutrientReset} days ago`
    }
    return 'No active plants with nutrient reset notes'
}
  
  