import React from 'react'
import ETGButton from '../../shared/global/ETGButton'
import { returnAccountLimits } from '../../../Data/TierData'
import { useUpdatePlantData } from '../../../hooks/useUpdatePlantData'

export default function HarvestToFlowerView({ setGrowsModal, appData, setCurrentPhase,
    currentPlantData, setIsProcessing, updateErrorData, handleNewPlantSelected }) {
    const userTierLimits = returnAccountLimits(appData.profileData.accessTier)
    const { updatePlantData, updatePlantDataError } = useUpdatePlantData(appData.profileData)

    const handleHarvestToFlower = async () => {
        setIsProcessing(true)
        

        //check if active plants has room for additional plant
        
        //if there is no room, output alert to user
        if (appData.profileData.activePlants.length >= userTierLimits.activePlantLimit) {
            alert("You have reached the plant capacity of your account, consider upgrading ;)")
            return
        }                
        
        //if there is room, remove from harvested, add to activePlants and update document
        if (appData.profileData.activePlants.length < userTierLimits.activePlantLimit) {
            let indexOfHarvestedPlant = appData.plantData.harvestedPlants.findIndex((plant) => plant.plantId === currentPlantData.plantId)
            if (indexOfHarvestedPlant !== -1) {
                
                let plantTransferring = appData.plantData.harvestedPlants[indexOfHarvestedPlant]
                            
                // remove note type lc-harvest            
                let noteIndexToRemove = plantTransferring.noteLog.findIndex((note) => note.type === 'lc-harvest')
                plantTransferring.noteLog.splice(noteIndexToRemove, 1)
                // update locaiton/position to match start of deleted note
                plantTransferring.status = "Flower"            
                await updatePlantData(plantTransferring)
                if (updatePlantDataError) {
                    updateErrorData(updatePlantDataError, appData.id)                
                } else {
                    handleNewPlantSelected(plantTransferring)
                }
                
                
            } else {
                updateErrorData('Error: Plant not found in harvestedPlants array - Harvest_To_Flower', appData.id)
            }
                
            setCurrentPhase("Flower")   
            setGrowsModal(false)    
            setIsProcessing(false)     
        }           
    }
  return (<>
    <section>
        {/* Save Buttons */}
        <div className="flex flex-col justify-evenly">                        
            <div className='flex flex-row pt-4 space-evenly mx-auto'>
                <div className="px-2">                        
                    <ETGButton
                    onClickFunction={handleHarvestToFlower}
                    icon='save'
                    btnText='Move Back To Flower'
                    type='caution'
                    />
                </div>
                <div className="px-2">                                                
                    <ETGButton
                        onClickFunction={() => setGrowsModal(false)}
                        icon='close'
                        btnText='Cancel'
                        type='delete'
                        />
                </div>
            </div>
        </div>
    </section>
  </>)
}
