import React, { useState } from 'react'
import { projectFirestore, timestamp } from '../../firebase/config'
import { useDocument } from '../../hooks/useDocument'
import ETGButton from '../shared/global/ETGButton'

export default function AdminMaintenance() {
    const [maintMessage, setMaintMessage] = React.useState('')
    const [maintStatus, setMaintStatus] = React.useState(false)
    const [knownIssues, setKnownIssues] = React.useState([])
    const today = new Date()
    const dateTime = new timestamp(Math.round(today.getTime() / 1000), 0)
    const [issueToAdd, setIssueToAdd] = React.useState({
        description: '',
        dateTimeSubmitted: dateTime,
        dateTimeEstComplete: dateTime,
    })

    // State to control fetching known issues
    const [fetchKnownIssues, setFetchKnownIssues] = useState(true);

    React.useEffect(() => {
        // Load Known Issues from Firestore
        if (fetchKnownIssues) {
            const tempIssuesRef = projectFirestore.collection('maintenanceData').doc('wt6KhFdiemihgYcDq0i2')

            tempIssuesRef
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        console.log("Document data:", doc.data());
                        setKnownIssues(doc.data().knownIssues);
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No known issues!");
                    }
                })
                .catch((error) => {
                    console.log("Error getting document:", error);
                });

            // Disable fetching known issues after initial load
            setFetchKnownIssues(false);
        }
    }, [fetchKnownIssues]);

    // retrieve the current maintenance message from the database
    const currentMessage = useDocument('maintenanceData', 'tQBOeIuMZN0b8Qv7ccTI')

    React.useEffect(() => {
        if (!currentMessage.error) {
            if (currentMessage.document !== null) {
                currentMessage.document.status ? setMaintStatus(true) : setMaintStatus(false)
            }
        }
    }, [currentMessage])

    // function to update the current maintenance message
    const handleUpdateMessage = async () => {
        projectFirestore.collection('maintenanceData').doc('tQBOeIuMZN0b8Qv7ccTI').update({
            message: maintMessage
        })
    }

    // function to toggle the maintenance message status, display or hide the maintenance message
    const handleToggleStatus = () => {
        projectFirestore.collection('maintenanceData').doc('tQBOeIuMZN0b8Qv7ccTI').update({
            status: !maintStatus
        })
    }

    // function to clone the current message to the maintenance message
    const handleCloneMessage = () => {
        setMaintMessage(currentMessage.document.message)
    }

    // function to add a new issue to Firestore
    const handleAddIssue = () => {
        // Check if the issue description is not empty
        if (issueToAdd.description.trim() !== '') {
            // Update Firestore with the new issue
            projectFirestore.collection('maintenanceData').doc('wt6KhFdiemihgYcDq0i2').update({
                knownIssues: [...knownIssues, issueToAdd],
            });

            // Clear the input field
            setIssueToAdd({
                description: '',
                dateTimeSubmitted: dateTime,
                dateTimeEstComplete: dateTime,
            });

            // Enable fetching known issues to update the displayed list
            setFetchKnownIssues(true);
        }
    }

    // function to delete a known issue from Firestore
    const handleDeleteIssue = (index) => {
        const updatedIssues = [...knownIssues];
        updatedIssues.splice(index, 1);

        projectFirestore.collection('maintenanceData').doc('wt6KhFdiemihgYcDq0i2').update({
            knownIssues: updatedIssues,
        });

        // Enable fetching known issues to update the displayed list
        setFetchKnownIssues(true);
    }

    return (
        <>
            <section className='flex flex-col py-16 bg-slate-200 my-10 mx-4 rounded-xl text-black'>
                <div className='flex flex-col justify-center items-center'>
                    <h1 className='etg-heading'>Maintenance</h1>
                    <p className='text-xl'>Welcome to the maintenance page</p>
                </div>

                {/* Current Message Display and Form To Update */}
                <div className='flex flex-col justify-center items-center py-4'>
                    <h2 className='etg-heading'>Current Message</h2>
                    {currentMessage.document && <p className='text-xl p-2'>{currentMessage.document.message}</p>}
                    <label htmlFor='message' className='etg-heading'>Update Message</label>
                    <textarea
                        name='message'
                        id='message'
                        cols='30'
                        rows='10'
                        className='border-2 border-black rounded-xl p-2'
                        value={maintMessage}
                        onChange={(e) => setMaintMessage(e.target.value)}
                    ></textarea>
                    <span className='etg-systemText'>{maintMessage.length} characters</span>
                    <ETGButton btnText='Update Message' onClickFunction={() => handleUpdateMessage()} />
                    {/* Button to clone the current message */}
                    <ETGButton btnText='Clone Current Message' onClickFunction={() => handleCloneMessage()} />
                </div>

                {/* Current Status Display and Toggle Switch to change */}
                <div className='flex flex-col justify-center items-center py-4'>
                    <h2 className='etg-heading'>Current Status</h2>
                    <p className='text-xl'>{maintStatus ? 'Maintenance Mode is ON' : 'Maintenance Mode is OFF'}</p>
                    <ETGButton btnText={maintStatus ? 'Turn OFF' : 'Turn ON'} onClickFunction={() => handleToggleStatus()} />
                </div>

                {/* Section to display known issues */}
                <div className='flex flex-col justify-center items-center py-4'>
                    <h2 className='etg-heading'>Current Known Issues</h2>
                </div>
                <div className='flex flex-col bg-slate-500 mx-4 gap-4 justify-center items-center py-4 rounded-xl'>
                    {knownIssues.map((issue, index) => (
                        <div className='flex flex-row text-lg' key={index}>
                            <p> - {issue.description}</p>
                            <button 
                                onClick={() => handleDeleteIssue(index)}
                                className='pl-2 text-red-500'
                            >Delete</button>
                        </div>
                    ))}
                </div>

                {/* Section to add known issues */}
                <div className='flex flex-col justify-center items-center py-4'>
                    <h2 className='etg-heading'>Add Known Issues</h2>
                    <input
                        type='text'
                        placeholder='Enter Issue Description'
                        className='border-2 border-black rounded-xl p-2'
                        value={issueToAdd.description}
                        onChange={(e) => setIssueToAdd({ ...issueToAdd, description: e.target.value })}
                    />
                    <ETGButton btnText='Add Issue' onClickFunction={() => handleAddIssue()} />
                </div>
            </section>
        </>
    )
}
