import { Heading3, Icon } from '@material-tailwind/react'
import React from 'react'
import AgeVerificationPublic from './AgeVerificationPublic'

export default function ProcessingDiv({ message = 'Processing Data', type = 'General', processRing = true, errorArray=null, controlBoolean }) {

    // handle error type - update message to display
    if (type === 'error') {
        processRing = false
        if (!errorArray) {
            // don't change message from passed value
        } else {
            message = errorArray[0]
        }
    }
    
  return (
    <>    
        <div className="container max-w-7xl py-28 px-4 mx-auto">
            <div className="relative flex flex-col bg-white min-w-0 w-full mb-6 py-4 rounded-2xl justify-center items-center ">
                {(type === 'error') && <>
                    <div className='flex mx-auto my-2 p-2 bg-black rounded-full border-4 border-yellow-400'>                        
                        <Icon name="error" size='5xl' color='yellow' />
                    </div>
                </>}
                {(processRing) && <>
                    <div className="flex items-center justify-center pt-2">
                        <div className="w-24 h-24 border-l-2 border-emerald-200 rounded-full animate-spin">                        
                        </div>
                    </div> 
                </>}
                <div className='py-14 text-center'>                    
                    <Heading3>
                        <div className='px-2'>
                            {message}
                        </div>
                    </Heading3>
                </div>
                {(type === 'NotFound') && <>
                    <div>
                        <p className='text-black text-lg italic'>
                            double check the url and display name you entered
                        </p>
                    </div>
                </>}
                {(type === 'error' && errorArray) && <>
                    <div className='w-8/12 mx-auto text-center text-black'>
                        <p>information has been forwarded to EctorGrow for review - reload the page to continue</p>
                    </div>
                </>}
                {(type === 'ageVerification') && <AgeVerificationPublic controlBoolean={controlBoolean} />}
            </div>
        </div>

    </>
  )
}
