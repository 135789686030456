import React from 'react'
import { returnAccountLimits } from '../../../Data/TierData'
import DashboardPlantsPhaseSection from './DashboardPlantsPhaseSection'
import DashboardPlantPhaseList from './DashboardPlantPhaseList'

export default function DashboardPlantsView({ appData, currentPhase, setCurrentPhase, setGrowsModal, handleNewPlantSelected, currentPlantData }) {
    const [ vegetativePlants, setVegetativePlants ] = React.useState([])
    const [ flowerPlants, setFlowerPlants ] = React.useState([])
    const [ harvestedPlants, setHarvestedPlants ] = React.useState([])
    const [ archivedPlants, setArchivedPlants ] = React.useState([])
    React.useEffect(() => {
        const sortedVegetativePlants = [...appData.plantData.activePlants]
            .filter((plant) => plant.status === 'Vegetative')
            .sort((a, b) => new Date(b.startDate.toDate()) - new Date(a.startDate.toDate()));
        setVegetativePlants(sortedVegetativePlants);

        const sortedFlowerPlants = [...appData.plantData.activePlants]
            .filter((plant) => plant.status === 'Flower')
            .sort((a, b) => new Date(b.startDate.toDate()) - new Date(a.startDate.toDate()));
        setFlowerPlants(sortedFlowerPlants);

        const sortedHarvestedPlants = [...appData.plantData.harvestedPlants]
            .sort((a, b) => new Date(b.harvestDate.toDate()) - new Date(a.harvestDate.toDate()));
        setHarvestedPlants(sortedHarvestedPlants);

        const sortedArchivedPlants = [...appData.plantData.archivedPlants]
            .sort((a, b) => new Date(b.harvestDate.toDate()) - new Date(a.harvestDate.toDate()));
        setArchivedPlants(sortedArchivedPlants);
    }, [appData]);

      


    const userTierLimits = returnAccountLimits(appData.profileData.accessTier)
    const addPlant = () => {
        // check for room in users active plants
        if((vegetativePlants.length + flowerPlants.length) >= userTierLimits.activePlantLimit) {
            //display alert saying I cannot add any more plants
            alert("You have reached the maxiumum number of active plants.")
        } else if ((vegetativePlants.length + flowerPlants.length + harvestedPlants.length + archivedPlants.length) >= userTierLimits.totalPlantLimit) {
            //display alert saying I cannot add any more plants
            alert("You have reached the maxiumum number of total plants.")
        } else {            
            // set EditPlantModal to ADD -> like previous addPlantModal
            setGrowsModal('add-plant')
        }         
    }    
  
  return (<>
    <section className='flex flex-col justify-evenly overflow-auto'>        

        {/* two equal size columns */}
        <div className='flex flex-col md:flex-row overflow-auto'>
            {/* list of phases */}
            <div className='flex flex-col md:flex-row justify-between w-full'>
                <DashboardPlantsPhaseSection
                    appData={appData} currentPhase={currentPhase} setGrowsModal={setGrowsModal}
                    setCurrentPhase={setCurrentPhase} addPlant={addPlant}
                    vegetativePlants={vegetativePlants} flowerPlants={flowerPlants} harvestedPlants={harvestedPlants} archivedPlants={archivedPlants}                
                />
            </div>

            {/* list of plants */}
            <div className='flex flex-col md:flex-row justify-between w-full'>
                <DashboardPlantPhaseList currentPhase={currentPhase} setCurrentPhase={setCurrentPhase} handleNewPlantSelected={handleNewPlantSelected}
                vegetativePlants={vegetativePlants} flowerPlants={flowerPlants} harvestedPlants={harvestedPlants} archivedPlants={archivedPlants}
                currentPlantData={currentPlantData}  />
            </div>            
        </div>
    </section>
  </>)
}
