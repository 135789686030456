import { Button, Heading3, Input, LeadText } from '@material-tailwind/react'
import { useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'



export default function Main() {
    const [ email, setEmail ] = useState('')
    const [ isSubmitted, setIsSubmitted ] = useState(false)


    const handleResetPassword = async () => {
        setIsSubmitted(true)        
        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
            // Password reset email sent!
            // ..
            })
            .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode + errorMessage)
            // ..
            })            
    }
    
    return (
        <>
            <section className="relative pt-8 pb-4 bg-gray-100">
                <div className="container max-w-7xl px-4 mx-auto">
                    <div className="relative flex flex-col min-w-0 break words w-full mb-6 shadow-xl rounded-2xl">
                        <div className="p-4 text-xl block text-black mx-auto">
                            <Heading3>Password Reset</Heading3>
                        </div>                        
                        {/* Form for Email to Reset Password */}
                        <div className="flex-row pt-4 py-6 mx-auto">                            
                            <div className='text-center'>
                                <LeadText>
                                    Enter your email address and we will send you an email to reset your password
                                </LeadText>
                            </div>
                            <div className='pt-6 px-4'>
                                {!isSubmitted && <Input
                                    type="email"
                                    color="green"
                                    size="regular"
                                    placeholder='Enter your email'
                                    outline={false}
                                    value={email}
                                    onChange={(e) => {setEmail(e.target.value)}}
                                    />
                                }                            
                            </div>
                        </div>
                        <div className='mx-auto py-5'>
                            {!isSubmitted && <Button
                                color="green"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="dark"                                
                                onClick={handleResetPassword}
                                > Reset Password
                            </Button>}
                            {isSubmitted && <LeadText>Password Reset Recieved</LeadText>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}