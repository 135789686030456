
export default function HomeHeader() {
    return (
        <div className="relative pt-10 pb-32 flex content-center items-end justify-center h-screen">
            <div className="bg-cover bg-center bg-etg-bg opacity-60 absolute top-0 w-full h-full drop-shadow-2xl"/>
            <div className="container max-w-8xl relative mx-auto">
                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4 ml-auto pb-8 mr-auto text-center">                        
                        <p className='text-white text-4xl font-medium'>Cultivate with Confidence</p>                        
                        {/* <p className='text-white text-4xl font-medium'>ELEVATE YOUR PERSONAL CANNABIS GROW</p>                         */}
                    </div>
                </div>
            </div>
        </div>
    );
}