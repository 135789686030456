import React from 'react'
import { timestamp } from '../../../firebase/config'
import ETGButton from '../../shared/global/ETGButton'
import { useUpdatePlantData } from '../../../hooks/useUpdatePlantData'
import { handlePlantDeletion } from '../../renderless/setData/handlePlantDeletion'

export default function DeletePlantView({ 
    appData, currentPlantData, currentPhase,
    setGrowsModal,        
    setCurrentPhase, updateErrorData, setIsProcessing
     }) {

    const { updatePlantData, updatePlantDataError } = useUpdatePlantData(appData.profileData)
    
    

    // function to archive the currently active plant instead of fully deleting it
    const handleArchive = async () => { 
        setIsProcessing(true)
        //find index position in the appData.profileData plants array
        let plantToArchive = currentPlantData
        
        // update status of removed plant
        plantToArchive.status = 'Archived'
        // add a note to the Growlog about 'Plant Archived'    
        const date = new Date()
        const timestampDate = timestamp.fromDate(date)
        plantToArchive.noteLog.push({
            note: `Plant Archived}`,
            date: timestampDate,
            type: 'lc-archived',
            comment: 'Plant Archived'
        })        

        
        
       
        //Update plant data in firebase
        await updatePlantData(plantToArchive)
        if (updatePlantDataError) {
            updateErrorData(updatePlantDataError, appData.id)
        }

        //Reset the current phase, which will also update the active plant and close modal
        setCurrentPhase('Archived')
        setGrowsModal(null)
        setIsProcessing(false)

    }
    


    
    const handleDelete = async () => {
        setIsProcessing(true)        

        // function that deletes the plant document from firestore and 
        // the images from the storage bucket
        handlePlantDeletion(currentPlantData)

        // Determine the phase to load, based on which phase currently has plants
        // If there are Veg or Flower Plants Load phase
        let phaseToLoad = 'Vegetative'
        if(appData.plantData.activePlants.length > 0) {
        // determine if there are plants in veg
        appData.plantData.activePlants.forEach((plant) => {
            if (plant.status === 'Flower') {
            phaseToLoad = 'Flower'              
            } else {
            phaseToLoad = 'Vegetative'
            }            
        })
        } else if (appData.plantData.harvestedPlants.length > 0) {
        phaseToLoad = 'Harvested'
        } else if (appData.plantData.archivedPlants.length > 0) {
        phaseToLoad = 'Archived'
        }

        //Reset the current phase, which will also update the active plant and close modal        
        setCurrentPhase(phaseToLoad)
        setGrowsModal(null)        
        setIsProcessing(false)
    }

  return (
    <>                        
        <section className=''>
            <div className='text-center text-black mx-auto'>            
                <p className="px-5 py-3 text-2xl font-bold">
                    You are about to permanently delete all data and images for:
                </p>
                <p className='py-4 text-2xl'>                        
                    <span className='font-bold'>
                        {` ${currentPlantData.strain}`}
                    </span>                                           
                    , currently growing in   
                    <span className='font-bold'>
                        {` ${currentPlantData.location} | ${currentPlantData.position}`}
                    </span>                                           
                </p>
                <p className='text-xl italic pb-3'>this action cannot be undone</p>
            </div>
            
            <div className='flex flex-col mx-auto text-black'>
                <div className='flex flex-row'>
                    <div className='mx-auto py-2 drop-shadow-lg'>                    
                        <ETGButton
                            onClickFunction={handleDelete}
                            icon='delete'
                            btnText='Yes, Permanently Delete'
                            type='caution'
                        />
                    </div>
                    {/* only display if currently active plant is not status Archived */}
                    {currentPhase === 'Archived' ? null :
                    <div className='mx-auto py-2 drop-shadow-lg'>
                        <ETGButton
                            onClickFunction={handleArchive}
                            icon='archive'
                            btnText='Archive Plant Instead'
                            type='caution'
                        />
                    </div>
                    }
                </div>
                <div className='flex w-10/12 mx-auto py-4'>
                    <p className='etg-userText'>
                        Archiving a plant will remove it from your active plants where no changes can be made! Make any changes to the plant, and Growlog before archiving it.
                    </p>
                </div>
                <div className='mx-auto py-2 drop-shadow-lg'>                    
                    <ETGButton
                        onClickFunction={() => setGrowsModal(null)}
                        type='delete'
                        icon='close'
                        btnText='Cancel'
                    />
                </div>
                
            </div>
        </section>        
    </>)
}
