import React from "react";
import { projectAuth } from "../../firebase/config";
import ETGButton from '../shared/global/ETGButton'
import ETGIconBtn from '../shared/global/ETGIconBtn'

    export default function AccountSettings({ setProfileModal, profileDocument, updateErrorData }) {      
        const [ verificationEmailSent, setVerificationEmailSent ] = React.useState(false)
    
        async function handleSendEmailVerificationEmail( ) {
            projectAuth.currentUser.sendEmailVerification()
            setVerificationEmailSent(true)
        }

        
      return (<section className='flex flex-col bg-gradient-to-b from-green-200 to-emerald-400 rounded-xl p-4 -mx-6 mt-10 border-black border-2'>
        <div>
            <div className="flex flex-col w-fit">
                <h3 className='etg-subHeading text-black text-center md:text-left'>Account Information</h3>
                <p className="etg-systemText">this information is only visible to you</p>
            </div>
            
            {/* Email Verification & Update */}            
            <div className="flex flex-col md:flex-row w-full justify-around border-2 border-slate-600 rounded-md my-10">

                {/* Account Info */}
                <div className="flex flex-col w-full px-4">
                    {/* Edit First, Last Name */}
                    <div className="flex w-full p-3 etg-sectionTitle items-center">
                        <h3 className="flex w-1/6 justify-center">Name: </h3>
                        <p className="flex justify-center text-sm md:text-xl w-4/6">{`${profileDocument.firstName}, ${profileDocument.lastName}`}</p>
                        <div className="flex justify-center w-1/6">
                            <ETGIconBtn type="edit" size='sm' onClickFunction={() => setProfileModal('editName')} />
                        </div>
                    </div>

                    {/* Edit Email Address */}
                    <div className="flex w-full p-3 overflow-auto etg-sectionTitle items-center">
                        <h3 className="flex w-1/6 justify-center">Email: </h3>
                        <p className="flex justify-center w-4/6 text-sm md:text-xl">
                            {`${projectAuth.currentUser.email}`}
                        </p>
                        {/* email is/is not verified */}
                        <div className="flex justify-center w-1/6">
                            <ETGIconBtn type="edit" size='sm' onClickFunction={() => setProfileModal('editEmail')} />
                        </div>
                    </div>                
                    <div className="flex flex-row justify-center py-2">
                        { projectAuth.currentUser.emailVerified ? <span className="etg-systemText">verified email address</span> :
                         !verificationEmailSent ? <button className="etg-systemText" onClick={() => handleSendEmailVerificationEmail()}>your email address is unverified, click to send verification email</button> : 
                         <span className="etg-systemText">verification email sent</span> 
                        }
                    </div>
                </div>
                
            </div>
            
            
            {/* Change Password */}
            <div className="flex flex-col py-4 drop-shadow-xl divide-y-2 divide-emerald-800 px-4">
                <div className="flex justify-end">
                    <ETGButton btnText="Change Your Password" onClickFunction={() => setProfileModal('passwordChange')} />                
                </div>
            
            {/* Delete Account */}
                <div className="flex justify-end mt-10 py-4">                
                    <div className="flex flex-col w-fit mr-0 bg-yellow-300 rounded-2xl items-center drop-shadow-xl">
                        <ETGButton type={'delete'} btnText='Delete My Account' onClickFunction={() => setProfileModal('deleteAccount')} textColor='black' />
                        <p className="text-black text-center px-4 font-medium mb-2 uppercase">WARNING: Deleting Your Account cannot be undone!</p>
                    </div>
                </div>
            </div>
        </div>
        
    </section>);
    }
  
  