import React from 'react'
import { returnAccountLimits } from '../../../Data/TierData'
import LocationsAddEdit from '../../shared/growspace/LocationsAddEdit'
import PositionsAddEdit from '../../shared/growspace/PositionsAddEdit'
import ETGButton from '../../shared/global/ETGButton'

export default function GettingStartedView({ appData, setGrowsModal }) {

  const accountTierLimits = returnAccountLimits(appData.profileData.accessTier)

  return (
    <section className="relative">
                <div className="container max-w-7xl px-4 mx-auto ">
                    <section className="relative flex flex-col min-w-0 w-full mb-6 shadow-xl rounded-2xl bg-gray-100">
                        {/* Display Welcome for Free Account */}
                        {(accountTierLimits === 9 || 1) && <>
                            <div className="text-xl block text-black p-3 text-center mx-auto">
                                <h2 className='etg-heading'>Welcome to EctorGrow.com</h2>
                                <h4 className='etg-subHeading'>and thank you for joining us!</h4>
                            </div>                        
                        </>}
                        {/* Display Welcome for Pro Account */}
                        {(accountTierLimits === 3) && <>
                            <div className="text-xl block text-black p-3 text-center mx-auto">
                                <h2 className='etg-heading'>Welcome to EctorGrow.com</h2>
                                <h4 className='etg-subHeading'>and thank you for supporting us with your subscription</h4>
                            </div>                        
                        </>}
                        <div className="mx-auto text-center p-4">
                            <div className='md:w-10/12 mx-auto py-4 bg-emerald-800/30 rounded-md shadow-md mb-5'>
                                <h3 className='text-center text-2xl tracking-wider text-black'>Getting Started</h3>
                                <h4 className='text-center text-xl text-black py-2 px-2'>describe the physical locations and positions for your grow</h4>
                            </div>
                            <div className='px-4 text-black w-8/12 mx-auto'>                                                                         
                                <p className='py-2'>
                                    <span className='font-bold'>Locations </span> 
                                    should represent something like a, 'Flower Tent', 'Cloning Station',
                                    or 'Backyard Garden'. 
                                    
                                </p>
                                <p className='py-2'>
                                    <span className='font-bold'>Positions</span> represent more specific areas within 
                                    your growing location, such as 'Rear Left' or 'North Corner'... really anything to help
                                    organize the physcial layout.
                                </p>
                            </div>
                        </div>

                        <section className='text-black p-2 mb-4 bg-emerald-800/30 rounded-xl m-4'>
                        
                            <p className='flex justify-center text-2xl text-black text-ceneter tracking-wide pt-4 font-medium'>
                                Define Your Grow Space
                            </p>
                            <p className='flex justify-center etg-systemText py-2'>add at least one location and position, then you can add your first plant</p>
                            <div className='flex flex-col md:flex-row space-evenly'>
                                <div className='flex mx-auto'>
                                    <LocationsAddEdit 
                                        growerProfile={appData.profileData}
                                        id={appData.id}
                                    />
                                </div>
                                <div className='flex mx-auto'>
                                    <PositionsAddEdit
                                        growerProfile={appData.profileData}
                                        id={appData.id}
                                    />
                                </div>
                            </div>
                                <p className='flex justify-center etg-systemText py-2'>
                                    You can update your grow space from the profile page, this is also where you can add custom nutrient schedules!    
                                </p>
                        </section>


                        <section className='flex text-black px-4 mx-auto mb-4'>                            
                                <div className='p-2 mx-auto'>
                                    <p className='text-2xl text-center pt-2 font-medium'>Next Steps</p>
                                    <div className='px-2'>
                                        {(appData.profileData.growLocations.length > 0 && appData.profileData.growPositions.length > 0) && <>
                                            <div className='flex flex-col justify-center w-fit p-2 mx-auto items-center rounded-xl'>
                                                <h3 className='text-center etg-sectionTitle pt-2'>Get Started By Adding Your First Plant Now</h3>
                                                <ETGButton
                                                    onClickFunction={() => setGrowsModal('add-plant')}
                                                    btnText='Add Your First Plant'
                                                    icon='add'
                                                />
                                            </div>
                                        </>}
                                        <p className=' px-8 text-center p-2 font-light'>
                                            
                                        </p>                                    
                                    </div>
                                    <div className='flex flex-col items-center text-center pt-4'>
                                        <p className='text-lg'>
                                            You are now ready to dismiss this window and start managing your grow
                                        </p>   
                                        <ETGButton onClickFunction={() => setGrowsModal(null)} btnText='Close This Window'/>                                          
                                    </div>                                    
                                </div>
                                
                        </section>

                    </section>
                </div>


            </section>
  )
}
