import React, { useState, useEffect } from 'react';
import DefaultNavbar from '../components/DefaultNavbar';
import DefaultFooter from '../components/DefaultFooter';
import { useAppData } from '../hooks/useAppData';
import LoadingDiv from '../components/LoadingDiv';
import ProcessingDiv from '../components/ProcessingDiv';
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';
import { GApageView } from '../components/renderless/helpers';
import { getAuth } from 'firebase/auth';
import useAdminSupportTask from '../hooks/useAdminSupportTask';
import { projectFirestore, timestamp } from '../firebase/config';
import { v4 as uuidV4 } from 'uuid';
import useMessageSender from '../hooks/useMessageSender';

export default function Feedback({ user }) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [knownIssues, setKnownIssues] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const { etgErrorDetected } = React.useContext(ErrorHandilingContext);

  // BEGIN DATA LOADING SECTION
  const [id, setId] = useState('notloggedin');

  useEffect(() => {
    if (user && id !== user.uid) {
      setId(user.uid);
    }
  }, [user, id]);

  const currentUser = getAuth().currentUser;

  // Load data from Firestore
  const { profileDocument } = useAppData(currentUser?.uid);

  useEffect(() => {
    if (profileDocument) {
      setIsPageLoading(false);
    }
  }, [profileDocument]);

  const { addTask } = useAdminSupportTask();
  const { sendEmail } = useMessageSender();

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleFeedbackSubmit = async () => {
    setIsSubmitting(true);
    const today = new Date();
    const dateTime = new timestamp(Math.round(today.getTime() / 1000), 0);

    // Build the task object to submit to Firestore
    const newTask = {
      adminNotes: [],
      category: selectedCategory || 'PlaceholderCategory',
      dateTimeSubmitted: dateTime,
      dateTimeLastUpdated: dateTime,
      datetimeCompleted: null,
      description: feedbackText || 'PlaceholderFeedback',
      priority: 'Low',
      status: 'Submitted',
      taskID: uuidV4(),
      title: 'Feedback - ' + selectedCategory,
      submittedByUID: currentUser?.uid,
    };

    try {
      // Call your Firestore hook function to add the task
      await addTask(newTask);

      // Send email to user - confirming receipt of feedback
      const userEmailData = {
        displayName: profileDocument.displayName,
        feedbackMessage: feedbackText,
        feedbackID: newTask.taskID,
      };
      await sendEmail(currentUser.email, 'feedbackSubmissionUser', userEmailData, 'Feedback Confirmation', currentUser.uid);

      // Send email to admin - notifying of new feedback
      const adminEmailData = {
        displayName: profileDocument.displayName,
        feedbackMessage: feedbackText,
        feedbackID: newTask.taskID,
      };
      await sendEmail('Team@EctorGrow.com', 'feedbackSubmissionAdmin', adminEmailData, 'New Feedback');

      setSubmissionSuccess(true);
    } catch (error) {
      // Handle error
      console.error('Error submitting feedback:', error);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    GApageView('feedback');
  }, []);

  useEffect(() => {
    // Load Known Issues from Firestore
    const tempIssuesRef = projectFirestore.collection('maintenanceData').doc('wt6KhFdiemihgYcDq0i2');
    
    tempIssuesRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          setKnownIssues(doc.data().knownIssues);
        } else {
          // doc.data() will be undefined in this case
          console.log("No known issues!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);


  console.log('knownIssues', knownIssues)
  return (
    <>
      {etgErrorDetected ? null : (
        <section className='flex flex-col h-screen justify-between'>
          {/* Navbar */}
          <div className='w-full z-20'>
            <DefaultNavbar user={user} profileData={profileDocument} />
          </div>
          {/* Page Specific Content */}
          {isPageLoading ? (
            <LoadingDiv />
          ) : (
            <>
              <main className='my-10 w-full flex justify-center'>
                <div className='bg-white text-black flex flex-col rounded-xl p-6 shadow-lg m-4'>
                  {selectedCategory ? (
                    submissionSuccess ? (
                      <div className='text-green-600'>
                        Thank you for your feedback! We appreciate it.
                      </div>
                    ) : (
                      <>
                        <h2 className='text-2xl font-semibold'>
                          Provide {selectedCategory} Feedback
                        </h2>
                        <p className='text-gray-600 my-2'>
                          We value your feedback and appreciate your input.
                        </p>
                        <textarea
                          rows='4'
                          value={feedbackText}
                          onChange={(e) => setFeedbackText(e.target.value)}
                          placeholder='Enter your feedback (max 1000 characters)'
                          className='w-full p-2 border rounded'
                        />
                        <div className='flex justify-center py-1'>
                          <button
                            onClick={handleFeedbackSubmit}
                            disabled={isSubmitting || feedbackText.length === 0}
                            className='bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 mt-4 rounded-full'
                          >
                            {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
                          </button>
                        </div>
                        <div className='flex justify-center py-1'>
                          {/* Cancel Button */}
                          <button
                            onClick={() => setSelectedCategory(null)}
                            className='bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded-full'
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      {/* Known Issues Section */}
                      <div className='flex flex-col justify-center py-1'>
                        <h2 className='text-2xl text-black text-center font-semibold'>
                          Known Issues
                        </h2>
                        <div className=''>
                          <p className='text-gray-600 text-center my-2'>
                            We are aware of the following issues and are working to resolve them as soon as possible.
                          </p>
                          {/* Map through known issues if knownIssues.knownIssues.length is more than 1 */}
                          {knownIssues && knownIssues.length >= 1 ? (
                            <div className='flex flex-col gap-4 justify-center items-center py-4 rounded-xl'>
                              {knownIssues.map((issue) => (
                                <div className='flex flex-row'>
                                  <p> - {issue.description}</p>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Add Feedback Section */}
                      <h2 className='text-2xl text-black text-center font-semibold'>
                        Select a Feedback Category
                      </h2>
                      <p className='text-gray-600 text-center my-2'>
                        We believe that your feedback is essential in improving our service.
                      </p>
                      <div className='flex flex-wrap gap-4 justify-center items-center py-4 bg-slate-400/40 rounded-xl'>
                        <button
                          onClick={() => handleCategorySelect('Bug')}
                          className='bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full'
                        >
                          Bug
                        </button>
                        <button
                          onClick={() => handleCategorySelect('Feedback')}
                          className='bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full'
                        >
                          Feedback
                        </button>
                        <button
                          onClick={() => handleCategorySelect('Other')}
                          className='bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full'
                        >
                          Other
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </main>
            </>
          )}
          <DefaultFooter />
        </section>
      )}
    </>
  );
}
