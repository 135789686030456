// THIS COMPONENT IS THE PRIVATE VIEW OF A PLANTS NUTRIENT SCHEDULE
// WHICH IS DISPLAYED IN THE PLANTCARD FOOTER

import React from 'react'
import ETGButton from '../../../shared/global/ETGButton'
import { calcDaysSinceLastNutrientReset } from '../../../renderless/calcData/calcDaysSinceLastNutrientReset'
import SaveInProgress from '../../../shared/global/SaveInProgress'
import { addNewNote } from '../../../renderless/setData/addNewNote'
import { daysSince } from '../../../renderless/helpers'



export default function ScheduleDisplay({ appData, currentPlantData, currentPhase, updateErrorData,
    findVegNutrientSchedule, findFlowerNutrientSchedule }) {
    const [ resetToday, setResetToday ] = React.useState(false)
    const [ isLoading, setIsLoading ]  = React.useState(false)
    const [ scheduleNotFound, setScheduleNotFound ] = React.useState(false)
    let hasVeg = (currentPlantData.vegNutrients !== '' && currentPlantData.vegNutrients !== 'none')  ? true : false
    let hasFlower = (currentPlantData.flowerNutrients !== '' && currentPlantData.flowerNutrients !== 'none') ? true : false
    const days = parseInt(daysSince(currentPlantData))
    let vegSchedule = null 
    let flowerSchedule = null 
    let vegMaxWeek = null
    let flowerMaxWeek = null
    
    
    React.useEffect(() => {
        let todaysDate = new Date().toLocaleDateString("en-US")
        if ((todaysDate === calcDaysSinceLastNutrientReset(currentPlantData) )) {
            // dates match        
            setResetToday(true)
        } else {
            setResetToday(false)
        }

    }, [currentPlantData, resetToday])
        
    if (hasVeg && !vegSchedule && !scheduleNotFound) {
        vegSchedule = findVegNutrientSchedule(currentPlantData.vegNutrients)
        // vegSchedule array is empty, set hasVeg to false
        if (vegSchedule.length === 0) {
            hasVeg = false
            // also displayScheduleNotFoundMessage
            setScheduleNotFound(true)
        } else {
            vegMaxWeek = parseInt(vegSchedule[0].numberOfWeeks)
        }
    }
    
    if (hasFlower && !flowerSchedule && !scheduleNotFound) {
        flowerSchedule = findFlowerNutrientSchedule(currentPlantData.flowerNutrients)
        // flowerSchedule array is empty, set hasFlower to false
        if (flowerSchedule.length === 0) {
            hasFlower = false
            // also displayScheduleNotFoundMessage
            setScheduleNotFound(true)
        } else {
            flowerMaxWeek = parseInt(flowerSchedule[0].numberOfWeeks)
        }
    }

    async function handleNutrientResetSave() {
        setIsLoading(true)
        const results = addNewNote(appData, currentPlantData, currentPhase, new Date(), 'Plant nutrients were reset', 'lc-nutrient')
        if(results.error) {
            updateErrorData(results.error, appData.id)
        }
        setIsLoading(false)
    }

    // potential week max range: 3 to 6 weeks
    let nutrientWeek = 0
    if (currentPhase === 'Vegetative') {    
        if(days >= 0 && days <= 7) {
            nutrientWeek = 1
        }
        if (days >= 8 && days <= 14) {
            nutrientWeek = 2
        }        
        if (days >= 15 && days <= 21) {
            nutrientWeek = 3
        }        
        if (days >= 22 && days <= 28) {
            nutrientWeek = 4
        }        
        if (days >= 29 && days <= 35) {
            nutrientWeek = 5
        }        
        if (days >= 36) {
            nutrientWeek = 6
        }    
        
        // reset if max exceeded
        if (nutrientWeek > vegMaxWeek) {
            nutrientWeek = vegMaxWeek
        }


    }
    // potential week max range: 8 to 12 weeks
    // nutirent week should start again at 1 - to pull from first week in array
    if (currentPhase === 'Flower') {        
        if(days >= 0 && days < 7) {
            nutrientWeek = 1
        }
        if (days >= 8 && days <= 14) {
            nutrientWeek = 2
        }        
        if (days >= 15 && days <= 21) {
            nutrientWeek = 3
        }        
        if (days >= 22 && days <= 28) {
            nutrientWeek = 4
        }        
        if (days >= 29 && days <= 35) {
            nutrientWeek = 5
        }        
        if (days >= 36 && days <= 42) {
            nutrientWeek = 6
        }        
        if (days >= 43 && days <= 49) {
            nutrientWeek = 7
        }        
        if (days >= 50 && days <= 56) {
            nutrientWeek = 8
        }     
         if (days >= 57 && days <= 63) {
             nutrientWeek = 9
         }
         if (days >= 64 && days <= 70) {
             nutrientWeek = 10
         }
         if (days >= 71 && days <= 77) {
             nutrientWeek = 11
         }
         if (days >= 78) {
             nutrientWeek = 12
         }

         // reset if max exceeded
        if (nutrientWeek > flowerMaxWeek) {
            nutrientWeek = flowerMaxWeek
        }
    }        

    // ** refactor to helper function
    const displayCurrentNutrients = () => {
        let returnValue = []        

        if (currentPhase === 'Vegetative' && hasVeg) {
            for (let index = 0; index < vegSchedule[0].numberOfNutrients; index++) {
                const nutrient = vegSchedule[0].nutrients[index];            
                returnValue.push(<div className='flex flex-col px-2 border-x-2 border-emerald-800/60' key={`nutrientIndex${index}`}>                
                    <p>{nutrient.nutrientName}</p>
                    <p className='pt-4'>{nutrient.weeklySchedule[ ( nutrientWeek - 1 ) ]}</p>
                    <p className='text-xs'>{nutrient.unitOfMeasure}</p>                    
                </div>)
            }            
        } else if (currentPhase === 'Flower' && hasFlower) {
            for (let index = 0; index < flowerSchedule[0].numberOfNutrients; index++) {
                const nutrient = flowerSchedule[0].nutrients[index];            
                returnValue.push(<div className='flex flex-col px-2 border-x-2 border-emerald-800/60' key={`nutrientIndex${index}`}>                
                    <p>{nutrient.nutrientName}</p>
                    <p className='pt-4'>{nutrient.weeklySchedule[ ( nutrientWeek - 1 ) ]}</p>
                    <p className='text-xs'>{nutrient.unitOfMeasure}</p>                    
                </div>)
            }
        }
        return returnValue
    }

    if (scheduleNotFound) {
        return (
          <div className="bg-emerald-100 border border-emerald-800 text-emerald-800 p-3 text-lg rounded-xl font-bold flex justify-center items-center">
            <p>nutrient schedule not found</p>
          </div>
        );
      }
      
    
  return (<>
        <section className='flex flex-col'>
            <div>
                <h4 className='text-2xl font-medium pb-4 underline underline-offset-2 uppercase tracking-wider'>Current Nutrients</h4>                
            </div>
            {(currentPhase === 'Vegetative' && hasVeg) && <>
                <div className='flex flex-col py-2'>
                    <p className=''>Vegetative Schedule: {vegSchedule[0].scheduleName}</p>
                    <p className='text-xs italic'>{vegSchedule[0].medium} - {vegSchedule[0].numberOfNutrients} nutrients - {vegSchedule[0].numberOfWeeks} weeks</p>
                </div>             
                <div className='flex flex-col'>
                    <p>Week {nutrientWeek} of {currentPhase}</p>
                </div>
                <div className='flex flex-row mx-auto'>
                    {displayCurrentNutrients()}
                </div>
                {/* button to reset nutrients now */}
                <div className='flex flex-col items-center justify-center m-3 py-4'>
                    { isLoading ? <SaveInProgress /> : !resetToday ? <>
                        <ETGButton btnText='Reset Nutrients Today' onClickFunction={() => {handleNutrientResetSave()}} />                        
                        <p className='etg-systemText pb-2'>last reset: {calcDaysSinceLastNutrientReset(currentPlantData)}</p>
                        </> 
                    : <p className='etg-systemText pb-2'>nutrients reset today</p> }
                </div>
            </>}            
            {(currentPhase === 'Flower' && hasFlower) && <>
                <div className='flex flex-col py-2'>
                    <p className=''>Flower Schedule: {flowerSchedule[0].scheduleName}</p>
                    <p className='text-xs italic'>{flowerSchedule[0].medium} - {flowerSchedule[0].numberOfNutrients} nutrients - {flowerSchedule[0].numberOfWeeks} weeks</p>
                </div>             
                <div className='flex flex-col'>
                    <p>Week {nutrientWeek} of {currentPhase}</p>
                </div>
                <div className='flex flex-row mx-auto'>
                    {displayCurrentNutrients()}
                </div>
                {/* button to reset nutrients now */}
                <div className='flex flex-col items-center justify-center pt-4'>
                    { isLoading ? <SaveInProgress /> : !resetToday ? <>
                        <ETGButton btnText='Reset Nutrients Today' onClickFunction={() => {handleNutrientResetSave()}} />
                        <p className='etg-systemText pb-2'>last reset: {calcDaysSinceLastNutrientReset(currentPlantData)}</p>
                        </> 
                    : <p className='etg-systemText pb-2'>nutrients reset today</p> }
                </div>

            </>}            
        </section>
    </>)
}
