import React from 'react'
import AddNutrientScheduleNutrients from './AddNutrientScheduleNutrients'
import ETGButton from '../../shared/global/ETGButton'

export default function AddNutrientScheduleSection({ 
  addNutrientProgress, setAddNutrientProgress,
  scheduleName, setScheduleName,
  medium, setMedium,
  containerSize, setContainerSize,
  schedulePhase, setSchedulePhase,
  numberOfNutrients, setNumberOfNutrients,
  numberOfWeeks, setNumberOfWeeks,
  nutrients, setNutrients,
  growerProfile, handleToggleSharePublicly, sharePublicStatus, scheduleDescription, setScheduleDescription }) {
  
    const [ inputLength, setInputLength ] = React.useState(0)

    const handleScheduleDescriptionChange = (event) => {
      const input = event.target.value;
      setInputLength(input.length);
  
      if (input.length <= 200) {
        setScheduleDescription(input);
      } else {
        const shortenedInput = input.slice(0, 200);
        setScheduleDescription(shortenedInput);
        alert('Input is too long! Maximum length is 200 characters.');
      }
    };
  
  
  const handleScheduleName = ( nameInput ) => {
      if (nameInput.length > 20) {
        alert('Schedule Names cannot exceed 20 characters in length')
      } else {
        setScheduleName(nameInput)
      }
  }



  const displayNutrientsInputs = () => {
    // go through a loop, each time I do, output the component for the number of nutrients user selected in stepA
    // use nutrientInSchedule for variable for index
    let returnValue = []
    for (let index = 0; index < numberOfNutrients; index++) {
      returnValue.push(<AddNutrientScheduleNutrients 
        nutrients={nutrients} setNutrients={setNutrients} 
        numberOfWeeks={numberOfWeeks} numberOfNutrients={numberOfNutrients}
        indexOfNutrient={index}
      />)
      
    }
    return returnValue
  }

  let vegOnly = false
  let flowerOnly = false



  if (growerProfile.vegNutrients.length < growerProfile.nutrientLimit && growerProfile.flowerNutrients.length === growerProfile.nutrientLimit) {
    vegOnly = true
    setSchedulePhase('Vegetative')
  } 
  if (growerProfile.flowerNutrients.length < growerProfile.nutrientLimit && growerProfile.vegNutrients.length === growerProfile.nutrientLimit) {    
    flowerOnly = true
    setSchedulePhase('Flower')
  }     
  
  
  return (<>
    <div className='flex flex-col px-2 text-black'>
      {/* SECTION with brief summary/description for entering a nutrient schedule */}
      <section className='w-8/12 mx-auto py-4'>
        {(addNutrientProgress === 'stepB') && <>
          <div className='text-center border-2 border-black/25 p-2 rounded-md'>
            <p>Designing a, <span className='font-medium'>{schedulePhase}</span> nutrient schedule named, <span className='font-medium'>{scheduleName}</span>.</p>
            <p>
              <span className='font-medium'>{numberOfNutrients}</span> nutrients for <span className='font-medium'>{numberOfWeeks}</span> weeks in 
              <span className='font-medium'> {containerSize}</span> gallon containers.              
            </p>          
            {/* toggle button for sharing publicly */}
            <div className='flex justify-center items-center border-2 border-slate-400 mt-2 rounded-xl'>            
              Share Schedule Publicly: 
              <ETGButton
                onClickFunction={handleToggleSharePublicly}
                btnText={sharePublicStatus ? 'ON' : 'OFF'}
              />              
            </div>
          </div>
        </>}
      </section>
      {/* SECTION for entering initial data to set up Nutrient Schedule - stepA */}
      {(addNutrientProgress === 'stepA') && <>
      <section className='flex flex-col w-8/12 mx-auto'>
        <div className='flex flex-col py-4 items-center'>
          <label>Schedule Name: {scheduleName} </label>      
          <input
            type="text"            
            className='w-1/2 rounded-xl p-1'
            placeholder=''
            outline="false"                                
            value={scheduleName}
            onChange={(e) => {handleScheduleName(e.target.value)}}
          /> 
        </div>
        {/* input for a breif description */}
          <div className='flex flex-col py-4 w-full items-center'>
            <label>Brief Description: </label>
            <textarea
              type="text"
              className='w-10/12 rounded-xl p-1'              
              placeholder=''
              outline="false"
              rows={3}
              value={scheduleDescription}
              onChange={handleScheduleDescriptionChange}
            />
            <span className='flex'>{inputLength}/200</span>
          </div>
          {/* Row for medium, container size, number of nutrients and the phase */}
          <div className='flex flex-col md:flex-row justify-evenly gap-2 md:py-3'>
            <div className='flex flex-col'>
              <p>Medium</p>      
              <select value={medium} onChange={(e) => setMedium(e.target.value)}>
              <option value="Hydro">Hydro</option>
              <option value="Soil">Soil</option>
              <option value="Coco">Coco</option>
              <option value="Peat Moss">Peat Moss</option>
              <option value="Rockwool">Rockwool</option>                  
              </select> 
            </div>
            <div className='flex flex-col'>
              <p>Nutrient Schedule Phase</p>                    
              <select value={schedulePhase} onChange={(e) => setSchedulePhase(e.target.value)}>
                {(vegOnly) && <option value="Vegetative">Vegetative</option> }
                {(flowerOnly) && <option value="Flower">Flower</option> }
                {(!vegOnly && !flowerOnly) && <>
                  <option value="Vegetative">Vegetative</option>
                  <option value="Flower">Flower</option>                                               
                </>}
              </select> 
            </div>
            <div className='flex flex-col'>
              <p>Container Size</p>      
              <select value={containerSize} onChange={(e) => setContainerSize(e.target.value)}>
                  <option value={1}>1 gallon</option>                
                  <option value={2}>2 gallons</option>                
                  <option value={3}>3 gallons</option>                
                  <option value={4}>4 gallons</option>                
                  <option value={5}>5 gallons</option>                
                  <option value={6}>6 gallons</option>                
                  <option value={7}>7 gallons</option>                
                  <option value={8}>8 gallons</option>                
                  <option value={9}>9 gallons</option>                
                  <option value={10}>10+ gallons</option>                
              </select> 
            </div>

          </div>
          <div className='flex flex-col md:flex-row justify-evenly pt-3 gap-2 md:py-3'>
            <div className='flex flex-col'>
              <p>Number of Nutrients</p>      
              <select value={numberOfNutrients} onChange={(e) => setNumberOfNutrients(e.target.value)}>
                  <option value={1}>1</option>              
                  <option value={2}>2</option>              
                  <option value={3}>3</option>              
                  <option value={4}>4</option>              
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>  

              </select> 
            </div>        
            <div className='flex flex-col'>
              <p>Weeks in Schedule</p> 
              {/* Display verison with 3 - 6 weeks for veg */}
              {(schedulePhase === 'Vegetative') && <>
              <select value={numberOfWeeks} onChange={(e) => setNumberOfWeeks(e.target.value)}>
                  <option value={3}>3</option>              
                  <option value={4}>4</option>              
                  <option value={5}>5</option>              
                  <option value={6}>6</option>                                      
              </select> 
              <p className='text-xs italic pt-2 max-w-xs'>
                vegetative schedules range from 3 to 6 weeks long, the schedule repeats the final week until they are in the Flower phase
              </p>
              </>}
              {/* Display verison with 8 - 12 weeks for flower */}
              {(schedulePhase === 'Flower') && <>
              <select value={numberOfWeeks} onChange={(e) => setNumberOfWeeks(e.target.value)}>
                  <option value={8}>8</option>              
                  <option value={9}>9</option>              
                  <option value={10}>10</option>              
                  <option value={11}>11</option>              
                  <option value={12}>12</option>              
              </select> 
              <p className='text-xs italic pt-2 max-w-xs'>
                flower schedules range from 8 to 12 weeks long, the final week is repeated until harvest
              </p>
              </>}
            </div>        
          </div> 
      </section></>}


      {/* SECTION for entering details of each nutrient within schedule - stepB */}
      {(addNutrientProgress === 'stepB') && <>
        <section className=''>            
          <div className=''>
            {displayNutrientsInputs()}
          </div>
        </section>
      </> }
    </div>
  </>)
}
