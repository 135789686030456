// js function to update order document with delay

import { projectFirestore } from '../../../firebase/config';

export const addOrderDelay = async (orderID, delay) => {
    const orderRef = projectFirestore.collection('growLabelOrders').doc(orderID);
    
    try {
        await orderRef.update({
        delay,
        });
    } catch (err) {
        console.error(err);
    }
}


