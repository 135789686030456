import React from 'react'
import ETGButton from '../../shared/global/ETGButton'
import { projectAuth } from '../../../firebase/config'
import { testEmail } from '../../renderless/GetData/testEmail'
import { getAuth, updateEmail, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth'
import SaveInProgress from '../../shared/global/SaveInProgress'

export default function EditEmailView({ handleProfileModalToggle, updateErrorData }) {    
    const [ emailAddress, setEmailAddress ] = React.useState('')    
    const [ password, setPassword ] = React.useState('')    
    const [ validEmail, setValidEmail ] = React.useState(true)    
    const [ isLoading, setIsLoading ] = React.useState(false)
    
    
    React.useEffect(() => {
        setEmailAddress(projectAuth.currentUser.email)        
      
    }, [])    

    const handleEmailInputChange = ( emailInput ) => {            
        const testResults = testEmail(  emailInput )            
        setEmailAddress(testResults.currentEmail)
        setValidEmail(testResults.isValid)        
    }

    function handlePasswordInputChange ( passwordInput) {
        setPassword(passwordInput)
    }

    const handleSaveNewEmail = async () => {     
        setIsLoading(true)
        const auth = getAuth()
        
        // authenticate user
        const credential = EmailAuthProvider.credential(auth.currentUser.email, password)

        // if successful, set email, otherwise return response
        reauthenticateWithCredential(auth.currentUser, credential).then(() => {
            // save new email            
             updateEmail(auth.currentUser, emailAddress).then(() => {                
                //  if succesful, close modal
                handleProfileModalToggle()
             }).catch((error) => {           
    
                if (error.code === 'auth/requires-recent-login') {                      
                    updateErrorData( 'Action requires re-authentication', auth.currentUser.uid)          
                    handleProfileModalToggle()
                } else  {
                    updateErrorData('Error updating email address')
                    handleProfileModalToggle()                
                }
            })
            
        }).catch((error) => {
            if (error.code === 'auth/wrong-password') {                                      
                alert('Invalid Password - please try again')
                setIsLoading(false)                
            } else  {
                updateErrorData('Error Authenticating While Updating Email')
                handleProfileModalToggle()                
            }            
        })






        
    }
        
  return (<>
    {/* DisplayName Field */}
    <div className="flex flex-col w-10/12 self-center text-black">
        <div className="flex flex-col items-center font-lg py-2 mt-8">                    
            {/* rules for the display name */}
            <div className='flex flex-col w-6/12 mx-auto etg-systemText -mt-8 -pt-4'>
                <p>your email address is never displayed publicly</p>
                <p className='pt-4'>sensitive actions, like changing your email, require your password</p>
            </div>            
        </div>
        <div className="flex flex-col bg-gray-300 w-full justify-center text-black rounded-md my-4 p-2">            

            <label className='w-10/12 md:w-full self-center py-2 text-lg md:text-2xl'>
                Password
            </label>
            <input
                type='password'
                placeholder={'password'}  
                value={password}                              
                className='etg-userText p-2 rounded-lg w-10/12 md:w-full self-center shadow-xl'
                onChange={(e) => handlePasswordInputChange(e.target.value)}
            />      



            <label className='w-10/12 md:w-full self-center py-2 text-lg md:text-2xl'>
                Email
            </label>
            <input
                placeholder={projectAuth.currentUser.email}  
                value={emailAddress}                              
                className='etg-userText p-2 rounded-lg w-10/12 md:w-full self-center shadow-xl'
                onChange={(e) => handleEmailInputChange(e.target.value)}
            />      

            <div className='flex w-fit self-center py-1 pt-2 etg-systemText'>
                {projectAuth.currentUser.emailVerified ? <p>current email address has been verified</p> : <p>current email address is unverified</p>}
            </div>

        </div>

        {/* Buttons for Save/Cancel */}
        <div className='flex flex-col md:flex-row justify-around my-4'>
            {/* conditional loading bar */}
            { isLoading ? <SaveInProgress /> :
            // {/* conditional save buttons */}
            !validEmail ? <ETGButton btnText='email not valid' disable={true} /> : <><ETGButton btnText='Save Email Address' onClickFunction={handleSaveNewEmail} />
             {/* cancel buttons */}
            <ETGButton btnText='Cancel' type='delete' onClickFunction={() => handleProfileModalToggle()} /></>
            }
        </div>
  </div>
  </>
  )
}
