import { useRef, useState } from 'react'
import ETGIconBtn from '../shared/global/ETGIconBtn';
import { projectStorage } from '../../firebase/config';
import { useFirestore } from '../../hooks/useFirestore';
import firebase from 'firebase/compat/app';
import LoadingDiv from '../LoadingDiv';
import ETGButton from '../shared/global/ETGButton';

export default function SocialInfoSettings({ profileDocument, handleProfileModalToggle, user, setProfileModal }) {
    const [ processingData, setProcessingData ] = useState(false)
    // Hidden file input for profile image
    const hiddenFileInput = useRef()
    const storageRef = firebase.storage()
    const { updateDocument, response } = useFirestore('growProfiles', user.uid)

    const handleSavewNewProfileImage = async ( e ) => {
        setProcessingData(true)
        // determine if current profile image needs to be deleted, if current value is the same as the default value 
        let deletePreviousImage = profileDocument.profileImg === 'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/profileImages%2FblankAvatar.png?alt=media&token=0f82d827-c1a0-4b47-81f1-8295185aa676' ? false : true                        
        
        // delete previous profile if neccessary 
        if (deletePreviousImage) {
            const previousProfileImgRef = storageRef.refFromURL(profileDocument.profileImg)
            previousProfileImgRef.delete().then(() => {
                //file deleted
            }).catch((error) => {
                console.log(error)
            })
            // console.log('prev img deleted')
        }    
        
        // upload new profile image        
        const uploadedImg = e.target.files[0]
        let imageName = `profileImg-${profileDocument.uid}`
        const uploadPath = `profileImages/${profileDocument.uid}/${imageName}`
        // save image to storage -> retrueve the reference url
        const img = await projectStorage.ref(uploadPath).put(uploadedImg)
        const imgUrl = await img.ref.getDownloadURL()
        
        
        //  update the profile document with the url of the new current profile 
        // clone profile document, update and upload new version
        let clonedProfileDocument = Object.assign({}, profileDocument)
        clonedProfileDocument.profileImg = imgUrl

        await updateDocument( profileDocument.uid, clonedProfileDocument)
        if (response.error) {
            console.log(`error w/ profile img` + response.error)
        }
        
        setProcessingData(false)
    }

    if (processingData) {
        return <LoadingDiv />
    }



    return <section className="flex flex-col text-center text-black justify-center py-2">
        {/* Profile thumbnail */}
        {/* Hidden image upload */}
        <div className='invisible'>
            <input
                type="file"
                ref={hiddenFileInput}
                accept="image/*"
                onChange={handleSavewNewProfileImage} />
        </div>
        {/* Profile Image & Upload */}
        <div className='pb-4'>
            <img
                className='flex h-60 rounded-3xl shadow-xl mx-auto border-sky-600/40 border-2'
                src={profileDocument.profileImg}
                alt={`${profileDocument.displayName}'s profile`} />
            <div className='flex flex-row justify-center m-auto'>
                <p className='flex items-center font-light'>
                    upload a new profile image
                </p>
                <ETGIconBtn type='edit' size='sm' onClickFunction={() => hiddenFileInput.current.click()} />

            </div>
        </div>

        {/* Hello msg & profile Display Name */}
        <div className='flex flex-col items-center py-10'>
            <div className='flex flex-row w-full justify-center'>
                <div className='flex flex-col justify-center items-center etg-heading'>
                    Hello <span className='underline'> {` ${profileDocument.displayName}`}</span>
                    <div className='flex'>
                        <span className='etg-systemText text-center my-auto'> edit display name </span> 
                        <ETGIconBtn type='edit' size='xs' onClickFunction={() => setProfileModal('editDisplayName')} />
                    </div>
                    
                </div>
                

            </div>

            <div className='flex flex-col mx-auto border-2 border-sky-600/40 rounded-lg mt-4 pt-2 w-8/12 '>
                <div className='flex flex-col'>
                    <h2 className='etg-subHeading'>About Me</h2>
                    <p className='etg-systemText'>publicly visible on EctorGrow</p>
                </div>
                
                
                <div className='flex flex-col md:flex-row'>
                    
                    
                    <div className='flex md:w-9/12 justify-center items-center py-4'>
                        <p className='etg-userText px-2'>
                            { (profileDocument.aboutMe !== undefined || profileDocument.aboutMe > 0 ) ? profileDocument.aboutMe : 'nothing entered yet'}
                        </p>
                    </div>
                    
                    
                    {/* if mobile, display etgButton if larger viewport, display etgIconButton */}
                    <div className='flex w-fit md:w-3/12 items-center pt-4 md:pt-0'>
                        <div className='invisible md:visible'>
                            <ETGIconBtn type='edit' size='sm' onClickFunction={() => setProfileModal('editAboutMe')} />
                        </div>
                        <div className='visible md:invisible -mt-3'>
                            <ETGButton type={'edit'} btnText='Edit About Me' onClickFunction={() => setProfileModal('editAboutMe')} />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>;
}



