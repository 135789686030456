import { useState } from "react";
import { projectFirestore } from "../firebase/config";
import { emailTemplates } from "../Data/EmailTemplates";

const replaceParams = (text, params) => {
  for (const [key, value] of Object.entries(params)) {
    const placeholder = `{${key}}`;
    text = text.replace(new RegExp(placeholder, "g"), value);
  }
  return text;
};

const useMessageSender = () => {
  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = async (sendTo, scenario, params, emailType = 'param empty', toUID = null, orderID = null) => {    


    try {
      const template = emailTemplates[scenario];
      if (!template) {
        // Handle template not found
        const errorMessage = `Template for "${scenario}" scenario not found. Params: ${JSON.stringify(params)}`;
        const errorSubject = `Email Template Error - Not Found`;

        const emailDocument = {
          emailType,
          to: "team@ectorgrow.com",
          message: {
            subject: errorSubject,
            text: errorMessage
          }
        }
        if (toUID !== null) {
          emailDocument.toUID = toUID;
        }
  
        if (orderID !== null) {
          emailDocument.orderID = orderID;
        }
  
        
        // Send email to team@ectorgrow.com if template not found
        await projectFirestore.collection("mailSentBySendGrid").add(emailDocument);

        throw new Error(errorMessage);
      }

      const { subject, text } = template;
      const personalizedText = replaceParams(text, params);

      const emailDocument = {
        emailType,
        to: sendTo,
        message: {
          subject,
          html: personalizedText
        }
      }
      if (toUID !== null) {
        emailDocument.toUID = toUID;
      }

      if (orderID !== null) {
        emailDocument.orderID = orderID;
      }

      await projectFirestore.collection("mailSentBySendGrid").add(emailDocument);

      setMessageSent(true);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessageSent(false);
    }
  };

  return {
    sendEmail,
    messageSent,
  };
};

export default useMessageSender;
