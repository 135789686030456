import { useState, useEffect } from 'react';
import { projectAuth } from '../firebase/config';
import { useAuthContext } from './useAuthContext';

export const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    setErrorMessage(null);
    setIsPending(true);

    try {      

      // Login
      const res = await projectAuth.signInWithEmailAndPassword(email, password);

      

      // Dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user });

      if (!isCancelled) {
        setIsPending(false);
        setErrorMessage(null);
      }
    } catch (error) {
      console.log('error', error)
      if (error.message.includes('auth/wrong-password') || error.includes('auth/wrong-password')) {
        setErrorMessage('Invalid Password - try again or reset password');
      } else if (error.message.includes('auth/user-not-found') || error.includes('auth/user-not-found')) {
        setErrorMessage('User Not Found - try again or register');
      } else {
        setErrorMessage('Error Logging In, Please Check Your Password and Email');
      }

      if (!isCancelled) {
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { login, isPending, errorMessageToDisplay: errorMessage };
};
