import React, { useState } from 'react'
import { timestamp } from '../../../firebase/config';
import ETGButton from '../../shared/global/ETGButton';
import { useUpdatePlantData } from '../../../hooks/useUpdatePlantData';


export default function EditPlantView({ 
    setGrowsModal, currentPlantData, currentPhase,
    appData,
    handleNewPlantSelected, 
    setCurrentPhase,  
    setIsProcessing, updateErrorData,
    vegScheduleOptions, flowerScheduleOptions, 
    getNutrientScheduleObj, getNutrientScheduleName }) { 
    

    
    
    const [ strain, setStrain ] = useState('')
    const [ status, setStatus ] = useState()
    const [ location, setLocation ] = useState()
    const [ position, setPosition ] = useState()
    const [ medium, setMedium ] = useState()
    const [ harvestYield, setHarvestYield ] = useState(0)
    const [ startDate, setStartDate ] = useState()
    const [ transferDate, setTransferDate ] = useState()
    const [ harvestDate, setHarvestDate ] = useState()
    const [ noteLog, setNoteLog ] = useState()
    const [ vegNutrients, setVegNutrients ] = useState([])
    const [ flowerNutrients, setFlowerNutrients ] = useState([])
    const [ images, setImages ] = useState()

    const { updatePlantData, updatePlantDataError } = useUpdatePlantData(appData.profileData)
    
    

    function dateBuilder(date) {        
        let dateString = new Date(date)    
        return dateString        
    }

    const loadData = () => {
        setStrain(currentPlantData.strain)
        setStatus(currentPlantData.status)
        setLocation(currentPlantData.location)
        setPosition(currentPlantData.position)
        setMedium(currentPlantData.medium)
        const tempStart = dateBuilder(currentPlantData.startDate.toDate())
        setStartDate(tempStart)
        const tempTransfer = dateBuilder(currentPlantData.transferDate.toDate())
        setTransferDate(tempTransfer)
        const tempHarvest = dateBuilder(currentPlantData.harvestDate.toDate())
        setHarvestDate(tempHarvest)
        setNoteLog(currentPlantData.noteLog) 
        setImages(currentPlantData.imgArray)
        // Set Veg and Flower Nutrients by matching the ID and returning an array - [name, id]
        
        // set the state of the nutrient to the name and the id

        setVegNutrients([currentPlantData.vegNutrients, getNutrientScheduleName(currentPlantData.vegNutrients)])
        setFlowerNutrients([currentPlantData.flowerNutrients, getNutrientScheduleName(currentPlantData.flowerNutrients)])

        
        setHarvestYield(currentPlantData.harvestYield)        
    }
    if(!strain) {
        loadData()
    }
    
    const handleSavePlantChanges = async () => {    
        setIsProcessing(true)    
        // build new object to contian new plant info
        let newPlant = {
            strain, 
            status, 
            location,
            position,
            medium,
            harvestYield,
            isPublic: typeof currentPlantData.isPublic === 'undefined' ? true : currentPlantData.isPublic,
            imgArray: images,
            startDate: new timestamp(Math.round(startDate.getTime()/1000), 0), 
            transferDate: new timestamp(Math.round(transferDate.getTime()/1000), 0),
            harvestDate: new timestamp(Math.round(harvestDate.getTime()/1000), 0),
            noteLog,
            plantCategory: typeof currentPlantData.plantCategory === 'undefined' ? 'General' : currentPlantData.plantCategory,
            plantId: currentPlantData.plantId,
            vegNutrients: vegNutrients[1],
            flowerNutrients: flowerNutrients[1],
            plantOwnerUID: appData.id
        }        
        
        //Update firestore docuemnt
        try {
            await updatePlantData(newPlant)            
        } catch (error) {
            updateErrorData(error, appData.id)
        }
        if(updatePlantDataError) {
            console.log('updatePlantDataError', updatePlantDataError)
            updateErrorData(updatePlantDataError, appData.id)
        } else {            
            setCurrentPhase(newPlant.status)
            handleNewPlantSelected(newPlant)                
        }

        //close modal after succesfully updating
        setGrowsModal(false)
        setIsProcessing(false)
    }

    // function that takes in one param, an input to be validated for the harvest total
    function validateHarvestInput(input) {
        // if input is a number, return true
        if (!isNaN(input)) {
            setHarvestYield(input)
        } else {
            // if input is not a number, return false            
            alert('Please enter a number')
        }
    }


    function handleVegNutrients (selectedScheduleName) {
        const selectedScheduleID = getNutrientScheduleObj(selectedScheduleName)    
        setVegNutrients([selectedScheduleName, selectedScheduleID])
    }

    function handleFlowerNutrients (selectedScheduleName) {
        const selectedScheduleID = getNutrientScheduleObj(selectedScheduleName)        
        setFlowerNutrients([selectedScheduleName, selectedScheduleID])
    }


    // useEffect that runs when the component mounts
    // if currentPlantData.vegNutrients or currentPlantData.flowerNutrients is not null, 
    // set the state of the nutrient to an array [name, id]
    React.useEffect(() => {
        if(currentPlantData.vegNutrients) {
            setVegNutrients([getNutrientScheduleName(currentPlantData.vegNutrients), currentPlantData.vegNutrients])
        }
        if(currentPlantData.flowerNutrients) {
            setFlowerNutrients([getNutrientScheduleName(currentPlantData.flowerNutrients), currentPlantData.flowerNutrients])
        }
    }, [])

            
    
  return (
    <>
        
        
               


        <div className="inline-block text-center mt3">
        {(currentPhase !== "Harvested") && 
            <h2 className='etg-sectionTitle'>Growing in {currentPlantData.location} </h2>            
        }
        </div>

        <div className="flex-row">
            <div className="color-black m-2 py-4 px-2">
                {/* strain div */}
                <div className='flex flex-col justify-center py-3'>
                    <div className='px-4 mx-auto text-black text-center max-w-full break-words'>
                    
                        <label className='etg-inputLabel text-black text-lg p-1'>
                            Strain: {strain}
                        </label>
                        <input 
                            type="text" 
                            className='text-textInput text-black bg-slate-200 p-2 mb-4 m-1 rounded-xl tracking-wide text-base md:text-lg'
                            placeholder='Strain Name'
                            value={strain} 
                            onChange={(e) => setStrain(e.target.value)} 
                        />   
                    </div>
                    <div className='py-4 mx-auto text-black text-center max-w-full break-words'>
                        {(currentPhase === 'Harvested') && <>
                            <label className='etg-inputLabel text-black text-lg p-1'>
                                Yield: {harvestYield} (in grams) 
                            </label>
                            <input 
                                type='number' 
                                className='text-black text-lg p-1 bg-slate-200 rounded-xl'
                                value={harvestYield} 
                                onChange={(e) => validateHarvestInput(e.target.value)} 
                            />
                        </>}
                        {(currentPhase !== "Harvested") && <>
                            <div className='py-2'>
                                <label className='etg-inputLabel text-black text-lg p-1'>
                                    Medium: {medium}
                                </label>
                                <select className='etg-selectInput p-2 rounded-xl' value={medium} onChange={(e) => setMedium(e.target.value)}>
                                    <option value="Hydro">Hydro</option>
                                    <option value="Soil">Soil</option>
                                    <option value="Coco">Coco</option>
                                    <option value="Peat Moss">Peat Moss</option>
                                    <option value="Rockwool">Rockwool</option>
                                </select>
                            </div>
                            <div className='py-2'>
                                <label className='etg-inputLabel text-black text-lg p-1'>
                                    Veg Nutrient Schedule: 
                                </label>
                                <select className='etg-selectInput p-2 rounded-xl' value={vegNutrients[0]} onChange={(e) => handleVegNutrients(e.target.value)}>                                    
                                    {/* output options through an array */}
                                    <option value='none' key='none' >none</option>
                                    {vegScheduleOptions}
                                </select>
                            </div>
                            <div className='py-2'>
                                <label className='etg-inputLabel text-black text-lg p-1'>
                                    Flower Nutrient Schedule: 
                                </label>
                                <select className='etg-selectInput p-2 rounded-xl' value={flowerNutrients[0]} onChange={(e) => handleFlowerNutrients(e.target.value)}>                                    
                                    {/* output options through an array */}
                                    <option value='none' key='none' >none</option>
                                    {flowerScheduleOptions}
                                </select>
                            </div>                                   
                        </>}
                    </div>                     
                </div>                                                                                                       
            </div>                    
        </div>

        <div className="flex flex-col justify-evenly">               
            <div className='flex flex-row pt-4 space-evenly mx-auto'>
            <div className="px-2">                
                <ETGButton
                    onClickFunction={handleSavePlantChanges}
                    icon='save'
                    btnText='Save Changes'
                />
            </div>  
                <div className="px-2">                                            
                    <ETGButton
                        onClickFunction={() => setGrowsModal(null)}
                        icon='close'
                        btnText='Cancel'
                        type='delete'
                    />
                </div>
            </div>
        </div>

        <div className='flex flex-col bg-slate-500/40 border-2 bordger-gray-800 rounded-xl'>
            <div className='mx-auto p-1 etg-subHeading text-black'>
                <h2>Special Actions</h2>
            </div>
            <div className='flex flex-wrap'>
                {((currentPhase === 'Vegetative') || (currentPhase === 'Flower')) &&                 
                    <div className='mx-auto'>
                    <ETGButton
                        onClickFunction={() => setGrowsModal('lc-move')}
                        icon='move_down'
                        btnText='Change Location/Position'
                    />
                    </div>
                                                        
                }
                {(currentPhase === 'Vegetative') &&                 
                    <div className='mx-auto'>
                        <ETGButton
                            onClickFunction={() => setGrowsModal('veg-to-flower')}
                            icon='input'
                            btnText='Transfer To Flower'
                        />
                    </div>                                  
                }
                {(currentPhase === 'Flower') && 
                    <>
                    <div className='mx-auto'>
                        <ETGButton
                            onClickFunction={() => setGrowsModal('flower-to-harvest')}
                            icon='input'
                            btnText='Harvest'
                        />
                    </div>                                              
                    {/* Display Option To Move Plant Back to Veg, From Flower */}
                    <div className="mx-auto">                    
                        <ETGButton
                            onClickFunction={() => setGrowsModal('flower-to-veg')}
                            btnText='Transfer Back To Vegetative'
                            type='caution'
                        />
                    </div>
                    </>
                }
                {/* Display Option To Move Plant Back to Flower, From Harvest */}
                {(currentPhase === "Harvested") &&  <>
                    <div className="mx-auto">
                    <ETGButton
                        onClickFunction={() => setGrowsModal('harvest-to-flower')}
                        btnText='Transfer Back To Flower'
                        type='caution'
                    />                    
                    </div>
                </>}           
                                                            
            <div className="flex flex-row px-2 mx-auto gap-4">
                
                <ETGButton
                    onClickFunction={() => setGrowsModal('delete-plant')}
                    icon='delete'
                    btnText='Delete'
                    type='delete'
                />
            </div>

            
        </div>

        </div>
    </>)
}
