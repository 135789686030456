import React from 'react'
import AddNutrientScheduleNutrients from './AddNutrientScheduleNutrients'
import { timestampToDate } from '../../renderless/helpers'
import ETGButton from '../../shared/global/ETGButton'


export default function EditNutrienScheduletView({ 
  addNutrientProgress,
  scheduleName,
  containerSize,
  schedulePhase,
  numberOfNutrients,
  numberOfWeeks,
  nutrients, setNutrients, activeNutrientObj, handleToggleSharePublicly, sharePublicStatus,
  scheduleDescription, setScheduleDescription }) {
    const [ inputLength, setInputLength ] = React.useState(0)
  
    const handleScheduleDescriptionChange = (event) => {
      const input = event.target.value;
      setInputLength(input.length);
  
      if (input.length <= 200) {
        setScheduleDescription(input);
      } else {
        const shortenedInput = input.slice(0, 200);
        setScheduleDescription(shortenedInput);
        alert('Input is too long! Maximum length is 200 characters.');
      }
    };


  const displayNutrientsInputs = () => {
    // go through a loop, each time I do, output the component for the number of nutrients user selected in stepA
    // use nutrientInSchedule for variable for index
    let returnValue = []
    for (let index = 0; index < numberOfNutrients; index++) {
      returnValue.push(<AddNutrientScheduleNutrients 
        nutrients={nutrients} setNutrients={setNutrients} 
        numberOfWeeks={numberOfWeeks} numberOfNutrients={numberOfNutrients}
        indexOfNutrient={index}
        key={`addNutrientSchedule${index}`}
      />)
      
    }
    return returnValue
  }
  
  return (<>
    <div className='flex flex-col px-2 text-black'>
      {/* SECTION with brief summary/description for entering a nutrient schedule */}
      <section className='w-8/12 mx-auto py-4'>
        {(addNutrientProgress === 'stepB') && <>
          <div className='text-center border-2 border-black/25 p-2 rounded-md'>
            <p>Designing a, <span className='font-medium'>{schedulePhase}</span> nutrient schedule named, <span className='font-medium'>{scheduleName}</span>.</p>
            <p>
              <span className='font-medium'>{numberOfNutrients}</span> nutrients for <span className='font-medium'>{numberOfWeeks}</span> weeks in 
              <span className='font-medium'> {containerSize}</span> gallon containers.              
            </p>          
            <p>
              Created: <span className='font-medium'>{ timestampToDate(activeNutrientObj.dateCreated) }</span>              
            </p>
            <p>
              Modified: <span className='font-medium'>{ timestampToDate(activeNutrientObj.dateModified)}</span>
            </p>
            
            
            {/* toggle button for sharing publicly */}
            <div className='flex justify-center items-center border-2 border-slate-400 mt-2 rounded-xl'>            
              Share Schedule Publicly: 
              <ETGButton
                onClickFunction={handleToggleSharePublicly}
                btnText={sharePublicStatus ? 'ON' : 'OFF'}
              />              
            </div>
            {/* input for a breif description */}
          <div className='flex flex-col py-4 w-full items-center'>
            <label>Brief Description: </label>
            <textarea
              type="text"
              className='w-10/12 rounded-xl p-1'              
              placeholder=''
              outline="false"
              rows={3}
              value={scheduleDescription}
              onChange={handleScheduleDescriptionChange}
            />
            <span className='flex'>{inputLength}/200</span>
          </div>
          </div>

        </>}
      </section>
      
      


      {/* SECTION for entering details of each nutrient within schedule - stepB */}
      {(addNutrientProgress === 'stepB') && <>
        <section>            
          <div className=''>
            {displayNutrientsInputs()}
          </div>
        </section>
      </> }
    </div>
  </>)
}
