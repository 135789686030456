import { useState } from 'react'
import { gaEvent } from '../components/renderless/helpers';
import useAdminSupportTask from './useAdminSupportTask';
import { timestamp } from '../firebase/config';
import { v4 as uuidv4 } from 'uuid';


export const useErrorHandiling = () => {
  const [ etgErrorDetected, setEtgErrorDeted ] = useState(false);    
  const [ status, setStatus ] = useState('pending')
  const [ errMessage, setErrMessage ] = useState('An error has been detected')

  const { addTask } = useAdminSupportTask();
  
  async function updateErrorData( errDescription, errUserID, errDetailedDescription = null ) {
    setEtgErrorDeted(!etgErrorDetected);
    setErrMessage(errDescription)
    gaEvent('error', errDescription, errUserID)      


    const date = new Date()
    const dateTime = new timestamp(Math.round(date.getTime()/1000), 0)
    const adminTask = {
      adminNotes: [],
      category: 'Bug',
      errDetailedDescription,
      dateTimeLastUpdated: dateTime,
      dateTimeSubmitted: dateTime,
      dateTimeCompleted: null, 
      description: `Error: ${errDescription}`,
      priority: 'Critical',
      status: 'Submitted',
      submittedByUID: errUserID,
      taskID: uuidv4(),
      title: `Error: ${errDescription}`,
    }

    // const result = newSupportRequest('Error', errDescription, errUserID)
    try {
      await addTask(adminTask)
    } catch (error) {
      gaEvent('errorHandiling failed', errDescription, errUserID)      
    }
    
    setStatus('success')    
  }

  

  return { etgErrorDetected, status, errMessage, updateErrorData }
}

