import { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';

const useStrainNameSuggest = () => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState(new Set()); // Initialize as a Set

  // Define an array of inappropriate keywords/phrases
  const inappropriateKeywords = [
    'bitch',
    'fuck',
    'shit',
    'sex',
    'dick',
    'penis',
    'vagina'
    // Add more inappropriate keywords/phrases as needed
  ];

  useEffect(() => {
    // Implement Firestore query logic here based on inputValue
    const queryStrainNames = async () => {
      try {
        if (inputValue) {
          const querySnapshot = await projectFirestore
            .collection('plants')
            .where('strain', '>=', inputValue)
            .where('strain', '<=', inputValue + '\uf8ff')
            .limit(5) // Limit the number of suggestions
            .get();

          // Create a Set to store unique suggestions
          const uniqueSuggestions = new Set();

          querySnapshot.docs.forEach((doc) => {
            const trimmedStrainName = doc.data().strain.trim();
            
            // Check if the suggestion contains any inappropriate keywords/phrases
            const isSuggestionInappropriate = inappropriateKeywords.some((keyword) =>
              trimmedStrainName.toLowerCase().includes(keyword.toLowerCase())
            );

            // Add the suggestion to uniqueSuggestions only if it's not inappropriate
            if (!isSuggestionInappropriate) {
              uniqueSuggestions.add(trimmedStrainName);
            }
          });

          setSuggestions(uniqueSuggestions);
          console.log('suggestions', Array.from(uniqueSuggestions)); // Log the unique suggestions
        } else {
          setSuggestions(new Set()); // Clear suggestions if the input is empty
        }
      } catch (error) {
        console.error('Error querying Firestore:', error);
      }
    };

    queryStrainNames();
  }, [inputValue]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleSuggestionClick = (selectedSuggestion) => {
    setInputValue(selectedSuggestion);
    setSuggestions(new Set()); // Clear suggestions after selection
  };

  return {
    inputValue,
    suggestions: Array.from(suggestions), // Convert Set to array for rendering
    handleInputChange,
    handleSuggestionClick,
  };
};

export default useStrainNameSuggest;
