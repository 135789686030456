import React from 'react'

export default function NoteDateDayOfDisplay({ toggleDateDisplay, dayOf }) {
  return (<>
    <div 
      className='flex flex-col items-center text-lg font-medium border-2 border-violet-800 rounded-md p-1'
      onClick={() => toggleDateDisplay()}
      >
      {dayOf()}             
    </div>
  </>)
}
