import { projectFirestore, timestamp } from '../../../firebase/config';

export async function retrievePosts(beginDate, endDate, loadAll = false) {
  try {
    let snapshot;
    if (!loadAll) {
    snapshot = await projectFirestore
      .collection('socialPosts')
      .where('dateTimePosted', '>=', timestamp.fromDate(beginDate))
      .where('dateTimePosted', '<=', timestamp.fromDate(endDate))
      .get();
    } else {
      snapshot = await projectFirestore
      .collection('socialPosts')
      .get();
    }

    const socialPosts = snapshot.docs.map((doc) => doc.data());

    return socialPosts;
  } catch (err) {
    console.error('Error retrieving posts:', err);
    return [];
  }
}

export default retrievePosts;
