import React from 'react'
import ETGButton from '../../shared/global/ETGButton'
import { useFirestore } from '../../../hooks/useFirestore'
import { testAboutMeInput } from '../helpers/testAboutMeInput'
import { useKeyPress } from '../../../hooks/useKeyPress'
import SaveInProgress from '../../shared/global/SaveInProgress'

export default function EditAboutMeView({ handleProfileModalToggle, profileDocument }) {
    const [ placeholder, setPlaceholder ] = React.useState(profileDocument.aboutMe)        
    const [ aboutMe, setAboutMe ] = React.useState(profileDocument.aboutMe)
    const [ isLoading, setIsLoading ] = React.useState(false)
    const { updateDocument, response } = useFirestore('growProfiles')    


    const { keyPressed } = useKeyPress('Escape')
    if (keyPressed) {
        handleProfileModalToggle()
    }

    function handleClearAboutMe () {        
        setAboutMe('')
        setPlaceholder('')
    }
    function handleResetAboutMe () {
        setAboutMe(profileDocument.aboutMe)
        setPlaceholder(profileDocument.aboutMe)
    }

    const handleInputChange = ( aboutMeInput ) => {                    
        const results = testAboutMeInput( aboutMeInput )
        // if passes validation update about me - if fails, clear about me
        if (results.validationPassed) { 
            setAboutMe(aboutMeInput)        
        } else { 
            alert('Prohibited Input Detected')
            setAboutMe(results.aboutMeInput)
            setAboutMe('')
        }
    }

    const handleSaveAboutMe = async () => {        
        setIsLoading(true)
        // clone and update document
        let clonedProfile = Object.assign({}, profileDocument)        
        clonedProfile.aboutMe = aboutMe    
        // save document
        updateDocument(profileDocument.uid, clonedProfile)                        
        if (response.error) {
            // throw error
        } else {
            handleProfileModalToggle()
        }
        setIsLoading(false)
    }    
        

  return (<>
    {/* DisplayName Field */}
    <div className="flex flex-col w-10/12 self-center text-black">
        <div className="flex flex-col items-center font-lg py-4 mt-4">                    
            {/* rules for the display name */}
            <div className='flex flex-col my-4 p-2'>
                <div className='etg-userText text-center pt-2 bg-gray-300 rounded-md mx-4 p-2'>
                    <p className='py-1'>
                        This is your chance to introduce yourself to the rest of the <span className='etg-ectorGrowText'>EctorGrow</span> commmunity. 
                    </p>
                    <p className='py-1'>
                        Here are a few suggestions
                        to inspire your 'About Me' section:
                    </p>
                    {/* list items */}
                    <div className='pt-4 etg-systemText'>                        
                        <ul className='flex flex-col gap-2'>
                            <li>how long have you been growing</li>
                            <li>soil or hydro - auto or photosynthesis</li>
                            <li>why did you start growing</li>
                            <li>favorite or preferred strains</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex flex-col bg-gray-300 w-full justify-center text-black rounded-md my-8 p-2 pt-4">            
            <label className='etg-sectionTitle text-center pb-6'>About Me</label>
            <textarea                
                cols='30'
                rows='5'
                placeholder={placeholder}  
                value={aboutMe}                              
                className='etg-userText p-2 rounded-lg w-10/12 md:w-full self-center shadow-xl'
                onChange={(e) => handleInputChange(e.target.value)}
            />
            <div className='flex justify-around pt-2'>
                <div className='flex justify-center'>
                    <ETGButton btnText='Clear' type='edit' onClickFunction={() => handleClearAboutMe()} />
                </div>
                <div className='flex justify-center'>
                    <ETGButton btnText='Reset' type='edit' onClickFunction={() => handleResetAboutMe()} />
                </div>

            </div>
            <span className='self-end etg-systemText pt-6'> {aboutMe.length} | 120</span>            
        </div>
        {/* Buttons for Save/Cancel */}
        <div className='flex flex-col md:flex-row justify-around my-4'>

            {/* conditional save buttons */}
            { isLoading ? <SaveInProgress message='Saving About Me' /> : <>
            <ETGButton btnText='Save About Me' onClickFunction={handleSaveAboutMe} />
            {/* cancel buttons */}
            <ETGButton btnText='Cancel' type='delete' onClickFunction={() => handleProfileModalToggle()} />
            </>}
        </div>
  </div>
  </>
  )
}
