import React from 'react';


export default function AccountFeaturesandFree() {
    


    return <section className=" bg-gray-200 text-gray-600 tracking-wider text-center body-font rounded-xl -mt-2">
        <div className="container px-5 py-20 mx-auto">
            <div className="flex flex-col text-center w-full mb-10">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 uppercase">Benefits all users enjoy</h1>
                <p className="lg:w-2/3 mx-auto leading-relaxed text-lg md:text-xl">
                All users can track their plant's growth using the growlog, upload images, engage in social discussions, and share their progress. Pro users enjoy additional benefits, including enhanced growlog features, increased account limits, and more comprehensive plant recording and tracking.
                </p>
            </div>                    
            <div className="flex flex-wrap justify-around">
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 m-1">
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">GrowLogs for every plant</h2>
                    <p className="leading-relaxed text-base mb-4">
                    EctorGrow makes it easy to track important Plant events such as Trimming, Training, Watering, Nutrient Resets, your plants Aroma, Harvest Reviews, personalzied notes and more
                    </p>

                </div>
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 m-1"> 
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Image upload and tracking</h2>
                    <p className="leading-relaxed text-base mb-4">
                    Images you upload are also added to your Growlog, making it easy (and a pleasure) to see the events you've tracked and the imapct on your plants as they grow
                    </p>

                </div>
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 m-1"> 
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Information & Support</h2>
                    <p className="leading-relaxed text-base mb-4">
                        EctorGrow is designed for any expereince level and offers specfic information based on what your plants need during all phases of the growing process
                    </p>
                </div>
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 m-1"> 
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Nutrient Schedules</h2>
                    <p className="leading-relaxed text-base mb-4">
                        Easily upload your Nutrient Schedule or find an existing one from the EctorGrow Community. Every Schedule allows you to specify a Vegetative and Flower Nutrient Schedule
                    </p>
                </div>
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 m-1"> 
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Nutrient Schedules Reminder Emails</h2>
                    <p className="leading-relaxed text-base mb-4">
                    Once you start tracking your plants nutrients, EctorGrow will automatically email reminders when its time to reset your plant nutrients
                    </p>
                </div>
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 m-1"> 
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Details & Definitions</h2>
                    <p className="leading-relaxed text-base mb-4">
                        <span className='font-bold'>Active Plants:</span> Plants in the Vegetative or Flower phase are considered Active.<br />
                        <span className='font-bold'> Custom Nutrient Schedules:</span> Each custom nutrient schedule includes a schedule for both Vegetative & Flower phases of growth<br />
                        <span className='font-bold'> Plant or Image limits</span> Free accounts have some user limits, however Plants or images can always be deleted to allow new uploads
                    </p>
                </div>
            </div>            
            <div>
                <p>These features and more are available to all users of EctorGrow</p>
            </div>
        </div>
    </section>;
}
