import React, { useState } from 'react';
import DefaultFooter from '../components/DefaultFooter';
import DefaultNavbar from '../components/DefaultNavbar';
import { useParams } from 'react-router-dom'
import LoadingDiv from '../components/LoadingDiv';
import ProcessingDiv from '../components/ProcessingDiv'
import { GApageView } from '../components/renderless/helpers';
import { loadDisplayNames } from '../components/renderless/GetData/loadDisplayNames';
import PlantCard from '../components/grows/plantCard/PlantCard';
import { useKeyPress } from '../hooks/useKeyPress';
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';
import GrowsModalMain from '../components/grows/modals/GrowsModalMain';
import ETGButton from '../components/shared/global/ETGButton';
import { growsPlantDataProcessing } from '../components/grows/helpers/growsPlantDataProcessing';
import usePlantData from '../hooks/usePlantData';
import { useProfileDocument } from '../hooks/useProfileDocument';
import DashboardMain from '../components/grows/Dashboard/DashboardMain';
import fetchNutrientSchedulesData from '../components/renderless/GetData/fetchNutrientSchedulesData';
import { getAuth } from 'firebase/auth';





export default function Grows({ user }) {
  const { etgErrorDetected, updateErrorData } = React.useContext(ErrorHandilingContext)   
  const [ currentPlantID, setCurrentPlantID ] = React.useState('unset')
  const [ currentPhase, setCurrentPhase ] = React.useState('Vegetative')
  const [ growsModal, setGrowsModal ] = React.useState(false)
  const [ currentPlantData, setCurrentPlantData ] = React.useState(null)
  const [ activeNutrientObj, setActiveNutrientObj ] = React.useState(null)
  const [ plantNotFound, setPlantNotFound ] = React.useState(null)
  

  const params = useParams()
  let urlParamsDisplayName = params.displayName   
  let urlParamsPlantID = params.plantIDToLoad



  // Render/Call only once
  // Google Analytics Logging
  React.useEffect(() => {
    if (urlParamsDisplayName) {
      GApageView(`grows/${urlParamsDisplayName}`)      
    } else {
      GApageView('grows')      
    }
    
  }, [urlParamsDisplayName])
  
    
  // BEGIN DATA LOADING SECTION
  const [ appData, setAppData] = useState({
    id: 'notloggedin',
    publicView: true,
    user
  })
  const [ viewDetermined, setViewDetermined ] = useState(false)
  const [ userDisplayNameIdArray, setUserDisplayNameIdArray ] = useState([])
  const [ ageCheckPass, setAgeCheckPass ] = useState(false)
  if  ((user) && (appData.id !== user.uid) && ( urlParamsDisplayName === undefined ) ) {
      // setId(user.uid)
      setAppData(appData => ({
        ...appData,
        id: user.uid
      }))
  }
  
  
  if(userDisplayNameIdArray.length === 0) {
    // load displayNames w/param true to return uid as well, needed for loading public views
    loadDisplayNames( true ).then(array => {         
      setUserDisplayNameIdArray([...array])       
      setUserDisplayNameIdArray(array)       
    })
  }
  
  
  // END DATA LOADING SECTION  
 
  // if urlParamsDisplayName is undefined && publicView is true -> public view is false, load page as normal
  if (urlParamsDisplayName === undefined && appData.publicView && !viewDetermined && appData.id !== 'notloggedin') {    
    // SCENARIO 1 - user viewinng own plants, private view, the default view
    setAppData(appData => ({
      ...appData,
      isOwner: true,
      id: user.uid,
      publicView: false,
      displayNameFound: true,
      verifyAge: false
    }))    
    // setId(user.uid)
    setViewDetermined(true)
  }

  // for scenarios where a displayName is passed as urlParam
  if ((urlParamsDisplayName !== undefined) && (userDisplayNameIdArray.length > 0) && !viewDetermined) {        
    userDisplayNameIdArray.forEach((displayNameIDPair) => {            
    // if urlParamsDisplayName match a displayName
    if (displayNameIDPair[0] === urlParamsDisplayName) {
      // setID to the matching id                
      setAppData(appData => ({
        ...appData,
        id: displayNameIDPair[1]
      }))        

      if(displayNameIDPair[0] === urlParamsDisplayName && !user) {
        // SCENARIO 2 - user not logged in, loading w/ params, loading public page IS NOT owner              
        setAppData(appData => ({
          ...appData,
          isOwner: false,
          publicView: true,
          displayNameFound: true,
          verifyAge: true
        }))                
      } else if ((user !== 'notloggedin') && displayNameIDPair[1] === user.uid) {
        // SCENARIO 3 - logged in, viewing own public page        
        setAppData(appData => ({
          ...appData,
          isOwner: true,
          publicView: true,
          displayNameFound: true,
          verifyAge: false
        }))                        

      } else {
        // SCENARIO 4 - logged in, w/ params, publicView true, IS NOT owner        
        setAppData(appData => ({
          ...appData,
          isOwner: false,
          publicView: true,
          displayNameFound: true,
          verifyAge: false
        }))                      
      }
    } else {      
      // handle no matching displayNames
      setAppData(appData => ({
        ...appData,
        displayNameFound: false
      }))      
    }     
  })
  setViewDetermined(true)  
    
  }  
  
  const currentUser = getAuth().currentUser
  const [ isPageLoading, setIsPageLoading ] = React.useState(true)
  const [ isProcessing, setIsProcessing ] = useState(false)
  const [ nutrientScheduleData, setNutrientScheduleData ] = useState(null)
  // Load data from firestore
  const { profileDocument } = useProfileDocument(appData.id)
  const  usersPlantData = usePlantData(appData.id)
  
  // Calculated State
  
  // Component &  Visibility State
  const [ appDisplayControl, setAppDisplayControl ] = useState({
    noteLogVisibility: 'h-0 invisible',
    listVisibility: 'visible',
    cardVisibility: 'invisible',
    nutrientVisibility: 'h-0 invisible',
    plantActionVisibility: 'h-0 invisible',
    infoSupportVisibility: 'h-0 invisible',
    plantCommentVisibility: 'h-0 invisible' 
  })  

  // Hook to capture key strokes for modals
  const closeModal = useKeyPress("Escape")
  // single line if statemenet to close editPlantModal on escape key stroke
  if (closeModal.keyPressed && growsModal !== null) {  
    setGrowsModal(null)  
  }  
  // useEffect to update appData with userPlantData
  React.useEffect(() => {   
    // function to update plantData correctly
    let processedPlantData = growsPlantDataProcessing(  usersPlantData )
    setAppData( appData => ({
      ...appData,    
      plantData: processedPlantData
    }))        
      
  }, [ usersPlantData])
  
  // useEffect to update appData with user profile data
  React.useEffect(() => {    
    // update appData with profileDocument
    setAppData( appData => ({
      ...appData,       
      profileData: profileDocument
    }))
    // if profileDocument is loaded, load nutrient schedule data
    if (profileDocument) {
      let totalNumSchedules = profileDocument.vegNutrients.length + profileDocument.flowerNutrients.length

      // if no schedules, set activeNutrientObj to empty obj
      if (totalNumSchedules === 0) {
        setNutrientScheduleData({
          vegNutrients: [],
          flowerNutrients: []
        })
        
      } else {
        // if schedules, load data
        let tempResult = fetchNutrientSchedulesData([...profileDocument.vegNutrients, ...profileDocument.flowerNutrients])
        tempResult.then(result => {
          if (result.error) {
            updateErrorData(result.error, user.uid)
          } else {
            setNutrientScheduleData(result.data)
          }
        })
      }
    }

  }, [profileDocument])    

  // useEffect to update currentPlantData with userPlantData
  React.useEffect(() => {
    if (appData.plantData && currentPlantID !== 'unset') {
      // find index of currentPlantID in activePlants array, if plant is in vegetative or flower state
      if (currentPhase === 'Vegetative' || currentPhase === 'Flower') {
        const plantIndex = appData.plantData.activePlants.findIndex(plant => plant.plantId === currentPlantID)        
        setCurrentPlantData(appData.plantData.activePlants[plantIndex])
      } else if (currentPhase === 'Harvested') {
        const plantIndex = appData.plantData.harvestedPlants.findIndex(plant => plant.plantId === currentPlantID)        
        setCurrentPlantData(appData.plantData.harvestedPlants[plantIndex])
      } else if (currentPhase === 'Archived') {
        const plantIndex = appData.plantData.archivedPlants.findIndex(plant => plant.plantId === currentPlantID)        
        setCurrentPlantData(appData.plantData.archivedPlants[plantIndex])
      }
    } else {
      setCurrentPlantData(null)
    }
  }, [ usersPlantData, currentPlantID, currentPhase])

    
  // function to handle new plant selection, passed a plant object as param to be loaded into activePlantData
  const handleNewPlantSelected = ( plant ) => {              

    // testing for new plantID state
    setCurrentPlantID(plant.plantId)
    setCurrentPhase(plant.status)
    setCurrentPlantData(plant)

    setAppDisplayControl( appDisplayControl => ({
      ...appDisplayControl, 
      cardVisibility: 'visible',
      nutrientVisibility: 'h-0 invisible',
      noteLogVisibility: 'h-0 invisible',
      plantActionVisibility: 'h-0 invisible',
      infoSupportVisibility: 'h-0 invisible',
      plantCommentVisibility: 'h-0 invisible' 
    }))
  
  }



  // conditional statement to handle loading a default plant, if no plant is selected, load the first plant in the array
  if (
    appData.plantData &&
    appData.plantData.activePlants &&
    (appData.plantData.activePlants.length > 0 ||
      appData.plantData.harvestedPlants.length > 0 ||
      appData.plantData.archivedPlants.length > 0) &&
    currentPlantID === 'unset'
  ) {

    const allPlants = [...appData.plantData.activePlants, ...appData.plantData.harvestedPlants, ...appData.plantData.archivedPlants]    
    // find index of the plant matching urlParamsPlantID.plantId with the urlParamsPlantID
    const plantIndex = allPlants.findIndex(plant => plant.plantId === urlParamsPlantID)
    if (plantIndex !== -1) {
        handleNewPlantSelected(allPlants[plantIndex])
      

    } else {

      // If there is a passed plantID in params but it is not found, set the plantNotFound state
      if (urlParamsPlantID) {
        setPlantNotFound(true)
      }
      
      const plantPhaseArray = {
        vegetativePlants: appData.plantData.activePlants
          .filter(plant => plant.status === 'Vegetative')
          .sort((a, b) => b.startDate.seconds - a.startDate.seconds || b.startDate.nanoseconds - a.startDate.nanoseconds),
        flowerPlants: appData.plantData.activePlants
          .filter(plant => plant.status === 'Flower')
          .sort((a, b) => b.transferDate.seconds - a.transferDate.seconds || b.transferDate.nanoseconds - a.transferDate.nanoseconds),
        harvestedPlants: appData.plantData.harvestedPlants
          .sort((a, b) => b.harvestDate.seconds - a.harvestDate.seconds || b.harvestDate.nanoseconds - a.harvestDate.nanoseconds),
        archivedPlants: appData.plantData.archivedPlants
          .sort((a, b) => b.harvestDate.seconds - a.harvestDate.seconds || b.harvestDate.nanoseconds - a.harvestDate.nanoseconds)
      };
      
    
      if (
        currentPhase === 'Vegetative' &&
        plantPhaseArray.vegetativePlants.length > 0
      ) {      
        handleNewPlantSelected( plantPhaseArray.vegetativePlants[plantPhaseArray.vegetativePlants.length - 1])
      }
    
      if (currentPhase === 'Flower' && plantPhaseArray.flowerPlants.length > 0) {      
        handleNewPlantSelected( plantPhaseArray.flowerPlants[plantPhaseArray.flowerPlants.length - 1])
      }
    
      if (
        currentPhase === 'Harvested' &&
        appData.plantData.harvestedPlants.length > 0
      ) {      
        handleNewPlantSelected( plantPhaseArray.harvestedPlants[plantPhaseArray.harvestedPlants.length - 1])
      }
    
      if (
        currentPhase === 'Archived' &&
        appData.plantData.archivedPlants.length > 0
      ) {            
        handleNewPlantSelected( plantPhaseArray.archivedPlants[plantPhaseArray.archivedPlants.length - 1])
      }
    }
    
  }
  

  
  

  // conditional statement to handle selecting a plant when the user swithces to a new plant phase, load the first plant in the array, or pass 'unset' to the handleNewPlantSelected function or load the plantData for the currentPlantID
  if ( currentPlantData !== null && currentPlantData !== undefined && currentPlantID !== 'unset' && currentPhase !== currentPlantData.status) {      
    // filter the plantData array to  return an array of plants in the currentPhase
    const plantPhaseArray = {
      vegetativePlants: appData.plantData.activePlants
        .filter(plant => plant.status === 'Vegetative')
        .sort((a, b) => b.startDate.seconds - a.startDate.seconds || b.startDate.nanoseconds - a.startDate.nanoseconds),
      flowerPlants: appData.plantData.activePlants
        .filter(plant => plant.status === 'Flower')
        .sort((a, b) => b.transferDate.seconds - a.transferDate.seconds || b.transferDate.nanoseconds - a.transferDate.nanoseconds),
      harvestedPlants: appData.plantData.harvestedPlants
        .sort((a, b) => b.harvestDate.seconds - a.harvestDate.seconds || b.harvestDate.nanoseconds - a.harvestDate.nanoseconds),
      archivedPlants: appData.plantData.archivedPlants
        .sort((a, b) => b.harvestDate.seconds - a.harvestDate.seconds || b.harvestDate.nanoseconds - a.harvestDate.nanoseconds)
    };
    
    
  
    if (currentPhase === 'Vegetative') {          
      // if array has plants, add a default, otherwise, set to 'unset'
      if (plantPhaseArray.vegetativePlants.length > 0) {       
        // check if currentPlantID is in the array, if so, load it, otherwise, load the first plant in the array
        const plantIndex = plantPhaseArray.vegetativePlants.findIndex(plant => plant.plantId === currentPlantID)
        if (plantIndex !== -1) {
          handleNewPlantSelected(plantPhaseArray.vegetativePlants[plantIndex])
        } else {          
          handleNewPlantSelected(plantPhaseArray.vegetativePlants[plantPhaseArray.vegetativePlants.length - 1])
        }      
      } else {
        setCurrentPlantID('unset')
      }
    } else if (currentPhase === 'Flower') {      
      if (plantPhaseArray.flowerPlants.length > 0) {        
        const plantIndex = plantPhaseArray.flowerPlants.findIndex(plant => plant.plantId === currentPlantID)
        if (plantIndex !== -1) {
          handleNewPlantSelected(plantPhaseArray.flowerPlants[plantIndex])
        } else {          
          handleNewPlantSelected(plantPhaseArray.flowerPlants[plantPhaseArray.flowerPlants.length - 1])
        }          
      } else {        
        setCurrentPlantID('unset')
      }
    } else if (currentPhase === 'Harvested') {
      if (plantPhaseArray.harvestedPlants.length > 0) {
        // check if currentPlantID is in the array, if so, load it, otherwise, load the first plant in the array
        const plantIndex = plantPhaseArray.harvestedPlants.findIndex(plant => plant.plantId === currentPlantID)
        if (plantIndex !== -1) {
          handleNewPlantSelected(plantPhaseArray.harvestedPlants[plantIndex])
        } else {          
          handleNewPlantSelected(plantPhaseArray.harvestedPlants[plantPhaseArray.harvestedPlants.length - 1])
        }
      } else {
        setCurrentPlantID('unset')
      }
    }
    else if (currentPhase === 'Archived') {
      if (plantPhaseArray.archivedPlants.length > 0) {
        // check if currentPlantID is in the array, if so, load it, otherwise, load the first plant in the array
        const plantIndex = plantPhaseArray.archivedPlants.findIndex(plant => plant.plantId === currentPlantID)
        if (plantIndex !== -1) {
          handleNewPlantSelected(plantPhaseArray.archivedPlants[plantIndex])
        } else {                        
        handleNewPlantSelected(plantPhaseArray.archivedPlants[0])
        }
      } else {
        setCurrentPlantID('unset')
      }
    }
  }

  // React useEffect, if app.plantData changes, update the currentPlantData
  React.useEffect(() => {
    if (appData.plantData && appData.plantData.activePlants && appData.plantData.activePlants.length > 0 && currentPlantID !== 'unset') {
      const plantIndex = appData.plantData.activePlants.findIndex(plant => plant.plantId === currentPlantID)
      if (plantIndex !== -1) {
        setCurrentPlantData(appData.plantData.activePlants[plantIndex])
      }
    }
    
  }, [appData.plantData])

  

  // page verification that all data is loaded before rendering and dismissing the loading div
  if (isPageLoading && appData.profileData && appData.plantData ) {        
    setIsPageLoading(false)        
  }
  
  // used when processing data and wanting to cover the entire plantCard
  if (isProcessing) {
    return <ProcessingDiv />
  }
 
  // if the displayName was not found
  if(!appData.displayNameFound && profileDocument === null && viewDetermined) {    
    return <ProcessingDiv 
      type='NotFound' 
      processRing={false} 
      message={`The display name entered, ${urlParamsDisplayName}, did not match any current Display Names... `}
    />
  }

  // copied from profile page for nutrient schedules - load default info
  if (!activeNutrientObj && profileDocument && nutrientScheduleData && ( nutrientScheduleData.vegNutrients.length > 0 || nutrientScheduleData.flowerNutrients.length > 0 )  ) {
        
    if (profileDocument.vegNutrients.length > 0 ) {
        setActiveNutrientObj(nutrientScheduleData.vegNutrients[0])
    } else if (profileDocument.flowerNutrients.length > 0 ) {
        setActiveNutrientObj(nutrientScheduleData.flowerNutrients[0])
    } else {
        setActiveNutrientObj({
            scheduleName: 'NO SCHEDULES CREATED YET',
            emptyObj: true
        })
    }
}
  
  
  // If user = Scenario 2 (when determining view) since there is no user, verify age
  if (appData.verifyAge && !ageCheckPass) {
    return <ProcessingDiv message="Please Verify You Are 18+ Years Old" type='ageVerification' processRing={false} controlBoolean={setAgeCheckPass} />    
  }
  

  return (
    <> { etgErrorDetected ? null :       
    <section className='flex flex-col h-screen justify-between'>                      
      {/* Page & Component Display */}
      <div className='w-full z-20'>        
          <DefaultNavbar 
            user={user}
            profileData={appData.profileData}            
          />
      </div>
      {/* V2 Page Modals */}            
      <div className='flex flex-col' id='grows-modal'>
          { growsModal ? <GrowsModalMain 
            growsModal={growsModal} 
            setIsProcessing={setIsProcessing}
            setGrowsModal={setGrowsModal} 
            profileDocument={profileDocument} updateErrorData={updateErrorData}                         
            appData={appData} setAppData={setAppData} currentPlantData={currentPlantData} currentPhase={currentPhase}
            handleNewPlantSelected={handleNewPlantSelected}
            setCurrentPhase={setCurrentPhase}
            appDisplayControl={appDisplayControl} setAppDisplayControl={setAppDisplayControl}
            activeNutrientObj={activeNutrientObj} setActiveNutrientObj={setActiveNutrientObj}
            setNutrientScheduleData={setNutrientScheduleData} nutrientScheduleData={nutrientScheduleData}
          /> 
          : null }          
      </div>  
      {/* Page Specifc Content */}
      { isPageLoading  ? <LoadingDiv /> : 
      <main className='mb-auto w-full mx-auto'>
        { growsModal ? null : <>
          <section>
            {/* development of new grows dashboard */}
            { plantNotFound ? 
            <div className='flex justify-center my-4 py-2 bg-slate-200 text-red-800 font-medium tracking-wide text-2xl border-2 border-dashed border-red-500'>
              A PLANT YOU ATTEMPTED TO LOAD COULD NOT BE FOUND
              
            </div>
            
            : null }
            <DashboardMain
              currentUser={currentUser}
              appData={appData} setAppData={setAppData}
              currentPhase={currentPhase} setCurrentPhase={setCurrentPhase}   
              handleNewPlantSelected={handleNewPlantSelected}   
              currentPlantData={currentPlantData}        
              setGrowsModal={setGrowsModal}
              activeNutrientObj={activeNutrientObj} setActiveNutrientObj={setActiveNutrientObj}
              nutrientScheduleData={nutrientScheduleData}     
            />            
          </section>
          <section>
            {currentPlantData ? <PlantCard
              currentPlantData={currentPlantData} currentPhase={currentPhase}              
              appData={appData} setAppData={setAppData}
              updateErrorData={updateErrorData}              
              setGrowsModal={setGrowsModal}                                                                                                         
              appDisplayControl={appDisplayControl} setAppDisplayControl={setAppDisplayControl}    
              nutrientScheduleData={nutrientScheduleData}
              currentUser={currentUser}     
            /> : null}
            {/* if no locations or positions, show button to begin setting up your grow space - launch getting started */}
            {profileDocument && ((profileDocument.growLocations.length === 0)  || (profileDocument.growPositions.length === 0)) && appData.isOwner && !growsModal && !isPageLoading ?
              <div className='flex w-fit h-fit m-auto bg-white p-4 rounded-xl'>                
                <ETGButton onClickFunction={() => setGrowsModal('getting-started')} btnText='Set Up Your Grow Space'/>                
              </div>
            : null }
          </section>  
        </> }    
      </main> 
      }
      <DefaultFooter />
    </section>
    
    } </>
  )
}
