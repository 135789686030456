import React from 'react'
import ETGButton from '../../shared/global/ETGButton';
import { getAuth } from 'firebase/auth';
import { NavLink } from 'react-router-dom';


export default function DashboardHeader({ appData, setAppData }) {
    // function to copy public link to clipboard    
    const CopyPublicLink = () => {
        navigator.clipboard.writeText(`https://ectorgrow.com/grows/${appData.profileData.displayName}`);
        alert("link copied")
    }    

    // function to redirect to public profile
    function handleViewPublicProfile() {
        window.location.href = `/grows/${appData.profileData.displayName}`
    }

    

    const handleOwnerPublicToPrivateView = () => {
        setAppData(appData => ({
            ...appData,
            publicView: false
        }))  
    }

    


    const currentUser = getAuth().currentUser
      

  return (<>
    <section className='bg-slate-200 rounded-xl'>
        {/* Display if user is viewing their own grow */}
        { appData.publicView === false ? 
            <div className='flex flex-col  my-2 w-10/12 mx-auto pt-1 text-center text-black'>
                <div className='flex flex-col text-lg mx-auto px-2'>
                    <p className='pr-2'>
                        View Your Public Profile:
                    </p>
                    <p className='break-all'>
                        EctorGrow.com/grows/{appData.profileData.displayName}
                    </p>
                </div>
                <div className='flex space-between pt-4 px-8 mx-auto'>
                    
                    <div className='flex flex-row px-4 self-center' onClick={CopyPublicLink} >
                        <i className='material-icons'>save</i>                            
                        Copy Link
                    </div>
                    <div className='flex flex-row px-4 self-center' onClick={handleViewPublicProfile}>
                        <i className='material-icons'>open_in_browser</i>
                        View Now
                    </div>
                </div>        
            </div>    
        : <div className='flex flex-col  my-2 w-10/12 mx-auto pt-1 text-center text-black'>
            <p className='text-2xl md:text-4xl font-medium break-words py-2'>
                Viewing <span className='underline underline-offset-2'>{appData.profileData.displayName}'s</span> Grow
            </p>
            <p className='italic break-words text-sm text-gray-700'>{`http://EctorGrow.com/grows/${appData.profileData.displayName}`}</p>
        </div> }        

        {/* if not logged in / display marketing message */}
        { currentUser === null ? 
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-10 mx-auto">
                <div className="lg:w-2/3 flex flex-col justify-center items-center mx-auto">
                    <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900 p-2 pb-4 text-center">New to Ector Grow? Welcome! Ector Grow is here to help personal cannabis growers with the 
                    easiest way to track your personal grow, diagnose issues, show off your harvests, past and present, with anyone online!</h1>
                    <NavLink to='/'>
                    <span className="text-white bg-emerald-800 border-0 py-2 px-8 focus:outline-none hover:bg-emerald-900 rounded-xl text-lg mt-10 sm:mt-0"
                        >Learn more</span>
                    </NavLink>
                </div>
                </div>
            </section> : null }
        {/* display buttons to view logged in users personal or private view of their grow */}
        <div className='flex md:flex-row justify-evenly gap-4 pt-6'>                    
            {/* if owner, switch back to private view of grow */}
            {(appData.isOwner && appData.publicView) && <>          
            <ETGButton
                btnText='Return To The Personal View Of Your Grow'
                onClickFunction={handleOwnerPublicToPrivateView}
            />
            </>}        
            {/* {(!appData.isOwner && appData.publicView && currentUser !== null ) && <>          
                <NavLink to='/grows'>
                <div className='flex flex-col text-center'>                                       
                    <ETGButton 
                    btnText='Return To Your Grow'                           
                    />
                </div>
                </NavLink>
            </>} */}
        </div>
    </section>
  </>)
}
