import React, { useEffect, useState } from "react"
import { ErrorHandilingContext } from "../context/ErrorHandilingContext"
import { projectFirestore } from "../firebase/config"
let profileCollection = 'growProfiles'

export const useAppData = (id) => {
  const [ profileDocument, setProfileDocumennt ] = useState(null)
  const [ appDataError, setAppDataError ] = useState(false)
  const { updateErrorData } = React.useContext(ErrorHandilingContext)



  // realtime document data
  useEffect(() => { 
    if (appDataError) {
      return
    }
    if (id === 'notloggedin') {
      return
    }

    // Load Profile Data
    const profileRef = projectFirestore.collection(profileCollection).doc(id)        

    const unsubscribeProfile = profileRef.onSnapshot(snapshot => {
      // need to make sure the doc exists & has data
      if(snapshot.data()) {
        setProfileDocumennt({...snapshot.data(), id: snapshot.id})
        // setProfileDocumenntError(null)
      }
      else {
        setAppDataError(true)
        updateErrorData('Profile Data - no such document exists - useAppData', id)
      }
    }, err => {            
      setAppDataError(true)
      updateErrorData(err.message, id)
    })
        

    // Shared unsubscribe function
    const unsubscribe = () => {            
        // unsubscribePlant()
        unsubscribeProfile()
        
    }
    
    // unsubscribe on unmount
    return () => unsubscribe()

  }, [id, updateErrorData, appDataError])

  return { profileDocument }
}