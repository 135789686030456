import React from 'react';
import { NavLink } from 'react-router-dom';

export default function AboutFeatureExplainer() {
    

    return (
        <>
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className='flex justify-center pt-4 pb-8 text-3xl uppercase tracking-wider text-emerald-800 text-center'>
                        <h3>A little more about EctorGrow</h3>
                    </div>
                    <div className="flex flex-wrap -mx-4 -mb-10 text-center text-black">
                        {/* EctorGrow Community */}
                        <div className="sm:w-1/2 mb-10 px-4">
                            <div className="rounded-lg h-64 overflow-hidden">
                                <img 
                                    alt="content" 
                                    className="object-cover object-center h-full w-auto m-auto rounded-xl shadow-2xl" 
                                    src="https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FAbout%2FEctorGrow%20Community.jpeg?alt=media&token=0fbf25b4-f403-4d0a-9546-2dfc078092f5" 
                                />
                            </div>
                            <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">EctorGrow Community</h2>
                            <p className="leading-relaxed text-base py-2">
                                Join a network of passionate cultivators to share your journey, showcase your plants, access nutrient schedules, and connect with like-minded growers from around the world.
                            </p>
                            <p className="leading-relaxed text-base py-2">
                                Socials Posts are a great way to ask questions, share advice, and connect with other growers.
                            </p>
                            {/* Community Guidelines */}
                            <div className='mx-4'>
                                <h4 className='text-2xl pt-4 text-slate-800'>Community Guidelines</h4>
                                <ul className="list-disc list-inside etg-systemText py-4">
                                    <li className="mb-2">Respect Each Other: Treat fellow growers with kindness and respect. We're here to learn and share together.</li>
                                    <li className="mb-2">Stay on Topic: Keep discussions focused on cannabis cultivation, strains, growing techniques, and related topics.</li>
                                    <li className="mb-2">No Promotions or Spam: Avoid excessive self-promotion or spamming the community. We're here to learn and connect, not for advertising.</li>
                                    <li className="mb-2">Be Safe and Legal: Ensure all discussions and advice shared adhere to legal regulations surrounding cannabis in your region.</li>
                                    <li className="mb-2">No Offensive Content: Do not post offensive, discriminatory, or harmful content. We aim to maintain a positive and inclusive environment.</li>
                                    <li className="mb-2">Share Knowledge: Contribute your insights and experiences to help others grow successfully.</li>
                                    <li className="mb-2">Protect Privacy: Respect the privacy of others and avoid sharing personal information without consent.</li>
                                    <li>Report Issues: If you come across any issues or violations of these guidelines, please report them to the moderators for action.</li>
                                </ul>
                            </div>
                            <div className='flex justify-center'>
                                <NavLink to="/community" className="flex mx-auto mt-6 text-white bg-green-500 border-0 py-2 px-5 focus:outline-none hover:bg-green-600 rounded">Learn More</NavLink>
                            </div>
                        </div>

                        {/* EctorGrow Labels */}
                        <div className="sm:w-1/2 mb-10 px-4">
                            <div className="rounded-lg h-64 overflow-hidden">
                                <img 
                                    alt="content" 
                                    className="object-cover object-center h-full w-auto m-auto rounded-xl shadow-2xl" 
                                    src="https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FAbout%2FEctorGrow%20Labels.jpeg?alt=media&token=5492b2d7-e89e-48ab-84bb-0c461f962ee4" 
                                />
                            </div>
                            <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">EctorGrow Labels</h2>
                            <p className="leading-relaxed text-base py-2">
                                EctorGrow Labels – the perfect companion for sharing your cannabis cultivation journey with your in-person community!
                            </p>
                            <p className="leading-relaxed text-base py-2">
                                With EctorGrow Labels, you can now bring the rich history of your plant's growth to life. Whether you're labeling your jars, bags, or other containers, these physical labels feature a unique QR code linking your physical harvest to its digital history and grow log.
                            </p>
                            <div className='mx-4'>
                                <h4 className='text-2xl pt-4 text-slate-800'>EctorGrow Labels Are Great For</h4>

                                <p className='leading-relaxed text-base py-4'>
                                    <span className='font-medium'>Sharing Your Harvest</span>: cultivators love sharing their hard work with friends and family. With EctorGrow Labels, you can now easily share your harvests with others and give them a glimpse into your plant's history with just a quick scan of your EctorGrow Labels.
                                </p>
                                <p className='leading-relaxed text-base py-4'>
                                    <span className='font-medium'>Organizing a Diverse Harvest Collection</span>: If you've grown and collected a variety of strains from previous harvests, labeling those harvests with EctorGrow Labels makes it easy to quickly identify each strain and view its entire history with EctorGrow.
                                </p>
                                <p className='leading-relaxed text-base py-4'>
                                    <span className='font-medium'>Organizing Plants During Growth</span>: If you're growing multiple plants at once, EctorGrow Labels are a great way to keep track of each plant's progress throughout the grow cycle. Label your grow space and containers to easily identify each plant and simply scan the QR code to view it and manage its history.
                                </p>
                            </div>
                            <div className='flex justify-center'>
                                <NavLink to="/EctorGrowLabels" className="flex mx-auto mt-6 text-white bg-green-500 border-0 py-2 px-5 focus:outline-none hover:bg-green-600 rounded">Learn More</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
