import React, { useState, useEffect } from 'react';
import { determineWeightOutput, timestampToDate } from '../../renderless/helpers';
import { GrPowerCycle } from 'react-icons/gr';

export default function DashboardPlantPhaseList({
  currentPhase,
  handleNewPlantSelected,
  vegetativePlants,
  flowerPlants,
  harvestedPlants,
  archivedPlants,
  currentPlantData,
}) {
  const [plantPhaseList, setPlantPhaseList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5; // Number of plants to display per page

  const selected = 'bg-emerald-800 rounded-xl p-1 mx-4 text-xl font-medium';
  const notSelected = 'py-1 text-xl font-medium';

    //   function to determine the total number of plants in the current phase
    function determineTotalNumberOfPlants() {
        let totalNumberOfPlants = 0
        if (currentPhase === 'Vegetative') {
            totalNumberOfPlants = vegetativePlants.length
        } else if (currentPhase === 'Flower') {
            totalNumberOfPlants = flowerPlants.length
        } else if (currentPhase === 'Harvested') {
            totalNumberOfPlants = harvestedPlants.length
        } else if (currentPhase === 'Archived') {
            totalNumberOfPlants = archivedPlants.length
        }
        return totalNumberOfPlants
    }
    // determine the total number of pages
    function determineTotalNumberOfPages() {
        const totalNumberOfPlants = determineTotalNumberOfPlants()
        const totalNumberOfPages = Math.ceil(totalNumberOfPlants / pageSize)
        return totalNumberOfPages
    }


  // Determine the plants to display based on the current phase and page
  useEffect(() => {
    let plantsToDisplay = [];

    if (currentPhase === 'Vegetative') {
      plantsToDisplay = vegetativePlants;
    } else if (currentPhase === 'Flower') {
      plantsToDisplay = flowerPlants;
    } else if (currentPhase === 'Harvested') {
      plantsToDisplay = harvestedPlants;
    } else if (currentPhase === 'Archived') {
      plantsToDisplay = archivedPlants;
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // determine if the plant array has any elements/notes with a type lc-nutrient    
    const plantIsSevenDaysSinceLastReset = (plant) => {      
      const todayDate = new Date()         
      // find of there are any notes with a type of lc-nutrient
      let nutrientNotes = plant.noteLog.filter(note => note.type === 'lc-nutrient')
      // if there are no nutrient notes, return false
      if (nutrientNotes.length === 0) {        
        return false
      } else {
        // if there are nutrient notes, find the most recent one
        // let mostRecentNutrientNote = nutrientNotes.reduce((prev, current) => (timestampToDate(prev.date) > timestampToDate(current.date)) ? prev : current)
        let mostRecentNutrientNote = nutrientNotes.reduce((prev, current) => (prev.date > current.date) ? prev : current)        
        const dateOfLastReset = new Date(timestampToDate(mostRecentNutrientNote.date) )        

        // find the difference in time between today and the date of the last reset
        let differenceInTime = Math.abs(dateOfLastReset - todayDate)
        let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))              
        
        // if the most recent nutrient note is more than 7 days ago, return true        
        if (differenceInDays > 7) {          
          return true
        } else {
          return false
        }        
      }
    }

      

    

    const tempPhaseList = plantsToDisplay
      .slice(startIndex, endIndex)
      .map((plant) => (
        <li
          className={`${currentPlantData.plantId === plant.plantId ? selected : notSelected} flex justify-around items-center`}
          key={plant.plantId}
          onClick={() => handleNewPlantSelected(plant)}
        >
          {currentPhase === 'Vegetative' && (
            <>
              {plant.strain} | { plantIsSevenDaysSinceLastReset(plant) ? <span className='p-1 rounded-full bg-red-500/70 animate-pulse'><GrPowerCycle/></span> : 'Started ' + timestampToDate(plant.startDate)}
            </>
          )}
          {currentPhase === 'Flower' && (
            <>
              {plant.strain} | { plantIsSevenDaysSinceLastReset(plant) ? <span className='p-1 rounded-full bg-red-500/70 animate-pulse'><GrPowerCycle/></span> : 'Transitioned ' + timestampToDate(plant.transferDate)}
            </>
          )}
          {(currentPhase === 'Harvested' || currentPhase === 'Archived') && (
            <>
              {plant.strain} | {determineWeightOutput(plant.harvestYield)}
            </>
          )}
        </li>
      ));

    setPlantPhaseList(tempPhaseList);
  }, [
    currentPhase,
    handleNewPlantSelected,
    vegetativePlants,
    flowerPlants,
    harvestedPlants,
    archivedPlants,
    currentPlantData,
    currentPage,
  ]);

  // Go to the next page
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Go to the previous page
  const previousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  
  return (
    <>
      {currentPhase !== '' ? (
        <div className="flex flex-col w-full h-96 overflow-auto text-white text-center py-3 rounded-md shadow-xl">
          <div className="flex flex-col h-full">
            {currentPhase !== 'Archived' ? (
              <div className="py-2">
                <h4 className="text-2xl md:text-4xl font-bold">Current {currentPhase} plants</h4>
              </div>
            ) : (
              <div className="py-2">
                <h4 className="text-2xl md:text-4xl font-bold">Archived plants</h4>
              </div>
            )}

            {/* Display list of plants, if there are any */}
            <ul className="list-none text-base">{plantPhaseList}</ul>
            {/* Pagination */}
            <div className="flex justify-center mt-4">
              {currentPage > 1 ?
                <button className="mx-1 px-3 py-1 bg-gray-800 rounded-md text-white" onClick={previousPage}>
                  Previous
                </button>
              : null}
              {( determineTotalNumberOfPlants() > pageSize && currentPage < determineTotalNumberOfPages() )  ? 
                <button className="mx-1 px-3 py-1 bg-gray-800 rounded-md text-white" onClick={nextPage}>
                  Next
                </button>
              : null}
            </div>

            <div className="flex flex-col h-full">
              {/* Display message that current phase has no plants currently, if valid */}
              {currentPhase === 'Vegetative' && vegetativePlants.length === 0 ? (
                <p className="text-sm italic font-medium">No plants in this phase</p>
              ) : null}
              {currentPhase === 'Flower' && flowerPlants.length === 0 ? (
                <p className="text-sm italic font-medium">No plants in this phase</p>
              ) : null}
              {currentPhase === 'Harvested' && harvestedPlants.length === 0 ? (
                <p className="text-sm italic font-medium">No plants in this phase</p>
              ) : null}
              {currentPhase === 'Archived' && archivedPlants.length === 0 ? (
                <p className="text-sm italic font-medium">No plants in this phase</p>
              ) : null}

              {/* Display warning to users about Harvested plants being moved to Archives automatically */}
              {(currentPhase === 'Harvested' || currentPhase === 'Archived') && (
                <div className="pt-4 w-full mx-auto mt-auto">
                  <p className="text-sm italic font-medium">
                    After ninety days, Harvested plants are Archived and can no longer be edited
                  </p>
                </div>
              )}
            </div>

            
          </div>
        </div>
      ) : null}
    </>
  );
}
