// write a js function that will handle the batch deletion of a plant from the firestore collection 
// as well as deleting the plant images, the url of which are stored in the plant.imgArray array, where each element has a imgUrl

import { getAuth } from "firebase/auth";
import { projectFirestore, projectStorage } from "../../../firebase/config";

export async function handlePlantDeletion(plant) {
    const currentUser = getAuth().currentUser;
    let status = ''
    // only continue if currentUser.uid === plant.plantOwnerUID
    if (currentUser.uid !== plant.plantOwnerUID) {
        return;
    }


    try {
        // delete the plant images
        plant.imgArray.forEach((img) => {
            const imgRef = projectStorage.refFromURL(img.imgUrl);
            imgRef.delete();
        })
    
        // delete the plant document from the firestore collection
        const plantRef = projectFirestore.collection('plants').doc(plant.plantId);
        await plantRef.delete();     
        
        status = 'success';
    } catch (error) {
        console.log('error', error)
        status = 'error';
    }







    


  return status;
}