import React from 'react'
import LocationsAddEdit from '../../shared/growspace/LocationsAddEdit'
import PositionsAddEdit from '../../shared/growspace/PositionsAddEdit'
import ETGButton from '../../shared/global/ETGButton'
import { returnAccountLimits } from '../../../Data/TierData'
import { BiLink } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'



export default function DashboardGrowSpaceView({ appData, setActiveNutrientObj, setGrowsModal, nutrientScheduleData }) {
    const [ growSpaceView, setGrowSpaceView ] = React.useState('Locations')  
    const selected = 'bg-emerald-800 rounded-xl p-1 mx-4 text-xl font-medium'
    const notSelected = ''
    const userTierLimits = returnAccountLimits(appData.profileData.accessTier)

    const handleEditNutrientSchedule = (scheduleObj) => {
      // if appData.publicView is true, then return
      if (appData.publicView) return
      

      // set active nutrient object
      setActiveNutrientObj(scheduleObj)
      // set modal to edit nutrient schedule
      setGrowsModal('edit_nutrient')
    }    

    
    
    const displayVegSchedules = () => {
      return nutrientScheduleData.vegNutrients.map((schedule, index) => (
        <li
          onClick={() => handleEditNutrientSchedule(schedule)}
          key={`scheduleIndex${index}`}
          className="flex justify-center items-center"
        >
          {schedule.scheduleName} -{' '}
          <NavLink to={`/community/sc${schedule.nutrientScheduleID}`}>
          <span>
            <BiLink />
          </span>
          </NavLink>
        </li>
      ));
    };
    
  const displayFlowerSchedules = () => {
      let returnValue = []
      for (let index = 0; index < nutrientScheduleData.flowerNutrients.length; index++) {
          returnValue.push(<li 
            onClick={() => handleEditNutrientSchedule(nutrientScheduleData.flowerNutrients[index])} 
            key={`scheduleIndex${index}`}
            className='flex justify-center items-center'
            >
              {nutrientScheduleData.flowerNutrients[index].scheduleName} - <NavLink to={`/community/sc${nutrientScheduleData.flowerNutrients[index].nutrientScheduleID}`}>
              <span><BiLink /></span>
              </NavLink>
          </li>)            
      }
      return returnValue
  }    
  
  

  return (<>
    <section className='flex flex-col justify-evenly bg-emerald-700 shadow-xl rounded-xl overflow-auto'>        
      {/* two equal size columns */}
      <div className='flex flex-col md:flex-row overflow-auto'>
        {/* Column 1 */}
        {/* list of options - locations, position, nutrient schedules */}
        <div className="flex flex-col w-full items-strech text-white text-center py-3 rounded-md shadow-xl overflow-auto">

          <div className="py-2">
            <h4 className="text-2xl md:text-4xl font-bold">Define Your Grow Space</h4>
          </div>

          <div className="flex flex-col">
            <div className={(growSpaceView === 'Locations' ? selected : notSelected)} onClick={() => setGrowSpaceView('Locations')}>
              <p className="py-1 text-xl font-medium">Locations</p>          
            </div>

            <div className={(growSpaceView === 'Positions' ? selected : notSelected)} onClick={() => setGrowSpaceView('Positions')}>
              <p className="py-1 text-xl font-medium">Positions</p>          
            </div>

            <div className={(growSpaceView === 'Nutrient Schedules' ? selected : notSelected)} onClick={() => setGrowSpaceView('Nutrient Schedules')}>
              <p className="py-1 text-xl font-medium">Nutrient Schedules</p>          
            </div>


          </div>
          <div className='mt-auto  pt-4 mb-4'>
            <p>
              Add detailed descriptions of your grow space, to help you, and the community, understand your grow space 
              and progress.
            </p>
          </div>

              
          </div>

          {/* Column 2 */}
          <div className="flex flex-col h-96 w-full overflow-auto text-white text-center py-3 rounded-md shadow-xl">
            {/* Locations Display */}
            {growSpaceView === 'Locations' ?
              <div className="flex flex-col w-full text-white text-center py-3 rounded-md shadow-xl">
                <div className="py-2">
                  <h4 className="text-2xl md:text-4xl font-bold">Grow Locations</h4>
                </div>
                <LocationsAddEdit growerProfile={appData.profileData} publicView={appData.publicView} id={appData.id} />
            </div> : null }

            {/* Positions Display */}
            {growSpaceView === 'Positions' ?
              <div className="flex flex-col w-full text-white text-center py-3 rounded-md shadow-xl">
                <div className="py-2">
                  <h4 className="text-2xl md:text-4xl font-bold">Grow Positions</h4>
                </div>
                <PositionsAddEdit growerProfile={appData.profileData} publicView={appData.publicView} id={appData.id} />
            </div> : null }

            {/* Nutrient Schedules Display */}
            {growSpaceView === 'Nutrient Schedules' ?
            <div className='flex flex-col w-full text-white text-center py-3 rounded-md shadow-xl'>        
                <div className="py-2">
                  <h4 className="text-2xl md:text-4xl font-bold">Nutrient Schedules</h4>
                </div>
              {/* Veg Section */}
              <div className='border-2 border-slate-800/40 m-2 text-center bg-emerald-700/40 p-2 rounded-md'>
                  <p className=''>Vegetative Schedules</p>
                  <p className='text-sm border-b-2 border-emerald-800 mb-4'>{appData.profileData.vegNutrients.length} of {userTierLimits.nutrientLimit}</p>                        
                  <ul>
                      {displayVegSchedules()}
                  </ul>
              </div>        
              {/* Flower Section */}
              <div className='border-2 border-slate-800/40 m-2 text-center bg-emerald-800/50 p-2 my-4 rounded-md'>
                  <p className=''>Flower Schedules</p>
                  <p className='text-sm border-b-2 border-emerald-800 mb-4'>{appData.profileData.flowerNutrients.length} of {userTierLimits.nutrientLimit}</p>                        
                  <ul>
                      {displayFlowerSchedules()}
                  </ul>
              </div>     
              {/* Add Schedule Section - only if either veg or flower has room */}
              { !appData.publicView ? 
                <div className='flex flex-wrap justify-center'>
                  {((appData.profileData.vegNutrients.length < userTierLimits.nutrientLimit) || (appData.profileData.flowerNutrients.length < userTierLimits.nutrientLimit)) && <>                        
                      <ETGButton
                          onClickFunction={() => setGrowsModal('add_nutrient')}
                          btnText='Add A Nutrient Schedule'
                          textColor='white'
                      />
                  </>}
                  {/* Add another button to 'find public nutrient schedules' that redirects the user to /schedueles */}
                  <NavLink to='/community'>
                  <ETGButton
                      onClickFunction={() => {}}
                      btnText='Find Public Nutrient Schedules'
                      textColor='white'
                  />
                  </NavLink>

                </div>
              : null }
            </div> : null }
                


          </div>
        </div>
    </section>
  </>)
}
