import React from 'react'
import ETGButton from '../../shared/global/ETGButton'
import { timestamp } from '../../../firebase/config'
import { useUpdatePlantData } from '../../../hooks/useUpdatePlantData'

export default function PlantMovementView({ appData, currentPlantData,
setGrowsModal, locations, positions, setIsProcessing, updateErrorData }) {
    const { updatePlantData, updatePlantDataError } = useUpdatePlantData(appData.profileData)
    const [ locationEnd, setLocationEnd ] = React.useState(currentPlantData.location)
    const [ positionEnd, setPositionEnd ] = React.useState(currentPlantData.position)

    // HANDLE PLANT MOVEMENT
    const handlePlantMovement = async () => {      
        setIsProcessing(true)  
        
        // similar to edit, create new array with updated info        
        let todayDate = new Date()
        let dateOfPlantMovement = new timestamp(Math.round(todayDate.getTime()/1000), 0)
        const plantTransferredNote = {
            comment: 'Plant Movement',
            date: dateOfPlantMovement, 
            type: 'lc-move',
            locationStart: currentPlantData.location,
            positionStart: currentPlantData.position,
            locationEnd,
            positionEnd
        }
        
        let newPlant = {
            strain: currentPlantData.strain, 
            status: currentPlantData.status, 
            location: locationEnd,
            position: positionEnd,
            medium: currentPlantData.medium,
            harvestYield: currentPlantData.harvestYield,
            startDate: currentPlantData.startDate,
            transferDate: currentPlantData.transferDate,
            harvestDate: currentPlantData.harvestDate,
            noteLog: [...currentPlantData.noteLog, plantTransferredNote],
            plantId: currentPlantData.plantId,
            imgArray: currentPlantData.imgArray,
            vegNutrients: currentPlantData.vegNutrients, 
            flowerNutrients:  currentPlantData.flowerNutrients,
            plantOwnerUID: currentPlantData.plantOwnerUID
        }
        if (updatePlantDataError) {
            updateErrorData(updatePlantDataError, appData.id)            
        } else {
            await updatePlantData(newPlant)            
        }
        
        setGrowsModal(false)     
        setIsProcessing(false)   
    }
  
  
  return ( <>
    <section>
        <div className='flex justify-center py-3'>
            <div className='px-4 mx-auto text-black text-center'>   
                <div className='flex flex-col py-2'>
                    <h4 className='etg-sectionTitle'>
                        Current Location: {currentPlantData.location}
                    </h4>
                    <label className='etg-inputLabel text-black text-lg p-1'>
                        New Location
                    </label>
                    <select className='etg-selectInput p-2 rounded-xl' value={locationEnd} onChange={(e) => setLocationEnd(e.target.value)}>
                        {locations}
                    </select>
                </div>                                 

                <div className='flex flex-col py-2'>
                    <h4 className='etg-sectionTitle'>
                        Current Position: {currentPlantData.position}
                    </h4>                            
                    <label className='etg-inputLabel text-black text-lg p-1'>
                        New Position
                    </label>
                    <select className='etg-selectInput p-2 rounded-xl' value={positionEnd} onChange={(e) => setPositionEnd(e.target.value)}>
                        {positions}    
                    </select>                                          
                </div>                                                                                                          
            </div>
        </div>

        <div className="flex flex-col justify-evenly">
            <div className='flex flex-wrap pt-4 justify-center'>
                <div className="px-2">                        
                    <ETGButton
                    onClickFunction={handlePlantMovement}
                    icon='save'
                    btnText='Save Location/Position Change'
                    type='caution'
                />
                </div>
                <div className="px-2">                                                
                    <ETGButton
                        onClickFunction={() => setGrowsModal(false)}
                        icon='close'
                        btnText='Cancel'
                        type='delete'
                    />
                </div>
            </div>
        </div>                      
    </section>
  </>)
}
