import { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';



// Task Structure
// {
  // adminNotes: [{
  //   note: '',
  //   date: '',
  // }]
  // category: '', // 
  // dateTimeLastUpdated: '',
  // dateTimeSubmitted: '',
  // dateTimeCompleted: '',
  // description: '',
  // priority: '',
  // status: '',
  // submittedByUID: '',
  // taskID: '',
  // title: '',

const useAdminSupportTask = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    // Function to fetch tasks from Firestore
    const fetchTasks = async () => {
      try {
        const taskCollection = projectFirestore.collection('adminSupportTask');
        const snapshot = await taskCollection.get();
        const taskList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTasks(taskList);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    // Fetch tasks when the component mounts
    fetchTasks();
  }, []);

  // Function to add a new task
  const addTask = async (newTask) => {
    try {
      // add the new task to the firestore collection at the taskID field of the new task
      const taskCollection = projectFirestore.collection('adminSupportTask');
      await taskCollection.doc(newTask.taskID).set(newTask);      

      // Refresh the task list after adding a new task
      const updatedTasks = [...tasks, newTask];
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  // Function to update an existing task
  const updateTask = async (taskId, updatedTask) => {
    try {
      const taskCollection = projectFirestore.collection('adminSupportTask');
      await taskCollection.doc(taskId).update(updatedTask);
      // Refresh the task list after updating the task
      const updatedTasks = tasks.map(task =>
        task.id === taskId ? { ...task, ...updatedTask } : task
      );
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  // Function to delete a task
  const deleteTask = async (taskId) => {
    try {
      const taskCollection = projectFirestore.collection('adminSupportTask');
      await taskCollection.doc(taskId).delete();
      // Refresh the task list after deleting the task
      const updatedTasks = tasks.filter(task => task.id !== taskId);
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  return {
    tasks,
    addTask,
    updateTask,
    deleteTask
  };
};

export default useAdminSupportTask;
