import React from 'react'
import DatePicker from 'react-date-picker'
import ETGButton from '../../shared/global/ETGButton'
import { timestamp } from '../../../firebase/config'
import { useUpdatePlantData } from '../../../hooks/useUpdatePlantData'


export default function VegToFlowerView({ locations, positions, currentPlantData, setIsProcessing, 
    appData, setCurrentPhase, setGrowsModal, updateErrorData, handleNewPlantSelected }) {
    const [ locationEnd, setLocationEnd ] = React.useState(currentPlantData.location)
    const [ positionEnd, setPositionEnd ] = React.useState(currentPlantData.position)
    const [ transferDate, setTransferDate ] = React.useState()

    const { updatePlantData, updatePlantDataError } = useUpdatePlantData(appData.profileData)
    function dateBuilder(date) {        
        let dateString = new Date(date)    
        return dateString        
    }

    React.useEffect(() => {
        let todaysDate = new Date()
        todaysDate = new timestamp(Math.round(todaysDate.getTime()/1000), 0)   
        todaysDate = dateBuilder(todaysDate.toDate())
        setTransferDate(todaysDate)

    }, [])
    
        
    //transfer from veg to flower
    const handleTransfer = async () => {      
        setIsProcessing(true)          
        // similar to edit, create new array with updated info        
        let tempTransferDate = new timestamp(Math.round(transferDate.getTime()/1000), 0)
        // create an object representing the new note to be saved
        const plantTransferredNote = {
            comment: 'Transferred to Flower',
            date: tempTransferDate, 
            type: 'lc-transfer',
            locationStart: currentPlantData.location,
            positionStart: currentPlantData.position,
            locationEnd,
            positionEnd
        }
        // create an object representing the plant, and all updated values being saved
        let newPlant = {
            strain: currentPlantData.strain, 
            status: 'Flower', 
            location: locationEnd,
            position: positionEnd,
            medium: currentPlantData.medium,
            harvestYield: currentPlantData.harvestYield,
            startDate: currentPlantData.startDate,
            transferDate: tempTransferDate,
            harvestDate: currentPlantData.harvestDate,
            noteLog: [...currentPlantData.noteLog, plantTransferredNote],
            plantId: currentPlantData.plantId,
            imgArray: currentPlantData.imgArray,
            vegNutrients: currentPlantData.vegNutrients,
            flowerNutrients: currentPlantData.flowerNutrients,
            plantOwnerUID: currentPlantData.plantOwnerUID
        }
        


        await updatePlantData(newPlant)
        if (updatePlantDataError) {
            updateErrorData(updatePlantDataError, appData.id)
        } else {
            handleNewPlantSelected(newPlant)
        }
        
        
        setCurrentPhase('Flower')
        setGrowsModal(false)      
        setIsProcessing(false)  
    }


    


  return ( <>
    <section>
        {/* Plant Strain Name */}
        <div className='flex flex-col text-black text-center'>        
            <p className='pt-2 italic'>update the location, position and date of transfer</p>
        </div>
        <div className='flex flex-col w-full justify-center py-3'>
            <div className='px-4 mx-auto text-black text-center'>   
                <div className='flex flex-col py-2'>
                    <h4 className='etg-sectionTitle'>Update The Location</h4>
                    <label className='etg-inputLabel text-black text-lg p-1'>
                        Current Location: {currentPlantData.location}
                    </label>
                    <select className='etg-selectInput p-2 rounded-xl' value={locationEnd} onChange={(e) => setLocationEnd(e.target.value)}>
                        {locations}
                    </select>
                </div>                                 

                <div className='flex flex-col py-2'>
                    <h4 className='etg-sectionTitle'>Update The Position</h4>
                    <label className='etg-inputLabel text-black text-lg p-1'>
                        Current Position: {currentPlantData.position}
                    </label>
                    <select className='etg-selectInput p-2 rounded-xl' value={positionEnd} onChange={(e) => setPositionEnd(e.target.value)}>
                        {positions}    
                    </select>                                          
                </div>
                <div className='flex flex-col w-full py-4'>
                    <label className='etg-inputLabel text-black text-lg p-1'>
                        Transition Date: 
                    </label>
                    <div className='flex flex-col justify-center text-black text-center etg-sectionTitle'>
                        <DatePicker onChange={(e) => setTransferDate(e)} value={transferDate} />                                    
                        <span className='etg-systemText'>
                            the date can be updated from the grow log
                        </span>
                    </div>
                </div>                                                                        
            </div>
        </div>

        {/* section explaining when a plant transitions from veg to flower */}
        <div className='flex flex-col text-black text-center m-4 bg-emerald-800/30 rounded-xl shadow-xl p-4'>            

            {/* list of 5 bullet points describing transition */}
            <div className='flex flex-col justify-center w-8/12 mx-auto'>
                <div className='flex flex-col justify-center text-black text-center etg-sectionTitle'>What to look for:</div>
                    <ul className='etg-systemText tracking-wide'>
                        <li className='p-1'>Buds begin to form: Look for the emergence of small, white pistils at the nodes where the leaves meet the stem. These will develop into dense clusters of flowers or buds.</li>
                        <li className='p-1'>Changes in leaf structure: The leaves may become wider, thicker, and darker in color, and some of the lower leaves may yellow and fall off.</li>
                        <li className='p-1'>Height growth slows or stops: The plant will focus on developing its buds rather than growing taller.</li>
                        <li className='p-1'>Smell: During the early stages of flowering, cannabis plants can have a pungent smell described as sweet, fruity, or skunky.</li>
                        <li className='p-1'>Light cycle: Manipulating the light cycle by switching to a 12/12 light cycle is the most reliable way to trigger the plant to begin flowering.</li>
                    </ul>                
            </div>


            

            

        </div>
        

        {/* Save Buttons */}
        <div className="flex flex-col justify-evenly">
            <div className='flex flex-row pt-4 space-evenly mx-auto'>
                <div className="px-2">                        
                    <ETGButton
                    onClickFunction={handleTransfer}
                    icon='save'
                    btnText='Move to Flower'
                    type='caution'
                    />
                </div>
                <div className="px-2">                                                
                    <ETGButton
                        onClickFunction={() => setGrowsModal(false)}
                        icon='close'
                        btnText='Cancel'
                        type='delete'
                    />
                </div>
            </div>
        </div>                    
    </section>
  </>)
}
