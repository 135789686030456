import React from 'react'
import { timestamp } from '../../../firebase/config'
import { gaEvent } from '../../renderless/helpers'
import AddPlantModalInputs from './AddPlantModalInputs'
import ETGButton from '../../shared/global/ETGButton'
import { v4 as uuidv4 } from 'uuid'
import { useUpdatePlantData } from '../../../hooks/useUpdatePlantData'
import { getAuth } from 'firebase/auth'

export default function     AddPlantView({
    setGrowsModal, appData,
    handleNewPlantSelected, setCurrentPhase,
    setAppDisplayControl, setIsProcessing, updateErrorData,
    locations, positions, vegScheduleOptions, flowerScheduleOptions, getNutrientScheduleObj }) {
        const [ strain, setStrain ] = React.useState('')    
        const [ location, setLocation ] = React.useState(appData.profileData.growLocations[0])
        const [ position, setPosition ] = React.useState(appData.profileData.growPositions[0])
        const [ medium, setMedium ] = React.useState('Hydro')
        const [ vegNutrients, setVegNutrients ] = React.useState([])
        const [ flowerNutrients, setFlowerNutrients ] = React.useState([])
        const today = new Date()
        const [ startDate, setStartDate ] = React.useState(today)
        // set defautls so I can manage program later
        let noteLog = []
        const harvestYield = 0
        const transferDate = new Date('April 20, 2020')
        const harvestDate = new Date('April 20, 2020')
        const plantId = uuidv4()
        const imgArray = []
        const [ isSetup, setIsSetup ] = React.useState(false)
        
        
        console.log('appData.profileData', appData.profileData)
        console.log('vegNutrients', vegNutrients)
        console.log('flowerNutrients', flowerNutrients)

        // useEffect that runs on component load
        // if vegNutrients array is empty - and the users 
    
    const { updatePlantData, updatePlantDataError } = useUpdatePlantData(appData.profileData)
    const currentUser = getAuth().currentUser
    
        

    if((isSetup === false) && ((location === undefined) || (position === undefined))) {
        setIsSetup(true)
    }


    const handleSaveNewPlant = async () => {
        setIsProcessing(true)
        if(strain === '') {
            alert('Strain name cannot be empty!')
            return
        }
        
        // Create Plant Started Note
        const plantStartedNote = {
            comment: 'Plant Started',            
            date: new timestamp(Math.round(startDate.getTime()/1000), 0), 
            type: 'lc-started',
            locationStart: location, 
            positionStart: position
        }
        noteLog = [...noteLog, plantStartedNote]
                        


        gaEvent( 'Plant Action', 'New Plant Added', `User: ${appData.id}`)        
        let newPlant = {
            strain,
            status: 'Vegetative',
            location,
            position,
            medium,
            harvestYield,
            isPublic: true,
            startDate: new timestamp(Math.round(startDate.getTime()/1000), 0), 
            transferDate: new timestamp(Math.round(transferDate.getTime()/1000), 0),
            harvestDate: new timestamp(Math.round(harvestDate.getTime()/1000), 0),
            noteLog,
            plantCategory: 'General',
            plantId,
            imgArray,                  
            vegNutrients: vegNutrients.length ? vegNutrients[1] : 'none',
            flowerNutrients: flowerNutrients.length ? flowerNutrients[1] : 'none',
            plantOwnerUID: currentUser.uid
        }
        
        
        // upload new plant document to firestore using custom hook                
        try {
            await updatePlantData(newPlant)            
        } catch (error) {
            updateErrorData(error.message, appData.id)
        }
        if (updatePlantDataError) {
            updateErrorData(updatePlantDataError, appData.id)
        }
        setCurrentPhase(newPlant.status)
        handleNewPlantSelected(newPlant)
        
        //close modal after succesfully adding
        setAppDisplayControl( appDisplayControl => ({
            ...appDisplayControl,
            noteLogVisibility: 'h-full',
            plantActionsVisibility: 'h-0 invisible'
        }))
        setGrowsModal(null)        
        setIsProcessing(false)

    }
    

  return (
    <>                        
        <AddPlantModalInputs
            strain={strain} setStrain={setStrain}
            startDate={startDate} setStartDate={setStartDate}
            location={location} setLocation={setLocation}
            position={position} setPosition={setPosition}
            vegNutrients={vegNutrients} setVegNutrients={setVegNutrients}
            flowerNutrients={flowerNutrients} setFlowerNutrients={setFlowerNutrients}
            medium={medium} setMedium={setMedium}            
            locations={locations}
            positions={positions}
            vegScheduleOptions={vegScheduleOptions}
            flowerScheduleOptions={flowerScheduleOptions}
            getNutrientScheduleObj={getNutrientScheduleObj}       
        />
        
        <div className="flex justify-end">
            {!isSetup && 
            <div className="px-2">                
                <ETGButton 
                    onClickFunction={handleSaveNewPlant}
                    icon='add'
                    btnText='Add Plant'
                />
            </div>
            }
            <div className="px-2">                        
                <ETGButton 
                    onClickFunction={() => setGrowsModal(null)}
                    icon='close'
                    btnText='Cancel'
                    type='delete'
                />                
            </div>
        </div>        
    </>
  )
}
