import { projectFirestore } from '../../../firebase/config'

export async function retrieveSchedules(phases, mediums, loadAll = false) {
  
  // loadAll is used to load all schedules, regardless of phase and medium
  // this allows the schedules page to display the scheduleID from the URL - and its related schedules

  try {

    let snapshot;


    if (!loadAll) {
    snapshot = await projectFirestore
      .collection('nutrientSchedules')
      .where('phase', 'in', phases)      
      .where('sharePublicly', '==', true)
      .where('medium', 'in', mediums)
      .get();
    } else {
      snapshot = await projectFirestore
      .collection('nutrientSchedules')
      .where('sharePublicly', '==', true)
      .get();
    }

    
    const nutrientSchedules = [];

    snapshot.docs.forEach((doc) => {
      const nutrient = { ...doc.data() };

      nutrientSchedules.push(nutrient);
    });
         
    return nutrientSchedules
  } catch (err) {    
    return [];
  }


  
}

export default retrieveSchedules;
