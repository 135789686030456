import React from 'react'
import DefaultSlider from '../shared/Marketing/DefaultSlider'

export default function AboutIntroductionSection() {
  return (<>
    <div className='flex flex-col w-full items-center justify-center py-6'>
        <h1 className='etg-heading text-black text-6xl pb-3 text-center'>About EctorGrow</h1>
        <p className='text-xl text-gray-800 py-10 tracking-wider'>
            Welcome to EctorGrow, the perfect tool for tracking the growth of your personal cannabis! Our platform is designed to make it easy for you 
            to keep track of your plants' growth, from the very beginning to the final harvest.
        </p>
        {/* Image Slider */}
        <DefaultSlider textColor='text-white' />


        <div className='flex flex-col md:flex-row p-2 bg-emerald-800/30 rounded-xl shadow-xl'>
            {/* image */}
            <div className='flex flex-col py-8'>
                <h3 className='text-3xl text-black font-medium text-center p-1'>How Does EctorGrow Work?</h3>
                <img 
                    className='w-fit mx-auto rounded-2xl shadow-xl m-4' 
                    src='https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FAboutIntroCannabisFlower.jpg?alt=media&token=a7ae1741-6756-4df1-a250-abe262665093' 
                    alt='cannabis plant' 
                />
                {/* image credit */}
                <p className='text-xs text-center text-gray-800 py-3 px-4'>
                    Photo by <a href='https://unsplash.com/photos/WhFDyPpBG2E'>Diyahana Lewis</a> on Unsplash
                </p>
            </div>

            {/* text */}
            <div className='flex flex-col items-center justify-center'>
                <p className='text-lg text-center text-gray-900 p-4'>
                    Our app offers a range of features specifically designed to help you track your plants' progress, 
                    including the ability to take images, add notes, and track important events such as watering, training, nutrient schedules and more. 
                    With EctorGrow, you'll <span className="font-bold">never miss an important milestone in your plant's growth again</span>.
                </p>
                <p className='text-lg text-center text-gray-900 p-4'>
                    One of the unique benefits of EctorGrow is the ability to <span className="font-bold">publicly share your grow</span> with others and receive advice from the community. 
                    This feature allows you to connect with other cannabis growers, share tips and tricks, and get valuable feedback on your grow.
                </p>
                <p className='text-lg text-center text-gray-900 p-4'>
                    We understand that growing cannabis can be a challenging and rewarding experience, which is why we've designed our platform to be <span className="font-bold">as 
                    user-friendly as possible</span>. Whether you're a beginner or an experienced grower, our app is the perfect tool for helping you track your plants' growth and harvests.
                </p>
                <p className='text-lg text-center text-gray-900 p-4'>
                    So if you're looking for a way to keep track of your personal cannabis grow, look no further than EctorGrow. <span className="font-bold">Sign up today </span>  
                    and start tracking your plants' growth and sharing your journey with the community!    
                </p>        
            </div>

        </div>

    </div>
  </>)
}
