import React from 'react'
import {  } from 'react-icons/fa'
import { GrClose, GrEdit, GrAdd, GrStatusWarning, GrLike, GrFavorite, GrSave, GrFormTrash, GrSettingsOption } from 'react-icons/gr'
import { TiInfoLargeOutline } from 'react-icons/ti'
import { MdAddAPhoto } from 'react-icons/md'
import { RiExternalLinkFill } from 'react-icons/ri'


export default function ETGIconBtn({ 
    type = 'close', 
    onClickFunction,
    size = 'md'
    }) {

        
    // Default Type & Styling
    let buttonStyling = 'md:hover:bg-emerald-600/50 active:translate-y-1 md:active:bg-emerald-900'
    let icon = <GrClose />
    let btnSize = ''

    // general (default) -> emerald color
    if (type === 'add') {
        buttonStyling = 'md:hover:bg-emerald-600/50 active:translate-y-1 md:active:bg-emerald-900'
        icon = <GrAdd/>
    }

    // caution -> yellowish amber color, used as a caution button
    if (type === 'caution') {
        buttonStyling = 'md:hover:bg-amber-600/50 active:translate-y-1 md:active:bg-amber-900'
        icon = <GrStatusWarning />
    }

    // delete -> red color, used when deleting something    
    if (type === 'delete' || type === 'close') {
        buttonStyling = 'md:hover:bg-red-600/50 active:translate-y-1 md:active:bg-red-900'
        icon = <GrFormTrash />
    }

    // socialLike -> blue color, used when liking a comment, plant or post 
    if (type === 'socialLike') {
        buttonStyling = 'md:hover:bg-sky-600/50 active:translate-y-1 md:active:bg-sky-900'
        icon = <GrLike />
    }
    if (type === 'socialFavorite') {
        buttonStyling = 'md:hover:bg-sky-600/50 active:translate-y-1 md:active:bg-sky-900'
        icon = <GrFavorite />
    }

    // edit -> blue color, used with social interactions
    if (type === 'edit') {
        buttonStyling = 'md:hover:bg-green-600/50 active:translate-y-1 md:active:bg-green-600'
        icon = <GrEdit />
    }

    if (type === 'save') {
        buttonStyling = 'md:hover:bg-green-600/50 active:translate-y-1 md:active:bg-green-600'
        icon = <GrSave />
    }

    if (type === 'info') {
        buttonStyling = 'text-black md:hover:bg-blue-600/50 active:translate-y-1 md:active:bg-blue-600'
        icon = <TiInfoLargeOutline />
    }

    if (type === 'actions') {
        buttonStyling = 'text-black md:hover:bg-blue-600/50 active:translate-y-1 md:active:bg-blue-600'
        icon = <GrSettingsOption />
    }

    if (type === 'addImage') {
        buttonStyling = 'text-black md:hover:bg-blue-600/50 active:translate-y-1 md:active:bg-blue-600'
        icon = <MdAddAPhoto />        
    }

    if (type === 'inner-link') {
        buttonStyling = 'border-sky-800 bg-sky-600/50 hover:bg-sky-700/80 md:active:scale-75 md:active:bg-sky-800'
        icon = <RiExternalLinkFill />
    }
        
    
    



    // Size Switch
    switch (size) {
        case 'xs':
            btnSize = 'scale-50'
            break;
        case 'sm':
            btnSize = 'scale-75'
            break;            
        case 'lg':
            btnSize = 'scale-125'
            break;
        default:
            break;
    }
    

  return (
    <>
    <div className={`flex ${btnSize}`}>
        <button 
            className={`border-2 border-slate-800 rounded-full ${buttonStyling}`}
            onClick={onClickFunction} >
            
            <p className='flex justify-items-center gap-1 p-2'>                                    
                {icon}
            </p>
        
        </button>                
    </div>
        
    
    </>
  )
}
