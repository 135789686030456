import React from "react";

export default function AboutMissionValuesSection() {
    return (
    <div className='flex flex-col items-center justify-center py-6 bg-white rounded-xl'>
        <h1 className='text-3xl font-bold text-center text-gray-800'>Our Mission</h1>
        <p className='text-lg text-center text-gray-800 py-3 px-4'>
            At EctorGrow, our mission is to provide a platform that empowers personal cannabis growers to cultivate their plants 
            in a responsible and sustainable way. We believe that <span className="font-bold">cannabis has the potential to be a positive force in society</span> and 
            by promoting responsible growing and consumption practices, we hope to create a more informed 
            and educated community around cannabis.
        </p>
        <p className='text-lg text-center text-gray-800 py-3 px-4'>
            This means not only educating our users about how to cultivate their plants 
            safely and responsibly, but also educating the wider public about the potential benefits, negatives, and medicinal benefits of cannabis and its related compounds.
        </p>
        <p className='text-lg text-center text-gray-800 py-3 px-4'>
            We are committed to supporting unbiased cannabis research and advocating for cannabis justice reform, better regulation based on a modern understanding of cannabis 
            consumption and its surronding economy. By doing so, we hope to help break down the barriers that currently prevent people from accessing the <span className="font-bold">benefits of cannabis safely and responsibly</span>.            
        </p>




        {/* new section for displaying other orginizations with a similar mission or values */}
            {/* section of different orginizations */}
        <div className='flex flex-row justify-center'>
            <section className="text-gray-600 body-font">
            <div className="container px-2 py-24 mx-auto">
                <div className="flex flex-col text-center w-full mb-20">
                <h2 className="text-xs text-green-500 tracking-widest font-medium title-font mb-1">Supporting Positive Change</h2>
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Cannabis Advocacy and Reform</h1>
                <p className="lg:w-2/3 mx-auto leading-relaxed text-base">take a look at (and consider supporting) these orginizationsn that are working to promote cannabis reform and education.</p>
                </div>
                <div className="flex flex-wrap">
                
                
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">NORML</h2>
                    <p className="leading-relaxed text-base mb-4">NORMLs mission is to move public opinion sufficiently to legalize the responsible use of marijuana by adults, and to serve as an advocate for consumers to assure they have access to high quality marijuana that is safe, convenient and affordable.</p>
                    <a 
                        className="text-green-500 inline-flex items-center"
                        href="https://norml.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >Learn More
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                    </a>
                </div>

                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Last Prisoner Project</h2>
                    <p className="leading-relaxed text-base mb-4">The Last Prisoner Project (LPP) is a national, nonpartisan nonprofit dedicated to cannabis criminal justice reform.</p>
                    <a 
                        className="text-green-500 inline-flex items-center"
                        href="https://www.lastprisonerproject.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >Learn More
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                    </a>
                </div>
                
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">On Something Podcast</h2>
                    <p className="leading-relaxed text-base mb-4">A podcast exploring the political, legal and cultural effects of marijuana legalization. From Colorado Public Radio and PRX, these are stories about life after legalization, hosted by journalist Ann Marie Awad.</p>
                    <a 
                        className="text-green-500 inline-flex items-center"
                        href="https://onsomething.org/?_ga=2.87143643.271337146.1681951341-1939574358.1681951340"
                        target="_blank"
                        rel="noopener noreferrer"
                    >Learn More
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                    </a>
                </div>
                

                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Realm of Caring</h2>
                    <p className="leading-relaxed text-base mb-4">Realm of Caring (RoC) serves anyone in need of more information about cannabinoid therapies. Through revolutionary research, innovative education, and life-changing grants, RoC seeks to facilitate and encourage the mainstream acceptance of transformative, plant powered therapies to benefit individuals and families and serve healthcare providers.</p>
                    <a 
                        className="text-green-500 inline-flex items-center"
                        href="http://www.realmofcaring.org"
                        target="_blank"
                        rel="noopener noreferrer"
                    >Learn More
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                    </a>
                </div>
                

                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                    <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">Medical Marijuana 411</h2>
                    <p className="leading-relaxed text-base mb-4">Founded in 2009, Medical Marijuana 411 is one of the first and most trusted online resources for medical cannabis education.</p>
                    <a 
                        className="text-green-500 inline-flex items-center"
                        href="http://medicalmarijuana411.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >Learn More
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                    </a>
                </div>
                
                </div>                
            </div>
            </section>
        </div>



    </div>);
}
  
  