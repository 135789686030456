import React, { useState } from 'react'
import { returnAccountLimits } from '../../../Data/TierData'
import { determineOldestActivePlant } from '../../renderless/helpers'
import { determineOldestNutrient } from '../helpers/determineOldestNutrient'

export default function InfoBubbleDisplay({ appData }) {
    const [ infoBubble, setInfoBubble ] = useState(0)
    const vegetativePlants = appData.plantData.activePlants.filter((plant) => plant.status === 'Vegetative')
    const flowerPlants = appData.plantData.activePlants.filter((plant) => plant.status === 'Flower')
    const harvestedPlants = appData.plantData.harvestedPlants
    const archivedPlants = appData.plantData.archivedPlants
    const userTierLimits = returnAccountLimits(appData.profileData.accessTier)
    const currentActivePlants = vegetativePlants.length + flowerPlants.length + harvestedPlants.length
    const totalPlants = currentActivePlants + archivedPlants.length


    const updateInfoBubble = () => {
        if (infoBubble === 3) {
            setInfoBubble(0)
        }
        if (infoBubble <= 2) {
            let tempVal = infoBubble
            setInfoBubble(tempVal+1)
        }        
    }

    const infoBubbleDisplay = () => {        
        // let returnInfoToDisplay = null
        let returnInfoToDisplay = []


        switch (infoBubble) {
            case 0:
                // returnInfoToDisplay = `Oldest Active Plant: ${determineOldestActivePlant( vegetativePlants, flowerPlants)}`
                returnInfoToDisplay.push(<div className='mx-auto' key={'info-Oldest'}>
                    <p>{determineOldestActivePlant( vegetativePlants, flowerPlants)}</p>
                    <p className='text-sm italic'>oldest active plant</p>
                </div>)
                break;
            case 1:
                // returnInfoToDisplay = `Plant with most days since nutrient reset: ${determineOldestNutrient()}`
                returnInfoToDisplay.push(<div className='mx-auto' key={'info-nurtrient'}>
                    <p>{determineOldestNutrient( currentActivePlants, flowerPlants, vegetativePlants )}</p>
                    <p className='text-sm italic'>Plant with most days since nutrient reset</p>
                </div>)
                break;
            case 2:
                // returnInfoToDisplay = `Currently using ${currentActivePlants} of ${appData.profileData.activePlantLimit} available active plants`                
                returnInfoToDisplay.push(<div className='mx-auto' key={'info-activeLimit'}>
                    <p>{currentActivePlants} of {userTierLimits.activePlantLimit}</p>
                    <p className='text-sm italic'>current active plants</p>
                </div>)
                break;
            case 3:
                // returnInfoToDisplay = `Currently using ${totalPlants} of your ${appData.profileData.totalPlantLimit} plant capacity`
                returnInfoToDisplay.push(<div className='mx-auto' key={'info-totalLimit'}>
                    <p>{totalPlants} of {userTierLimits.totalPlantLimit}</p>
                    <p className='text-sm italic'>current total plants</p>
                </div>)
                break;

            default:
                break;
        }
        return returnInfoToDisplay
    }


  return (
    <><section className='text-white w-full'> 
            <div className='flex p-2 text-center text-lg font-light border-2 border-white bg-emerald-800 md:hover:bg-emerald-600 m-2 rounded-md' onClick={updateInfoBubble}>
                {infoBubbleDisplay()} 
                <div className='flex place-items-center'>
                    <i className='material-icons'>navigate_next</i>                    
                </div>
        {/* <div className='flex flex-row w-full justify-evenly align-center'>                
            </div> */}
        </div>            
    </section></>
  )
}
