import React from 'react'
import ETGButton from '../../shared/global/ETGButton'
import { testDesiredUsername } from '../../renderless/TestInput/testDesiredDisplayName'
import { loadDisplayNames } from '../../renderless/GetData/loadDisplayNames'
import { useFirestore } from '../../../hooks/useFirestore'
import { getAuth, updateProfile } from 'firebase/auth'
import SaveInProgress from '../../shared/global/SaveInProgress'


export default function EditDisplayNameView({ handleProfileModalToggle, profileDocument, updateErrorData }) {    
    const [ displayName, setDisplayName ] = React.useState(profileDocument.displayName)
    const [ userDisplayNames, setUserDisplayNames ] = React.useState()
    const [ isLoading, setIsLoading ] = React.useState(false)
    const [ desiredDisplayNameAvailable, setDesiredDisplayNameAvailable ] = React.useState([])
    const { updateDocument, response } = useFirestore('growProfiles')


    const handleInputChange = (desiredDisplayName) => {            
        const testResults = testDesiredUsername( desiredDisplayName, userDisplayNames)            
        setDisplayName(testResults.currentDisplayName)
        setDesiredDisplayNameAvailable(testResults.isNameAvailable)
    }

    const handleSaveNewDisplayName = async () => {    
        setIsLoading(true) 
        // update auth user displayName, this is used for emails and elsewhere with Firebase Authentication
        const auth = getAuth()  
        
        updateProfile(auth.currentUser, { displayName }).then(() => {
            // profile updated
            // clone and update profile document in firestore
            let clonedProfile = Object.assign({}, profileDocument)
            clonedProfile.displayName = displayName
            
            // save document
            updateDocument(profileDocument.uid, clonedProfile).then(() => {
                if (response.error) {
                    // throw error
                    updateErrorData('Error detected while updating Profile - Display Name', auth.currentUser.uid)
                    handleProfileModalToggle()
                } else {
                    handleProfileModalToggle()
                }
            })      
            // make error if response is not null otherwise close modal
            
        }).catch((error) => {
            updateErrorData(`Error Detected - ${error}`, auth.currentUser.uid)
            setIsLoading(false)
            handleProfileModalToggle()
        })
                
        setIsLoading(false)
    }    
    
    // use to initially download all the existing Display Name IDs  
    React.useEffect(() => {
        loadDisplayNames( false ).then((result) => {            
            setUserDisplayNames(result)        
        })
                    
    }, [])


  return (<>
    {/* DisplayName Field */}
    <div className="flex flex-col w-10/12 self-center text-black">
        <div className="flex flex-col items-center font-lg py-4 mt-8">                    
            {/* rules for the display name */}
            <div className='flex flex-col w-6/12 mx-auto etg-systemText -mt-8 -pt-4'>
                <p>Your Display Name is public and will also your EctorGrow url: <span className='font-medium'>EctorGrow.com/{desiredDisplayNameAvailable ? displayName : null}</span></p>
            </div>
            <div className='flex flex-col my-4 p-2'>
                <h3 className='etg-sectionTitle text-center'>Display Name Requirements</h3>
                <ul className='etg-systemText pt-2 bg-gray-300 rounded-md mx-4'>
                    <li>3-10 characters in length</li>
                    <li>has at least one character</li>
                    <li>contains no special characters</li>
                </ul>
            </div>
        </div>
        <div className="flex flex-col bg-gray-300 w-full justify-center text-black rounded-md my-8 p-2">
            <p className='flex w-fit self-center py-1 etg-systemText'>
                enter your desired display name
            </p>
            <input
                placeholder={profileDocument.displayName}  
                value={displayName}                              
                className='etg-userText p-2 rounded-lg w-10/12 md:w-full self-center shadow-xl'
                onChange={(e) => handleInputChange(e.target.value)}
            />      
            <div className='flex flex-col w-fit self-center py-1 pt-2 etg-systemText'>
                {desiredDisplayNameAvailable ? <p>display name, <span className='font-medium'>{displayName}</span>, is available</p> : <p>display name is unavailable</p>}
                <p className='p-4'>
                    changing your display name will chang your EctorGrow URL - and if you have purhcased any EctorGrow Labels, changing your display name will make those labels invalid
                </p>
            </div>
        </div>
        {/* Buttons for Save/Cancel */}
        <div className='flex flex-col md:flex-row justify-around my-4 py-4'>
            {/* conditional save buttons */}
            { isLoading ? <SaveInProgress message='Saving Display Name' /> : <>
            { desiredDisplayNameAvailable ? <ETGButton btnText='Save Display Name' onClickFunction={handleSaveNewDisplayName} /> : <ETGButton btnText='Display Name Unavailable' disable={true} />}
            {/* cancel buttons */}
            <ETGButton btnText='Cancel' type='delete' onClickFunction={() => handleProfileModalToggle()} />
            </>}
        </div>
  </div>
  </>
  )
}
