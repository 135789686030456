// js function that updates order document with a property, sentToSandP that is set to true

import { projectFirestore } from '../../../firebase/config';


export const addOrderSentTOSP = async (orderID, SandPOrderID) => {
    const orderRef = projectFirestore.collection('growLabelOrders').doc(orderID);
    
    try {
        await orderRef.update({
        sentToSandP: true,
        SandPOrderID
        });
    } catch (err) {
        console.error(err);
    }
}