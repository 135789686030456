import { useState } from "react"
import { projectFirestore } from "../firebase/config"
import { getAuth } from "firebase/auth"


// the plant and profileDocument are passed in as arguments and should be fully updated before passing to this hook
export const useUpdatePlantData = () => {
    const [updatePlantDataError, setUpdateplantDataError] = useState(null)    
    const currentUser = getAuth().currentUser

    const updatePlantData = async (plant) => {       
        // validate that the plants plantOwnerUID matches the currentAuth UID
        if (plant.plantOwnerUID !== currentUser.uid) {
            setUpdateplantDataError('plantOwnerUID does not match currentUser UID')            
            console.log('called to not update plant data')
            return
        }
        // console.log('plant', plant)

        try {
            await projectFirestore.collection('plants').doc(plant.plantId).set(plant).then(() => {
                // plant uploaded                
            })              
        } catch (error) {
            console.log('error', error)
            // updatePlantDataError(error.message)
        }


        
    }

    return { updatePlantData, updatePlantDataError }
}