import React from 'react'
import DatePicker from 'react-date-picker'
import ETGButton from '../../shared/global/ETGButton'
import { timestamp } from '../../../firebase/config'
import { useUpdatePlantData } from '../../../hooks/useUpdatePlantData'

export default function FlowerToHarvestView({ appData, currentPlantData,
    setCurrentPhase, setGrowsModal, setIsProcessing, updateErrorData }) {
    const [ harvestYield, setHarvestYield ] = React.useState(0)
    const [ harvestDate, setHarvestDate ] = React.useState()

    function dateBuilder(date) {        
        let dateString = new Date(date)    
        return dateString        
    }
    const { updatePlantData, updatePlantDataError } = useUpdatePlantData(appData.profileData)

    React.useEffect(() => {
        let todaysDate = new Date()
        todaysDate = new timestamp(Math.round(todaysDate.getTime()/1000), 0)   
        todaysDate = dateBuilder(todaysDate.toDate())
        setHarvestDate(todaysDate)

    }, [])
    

    const handleHarvestUpdate = ( value ) => {
        //  if value is a number, pass new value to harvest yield
        if (!isNaN(value) && value !== '') {            
            setHarvestYield(value)
        } else if (value === '' && harvestYield !== '') {
            // new input from user was not a number, no updates to app
            return
        }
    }
    
    // HANDLE TRANSFER FROM FLOWER TO HARVEST
    const handleHarvest = async () => {        
        setIsProcessing(true)
        
        
        
        let tempHarvestDate = new timestamp(Math.round(harvestDate.getTime()/1000), 0)
        const plantHarvestedNote = {
            comment: 'Plant Harvested',
            date: tempHarvestDate, 
            type: 'lc-harvest'            
        }
        let newPlant = {
            strain: currentPlantData.strain, 
            status: 'Harvested', 
            location: currentPlantData.location,
            position: currentPlantData.position,
            medium: currentPlantData.medium,
            harvestYield,
            startDate: currentPlantData.startDate,
            transferDate: currentPlantData.transferDate,
            harvestDate: tempHarvestDate,
            noteLog: [...currentPlantData.noteLog, plantHarvestedNote],
            plantId: currentPlantData.plantId,
            imgArray: currentPlantData.imgArray,
            vegNutrients: currentPlantData.vegNutrients,
            flowerNutrients: currentPlantData.flowerNutrients,
            plantOwnerUID: currentPlantData.plantOwnerUID
        }
        
        

        
            
            // update plant data in firebase
            await updatePlantData(newPlant)
            if (updatePlantDataError) {
                updateErrorData(updatePlantDataError, appData.id)
            }
            
                        
        setCurrentPhase('Harvested') 
        setGrowsModal(false)   
        setIsProcessing(false)
    }


  return (<>
    <section>
        <div className='flex flex-col justify-center py-3'>
            <div className='px-4 mx-auto text-black text-center'>                                       
                <div className='py-2'>
                    <label className='etg-inputLabel text-black text-lg p-1'>
                        Harvest Date: 
                    </label>
                    <DatePicker onChange={(e) => setHarvestDate(e)} value={harvestDate} />                                    
                </div>                                                                        
                <div className='flex flex-col py-2'>
                    <label className='etg-inputLabel text-black text-lg p-1'>
                        Yield: {harvestYield} (in grams)
                    </label>
                    <input 
                        type='number' 
                        className='etg-inputNumber text-black text-lg p-1 bg-slate-200 rounded-xl'
                        value={harvestYield} 
                        onChange={(e) => handleHarvestUpdate(e.target.value)} 
                    /> 
                </div>                                                                        
            </div>

            <div className="flex flex-col justify-evenly">
                <div className='flex flex-row pt-4 space-evenly mx-auto'>                    
                    <div className="px-2">                        
                        <ETGButton
                        onClickFunction={handleHarvest}
                        icon='save'
                        btnText='Harvest This Plant'
                        type='caution'
                        />
                    </div>
                    <div className="px-2">                                                
                        <ETGButton
                            onClickFunction={() => setGrowsModal(false)}
                            icon='close'
                            btnText='Cancel'
                            type='delete'
                            />
                    </div>
                </div>
            </div>
        </div>
    </section>
</>)
}
