import React from 'react';
import { NavLink } from 'react-router-dom';

export default function EctorGrowLabelPreviousOrders({ previousOrderData  }) {
  
  function formatMoney (valueInCents) {
    const valueInDollars = valueInCents / 100
    return valueInDollars.toLocaleString("en-US", {style:"currency", currency:"USD"}); 
  }

  return (
    <div className="flex flex-col m-4 overflow-x-auto">
      <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-right">
          <tr>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Order Created
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Description
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Total Price
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Status
            </th>
            <th className="px-4 py-2">Order Details</th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Order ID
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {previousOrderData.map((order) => (
            
            <tr key={order.squareOrderID}>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {order.dateTimeOrdered}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              {order.orderDescription}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              {formatMoney(order.paymentLinkResponse.related_resources.orders[0].total_money.amount)}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {order.orderStatus === 'Created' && order.paymentUpdate && order.paymentUpdate.data.object.payment.status === 'COMPLETED' ? 'Paid' :  order.orderStatus }
              </td>
              <td className="whitespace-nowrap px-4 py-2">
                <NavLink
                  to={`/order/${order.squareOrderID}`}
                  className="inline-block rounded bg-indigo-600 px-4 py-2 text-xs font-medium text-white hover:bg-indigo-700"                  
                >
                  View
                </NavLink>
              </td>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                {order.squareOrderID}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
