import React from 'react'

export default function NoteDateDisplay({ toggleDateDisplay, noteDate }) {
return (<>
    <div 
        className='flex flex-col items-center text-lg font-medium border-2 border-emerald-800/20 rounded-md p-1'
        onClick={() => toggleDateDisplay()}
    >
        <p>
        {noteDate}
    </p>
    </div>
    </>)
}
