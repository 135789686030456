import React, { useEffect, useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toolbarOptions } from '../../Data/ToolbarOptions';
import { v4 as uuidv4 } from 'uuid';
import { projectFirestore, projectStorage, timestamp } from '../../firebase/config'
import LoadingDiv from '../LoadingDiv'
import ETGButton from '../shared/global/ETGButton';
import Slider from 'react-slick';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ProcessingDiv from '../ProcessingDiv';

const AddSocialPost = ({ userDisplayNames, setAddPost, currentUser, updateErrorData, setDocumentToLoad }) => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('General');
  const [postText, setPostText] = useState(EditorState.createEmpty());
  const [ isLoading, setIsLoading ] = useState(false)  
  const [ postImages, setPostImages ] = useState([])
  const [ currentImageIndex, setCurrentImageIndex ] = useState(0)
  const sliderRef = React.useRef(null);
  const [panPosition, setPanPosition] = useState({ x: 50, y: 50 });
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [imageCaption, setImageCaption] = useState('');
  const [processingImageUpload, setProcessingImageUpload] = useState(false);
  const postID = uuidv4()


  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    let shortenedValue = inputValue;
  
    if (inputValue.length > 60) {
      shortenedValue = inputValue.slice(0, 60);
      alert('Title can be no longer than 60 characters.');
    }
  
    setTitle(shortenedValue);
  };

  // function to handle image upload
  // upload to firebaseStorage/socialPostImages/postID/imageID
  // save the imageID and the downloadURL to the postImages array
  async function handleImageUpload() {
    setProcessingImageUpload(true)
    if (!selectedImageFile) {
      alert('Please select an image to upload.');
      return;
    }
    
    const imageID = uuidv4();
    const storageRef = projectStorage.ref(`socialPostImages/${postID}/${imageID}`);
    
    try {
      const fileRef = await storageRef.put(selectedImageFile);
      const downloadURL = await fileRef.ref.getDownloadURL();
      setPostImages((prevPostImages) => [...prevPostImages, { imageID, downloadURL, caption: imageCaption }]);
      
    } catch (error) {
      console.error(error);
      updateErrorData(error.message, currentUser.uid)
    }
    // Clear the selected image file and image caption after upload
    // document.getElementById('imageUpload').value = '';
    setSelectedImageFile(null); // Clear the selected image file after upload
    setImageCaption(''); // Clear the image caption after upload
    setProcessingImageUpload(false)
  }

  

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };  

  const handlePostTextChange = (editorState) => {
    setPostText(editorState);
  };

  const handleSubmit = async () => {
    
    // check if title is empty or less than 3 characters, if so , alert to user and return
    if (title.length < 3) {
      alert('Title must be at least 3 characters long. Descriptive titles help the Community and help your post reach more users.');
      return;
    }

    setIsLoading(true)


    const contentState = postText.getCurrentContent();
    const rawPostText = JSON.stringify(convertToRaw(contentState));    

    const today = new Date()
    
    let error = null
    let result = null
        
    const postObject = {
      title,
      category,
      rawPostText,
      dateTimePosted: new timestamp(Math.round(today.getTime()/1000), 0),
      postID,
      postedByUID: currentUser.uid,
      postImages,
      likes: [],
      tags: [],
    }
    
    try {
      // save the postObject to the firestore socialPosts collection
      await projectFirestore.collection('socialPosts').doc(postObject.postID).set(postObject)
      result = 'success'
      
      setDocumentToLoad( { type: 'socialPost', IDToLoad: postObject.postID } )
    } catch (databaseError) {
      error = databaseError

      
    }
    

    setIsLoading(false)
    setAddPost(false)
    return [result, error]
    
  };

  const handleImageMouseMove = (e) => {
    const { offsetX, offsetY, target } = e;
    const { clientWidth, clientHeight } = target;

    const x = (offsetX / clientWidth) * 100;
    const y = (offsetY / clientHeight) * 100;

    setPanPosition({ x, y });
  };

  const handleImageTouchMove = (e) => {
    const touch = e.touches[0];
    const { clientX, clientY, target } = touch;
    const { clientWidth, clientHeight } = target;

    const x = (clientX / clientWidth) * 100;
    const y = (clientY / clientHeight) * 100;

    setPanPosition({ x, y });
  };

  const transformWrapper = (image, index) => (
    <TransformWrapper
      defaultPositionX={panPosition.x}
      defaultPositionY={panPosition.y}
      options={{
        limitToBounds: false,
        minScale: 0.5,
        maxScale: 3,
        limitToWrapperBounds: true,
        preventDefault: false, // Prevent page scrolling on image zoom
      }}
      pan={{
        disabledOnDoubleClick: false,
      }}
      pinch={{ disabled: false }}
      wheel={{ disabled: false }}
      doubleClick={{ disabled: false }}
      pinchRotate={{ disabled: false }}      
      key={index}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <TransformComponent>
            <img
              src={image.downloadURL}
              alt={image.caption}              
              onMouseMove={handleImageMouseMove}
              onTouchMove={handleImageTouchMove}
              className={`max-w-full max-h-[650px] p-2`}
            />
          </TransformComponent>
          <div className='flex justify-between'>
            
            { postImages.length > 1 ? <button className='p-1 bg-slate-200 rounded-xl' onClick={() => sliderRef.current.slickPrev()}>Previous Image</button> : null }
            <p className='px-4 text-xl font-bold'>{image.caption}</p>
            { postImages.length > 1 ? <button className='p-1 bg-slate-200 rounded-xl' onClick={() => sliderRef.current.slickNext()}>Next Image</button> : null }
          </div>
          {/* button to delete image */}
          <div>
            <button className='mt-2 p-1 bg-red-200 rounded-xl' onClick={() => handleDeleteImage(index)}>Delete Image</button>
          </div>
        </React.Fragment>
      )}
    </TransformWrapper>
  );

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    lazyLoad: true,         
    style: { width: '100%', height: '100%' },        
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: setCurrentImageIndex, // Update the currentImageIndex after slide change
    initialSlide: currentImageIndex, // Set the initial slide to the currentImageIndex
  };


  

  
  // useEffecto for imageCaption, if text is longer than 30 characters, alert user, limit to 30 characters and return
  useEffect(() => {
    
    if (imageCaption.length > 30) {
      alert('Image caption can be no longer than 30 characters.');
      setImageCaption(imageCaption.slice(0, 30));
      return;
    }
  }, [imageCaption]);

  // function to handle user clicking on the delete image button, takes image index as argument
  const handleDeleteImage = (imageIndex) => {
    // delete the image from firebase storage
    try {
      const storageRef = projectStorage.ref(`socialPostImages/${postID}/${postImages[imageIndex].imageID}`);
      storageRef.delete();
      console.log('image deleted')      
    } catch (error) {
      console.log('error', error)
    }
    // create a new array with all images except the one at the index passed in
    const newPostImages = postImages.filter((image, index) => index !== imageIndex);
    
    // set the postImages array to the new array
    setPostImages(newPostImages);
    // if the currentImageIndex is greater than the index of the image deleted, subtract 1 from the currentImageIndex
    if (currentImageIndex > imageIndex) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
    // if the currentImageIndex is equal to the index of the image deleted, set the currentImageIndex to 0
    if (currentImageIndex === imageIndex) {
      setCurrentImageIndex(0);
    }
  };

  
  
  // function that handles user canceling social post creation, deletes any images uploaded to firebase storage
  const handleCancel = () => {
    // if there are images in the postImages array, delete them from firebase storage
    if (postImages.length > 0) {
      postImages.forEach((image) => {
        const storageRef = projectStorage.ref(`socialPostImages/${postID}/${image.imageID}`);
        storageRef.delete();
      });
    }
    setAddPost(false);
  };
  



  if( isLoading ) return (
    <LoadingDiv />
  )
  
  return (    
    <section className="flex flex-col w-full p-4 text-black">
      <h2 className="text-3xl text-center mb-4">Create A New Social Post</h2>
      {/* Section Describing Social Posts and Limitations */}
      <div className='px-8 bg-emerald-800/50 rounded-xl my-8 py-2 tracking-wider text-lg'>
        <p className=''>
          Social Posts are a way to share information with the community. 
        </p>
        <ul className='text-base list-disc pt-2'>
          <li>You can add images, text, and tags to your post, you can also mention other users by typing @ followed by their display name.</li>
          <li>You can also add a category to your post to help other users find it.</li>
          <li> You can also add up to 5 images to your post, and each image can have a caption of up to 30 characters.</li>
        </ul>
      </div>

        {/* Image Display for Social Post */}
        { processingImageUpload ? <ProcessingDiv message='Uploading image' /> : <>
          <div className="flex flex-col justify-center outline-2 outline-emerald-800 outline-double rounded-xl p-2 mb-4">
          { ( postImages.length < 5 )  ? <>

          {/* Image Upload Section */}
          <div className='flex flex-wrap gap-8 text-center px-6 justify-center items-center'>
            <div>
              <label htmlFor="imageUpload" className="block font-medium mb-1">Upload Image:</label>
              <input type="file" id="imageUpload" className='text-center' accept="image/*" onChange={(e) => setSelectedImageFile(e.target.files[0])} />            

            </div>
            <div>
              <label htmlFor="imageCaption" className="block font-medium mb-1 pt-4">Add an Image Caption:</label>
              <input type='texxt' value={imageCaption} onChange={(e) => setImageCaption(e.target.value)} />
            </div>
          </div>

          {/* Button to add images */}
          { selectedImageFile ? <>
            <div className='flex justify-center'>
              <ETGButton onClickFunction={handleImageUpload} btnText="Add Image" />
            </div> </> : null}
          </> : null}
          {/* Display image count if more than 0  */}
          { postImages.length > 0 ? <p className='text-lg text-center pt-4'>{postImages.length} of 5 images uploaded</p> : null }

            {/* Image Display Slider */}
            { postImages.length > 0 ? <>
              <div className='flex flex-col bg-emerald-800 p-4 rounded-xl shadow-xl max-h-[780px] my-10'>                                  
                <Slider key={currentImageIndex} {...sliderSettings} ref={sliderRef}>
                  {postImages.map((image, index) => (
                    <div key={index}>
                      <div className="flex flex-col items-center justify-center">
                        {transformWrapper(image, index)}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </> : <>
            <p className='text-lg text-center pt-4'>No images added yet</p></>}
            
            
          </div>
        </>}

      
        <div className="flex flex-col mb-4">
          <label htmlFor="title" className="block font-medium mb-1">
            Title
          </label>
          <input
            type="text"
            id="title"
            className="w-full border-gray-300 rounded-md p-2"
            value={title}
            onChange={handleTitleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="category" className="block font-medium mb-1">
            Category
          </label>
          <select
            id="category"
            className="w-full border-gray-300 rounded-md p-2"
            value={category}
            onChange={handleCategoryChange}
            required
          >
            <option value="General">General</option>
            <option value="Question">Question</option>
            <option value="Random">Random</option>
            <option value="Hardware">Hardware</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* lower section with Editor, followed by button to save */}
        <div className="relative min-h-fit w-full">      
          <div className="mt-4 text-black bg-slate-300 rounded-xl">
            <Editor
              editorState={postText}
              onEditorStateChange={handlePostTextChange}
              editorClassName="bg-gray-300 rounded-md p-2"
              placeholder="Write your post here..."
              toolbarClassName="toolbar-class"
              toolbar={toolbarOptions}
              maxLength={10000}
              mention={{
                separator: ' ',
                trigger: '@',
                suggestions: userDisplayNames,
              }}
            />
          </div>        
              {/* display only if less than 500 characters less */}
              { postText.getCurrentContent().getPlainText('').length > 9500 && (
                <label htmlFor="postText" className="flex justify-end font-medium mb-1">
                <span className='etg-descriptionText'>{10000 - postText.getCurrentContent().getPlainText('').length} of 10000 characters remaining</span>
              </label>
              )}
          
          <div className='flex flex-wrap justify-around'>            
            <ETGButton
              onClickFunction={handleSubmit}
              btnText='Create Post'
            />


            <ETGButton
              btnText='Cancel'
              onClickFunction={() => handleCancel()}
              />
          </div>

        </div>
    
    </section>
  );
};

export default AddSocialPost;
