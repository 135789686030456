import prohibitedNameList from "../../../Data/prohibitedNameList"

export function testAboutMeInput ( aboutMeInput ) {
    let validationPassed = true

    // test length
    if (aboutMeInput.length >= 120) {
        // fail validation for exceeding length
        validationPassed = false
        alert('about me can only be 120 characters long')
        aboutMeInput = aboutMeInput.slice(0, 120)        
    }

    // test prohibited words
    let prohibitedWord = false
    prohibitedNameList.forEach(word => {
        prohibitedWord = aboutMeInput.includes(word)

        // check if was prohibited
        if (prohibitedWord) {
            aboutMeInput = ''
            validationPassed = false
            alert('A prohibited word was detected - please try again')
        }
    })


    const results = { validationPassed, aboutMeInput}

    return results
}