import React from 'react'
import ETGButton from '../../shared/global/ETGButton'
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import { testPassword } from '../../renderless/TestInput/testPassword'
import PasswordChecklist from 'react-password-checklist'
import SaveInProgress from '../../shared/global/SaveInProgress'

export default function EditPasswordView({ handleProfileModalToggle, updateErrorData }) {    
    const [ password, setPassword ] = React.useState('')    
    const [ validPassword, setValidPassword ] = React.useState(true)    
    const [ isLoading, setIsLoading ] = React.useState(false)
    const [ currentPassword, setCurrentPassword ] = React.useState('') 


    
    
    

    const handlePasswordInputChange = ( passwordInput ) => {            
        const testResults = testPassword(  passwordInput )            
        setPassword(testResults.newPassword)
        setValidPassword(testResults.isValid)        
    }

    function handleCurrentPasswordInputChange ( currentPasswordInput ) {
        setCurrentPassword(currentPasswordInput)
    }

    const handleSaveNewPassword = async () => {     
        setIsLoading(true)   
        const auth = getAuth()        
        const credential = EmailAuthProvider.credential(auth.currentUser.email, currentPassword)

        reauthenticateWithCredential(auth.currentUser, credential).then(() => {
            // save new email                        
            updatePassword( auth.currentUser, password ).then(() => {
                // update successful
            }).catch((error) => {
                // catch error
                if (error.code === 'auth/requires-recent-login') {                                          
                    updateErrorData( 'Action requires re-authentication', auth.currentUser.uid)          
                    handleProfileModalToggle()
                } else  {
                    updateErrorData('Error updating password')
                    handleProfileModalToggle()                
                }
            })

        }).catch((error) => {
            if (error.code === 'auth/wrong-password') {                                      
                alert('Invalid Password - please try again')
                setIsLoading(false)                
            } else  {
                updateErrorData('Error Authenticating While Updating Password')
                handleProfileModalToggle()                
            }
        })
        
    }            

        
  return (<>
    {/* DisplayName Field */}
    <div className="flex flex-col w-10/12 self-center text-black">
        <div className="flex flex-col items-center font-lg py-4 mt-8">                    
            {/* rules for the display name */}
            <div className='flex flex-col w-6/12 mx-auto etg-systemText -mt-8 -pt-4'>
                <p>your email address is never displayed publicly</p>
            </div>    
            {/* Password Validation */}
            <div className='flex flex-col my-4 p-2'>
                <h3 className='etg-sectionTitle text-center'>Password Requirements</h3>
                <ul className='etg-systemText p-2 bg-gray-300 rounded-md mx-4'>
                    <li>6 characters minimum in length</li>
                    <li>has at least one number</li>
                    <li>has at least one special character</li>
                    <li>has at least one capitalized character</li>                    
                </ul>
            </div>
            {/* Display password validation checklist */}
            <div>
                <PasswordChecklist 
                    rules={["minLength","specialChar","number","capital"]}
                    minLength={6}
                    value={password}                                        
                />
            </div>
        </div>
        <div className="flex flex-col bg-gray-300 w-full justify-center text-black rounded-md my-8 p-2">
            
            
            <label className='w-10/12 md:w-full self-center py-2 text-lg md:text-2xl'>
                Current Password
            </label>
            <input
            type='password'
                placeholder={''}  
                value={currentPassword}                              
                className='etg-userText p-2 rounded-lg w-10/12 md:w-full self-center shadow-xl'
                onChange={(e) => handleCurrentPasswordInputChange(e.target.value)}
            />      


            <label className='w-10/12 md:w-full self-center py-2 text-lg md:text-2xl'>
                New Password
            </label>
            <input
            type='password'
                placeholder={''}  
                value={password}                              
                className='etg-userText p-2 rounded-lg w-10/12 md:w-full self-center shadow-xl'
                onChange={(e) => handlePasswordInputChange(e.target.value)}
            />      
            <div className='flex w-fit self-center py-1 pt-2 etg-systemText'>
                {validPassword ? <p>current password is valid</p> : <p>current password is not valid</p>}
            </div>
        </div>
        {/* Buttons for Save/Cancel */}
        <div className='flex flex-col md:flex-row justify-around my-4'>
            {/* conditional loading bar */}
            { isLoading ? <SaveInProgress /> :
            // {/* conditional save buttons */}
            !validPassword ? <ETGButton btnText='password not valid' disable={true} /> : <><ETGButton btnText='Save New Password' onClickFunction={handleSaveNewPassword} />
             {/* cancel buttons */}
            <ETGButton btnText='Cancel' type='delete' onClickFunction={() => handleProfileModalToggle()} /></>
            }
        </div>
  </div>
  </>
  )
}
