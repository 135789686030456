import React, { useState } from 'react';
import { BsTrash, BsFlag, BsReply } from 'react-icons/bs';
import CommentHeader from './CommentHeader';
import CommentText from './CommentText';
import ReplyDisplayForReplies from './ReplyDisplayForReplies';
import { projectFirestore } from '../../../firebase/config';

export default function ReplyDisplay({
  reply,
  comment,
  getDisplayName,
  getUserThumbnail,
  commentTsToDate,
  handleDeleteReply,
  currentUser,
  updateErrorData,  
  setIsLoading,
  userData,
  replyUserType,
  setAddReply,
  setReplyTo,
  handleProfileClick, 
  handleFlagCommentReply
  
}) {

  const [repliesVisible, setRepliesVisible] = useState(false); 
  const [ replies, setReplies ] = useState([]);

  React.useEffect(() => {
    // Listen for changes in the sub-collection and update the component state
    const unsubscribe = projectFirestore
      .collection('userComments')
      .doc(comment.commentID)
      .collection('replies')
      .onSnapshot((snapshot) => {
        const updatedReplies = snapshot.docs
          .map((doc) => doc.data())
          .filter((reply) => reply.respondingTo !== comment.commentID); // Filter replies based on the condition
  
        setReplies(updatedReplies);
      });
  
    return () => {
      // Unsubscribe from Firestore when the component unmounts
      unsubscribe();
    };
  }, [comment.commentID]);
  


  

  const handleReplyToReply = () => {
    setReplyTo(reply.replyID);
    setAddReply(true); // Open the reply input field
  };

  const toggleReplies = () => {
    setRepliesVisible(!repliesVisible); // Updated the variable here
  };

  

  return (
    <div key={reply.replyID} className="grid grid-cols-12 bg-sky-200 text-black p-4 rounded-xl">
      <div className="col-span-12 flex">
        <div className="mx-2 flex h-16 w-16 items-center justify-center overflow-hidden rounded-full flex-shrink-0">
          <img 
            className='hover:cursor-pointer'
            onClick={() => handleProfileClick(reply.userID)}
            src={getUserThumbnail(reply.userID, userData)} 
            alt="user thumbnail" 
          />
        </div>
        <div className="flex flex-col ml-4 w-full justify-center">
          <CommentHeader 
            displayName={getDisplayName(reply.userID)}            
            dateTime={commentTsToDate(reply.date)}
            handleProfileClick={handleProfileClick}
            userID={reply.userID}
          />
          <CommentText text={reply.text} />
        </div>
      </div>


      {/* Replt Actions */}
      <div className="col-span-12 flex flex-wrap justify-end mt-1 gap-2 items-center">
        {replyUserType !== 'owner' && replyUserType === 'registeredVisitor' ? (
          <>
            {/* Add Reply Button */}
            <button
              className="px-4 py-2 rounded-xl bg-emerald-400 text-white font-medium flex items-center transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
              onClick={handleReplyToReply}
            >
              <BsReply /> <span className="ml-2">Reply</span>
            </button>            
          </>
        ) : null}

        {/* Button to toggle visibility of replies to the reply */}
        { replies && replies.length > 0 ? <>
        <button
        className="px-4 py-2 rounded-xl bg-sky-600 text-white font-medium flex items-center transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
        onClick={toggleReplies}
        >
        {repliesVisible ? 'Hide Replies' : 'Show Replies'}
        </button>
        </> : null }
        
        {replyUserType === 'owner' && (
          <button
          className="px-4 py-2 rounded-xl bg-red-400 text-white font-delete flex items-center transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
            onClick={() => handleDeleteReply(reply.replyID)}
          >
            <BsTrash /> <span className="ml-2">Delete</span>
          </button>
        )}

        {replyUserType === 'registeredVisitor' && (
          <button
            className="px-4 py-2 rounded-xl bg-amber-400 text-white font-medium flex items-center transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
            onClick={() => handleFlagCommentReply(comment.commentID, reply.replyID, 'reply', reply.userID)}
          >
            <BsFlag />
            <span className="ml-2">Report</span>
          </button>
        )}

      </div>
     
      {/* Display replies using ReplyDisplayForReplies if repliesVisible is true */}
      {repliesVisible &&
        replies.map((nestedReply) => (
          <div className="col-span-12" key={nestedReply.replyID}>
            <ReplyDisplayForReplies
              reply={nestedReply}
              comment={comment}
              getDisplayName={getDisplayName}
              getUserThumbnail={getUserThumbnail}              
              commentTsToDate={commentTsToDate}
              handleDeleteReply={handleDeleteReply}
              currentUser={currentUser}
              updateErrorData={updateErrorData}
              setIsLoading={setIsLoading}
              userData={userData}
              handleProfileClick={handleProfileClick}   
              handleFlagCommentReply={handleFlagCommentReply}     
            />
          </div>
        ))}
    </div>
  );
}
