import React, { useState } from 'react';
import { addReplyFunc } from './helpers/addReplyFunc';

export default function AddReply({
  replyTo,  
  setIsLoading,
  updateErrorData,
  getAuth, 
  comment,
  setAddReply,
}) {
  const [replyText, setReplyText] = useState('');  
  
  const handleAddReply = async () => {
    setIsLoading(true);

    try {
      let result = await addReplyFunc(comment.commentID, replyText, replyTo)     
      
      if (result === 'error') {
        updateErrorData('Error adding reply', getAuth().currentUser.uid);
      }
      
    } catch (error) {
      console.log('error', error);
      updateErrorData('Error adding reply', getAuth().currentUser.uid);      
    }



    setIsLoading(false);
    setReplyText('');
  };    
    
  
  return (
    <div className="m-8 grid grid-cols-12">
      <div className="flex rounded-xl bg-white p-4 col-span-12">
        <div className="ml-4 w-full">
          <div className="flex w-full items-center justify-between">
            <label htmlFor="commentLength" className="text-black">
              {/* {addReplyToReply ? `Reply to ${ reply && getDisplayName(reply.userID)}` : `Reply to ${getDisplayName(comment.userID)}`} */}
              Add Your Reply
            </label>
          </div>
          <div className="flex flex-col mt-4">
            <textarea
              className="w-full text-black p-2 bg-slate-200 rounded outline-none focus:ring-2 focus:ring-green-600"
              placeholder="Add a reply..."
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
            />
            <span className="flex etg-sysmteText justify-end text-slate-800">{replyText.length} | 400</span>
          </div>

          <div className="flex flex-wrap justify-end mt-4 space-x-2">
            <button
              className="px-4 py-2 rounded-xl bg-green-400 text-white font-medium transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
              onClick={handleAddReply}
            >
              Save Reply
            </button>

            <button
              className="px-4 py-2 rounded-xl bg-red-400 text-white font-medium transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-100"
              onClick={() => setAddReply(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
