import React, { useState, useEffect } from 'react';
import useAdminSupportTask from '../../hooks/useAdminSupportTask';
import LoadingDiv from '../LoadingDiv';
import { timestampToDate } from '../renderless/helpers';
import ETGButton from '../shared/global/ETGButton';
import ETGIconButton from '../shared/global/ETGIconButton';
import { timestamp } from '../../firebase/config';
import { commentTsToDate } from '../shared/Chat/helpers/commentTsToDate';
import { getGrowProfiles } from '../renderless/GetData/getGrowProfiles';

export default function RequestCard({
  request,
  categoryOptionsListItems,
  statusOptionsListItems,
  priorityOptionsListItems,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [requestState, setRequestState] = useState(request);
  const [adminNote, setAdminNote] = useState('');
  const [isEditingRequest, setIsEditingRequest] = useState(false);
  const { updateTask, deleteTask } = useAdminSupportTask();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Check conditions and update state accordingly
    if (request !== requestState && !isEditingRequest && !isLoading) {
      setIsEditingRequest(true);
    }

    if (request === requestState && isEditingRequest && !isLoading) {
      setIsEditingRequest(false);
    }
  }, [request, requestState, isLoading]);

  const getDisplayName = (userId) => {      
    console.log('userId', userId)  
    console.log('userData', userData)
    if (userData) {
      const user = userData.find((user) => user.uid === userId);          
      console.log('user', user)
      return user.displayName;
    }
  };

  useEffect(() => {
    const usersData = getGrowProfiles()        
    usersData.then((data) => {          
      setUserData(data);
    });
  }, []);
  
  // Detect changes in the adminNote textarea and update isEditingRequest accordingly
  useEffect(() => {
    if (adminNote.trim() !== '' && !isEditingRequest) {
      setIsEditingRequest(true);
    } else if (adminNote.trim() === '' && isEditingRequest) {
      setIsEditingRequest(false);
    }
  }, [adminNote, isEditingRequest]);

  async function handleDeleteRequest() {
    setIsLoading(true);
    // Call function from hook to delete request
    await deleteTask(request.taskID);
    setIsLoading(false);
  }

  function handleCancelChanges() {
    setRequestState(request);
    setAdminNote('');
  }

  async function handleSaveChanges() {
    setIsLoading(true);
    // create temporary copy of requestState
    const tempRequestState = requestState;


    // if adminNote is not empty, add it to the adminNotes array
    if (adminNote.trim() !== '') {
      const today = new Date();
      const dateTime = new timestamp(Math.round(today.getTime()/1000), 0);
      const updatedAdminNotes = [
        ...requestState.adminNotes,
        { note: adminNote, dateTime },
      ];
      tempRequestState.adminNotes = updatedAdminNotes;
      setRequestState(tempRequestState);      
    }
    updateTask(request.taskID, tempRequestState);
    setIsEditingRequest(false);
    setIsLoading(false);
  }

  function displayLinkToContent (content) {
    // for social posts
    if (content.contentType === 'Social Post') {
      return (
        <a href={`http://EctorGrow.com/community/${content.contentID}`} target='_blank' rel='noreferrer'>
          Link To Post
        </a>
      );
    }

    // for plants
    if (content.contentType === 'Plant') {
      return (
        <a href={`http://EctorGrow.com/grows/${getDisplayName(content.contentOwner)}/${content.contentID}`} target='_blank' rel='noreferrer'>
          Link To Plant
        </a>
      );
    }    
    
    
  }

  function displayLinkToContentOwner (content) {
    // for social posts
    if (content.contentType === 'Social Post') {      
      return (
        <a href={`http://EctorGrow.com/grows/${getDisplayName(content.contentOwner)}`} target='_blank' rel='noreferrer'>
          Link To Post Owner
        </a>
      );
    }
    
    // for plants 
    if (content.contentType === 'Plant') {
      return (
        <a href={`http://EctorGrow.com/grows/${getDisplayName(content.contentOwner)}`} target='_blank' rel='noreferrer'>
          Link To Plant Owner
        </a>
      );
    }
  }

  function displayLinkToFlaggedBy (content) {
    // for social posts
    if (content.contentType === 'Social Post') {
      return (
        <a href={`http://EctorGrow.com/grows/${getDisplayName(content.flaggedBy)}`} target='_blank' rel='noreferrer'>
          Link To Flagged By
        </a>
      );
    }

    // for plants
    if (content.contentType === 'Plant') {
      return (
        <a href={`http://EctorGrow.com/grows/${getDisplayName(content.flaggedBy)}`} target='_blank' rel='noreferrer'>
          Link To Flagged By
        </a>
      );
    }
  }



  if (isLoading) {
    return <LoadingDiv />;
  }

  return (
    <>
      <section>
        {/* Card for request */}
        <div className='flex w-10/12 mx-auto py-5 break-words'>
          {/* Card */}
          <div className='flex flex-col w-full'>
            {/* Request Details */}
            <div className='flex flex-col flex-grow w-full md:w-3/4 mx-4 border-2 border-emerald-800 rounded-xl'>
              {/* Card Title */}
              <div className='py-2 border-b-2 border-emerald-800'>
                <p className='py-1'>
                  Request ID: <span className='text-sm inline-block'>{request.taskID}</span>
                </p>
                <p className='text-xs'>Submitted: {`${timestampToDate(request.dateTimeSubmitted)} at ${commentTsToDate(request.dateTimeSubmitted).time}`}</p>
                {request.completionDate && (
                  <p className='text-xs'>completed: {`${timestampToDate(request.completionDate)}`}</p>
                )}
              </div>

              {/* Select Fields */}
              <div className='flex flex-col md:flex-row md:justify-between md:gap-3 p-4'>
                <div className='flex flex-col md:w-1/4'>
                  <p>Category:</p>
                  <select
                    className='p-1 rounded-xl'
                    value={requestState.category}
                    onChange={(e) =>
                      setRequestState((requestState) => ({
                        ...requestState,
                        category: e.target.value,
                      }))
                    }
                  >
                    {categoryOptionsListItems}
                  </select>
                </div>
                <div className='flex flex-col md:w-1/4'>
                  <p>Status:</p>
                  <select
                    className='p-1 rounded-xl'
                    value={requestState.status}
                    onChange={(e) =>
                      setRequestState((requestState) => ({
                        ...requestState,
                        status: e.target.value,
                      }))
                    }
                  >
                    {statusOptionsListItems}
                  </select>
                </div>
                <div className='flex flex-col md:w-1/4'>
                  <p>Priority:</p>
                  <select
                    className='p-1 rounded-xl'
                    value={requestState.priority}
                    onChange={(e) =>
                      setRequestState((requestState) => ({
                        ...requestState,
                        priority: e.target.value,
                      }))
                    }
                  >
                    {priorityOptionsListItems}
                  </select>
                </div>
              </div>

              {/* Description & Comments Fields */}
              <div className='py-4'>
                {/* Description */}
                <div className='border-b-2 border-emerald-800'>
                  <p className='px-4 text-left etg-subHeading'>User/System Provided Description</p>
                  <p className='etg-usertext'>{request.description}</p>
                </div>

                {/* section if flagged content exists */}
                { request.flaggedContent ? <>
                  <div className='border-b-2 border-emerald-800 p-2'>
                    <p className='text-lg pb-2 underline underline-offset-2'>Content Type: {request.flaggedContent.contentType}</p>
                    <p>{displayLinkToContent(request.flaggedContent)}</p>
                    <p>{displayLinkToContentOwner(request.flaggedContent)}</p>
                    <p>{displayLinkToFlaggedBy(request.flaggedContent)}</p>
                    { request.flaggedContent.commentID ? <>
                      <p>Comment ID: {request.flaggedContent.commentID}</p> 
                      <p>Content ID: {request.flaggedContent.contentID}</p>
                      <p>Content Type: { request.flaggedContent.contentType }</p>
                      <p>Display Type: { request.flaggedContent.displayType }</p>
                      <p>Flagged by: <a href={`http://EctorGrow.com/grows/${getDisplayName(request.flaggedContent.flaggedBy)}`} target='_blank' rel='noreferrer'>
                        {getDisplayName(request.flaggedContent.flaggedBy)}
                      </a></p>
                      <p>
                        Flagged User: <a href={`http://EctorGrow.com/grows/${getDisplayName(request.flaggedContent.flaggedUser)}`} target='_blank' rel='noreferrer'>
                          {getDisplayName(request.flaggedContent.flaggedUser)}
                        </a>
                         </p>
                    </>
                      : null
                    }
                  </div>
                </> : null}

                {/* Admin Comments */}
                <div className='flex flex-col p-2 border-t-2 border-emerald-800 bg-emerald-600/50'>
                  <p className='px-4 text-left etg-subHeading'>Add a Note</p>
                  <div className='flex flex-wrap justify-center p-2 gap-2'>
                    {requestState.adminNotes.map((note, index) => (
                      <div key={index} className='etg-userText bg-slate-200 rounded-xl p-2'>
                        <p>{note.note}</p>
                        <p className="text-xs text-gray-500 ml-2">
                          ({timestampToDate(note.dateTime) + ' at ' + commentTsToDate(note.dateTime).time})
                        </p>
                      </div>
                    ))}
                  </div>
                  <textarea
                    type='text'
                    value={adminNote}
                    className='p-2 rounded-xl etg-userText'
                    onChange={(e) => setAdminNote(e.target.value)}
                  />
                </div>
              </div>

              {/* Buttons to Save or Cancel changes made to the request */}
              <div className='flex flex-row justify-around font-light pt-4'>
                {isEditingRequest && (
                  <>
                    <ETGButton
                      onClickFunction={handleSaveChanges}
                      icon='save'
                      btnText='Save Changes'
                    />
                    <ETGButton
                      onClickFunction={handleCancelChanges}
                      icon='cancel'
                      btnText='Cancel Changes'
                      type='delete'
                    />
                  </>
                )}
                <div className='flex flex-col w-fit justify-center items-center'>
                  Delete Admin Task
                  <ETGIconButton onClickFunction={handleDeleteRequest} />
                  <span className='etg-systemText inline-block self-end'>cannot be undone</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
