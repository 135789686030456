import { useState } from 'react';
import Navbar from '@material-tailwind/react/Navbar';
import NavbarContainer from '@material-tailwind/react/NavbarContainer';
import NavbarWrapper from '@material-tailwind/react/NavbarWrapper';
import NavbarBrand from '@material-tailwind/react/NavbarBrand';
import NavbarToggler from '@material-tailwind/react/NavbarToggler';
import NavbarCollapse from '@material-tailwind/react/NavbarCollapse';
import Nav from '@material-tailwind/react/Nav';
import NavLink from '@material-tailwind/react/NavLink';
import Icon from '@material-tailwind/react/Icon';
import Button from '@material-tailwind/react/Button'
import { useLogout } from "../hooks/useLogout"
import ProcessingDiv from './ProcessingDiv';
import svgLogoDark from '../img/FinalLogoDarkBG.svg'
import { useDocument } from '../hooks/useDocument';

export default function DefaultNavbar({ user, profileData }) {
    const [openNavbar, setOpenNavbar] = useState(false)    
    const { logout, error, isPending } = useLogout()   
    
    let userAccessTier = null
    

    // retrieve the current maintenace from the database
    // if maintenance is true, display the maintenance message
    const currentMessage = useDocument('maintenanceData', 'tQBOeIuMZN0b8Qv7ccTI')
       

    // if user is null (user not logged in),  set access tier to 0
    // else if user logged in, set access level based on profile data
    if ((user === 'notloggedin') && !userAccessTier) {
        userAccessTier = 0
    } else {        
        // Set userAccessTier once when profileDocument has loaded
        if ( !userAccessTier && profileData) {
            userAccessTier = profileData.accessTier

    
        }
        // Error Handiling
        if (error) {
            return <ProcessingDiv message='Error Logging Out' type='error' />
        }
    }


    return (<>
        <Navbar color="transparent" navbar>
            <NavbarContainer>
                <NavbarWrapper>
                    <a
                        href="/"                        
                        rel="noreferrer"
                    >
                        <NavbarBrand>                            
                            <img className='w-auto h-11 mt-1 rounded-md p-2 bg-[#2d2d2d]' src={svgLogoDark} alt='svgLogo' />
                        </NavbarBrand>
                    </a>
                    <NavbarToggler
                        onClick={() => setOpenNavbar(!openNavbar)}
                        color="white"
                    />
                </NavbarWrapper>

                <NavbarCollapse open={openNavbar}>
                    <Nav>
                        <div className="flex flex-col z-30 lg:flex-row lg:items-center">                                                                                                                                  
                            {/* Grows Link */}
                            {
                                user && <NavLink
                                    href={`/grows/`}                                                            
                                    rel="noreferrer"
                                    ripple="light"
                                >
                                    <Icon name="local_florist" sixe="2xl" />
                                    Grows
                                </NavLink>
                            }
                            {/* Schedules Link */}
                            <NavLink
                                href={`/community/`}
                                rel="noreferrer"
                                ripple="light"
                            >
                                <Icon name="groups" size="2xl" />
                                Community
                            </NavLink>
                            {/* Profile Link */}
                            {
                                user && <NavLink
                                    href={`/profile/`}                                                      
                                    rel="noreferrer"
                                    ripple="light"
                                >
                                    <Icon name="account_circle" size="2xl"/>
                                    Profile
                                </NavLink>
                            }                                                                                                        
                            {(!user) && 
                            <NavLink
                            href="/register"                                
                            rel="noreferrer"
                            ripple="light"
                            >
                                <Icon name="how_to_reg" size="2xl" />
                                &nbsp;Register
                            </NavLink>
                            }
                            
                            {(userAccessTier === 9 && user) && 
                            <NavLink
                            href={`/admin/`}
                            rel="noreferrer"
                            ripple="light"
                            >
                                <Icon name="settings" size="2xl" />
                                &nbsp;Admin
                            </NavLink>
                            }  
                            {(userAccessTier === 9 && user) && 
                            <NavLink
                            href={`/dev/`}
                            rel="noreferrer"
                            ripple="light"
                            >
                                <Icon name="developer_board" size="2xl" />
                                &nbsp;Dev
                            </NavLink>
                            }  
                            {/* need to display login if not logged in */}
                            {
                                !user && <NavLink 
                                        href="/login"
                                        rel="noreferrer"
                                        ripple="light"
                                    >
                                        Log in
                                    </NavLink>                                                                
                            }
                            {                            
                            !isPending && user &&
                                <Button
                                color="transparent"
                                className="bg-white text-black ml-4"
                                ripple="dark"
                                onClick={logout}
                            >
                                Logout
                            </Button>  
                            }
                            {isPending && 
                                <div>
                                    <Button
                                    color="transparent"
                                    className="bg-white text-black ml-4"
                                    ripple="dark"                                    
                                    disabeled="true"
                                >
                                    Logging Out...
                                </Button>                                      
                                </div>
                            }                                                           
                        </div>
                    </Nav>
                </NavbarCollapse>
            </NavbarContainer>
        </Navbar>        
        <div>
            {currentMessage && currentMessage.document && currentMessage.document.status ? <>
            <div
                className="bg-red-500/90 border-2 border-white border-dotted rounded-lg py-4 px-6 m-4 text-white flex flex-col justify-between text-center md:text-left"
            >
                <div className="mb-4 md:mb-0 flex flex-wrap justify-center md:justify-start tracking-wide font-medium uppercase">              
                    <p className='flex'>
                        {currentMessage.document.message}
                    </p>
                    {/* right aligned paragraph */}
                </div>            
                <div className='mb-4 md:mb-0 flex justify-end tracking-wide font-medium'>
                    <p className='flex justify-end text-xl'>
                        - EctorGrow
                    </p>
                </div>
          </div> </> : null                
            }
        </div>
        </>
    );
}
