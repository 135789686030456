import React, { useEffect, useState } from 'react';
import { projectFirestore, projectStorage, timestamp } from '../../firebase/config';
import { getGrowProfiles } from '../renderless/GetData/getGrowProfiles';
import { FiCopy } from 'react-icons/fi';
import QRCode from 'qrcode.react';
import { toPng } from 'html-to-image';
import { addOrderDelay } from './helpers/addOrderDelay';
import { addOrderSentTOSP } from './helpers/addOrderSentTOSP';
import { addOrderNote } from './helpers/addOrderNote';
import { timestampToDate } from '../renderless/helpers'
import useMessageSender from '../../hooks/useMessageSender';
import { removeOrderDelay } from './helpers/removeOrderDelay';
import DisplayOrderItems from '../grows/plantCard/Footer/EctorGrowLabels/DisplayOrderItems';


export default function AdminUserManagementView() {
  const [orders, setOrders] = useState([]);
  const [userData, setUserData] = useState([]);
  const [activeOrderItem, setActiveOrderItem] = useState(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [ orderFilter, setOrderFilter ] = useState('All')
  const [ orderTotals, setOrderTotals ] = useState({
    created: 0, 
    createdPaid: 0, 
    processing: 0, 
    inTransit: 0, 
    delivered: 0,     
    cancelled: 0, 
  })
  const [ labelCustomizationOptions, setLabelCustomizationOptions ] = useState({
    // width: '300px',  // 3 inches at 300 DPI
    // height: '400px', // 4 inches at 300 DPI
    // doubled the dimensions to get a higher resolution image
    width: '600px',  // 3 inches at 300 DPI
    height: '800px', // 4 inches at 300 DPI
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    fgColor: '#fb923c',
    
  });

  const { sendEmail, messageSent } = useMessageSender();
  
  useEffect(() => {
    const usersData = getGrowProfiles()        
    usersData.then((data) => {          
      setUserData(data);
    });
  }, []);

  // function to download qr code as image
const downloadQRCode = () => {  
  const qrCodeContainer = document.getElementById('qrCodeContainer');
  const link = document.createElement('link');
  // const for the title of the image, which should be, the plantStrain-PlantID-OrderID-orderItemIndex
  // use the expandOrderID to get the orderItemIndex
  const orderIndex = orders.findIndex((orderItem) => orderItem.squareOrderID === expandedOrderID)  
  // plant strain - labelsForPlantID - squareOrderID - orderItemIndex
  const imageTitle = `${orders[orderIndex].plantStrain}~${orders[orderIndex].labelsForPlantID}~${orders[orderIndex].squareOrderID}~orderItem-${activeItemIndex}`
  link.rel = 'stylesheet';
  link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
  link.onload = () => {
    // After the font has loaded, proceed with capturing the image
    toPng(qrCodeContainer)
      .then((dataUrl) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        // downloadLink.download = 'qrcode.png';
        downloadLink.download = imageTitle
        downloadLink.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  document.head.appendChild(link);
};
  
  // get all orders from firestore
  useEffect(() => {      
      const unsub = projectFirestore.collection('growLabelOrders')
        .onSnapshot((snap) => {
          let documents = [];
          let tempOrderTotals = {
            created: 0,
            createdPaid: 0,            
            createdFailed: 0,
            processing: 0,
            inTransit: 0,            
            delivered: 0,
            cancelled: 0, 
            delayed: 0
          }
            
          snap.forEach((doc) => {
            // the paymentLinkResponse should be an object but is currently a string
            // so we need to parse it to an object
            doc.data().paymentLinkResponse = JSON.parse(doc.data().paymentLinkResponse);   
            
            documents.push({ ...doc.data(), paymentLinkResponse: JSON.parse(doc.data().paymentLinkResponse) });

            // switch to updated orderTotals based on the order status
            switch (doc.data().orderStatus) {
              case 'Created': 
                if (doc.data().paymentUpdate && doc.data().paymentUpdate.data.object.payment.status === 'COMPLETED') {
                  tempOrderTotals.createdPaid += 1
                } else if (doc.data().paymentUpdate && doc.data().paymentUpdate.data.object.payment.status === 'FAILED') {
                  tempOrderTotals.createdFailed += 1                  
                } else {
                  tempOrderTotals.created += 1                  
                }            
                break;
              case 'Processing':
                tempOrderTotals.processing += 1
                break;
              case 'In Transit':
                tempOrderTotals.inTransit += 1
                break;
              case 'Delivered':
                tempOrderTotals.delivered += 1                              
                break;
              case 'Cancelled':
                tempOrderTotals.cancelled += 1
                break;
              default:
                break;
            }
            
            // loop through the orderItems array and check to see if any of the items have a delayed property, if so add 1 to the delayed total
            doc.data().orderItems.forEach((item) => {
              if (item.delayReason) {
                tempOrderTotals.delayed += 1
              }
            })
            
          });
          setOrderTotals(tempOrderTotals)

          // if the orderFilter is not 'All', filter the documents array to only include orders that match the orderFilter
          if (orderFilter !== 'All') {
            documents = documents.filter((order) => order.orderStatus === orderFilter)
          }

          setOrders(documents);
        });
      return () => unsub();
    }, [orderFilter]);


      
    const getDisplayName = (userId) => {        
      if (userData) {
        const user = userData.find((user) => user.uid === userId);          
        return user.displayName;
      }
    };

    
    // useEffect to update the labelCustomizationOptions.fgColor whenever the labelColor changes
    // orange = #fb923c green = #84cc16 purple = #a78bfa
    // useEffect to update the labelCustomizationOptions.width and height whenever the labelType changes
    // Regular = 300px x 400px Mini = 600px x 400px
    useEffect(() => {      
      if (activeOrderItem) {
        const updatedOptions = { ...labelCustomizationOptions };        
         // Set color
         if (activeOrderItem.labelColor === 'Orange') {
          updatedOptions.fgColor = '#fb923c';
        } else if (activeOrderItem.labelColor === 'Green') {
            updatedOptions.fgColor = '#84cc16';
        } else if (activeOrderItem.labelColor === 'Purple') {
            updatedOptions.fgColor = '#a78bfa';
        }
        // Set width and height
        if (activeOrderItem.labelType === 'Regular') {
            // updatedOptions.width = '300px';
            // updatedOptions.height = '400px';
            updatedOptions.width = '600px';
            updatedOptions.height = '800px';
        } else if (activeOrderItem.labelType === 'Mini') {
            // updatedOptions.width = '600px';
            // updatedOptions.height = '400px';
            updatedOptions.width = '1200px';
            updatedOptions.height = '800px';
        }        
      // Update the state once with all changes
      setLabelCustomizationOptions(updatedOptions);

      }
  }   , [activeOrderItem])

    
  // State to store expanded order ID
  const [expandedOrderID, setExpandedOrderID] = useState('');

  // Function to toggle the expanded order
  const toggleOrder = (orderID) => {
    if (expandedOrderID === orderID) {
      setExpandedOrderID('');
    } else {
      setExpandedOrderID(orderID);
      setActiveItemIndex(0)
    }
  };

  function formatMoney (valueInCents) {
    const valueInDollars = valueInCents / 100
    return valueInDollars.toLocaleString("en-US", {style:"currency", currency:"USD"}); 
  }

  function copyTextToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    alert('copied to clipboard')
  }

  // add a delay to the order
  async function handleOrderDelay(orderData) {
    // prompt user to enter delay reason, take this text and save it to the order
    const delayReason = prompt('What is the reason for the delay?')   
    const delayNumDays = prompt('How many more business days (expected) is the delay?') 

    // if either delayReason or delayNumDays is null, return, ensures that both are entered before sending
    if (!delayReason || !delayNumDays) {
      alert('Please enter both a delay reason and the number of days the order will be delayed - message not sent')
      return
    }

    // ask the user to confirm the delay - display the delay reason and the new estimated delivery date
    const confirmation = window.confirm(`Are you sure you want to set this delay? ${delayReason} for ${delayNumDays} business days?`)    
    // if confirmed, update the document in firestore
    if (confirmation) {
      // update document in firestore
      await addOrderDelay(orderData.squareOrderID, delayReason)              
    }

    const messageParams = {
      displayName: getDisplayName(orderData.orderedByUID),
      delayReason: delayReason,
      delayNumDays: delayNumDays,
      orderID: orderData.squareOrderID,
    }   

    await sendEmail(orderData.orderEmail, 'orderDelayed', messageParams, 'ETGLabelsOrder', orderData.orderedByUID, orderData.squareOrderID)
    
    // add note to the order with delay info
    // await addOrderNote(orderData.squareOrderID, `Order Delayed: ${delayReason} for ${delayNumDays} business days`)
    let tempNotes = orderData.notes ?? []
    tempNotes.unshift({ message: `Order Delayed: ${delayReason} for ${delayNumDays} business days`, date: new timestamp(Math.round(new Date().getTime()/1000), 0) })
    
    const orderRef = projectFirestore.collection('growLabelOrders').doc(orderData.squareOrderID);
    await orderRef.update({
      notes: tempNotes
    });

    if (messageSent) {
      alert('Order Delay Message sent to customer and note created')
    }
    
  }

  // update order has been sent to Stickers and Posters for printing
  async function handleOrderSentToSP (orderData) {
    // If orderNotes is null, set it to an empty array
    orderData.notes = orderData.notes ?? [];
    // prompt the user for the SandP Order Number/ID
    const SandPOrderID = prompt('What is the Stickers and Posters Order Number/ID?')
    // if SandPOrderID is null, return, ensures that both are entered before sending
    if (!SandPOrderID) {
      alert('Please enter the Stickers and Posters Order Number/ID - message not sent')
      return
    }

    const confirmation = window.confirm('Are you sure this order has been successfully sent to Stickers and Posters?')
    if (confirmation) {

      // updates the order document with the sentToSandP property to true
      await addOrderSentTOSP(orderData.squareOrderID, SandPOrderID)     
    }
    
    
    // BUILD MESSAGE ABOUT ORDER PROCESSING
    const messageParams = {
      displayName: getDisplayName(orderData.orderedByUID),
      orderID: orderData.squareOrderID,
    }
    await sendEmail(orderData.orderEmail, 'orderProcessing', messageParams, 'ETGLabelsOrder', orderData.orderedByUID, orderData.squareOrderID)
    if (messageSent) {
      alert('message sent about order processing')
    }
    // add note about order being sent to Stickers and Posters
    const orderRef = projectFirestore.collection('growLabelOrders').doc(orderData.squareOrderID);
    const updatedNotes = [ { message: 'Order Sent to Stickers and Posters', date: new timestamp(Math.round(new Date().getTime()/1000), 0) }, ...orderData.notes]
    await orderRef.update({
      notes: updatedNotes
    });
    
  }

  async function handleAddOrderNote(orderIDForNote, orderNotes) {    
    // If orderNotes is null, set it to an empty array  
    orderNotes = orderNotes ?? [];

    // prompt user for note text
    const noteMessage = prompt('Add the order note')
    const today = new Date()
    const noteDate = new timestamp(Math.round(today.getTime()/1000), 0)

    // pass a temp array that will update the notes property
    let tempNotes = [ { message: noteMessage, date: noteDate }, ...orderNotes]

    await addOrderNote(orderIDForNote, tempNotes)
  }

// Function to handle saving the QR code image to Firebase Storage and update the order with the downloadURL
const handleSaveToFirestore = async ( order ) => {
  // Get the QR code container element
  const qrCodeContainer = document.getElementById('qrCodeContainer');
  // Define the path for the image in the Firebase Storage bucket

  const imagePath = `EctorGrowLabelOrderImages/${order.labelsForPlantID}/${order.squareOrderID}/orderItem${activeItemIndex}.png`;

  try {
    // Convert dataUrl to Blob
    const dataUri = await toPng(qrCodeContainer);
    const blob = dataURItoBlob(dataUri);

    // Upload the image Blob to the specified path
    const uploadedLabel = await projectStorage.ref(imagePath).put(blob);

    // Get the download URL of the uploaded image
    const downloadURL = await uploadedLabel.ref.getDownloadURL();

    // Update the Firestore document with the downloadURL
    const orderRef = projectFirestore.collection('growLabelOrders').doc(order.squareOrderID);
    // copy the order.orderItems array, update the imageStorageUrl for the activeItemIndex
    const updatedOrderItems = [...order.orderItems]
    updatedOrderItems[activeItemIndex].imageStorageUrl = downloadURL
    // update the order document with the updatedOrderItems array

    await orderRef.update({
      orderItems: updatedOrderItems
    });

    // Show a success message to the user
    alert('QR Code image saved to Firestore and order updated successfully!');
  } catch (error) {
    // Handle any errors that occur during the process
    console.error('Error saving QR Code image to Firestore and updating order:', error);
    alert('An error occurred while saving QR Code image and updating the order.');
  }

  // add note about saving the QR code to firestore, specify the item index in the order
  const orderRef = projectFirestore.collection('growLabelOrders').doc(order.squareOrderID);
  const updatedNotes = [ { message: `QR Code image saved to Firestore for order item ${activeItemIndex}`, date: new timestamp(Math.round(new Date().getTime()/1000), 0) }, ...order.notes]
  await orderRef.update({
    notes: updatedNotes
  });
};

const handleMoveToProcessing = async (orderID, orderNotes) => {
  // If orderNotes is null, set it to an empty array  
    orderNotes = orderNotes ?? [];

  const confirmation = window.confirm('Are you sure you want to move this order to Processing?')
  if (confirmation) {
    const orderRef = projectFirestore.collection('growLabelOrders').doc(orderID);
    const updatedNotes = [ { message: 'Payment Completed - Moved to Processing', date: new timestamp(Math.round(new Date().getTime()/1000), 0) }, ...orderNotes]
    await orderRef.update({
      orderStatus: 'Processing',
      notes: updatedNotes
    });


  }
}

// Function to convert dataURI to Blob
function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

// function to load the param, a link in a new window/tab
function handleLoadArtwork(urlToLoad) {
  // Open the URL in a new tab/window
  window.open(urlToLoad, '_blank');
}



async function handleCancelOrder (orderData) {
  const confirmation = window.confirm('Are you sure you want to cancel this order?')
  if (confirmation) {
    let cancellationReason = prompt('What is the reason for the cancellation?')
    let refundType = prompt('What type of refund is being issued? Partial or Full?')
    let refundAmount = prompt('What is the refund amount? $XX.XX - the order total was ' + formatMoney(orderData.paymentLinkResponse.related_resources.orders[0].total_money.amount))   
    const displayName = await getDisplayName(orderData.orderedByUID)

    // if any of the prompts are null, return, ensures that all are entered before sending
    if (!cancellationReason || !refundType || !refundAmount) {
      alert('Please enter all of the cancellation information - message not sent')
      return
    }


    let messageParams = {
      displayName,
      cancellationReason,
      refundType,
      refundAmount,
      orderID: orderData.squareOrderID,      
    }
    await sendEmail(orderData.orderEmail, 'orderCancelled', messageParams, 'ETGLabelsOrder', orderData.orderedByUID, orderData.squareOrderID)

    const orderRef = projectFirestore.collection('growLabelOrders').doc(orderData.squareOrderID);
    await orderRef.update({
      orderStatus: 'Cancelled',
      cancellationReason,
    });
    // if message setn is true, alert to user the order was canceleld
    if(messageSent) {
      let tempNotes = orderData.notes ?? []
    tempNotes.unshift({ message: `Order Cancelled: for ${cancellationReason}`, date: new timestamp(Math.round(new Date().getTime()/1000), 0) })
    
    const orderRef = projectFirestore.collection('growLabelOrders').doc(orderData.squareOrderID);
    await orderRef.update({
      notes: tempNotes
    });
      alert('Order cancelled ')
    }

  } else {
    alert('Order not cancelled')
  }

}

async function handleEmailUser(orderData) {
  
  let emailMessage = prompt('What is the complete message you want to send to the user?')
  // if emailMessage is null, return, ensures that both are entered before sending
  if (!emailMessage) {
    alert('Please enter the complete message you want to send to the user - message not sent')
    return
  }


  const confirmation = window.confirm('Are you sure you want to email this user?')
  if (confirmation) {
    const messageParams = {
      displayName: await getDisplayName(orderData.orderedByUID),
      orderID: orderData.squareOrderID,
      emailMessage,
      orderDate: new Date(orderData.dateTimeOrdered)
    }

    await sendEmail(orderData.orderEmail, 'orderUpdate', messageParams, 'ETGLabelsOrder', orderData.orderedByUID, orderData.squareOrderID)

    // if message setn is true, alert to user the order was canceleld\
    if(messageSent) {
      alert('Email sent')
    }
  } else {
    alert('Email not sent')
  }

}

// changes the order status from processing to inTransit
async function handleUpdateTracking( orderData ) {
  const confirmation = window.confirm('Are you sure you want to update the tracking information for this order?')
  if (confirmation) {
    let trackingNumber = prompt('What is the tracking number?')
    let carrier = prompt('What is the carrier? USPS, UPS, FedEx, etc.')
    // if any of the prompts are null, return, ensures that all are entered before sending
    if (!trackingNumber || !carrier) {
      alert('Please enter both the tracking number and the carrier - message not sent')
      return
    }
    
    
    // add tracking info to the order document
    const orderRef = projectFirestore.collection('growLabelOrders').doc(orderData.squareOrderID);
    await orderRef.update({
      trackingNumber,
      carrier,
      orderStatus: 'In Transit',
      notes: [ { message: `Order in Transit - Tracking Number: ${trackingNumber} - Carrier: ${carrier}`, date: new timestamp(Math.round(new Date().getTime()/1000), 0) }, ...orderData.notes]  
    });
    alert('Tracking information updated')

    const messageParams = {
      displayName: await getDisplayName(orderData.orderedByUID),
      carrier, 
      trackingNumber, 
      orderID: orderData.squareOrderID,
    }
    // send email
    await sendEmail(orderData.orderEmail, 'inTransit', messageParams, 'ETGLabelsOrder', orderData.orderedByUID, orderData.squareOrderID)

    if (messageSent) {
      if (orderData.orderDelay) {
        await removeOrderDelay(orderData.squareOrderID)
      }
      alert('Email sent & order delay removed')
    }
    


  } else {
    alert('Tracking information not updated')
  }
}

async function handleOrderDelivered ( orderData ) {
  const confirmation = window.confirm('Are you sure you want to mark this order as delivered?')

  if (confirmation) {
    const orderRef = projectFirestore.collection('growLabelOrders').doc(orderData.squareOrderID);
    await orderRef.update({
      orderStatus: 'Delivered',
      notes: [ { message: `Order Delivered`, date: new timestamp(Math.round(new Date().getTime()/1000), 0) }, ...orderData.notes]      
    });
  
    alert('Order marked as delivered')

    const messageParams = {
      displayName: await getDisplayName(orderData.orderedByUID),
      orderID: orderData.squareOrderID,
    }
    // send email
    await sendEmail(orderData.orderEmail, 'orderDelivered', messageParams, 'ETGLabelsOrder', orderData.orderedByUID, orderData.squareOrderID)

    if (messageSent) {
      if (orderData.orderDelay) {
        await removeOrderDelay(orderData.squareOrderID)
      }      
      alert('Email sent & order delay removed')
    }
      
    }
}

// set the correct activeItemIndex and activeOrderItem when the expandedOrderID changes
useEffect(() => {
  if (expandedOrderID) {
    const currentOrderIndex = orders.findIndex((order) => order.squareOrderID === expandedOrderID)
    setActiveOrderItem(orders[currentOrderIndex].orderItems[0])
    setActiveItemIndex(0)
  }  
}, [expandedOrderID])



// function to set the activeOrderItem, based on the index of the orderItems array
const handleSetActiveOrderItem = (index) => {
  const currentOrderIndex = orders.findIndex((order) => order.squareOrderID === expandedOrderID)
  setActiveOrderItem(orders[currentOrderIndex].orderItems[index])
  
}

function displayOrderStatusDetails () {
  // return a p describing the current order status
  switch (orderFilter) {
    case 'Created':
      return <><p className='etg-systemText'>Orders that have been created but not paid for</p>
            <ul>
              <li>Once paid, Click to move orders to Processing</li>
            </ul></>
    case 'Processing':
      return <><p className='etg-systemText'>Orders that have been paid for and are being processed both by ETG and SandP</p>
            <ul>  
              <li>Generate and save images to firestore</li>
              <li>Download images to local computer</li>
              <li>Use Downladed images to generate SandP order at <a className='text-blue-800' target='_blank' rel='noreferrer' href='https://sandpgateway.com/_t/c/A1020004-17756F45EB7350B9-37022D81?l=AABNAU6BTdCqXz%2FSb9ZVRvuQRaVEIKPHzAzadjc2lprh2tWBhYrjI160iR%2FH0sBySnF5CqPhwWzbl%2FS5BI%2F%2F%2Bsj3bOLV8Jw3kPeIDXUj6TaRgCBDdxYLl%2FcP7ehVZbkYEvzDoVoJ5LHZ0WKulrFsE3Q8e5%2BYB0TFtVTSVR%2FNij1NcLTBQl9ROjllo%2BSpG1%2FKEwEzP7DRdZrx%2BgINSO42fzx%2FCg%3D%3D&c=AAA0xu9fJ%2Bur8VFRC1xR%2BtuJYo5eCi03KehaYVmpq7zMhPLhCduWP3Mtgpg1nc3XH4h4OHkyWRFyShyJ0ZFWefkNhFec0%2Bdw2t%2BaI8dYBSqNypY3Yn%2FZoQZa1rehNTz3z3bqeMyhKFmkUdsqoDv2zDjoDhZEoScKsI065%2FxPd5NQvxoMdtyHsOlzK%2FI3uPPd83aEOedrXyrlM9z7Ni8usWCVVxiUymUcItWbwOv8cLpjxyoZml1zcgbDsHCojlk0gzySAhOXN9IbR8euDRuLuClhIQQEXHNHLHS8Nm8zXbGsFooVuIy2gslJU5bg%2B8lfqycqkY%2Bfjz5vYIuUL2nDqLP8NioAFJSORVpcrDgWsB%2FSQMJb5ETDGfOTDSMbWekLd%2B2g7MGrIddPSelP13bU8soPHAF5JFmQOwsrkr9bS%2Fj2Z8PO%2BEU7rvScwPobN7DLDVpUtOIBVkEwASXYvV26xZXIa5skmKN5yGWxroM67eo6sLPAKJU%3D'>SandP Link</a> </li>
              <li>Click to confirm SandP order created, enter SandP order# when prompted</li>
              <li>Hold until recieving in-transit info</li>
              <li>Enter Carrier service and tracking number after clicking 'Update Tracking Info'</li>
            </ul>
      </>
    case 'In Transit':
      return <><p className='etg-systemText'>Orders that have been paid for and are in transit to the customer</p>
      <ul>
        <li>Check orders daily to see if delivered or not</li>
        <li>Click to mark as delivered</li>
      </ul>
      </>
    case 'Delivered':
      return <p className='etg-systemText'>Orders that have been paid for and have been delivered to the customer</p>
    case 'Delayed':
      return <p className='etg-systemText'>Orders that have been delayed for some reason</p>
    case 'Cancelled':
      return <p className='etg-systemText'>Orders that have been cancelled</p>
    default:
      return <p className='etg-systemText'>All orders</p>

  }
}

function displayOrderStatus(order) {
  switch (order.orderStatus) {
    case 'Created':
      if (!order.paymentUpdate) {
        return <p className='py-2 uppercase text-yellow-500'>Awaiting Payment</p>;
      }

      if (order.paymentUpdate && order.paymentUpdate.data.object.payment.status === 'COMPLETED') {
        return <p className='text-lg font-medium animate-pulse text-red-600'>Payment Completed - Begin Processing</p>;
      } 
      break;
    case 'Processing':
      if (!order.sentToSandP) {
        return <p className='py-2 uppercase text-red-500'>Awaiting ETG Processing</p>;        
      }

      if (order.sentToSandP) {
        return <p className='py-2 uppercase text-yellow-500'>Order sent to S&P for Processing</p>;
      }
      break

    case 'In Transit':
      return <p className='py-2 uppercase text-yellow-500'>Order in Transit</p>;      
    case 'Delivered':
      return <p className='py-2 uppercase text-green-500'>Order Delivered</p>;

    default:
      if (order.sentToSandP) {
        return <p className='py-2 uppercase text-green-500'>Order sent to Stickers and Posters</p>;
      }
      
      return null; // Default case: No specific status to display
  }
}
function handleOrderFilter (filter) {
  // if the filter is the same as the current orderFilter, set the orderFilter to 'All'
  if (filter === orderFilter) {
    setOrderFilter('All')
  } else {
    setOrderFilter(filter)
  }
}

  
  // styling for buttons
  const buttonStyling = 'bg-slate-400 hover:bg-slate-500 text-white font-bold py-2 px-4 rounded-full my-2';  
  return (
    <>
      <section className='flex flex-col py-16 bg-slate-200 my-10 mx-4 rounded-xl text-black'>
        <div className='flex flex-col justify-center items-center'>
          <h1 className='etg-heading'>Label Order Management</h1>
          <p className='text-xl'>EctorGrow - Square - PipeDream - Stickers & Posters</p>
          {/* <p className='text-xl'>total orders: {orders.length}</p> */}
        </div>

        {/* Add Filters for order status iwth a count next to their name */}
        <div className='flex flex-wrap gap-4 p-4 justify-around items-center my-4'>
          <div onClick={() => handleOrderFilter('Created')} className='flex flex-col items-center'>
            <label>Created - {orderTotals.created}</label>           
            <label>Created/Paid - {orderTotals.createdPaid}</label>                       
            <label>Created/Failed - {orderTotals.createdFailed}</label>
          </div>
          <label onClick={() => handleOrderFilter('Processing')}>Processing  - {orderTotals.processing}</label>
          <label onClick={() => handleOrderFilter('In Transit')}>In Transit - {orderTotals.inTransit}</label>
          <label onClick={() => handleOrderFilter('Delivered')}>Delivered - {orderTotals.delivered}</label>          
          <label onClick={() => handleOrderFilter('Delayed')}>Delayed - {orderTotals.delayed}</label>
          <label onClick={() => handleOrderFilter('Cancelled')}>Cancelled - {orderTotals.cancelled}</label>
        </div>

        {/* filter description */}
        <div className='flex flex-col justify-center items-center'>
          <p className='etg-systemText'>
            { displayOrderStatusDetails(orderFilter) }
          </p>
          

        </div>

        {/* Transition Div */}
        <div className='py-4'>
          <p className='etg-systemText'>
            filter by order status by clicking a status above - click again to remove the filter
          </p>
          {/* add a bottom line seperating the filters from the orders */}
          <div className='border-b-2 border-black w-full my-4'></div>
          <p className='etg-systemText py-4'>displaying {orders.length} orders</p>
        </div>
        

        {/* Iterate through orders and display each order */}
        {( orders && orders.length > 0) && orders.map((order, orderIndex) => (          
          <div
            key={order.squareOrderID}
            className='border-black border-2 mx-4 rounded-md px-4 py-2 my-2 flex flex-col'
          >
            {/* Display minimal data: order status and date created */}
            <div className='flex flex-col md:flex-row justify-between items-center'>
              <p>Order Status: {order.orderStatus}</p>
              <p>Date Created: {order.dateTimeOrdered}</p>
              {/* Order Status */}
              { displayOrderStatus(order) }
              
              
              {/* Button to expand the order */}
              <button className={buttonStyling} onClick={() => toggleOrder(order.squareOrderID)}>Expand</button>
            </div>

            {/* Expanded row for full details */}
            {expandedOrderID === order.squareOrderID && (<>
              <div className='my-8'>                
                <p className='py-2'>Square Order ID: <span onClick={() => copyTextToClipboard(order.squareOrderID)} className='etg-systemText'>{order.squareOrderID}<FiCopy /></span></p>
                <p className='py-2'>Square Payment ID: <span onClick={() => copyTextToClipboard(order.squarePaymentID)} className='etg-systemText'>{order.squarePaymentID}<FiCopy /></span></p>
                {/* SandP order id */}
                { order.SandPOrderID ? <> <p onClick={() => copyTextToClipboard(order.SandPOrderID)} className='py-2'>SandP Order ID: {order.SandPOrderID}<FiCopy/></p> </>: null }
                <p className='py-2'>Link To Plant: <span onClick={() => copyTextToClipboard(`http://EctorGrow.com/grows/${getDisplayName(order.orderedByUID)}/${order.labelsForPlantID}`)} className='etg-systemText'>{`http://EctorGrow.com/grows/${getDisplayName(order.orderedByUID)}/${order.labelsForPlantID}`}</span></p>
                <p className='py-2'>Ordered By UID: <span className='etg-systemText'>{order.orderedByUID}</span></p>
                <p className='py-2'>Labels for Plant ID: <span className='etg-systemText'>{order.labelsForPlantID}</span></p>
                <p className='py-2'>Order Total: <span className='etg-systemText'>{formatMoney(order.paymentLinkResponse.related_resources.orders[0].total_money.amount)}</span></p>
                <p className='py-2'>Order Description: <span className='etg-systemText'>{order.orderDescription}</span></p>
                <p className='py-2'>Order Items</p>
                <DisplayOrderItems 
                  displayType='viewOnly' 
                  orderItems={order.orderItems} 
                  activeItemIndex={activeItemIndex}   
                  handleSetActiveOrderItem={handleSetActiveOrderItem}
                  />
                { ( order.delay && order.status === 'Created' ) ? <> 
                  <p className='py-2 uppercase text-red-700 font-medium'>Order is Delayed! <span className='etg-systemText text-red-700 font-medium'>{order.delay}</span></p> 
                </> : null }
                {/* image artwork saved to Firestore */}
                { order.orderItems[activeItemIndex].imageStorageUrl ? <>
                  <p className='py-2 bg-emerald-400'>
                    Order Item Artwork has been saved - <span onClick={() => handleLoadArtwork(order.orderItems[activeItemIndex].imageStorageUrl)}>open in new tab</span>
                  </p>
                </> : null}     

                {/* Section for order notes */}
                {order.notes ? (
                  <>
                    <h2 className='text-center pt-4'>Order Notes:</h2>
                    <ul className='flex flex-wrap justify-center gap-4'>
                      {order.notes.map((note, index) => (
                        <li className='w-1/4 outline outline-2 rounded-xl p-2' key={index}>
                          <p className='text-center pb-1'>Note - {index +1}</p>
                          <p>Date: {timestampToDate(note.date)}</p>
                          <p>Message: {note.message}</p>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <p>No notes have been saved yet.</p>
                )}




              </div>                    

              {/* Display the QR code once generated of the active order item, if it exists */}
              <div className="flex flex-col w-full my-8 justify-center items-center border-2 border-emerald-800/80 rounded-xl p-8">

 
                { activeOrderItem ? <>

                  <div className="flex flex-col items-center bg-transparent my-4 p-2">
                    <div 
                      className={`${activeOrderItem.labelType === 'Regular' ? 'bg-emerald-800' : 'bg-transparent border-2 border-dashed border-black' } self-center`} 
                      style={labelCustomizationOptions} 
                      id="qrCodeContainer"
                    >
                        <div className="pt-4 pb-8">
                        {/* Render QR codes based on labelType */}
                        {activeOrderItem.labelType === 'Mini' ? (
                            <div className="grid grid-cols-3 overflow-auto gap-24">
                            {[...Array(6)].map((_, index) => (
                                <div className='border-2 border-dashed border-black p-8 rounded-xl'>
                                <div className='bg-emerald-800 p-4 rounded-xl'>

                                <QRCode
                                key={index}                                                                
                                value={`http://EctorGrow.com/grows/${order.userDisplayName}/${order.labelsForPlantID}`}
                                size={125}
                                level="L"
                                renderAs="svg"
                                bgColor="#065f46"
                                fgColor={labelCustomizationOptions.fgColor}
                                />
                                </div>
                                </div>
                            ))}
                            </div>
                        ) : (
                          <div className='mt-4 p-2'>

                            <QRCode
                            value={`http://EctorGrow.com/grows/${order.userDisplayName}/${order.labelsForPlantID}`}
                            size={450}
                            level="L"
                            renderAs="svg"
                            bgColor="#065f46"
                            fgColor={labelCustomizationOptions.fgColor}
                            />
                          </div>
                        )}
                    </div>
                    { activeOrderItem.textOptions.type === 'default' && activeOrderItem.labelType === 'Regular' ? <>
                    <div className='flex flex-col p-2 text-white items-center h-2/5 justify-center'>                    
                        { activeOrderItem.textOptions.strain ? <h3 className='text-3xl tracking-wider font-medium'>{activeOrderItem.textOptions.strain}</h3> : null }
                        { activeOrderItem.textOptions.displayName ? <h3 className='text-xl pt-1 text-slate-300'>grown by {activeOrderItem.textOptions.displayName}</h3> : null }     
                        { activeOrderItem.textOptions.dateHarvested ? <h3 className='text-xl pt-1 text-slate-300'>harvested on { activeOrderItem.textOptions.dateHarvested }</h3> : null }
                    </div>
                    </> : null }
                    { activeOrderItem.textOptions.type === 'custom' && activeOrderItem.labelType === 'Regular' ? <> 
                    <div className='flex flex-col p-2 text-white items-center h-2/5 justify-center'>
                        <p className='text-xl tracking-wider font-medium overflow-auto '>{activeOrderItem.textOptions.customText}</p>
                        </div>
                        </> : null }
                    </div>              
                </div>
                </> : null }

                          
                {/* Button to download the QR code as an image */}
                <button className={buttonStyling} onClick={downloadQRCode}>Download QR Code</button>                
              </div>
              


              {/* Buttons for Order Actions */}
              <div className='flex flex-wrap gap-4 justify-around'>
                {/* Add buttons for actions like send to printify or email user */}
                { ( !order.orderItems[activeItemIndex].imageStorageUrl && order.orderStatus === 'Processing' ) ? <button className={buttonStyling} onClick={() => handleSaveToFirestore( order )}>Save QR Code to Firestore</button> : null}

                {/* Update sent to Sitckers and Posterss */}
                {  ( !order.sentToSandP && order.orderStatus === 'Processing'  ) ?  <button onClick={() => handleOrderSentToSP(order)} className={buttonStyling}>Order Created & Completed Through S&P Gateway</button>  : <></> }
                {/* Special Need - Should not be every occasion */}
                {/* <button className={buttonStyling}>Refresh Order Status - Square</button>                 */}
                <button className={buttonStyling} onClick={()=> handleEmailUser(order)} >Email User</button>
                { order.orderStatus === 'Processing' && order.sentToSandP ? 
                <button className={buttonStyling} onClick={() => handleUpdateTracking(order)}>Update Tracking Info from S&P</button> : null }

                {/* Add an order delay */}
                { ( !order.delay && order.orderStatus !== 'Cancelled' && order.orderStatus !== 'Delivered' ) ? <button onClick={() => handleOrderDelay(order)} className={buttonStyling}>Order Delay</button> : <></> }
                {/* Update Payment Completed */}
                {/* if payment is completed */}
                { order.orderStatus === 'Created' && order.paymentUpdate && order.paymentUpdate.data.object.payment.status === 'COMPLETED' ? <button className={buttonStyling} onClick={() => handleMoveToProcessing(order.squareOrderID, order.notes)}>Payment Complete - Move To Processing</button>  : null}
                
                {/* Allow cancellation if order is created and or processing */}
                { order.orderStatus === 'Created' || order.orderStatus === 'Processing' ? <button
                  onClick={() => handleCancelOrder(order)} className={buttonStyling}>Order Cancel</button> : null}
                
                {/* Mark Order as Delivered */}
                { order.orderStatus === 'In Transit' ? <button onClick={() => handleOrderDelivered(order)} className={buttonStyling}>Order Delivered</button> : null}

                {/* Add an order note */}
                <button onClick={() => handleAddOrderNote(order.squareOrderID, order.notes)} className={buttonStyling}>Add Order Note</button>
              </div></>
            )}
          </div>
        ))}
      </section>
    </>
  );
}
