import React from 'react';

export default function WeeklyScheduleDisplay({ nutrientIndex, activeNutrientObj }) {

  const displayWeeklySchedule = () => {
    let returnValue = [];

    for (let weekIndex = 0; weekIndex < activeNutrientObj.nutrients[nutrientIndex].weeklySchedule.length; weekIndex++) {
      returnValue.push(
        <div className='flex flex-col border-x-emerald-800/20 border-2' key={`displayWeekIndex${weekIndex}`}>
          <div className='px-4 text-center'>
            <p className='text-xs text-gray-800 py-1'>{`Week ${(weekIndex + 1)}`}</p>
          </div>
          <div className='py-2 m-auto'>
            <p className='text-center'>
                {activeNutrientObj.nutrients[nutrientIndex].weeklySchedule[weekIndex]}
            </p>
            <span className='etg-systemText'>{activeNutrientObj.nutrients[nutrientIndex].unitOfMeasure}</span>
          </div>
        </div>
      );
    }

    return returnValue;
  };

  return (
    <section className='flex flex-col md:mx-auto'>
      <div className='flex'>
        <div className='m-auto p-2 break-words' style={{ minWidth: 150 }}>
          <p className='text-lg pb-2'>{activeNutrientObj.nutrients[nutrientIndex].nutrientName}</p>          
        </div>
        <div className='flex'>
          <div className='flex flex-row'>
            {displayWeeklySchedule()}
          </div>
        </div>
      </div>
    </section>
  );
}
