import React from "react";

export default function AboutHistorySection() {
return (<div className='flex flex-col items-center justify-center py-6'>
        <h1 className='text-3xl font-bold text-center text-gray-800'>Our History</h1>
        <p className='text-lg text-center text-gray-800 py-3 px-4'>
            EctorGrow started as a solo developer project aimed at creating a platform to help personal cannabis growers track 
            their plant's growth in a responsible and sustainable way. Over time, the platform has attracted a small but growing 
            community of like-minded individuals who share a passion for cannabis cultivation.
        </p>
        <p className='text-lg text-center text-gray-800 py-3 px-4'>
            <span className="font-bold">Looking to the future</span>, EctorGrow plans to expand and improve its platform by introducing new features such as an 
            improved interface, the ability to add comments to plants, and a feature that enables users to easily share their grows 
            with other EctorGrow users in a public area where other user can discover comment and support your grow. 
        </p>
        <p className='text-lg text-center text-gray-800 py-3 px-4'>
            Additionally, pro features are in development that will provide users with more detailed plant tracking, improved enviroment tracking, increased image 
            capacity, and the ability to upload videos. With these improvements, EctorGrow aims to provide an even more valuable 
            tool for personal <span className="font-bold">cannabis growers looking to track and optimize their plant's growth</span>.
        </p>
    </div>);
    }
  
  