import React, { useState, useEffect } from 'react';
import DefaultNavbar from '../components/DefaultNavbar';
import DefaultFooter from '../components/DefaultFooter';
import { useAppData } from '../hooks/useAppData';
import LoadingDiv from '../components/LoadingDiv';
import ProcessingDiv from '../components/ProcessingDiv';
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';
import { GApageView } from '../components/renderless/helpers';
import { getAuth } from 'firebase/auth';
import useMessageSender from '../hooks/useMessageSender';

export default function Dev({ user }) {
  // Error Handling and Modal Management
  const { etgErrorDetected } = React.useContext(ErrorHandilingContext);
  const [isPageLoading, setIsPageLoading] = React.useState(true);


   const [sendTo, setSendTo] = useState("AF1219@gmail.com");
  

   const { sendEmail, messageSent } = useMessageSender();

  const handleSendMessage = () => {
    sendEmail(sendTo, 'orderCreated', { name: "John Doe", orderID: "123456"});
  };
  
  // BEGIN DATA LOADING SECTION
  const [id, setId] = useState('notloggedin');
  if (user && id !== user.uid) {
    setId(user.uid);
  }
  const currentUser = getAuth().currentUser;

  // Load data from Firestore
  const { profileDocument } = useAppData(currentUser.uid);

  // temp fix, this may break if isPageLoading reset by page
  if (isPageLoading && profileDocument) {
    setIsPageLoading(false);
  }

  useEffect(() => {
    GApageView('dev');
  }, []);

  // MAINTAIN THIS CODE AS PART OF DEV AND ADMIN ACCESS CONTROL
  // while user does not have correct access level, display error message
  let isAdminUser = false;
  if (profileDocument && !isAdminUser) {
    if (profileDocument.accessTier === 9) {
      isAdminUser = true;
    } else {
      return <ProcessingDiv message={'This page is only available to EctorGrow Administrators.'} type='error' />;
    }
  }
  

  return (
    <>
      {etgErrorDetected ? null : (
        <section className='flex flex-col h-screen justify-between'>
          {/* Navbar */}
          <div className='w-full z-20'>
            <DefaultNavbar user={user} profileData={profileDocument} />
          </div>
          {/* Page Modals */}
          <div className='flex flex-col' id='app-modal' />
          {/* Page Specific Content */}
          {isPageLoading ? (
            <LoadingDiv />
          ) : (
            <>
              <main className='my-10 w-10/12 mx-auto'>
                <div className='flex flex-col gap-5 text-black'>
      <input
        type="text"
        value={sendTo}
        onChange={(e) => setSendTo(e.target.value)}
        placeholder="To"
      />            
      <button onClick={handleSendMessage}>Send</button>
      {messageSent && <p>Message sent successfully!</p>}
    </div>
                
                
              </main>
            </>
          )}
          <DefaultFooter />
        </section>
      )}
    </>
  );
}
