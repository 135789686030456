import { useState, useEffect } from 'react'
import { projectAuth, projectFirestore, timestamp } from '../firebase/config'
import { useAuthContext } from './useAuthContext'
import useMessageSender from './useMessageSender'

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()
  const { sendEmail } = useMessageSender();

  const signup = async (email, password, displayName, firstName, lastName) => {
    setError(null)
    setIsPending(true)

    let registerDate = new Date()
    registerDate = new timestamp(Math.round(registerDate.getTime()/1000), 0)
    

  
    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }

      // add display name to user
      await res.user.updateProfile({ displayName })

      // add documents for growerProfile and plants
      await projectFirestore.collection('growProfiles').doc(res.user.uid).set({
        aboutMe: '',
        accessTier: 0,
        accountRenewal: null,
        displayName,
        firstName,
        flowerNutrients: [],
        growLocations: [],
        growPositions: [],
        lastName,
        profileImg: 'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/profileImages%2FblankAvatar.png?alt=media&token=0f82d827-c1a0-4b47-81f1-8295185aa676',
        registeredOn: registerDate,
        resetEmailNumDays: 7,
        uid: res.user.uid,
        vegNutrients: [],
        wantsNutrientResetEmail: true,
      });
       
            

      

      // send verify email, via firebase email
      projectAuth.currentUser.sendEmailVerification()

      const messageParams = {
        displayName,
        email        
      }

      // send welcome email, via sendgrid
      sendEmail(email, 'newUserRegistrationEmail', messageParams, 'user_account_created', res.user.uid)

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending }
}