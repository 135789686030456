import { FaInstagram, FaReddit, FaTwitter } from 'react-icons/fa';
import svgLogoWhiteBG from '../img/etgLogoWhiteBG.svg'
import { NavLink } from 'react-router-dom';

export default function DefaultFooter() {    
    // Version Number, displayed in footer
    const etgVersionNumber = '1.4.9.05'



    return (
        <>
            <footer className="flex w-full bg-gray-100 pt-10 pb-4">
                <div className="flex flex-col w-full mx-auto mt-auto px-4">
                    {/* EctorGrow Logo */}
                    <div>
                        <img className='w-auto h-20 rounded-md p-2 mx-auto md:mx-0' src={svgLogoWhiteBG} alt='svgLogo' />
                    </div>
                    
                    {/* Links and Footer Info */}
                    <div className="flex flex-col w-full justify-around items-center text-center pt-4">
                        {/* Social Links and EctorGrow Internal Links */}
                        <div className="w-full px-4">
                            <div className="flex justify-center">                                
                                <h3 className='etg-sectionTitle p-2 m-2 text-center'>Cultivate With Confidence</h3>
                            </div>
                            <div className="flex justify-around py-2 w-6/12 mx-auto">                                
                                <a
                                    href="https://twitter.com/EctorGrow"
                                    className="grid place-items-center bg-white text-blue-400 shadow-md font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaTwitter />
                                </a>                                
                                <a
                                    href="https://instagram.com/ectorgrow?igshid=YmMyMTA2M2Y="
                                    className="grid place-items-center bg-white text-green-800 shadow-md font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaInstagram />                                    
                                </a>                                
                                <a
                                    href="https://www.reddit.com/user/EctorGrow"
                                    className="grid place-items-center bg-white text-orange-800 shadow-md font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaReddit />                                    
                                </a>
                            </div>
                        </div>
                        
                        {/* EctorGrow Internal */}
                        <div className="w-full px-4 pt-16">                            
                            <div className="flex flex-wrap w-full text-center justify-around">        
                                <NavLink
                                    to="/about"
                                    rel="noreferrer"
                                    className="text-gray-700 hover:text-gray-900 inline-block p-1 text-sm"
                                > About
                                </NavLink>
                                <NavLink
                                    to="/community"
                                    rel="noreferrer"
                                    className="text-gray-700 hover:text-gray-900 inline-block p-1 text-sm"
                                > Community
                                </NavLink>
                                <NavLink
                                    to="/feedback"
                                    rel="noreferrer"
                                    className="text-gray-700 hover:text-gray-900 inline-block p-1 text-sm"
                                > Feedback
                                </NavLink>
                            
                                <NavLink
                                    to="/privacy"                                                
                                    rel="noreferrer"
                                    className="text-gray-700 hover:text-gray-900 inline-block p-1 text-sm"
                                > Privacy Policy
                                </NavLink>     
                                <NavLink
                                    className="text-gray-700 hover:text-gray-900 inline-block p-1 text-sm"
                                    to="/register"
                                > Register
                                </NavLink>                                                                

                                <NavLink
                                    to="/termsofservice"                                                
                                    rel="noreferrer"
                                    className="text-gray-700 hover:text-gray-900 inline-block p-1 text-sm"
                                > Terms of Service
                                </NavLink>
                            </div>                                
                        </div>
                    </div>
                    
                    {/* Seperation Line, probably can refactor */}
                    <hr className="my-4 border-gray-300" />
                    
                    {/* EctorGrow LLC Details and Version Number */}
                    <div className="flex flex-col items-center justify-center">
                        <div className="text-base text-gray-700 font-medium block-inline py-1">
                            EctorGrow LLC | 2022-{new Date().getFullYear()}                                 
                        </div>
                        <div className='text-slate-500 italic text-xs'>
                            V:{etgVersionNumber}
                        </div>                        
                    </div>
                </div>
            </footer>
        </>
    );
}
