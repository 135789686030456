import React from 'react'
import ETGButton from '../shared/global/ETGButton'
import { projectFirestore } from '../../firebase/config'
import { getGrowProfiles } from '../renderless/GetData/getGrowProfiles';
import { timestampToDate } from '../renderless/helpers';

export default function AdminUserMangementView() {
    const [ userData, setUserData ] = React.useState([])
    const [ uid, setUid ] = React.useState('')

    React.useEffect(() => {
      const usersData = getGrowProfiles()        
      usersData.then((data) => {           
        // sort the data by registeredOn date
        data.sort((a, b) => {
          return b.registeredOn - a.registeredOn
        })


        setUserData(data);
      });
    }, []);

    // function to retrieve a users profileData by entering a UID
    const handleRetrieveProfile = async () => {     
        console.log('handleRetrieveProfile Ran')
        
            const ref = projectFirestore.collection('growProfiles').doc(uid)
        
            ref.onSnapshot(snapshot => {
              // need to make sure the doc exists & has data
              if(snapshot.data()) {
                // setDocument({...snapshot.data(), id: snapshot.id})
                console.log('snapshot.data()', snapshot.data())
                // setError(null)
              }
              else {
                // setError('No such document exists')
              }
            }, err => {
              console.log(err.message)
            //   setError('failed to get document')
            })
        
            
        
    }

    const handleProfileClick = (userId) => {
      // use the id to find the user's displayName
      const user = userData.find((user) => user.uid === userId);
      // build the URL
      const url = `/grows/${user.displayName}`;
      // open the URL in a new tab
      window.open(url, '_blank');
    }
    
    

    console.log('userData', userData)
    // create a list of all users - display their displayName and a button to view their profile
    const renderUser = (user) => {
      return ( 
        <div key={user.uid} className='flex flex-col md:flex-row justify-between items-center py-2 px-4 bg-slate-100 rounded-xl my-2'>
          <p className='text-xl'>
            {user.displayName} - {user.uid} - registered on {user.registeredOn &&  timestampToDate(user.registeredOn)}
          </p>
          <ETGButton btnText='View Profile' onClickFunction={() => handleProfileClick(user.uid)} />
        </div>
      )
    }


  return (<>
    <section className='flex flex-col py-16 bg-slate-200 my-10 mx-4 rounded-xl text-black'>
        <div className='flex flex-col justify-center items-center'>
            <h1 className='etg-heading'>User Management</h1>
            <p className='text-xl'>Welcome to the user management page</p>
        </div>

        {/* output a list of all user displaynames */}
        <div>
          {userData.map((user) => renderUser(user))}
        </div>

        {/* section to retrieve a users profileData by entering a UID  */}
        <div className='flex flex-col justify-center items-center py-4'>
            <h2 className='etg-heading'>Retrieve User Profile</h2>
            <p className='text-xl'>Enter a UID to retrieve a users profile data</p>
            
            <label htmlFor='uid'>UID</label>
            <input type='text' value={uid} onChange={(e) => setUid(e.target.value)} name='uid' id='uid' />


            <ETGButton btnText='Retrieve Profile' onClickFunction={handleRetrieveProfile} />
        </div>






    </section>
  
  </>)
}
