import { Heading3 } from '@material-tailwind/react'
import React from 'react'

export default function LoadingDiv() {
  return (
    <>
    <section className="relative py-16 bg-[#2d2d2d]">
        <div className="container max-w-7xl py-28 px-4 mx-auto">
            <div className="relative flex flex-col bg-white min-w-0 w-full mb-6 shadow-xl rounded-2xl justify-center items-center">
                <div className="flex items-center justify-center pt-12">
                    <div className="w-24 h-24 border-l-2 border-emerald-700 rounded-full animate-spin">                        
                    </div>
                </div> 
                <div className='py-28 text-center'>
                    <Heading3>
                        Preparing Your Grow Space
                    </Heading3>
                </div>
            </div>
        </div>
    </section>    
    </>
  )
}
