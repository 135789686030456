// import React from "react";
import ReactGA from 'react-ga4'

// params -> category, action, label
// category == events that do, or do not generate page views
// action == 'the type of event you want to track'... error, addPlant, deletePlant, etc
// label == 'name assigned to the web page element whose user's interaction you want to track
export function gaEvent( category, action, label ) {
    ReactGA.event({
        category,
        action,
        label          
      })    
}

export function GApageView( ) {
  const pathName = window.location.pathname + window.location.search;  
  ReactGA.set({ page: pathName });
  ReactGA.pageview(pathName);
  // ReactGA.pageview(page)
}

export function GAmodalView( modal ) {
  ReactGA.modalview(modal)
}

export function timestampToDate( timestamp ) {
  let tempDate = timestamp.toDate()
  let date = tempDate.toLocaleDateString("en-US")
  return date
}

export function dateToTimestamp( date ) {
  // console.log(date)
  // let tempDate = new timestamp(Math.round(date.getTime()/1000, 0))
  let tempDate = Date.parse(date)
  return tempDate
}


export function noteProhibitedWordCheck( textToCheck ) {
  let isValid = null


  while ( isValid === null) {

    // if Checks for each prohibited word
    if ((textToCheck.includes('sale'))) {
      isValid = false
    }
    if ((textToCheck.includes('$'))) {
      isValid = false
    }
    if ((textToCheck.includes('price'))) {
      isValid = false
    }
    if ((textToCheck.includes('trade'))) {
      isValid = false
    }



    // If all tests pass, set to true
    if(isValid === null) {
      isValid = true
    }

  }

  return isValid
}

export function determineWeightOutput ( weight ) {
  let returnedString = ''
  // If weight is greater than 28 grams, display in oz, otherwise output weight in grams
  if (weight < 28) {
    returnedString = `${weight} grams`
  }
  if (weight > 28) {
    returnedString = `${(weight/28).toFixed(2)} oz`
  }
  if (weight === 0) {
    returnedString = `Yield Not Entered`
  }


  return returnedString
}

// passes a single plant as param, determines how many total days a plant had in Vegetative phase
export function daysOfVeg ( plant ) {
  let differenceInTime = Math.abs(plant.transferDate.toDate() - plant.startDate.toDate())
  let vegDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))        
  let returnString = `${vegDays} days in 'Veg'`
  return returnString
}
// passes a single plant as param, determines how many total days a plant had in Flower phase
export function daysOfFlower ( plant ) {
  let differenceInTime = Math.abs(plant.transferDate.toDate() - plant.harvestDate.toDate())
  let flowerDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))                 
  let returnString = `${flowerDays} days in 'Flower'` 
  return returnString
}

// passes a single plant as param, determines how many days a plant has been in it's current phase
export function daysSince ( plant ) {
  if(plant.status === 'Vegetative') {
    let today = new Date()        
    let differenceInTime = Math.abs(today - plant.startDate.toDate())
    let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))        
    return `${differenceInDays} `            
}
if(plant.status === 'Flower') {
    let today = new Date()
    let differenceInTime = Math.abs(today - plant.transferDate.toDate())
    let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))
    return `${differenceInDays} `
}   
if(plant.status === 'Harvested' || plant.status === 'Archived') {
    let today = new Date()
    let differenceInTime = Math.abs(today - plant.harvestDate.toDate())
    let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))
    return `${differenceInDays} `
}   
}

export function determineOldestActivePlant( vegetativePlants, flowerPlants ) {
  let hasActivePlants = false
  let oldestPlant = null
  
  if(flowerPlants.length > 0) {
      oldestPlant = flowerPlants[0]            
      // compare plants, find plant with oldest transfer date
      flowerPlants.forEach((plant) => {
          // console.log(timestampToDate(plant.transferDate))
          if(plant.transferDate.toDate() < oldestPlant.transferDate.toDate()) {
              // console.log('called')
              oldestPlant = plant
          }                 
      })
      let today = new Date()
      let differenceInTime = Math.abs(today - oldestPlant.transferDate.toDate())
      let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))

      return `${oldestPlant.strain} | Day ${differenceInDays} of Flower`            
  // if no plants exist in flower, check vegetative
  } else if (vegetativePlants.length > 0) {
      oldestPlant = vegetativePlants[0]            
      // compare plants, find plant with oldest start date
      vegetativePlants.forEach((plant) => {                                
          if(plant.startDate.toDate() < oldestPlant.startDate.toDate()) {                    
              oldestPlant = plant
          }                 
      })
      let today = new Date()
      let differenceInTime = Math.abs(today - oldestPlant.startDate.toDate())
      let differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))

      return `${oldestPlant.strain} | Day ${differenceInDays} of Vegetative`            
  }
  


  // if no plants in eiter, add a plant
  if ( hasActivePlants === false) {
      return 'No Active Plants'   
  }

}