
 
export default function DashboardPlantsPhaseSection({
  appData, currentPhase,   
  setCurrentPhase, 
  addPlant, 
  vegetativePlants, flowerPlants, harvestedPlants, archivedPlants
   }) {
 const selected = 'bg-emerald-800 rounded-xl p-1 mx-4 text-xl font-medium'
 const notSelected = ''

 const numPlants = vegetativePlants.length + flowerPlants.length + harvestedPlants.length + archivedPlants.length
 const animate = (numPlants === 0) ? 'animate-pulse' : ''
 
 
  return ( <>
    <div className="flex flex-col w-full text-white text-center py-3 rounded-md shadow-xl">
      <div className="py-2">
        <h4 className="text-2xl md:text-4xl font-bold">Select Phase of Growth</h4>
      </div>
      <div className="flex flex-col justify-evenly">
        <div className={(currentPhase === 'Vegetative' ? selected : notSelected)} onClick={() => setCurrentPhase('Vegetative')}>
          <p className="py-1 text-xl font-medium">{`Vegetative | ${vegetativePlants.length}`}</p>          
        </div>
        <div className={(currentPhase === 'Flower' ? selected : notSelected)} onClick={() => setCurrentPhase('Flower')}>
          <p className="py-1 text-xl font-medium">{`Flower | ${flowerPlants.length}`}</p>          
        </div>
        <div className={(currentPhase === 'Harvested' ? selected : notSelected)} onClick={() => setCurrentPhase('Harvested')}>
          <p className="py-1 text-xl font-medium">{`Harvested | ${harvestedPlants.length}`}</p>          
        </div>
        <div className={(currentPhase === 'Archived' ? selected : notSelected)} onClick={() => setCurrentPhase('Archived')}>
          <p className="py-1 text-xl font-medium">{`Archived | ${archivedPlants.length}`}</p>          
        </div>
        {(!appData.publicView && <>
          {/* if no plants, add pulse effect */}          
          <div className={`${notSelected} ${animate}`} onClick={() => addPlant()}>
            <p className="py-1 text-xl font-medium">Add New Plant</p>
          </div>          
        </>)}
      </div>      
    </div>    
  </>)
}