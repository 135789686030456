// function that deletes a comment document from firestore in the userComments collection
// only delete if the comment.userID === userID

import { getAuth } from "firebase/auth"
import { projectFirestore } from "../../../../firebase/config"

export async function deleteComment( comment ) {
    const userID = getAuth().currentUser.uid
    let result = null    
    
    try {
        
        if (comment.userID === userID) {
            await projectFirestore.collection('userComments').doc(comment.commentID).delete()
            result = 'success'
        } else {
            result = 'error  -  user does not own comment'
        }
    } catch (error) {
        console.log('error', error)
        result = 'error'
    }
    return result
}