import React from 'react';

export default function DisplayOrderItems ({ 
    displayType = 'orderDisplay', 
    orderItems, 
    setOrderItems = null, 
    activeItemIndex = null, 
    handleSetActiveOrderItem = null }) {


    return (
        <div className={`flex flex-wrap justify-center p-2 gap-4 `}>
            {orderItems && orderItems.length > 0 ? (
                orderItems.map((item, index) => (
                    <div 
                        key={index} 
                        className={`flex flex-col p-2 border-2 border-emerald-400/30 rounded-xl ${index === activeItemIndex ? 'bg-emerald-400/50' : null}`}
                        onClick={() => {
                            if (handleSetActiveOrderItem) {
                                handleSetActiveOrderItem(index);
                            }
                        }}
                    >
                        <p>{item.labelQuantity} {item.labelType === 'Regular' ? 'Label(s)' : 'Mini Label Sheet(s)'}  for ${item.cost}</p>
                        <p className='etg-systemText'>Label Color: {item.labelColor}</p>
                        { item.textOptions.type === 'default' && item.labelType === 'Regular' ? <>
                        <p className='etg-systemText'>{ item.textOptions.strain ? <span>Plant Strain</span> : null }</p>
                        <p className='etg-systemText'>{ item.textOptions.displayName ? <span>Display Name</span> : null }</p>
                        <p className='etg-systemText'>{ item.textOptions.dateHarvested ? <span>Harvest Date</span> : null }</p>                                                
                        </> : null }
                        { item.textOptions.type === 'custom' && item.labelType === 'Regular' ? <>
                        <p className='etg-systemText'>Custom Text</p>
                        <p className='etg-systemText'>{ item.textOptions.customText }</p>
                        </> : null }
                        {/* button to remove the orderItem from the array */}
                        { displayType === 'orderDisplay' ? <>
                        <button onClick={() => setOrderItems(orderItems.filter((orderItem, orderItemIndex) => orderItemIndex !== index))} 
                            className="inline-flex self-center text-white bg-red-500 border-0 py-2 px-2 focus:outline-none text-center hover:bg-red-600 rounded text-sm">
                                Remove From Order
                        </button>
                        </> : null}
                    </div>
                ))
            ) : (
                <p>Add Items To Your Cart To Generate An Order</p>
            )}
        </div>
    );
};

