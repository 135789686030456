import React, { useEffect } from 'react'
import DefaultNavbar from '../components/DefaultNavbar'
import DefaultFooter from '../components/DefaultFooter'
import LoadingDiv from '../components/LoadingDiv'
import { useState } from 'react'
import { useAppData } from '../hooks/useAppData'
import { GApageView } from '../components/renderless/helpers'
// import { getAuth } from 'firebase/auth'
import { ErrorHandilingContext } from '../context/ErrorHandilingContext'
import AboutIntroductionSection from '../components/about/AboutIntroductionSection'
import AboutHistorySection from '../components/about/AboutHistorySection'
import AboutContactSection from '../components/about/AboutContactSection'
import AboutMissionValuesSection from '../components/about/AboutMissionValuesSection'
import EctorGrowLogo from '../img/etgLogoWhiteBG.svg'
import AboutFeatureExplainer from '../components/about/AboutFeatureExplainer'

export default function About({ user }) {
    const [ isPageLoading, setIsPageLoading ] = React.useState(false)
    const { etgErrorDetected } = React.useContext(ErrorHandilingContext)
    
    // BEGIN DATA LOADING SECTION
    const [ id, setId ] = useState('notloggedin')
    if  ((user) && (id !== user.uid) ) {
        setId(user.uid)
    }
    // const currentUser = getAuth().currentUser
    
    // Load data from firestore
    const { profileDocument } = useAppData(id)    
    if (isPageLoading && profileDocument) {
        setIsPageLoading(false)
    }
    

    
    useEffect(() => {
        GApageView('about')    
    }, [])


    return (
    <> { etgErrorDetected ? null : <>   
    <section className='flex flex-col h-screen justify-between'>
        {/* NavBar */}
        <div className='relative w-full z-20'>
            <DefaultNavbar 
                user={user} 
                profileData={profileDocument} 
            />
        </div>
        {/* Page Specifc Content */}
        { isPageLoading ? <LoadingDiv /> : 
            <main className='my-10 w-10/12 mx-auto bg-slate-200 py-6 rounded-xl px-8'>            
                {/* Introduction section to what EctorGrow Is */}
                
                <AboutIntroductionSection     />
                <AboutFeatureExplainer />


                {/* Mission and Values of Ector Grow */}
                <AboutMissionValuesSection     />
                {/* Display the EctorGrow Logo  */}
                <div>
                    <img src={EctorGrowLogo} alt='EctorGrow Logo' className='w-full my-4 mx-auto' />
                </div>


                {/* Brief history and milestones */}
                <AboutHistorySection     />                

                {/* Contact Information */}
                <AboutContactSection     />
                

            </main>
        }
        <DefaultFooter/>
        </section>     
    </> } </>
    )
}
