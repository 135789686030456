


import React, { useState, useEffect } from 'react';
import { BiImages, BiNotepad } from 'react-icons/bi';
import { daysSince, timestampToDate } from '../renderless/helpers';
import ETGButton from '../shared/global/ETGButton';
import { projectFirestore } from '../../firebase/config';
import CommentSection from '../shared/Chat/CommentSection';
import { NavLink, useNavigate } from 'react-router-dom';
import GrowLog from '../grows/plantCard/Footer/GrowLog/GrowLog';

export default function PlantPost({
  plant,
  currentUser,
  updateErrorData,
  handleProfileClick,
  getDisplayName,
  getUserThumbnail,
  userData,
}) {
  const [commentData, setCommentData] = useState([]);
  const [footerDisplay, setFooterDisplay] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [plantImages, setPlantImages] = useState([]);

  const navigate = useNavigate();

  // generating const content for appData - to be used in growlog, can be refactored later
  const appData = {
    id: plant.plantOwnerUID,
    publicView: true,
  };

  useEffect(() => {
    const shuffleArray = (array) => {
      const shuffled = array.slice();
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    };
  
    if (plant.imgArray.length === 0) {      
      setPlantImages([]);
    } else if (plant.imgArray.length === 1) {      
      setPlantImages(plant.imgArray);
    } else if (plant.imgArray.length >= 3 && plant.imgArray.length < 5) {
      const shuffledArray = shuffleArray(plant.imgArray);
      const selectedImages = shuffledArray.slice(0, 3);
      setPlantImages(selectedImages);
    } else if (plant.imgArray.length >= 5) {
      const shuffledArray = shuffleArray(plant.imgArray);
      const selectedImages = shuffledArray.slice(0, 5);
      setPlantImages(selectedImages);
    }
  }, [plant.imgArray]);
  

  useEffect(() => {
    const commentQuery = projectFirestore.collection('userComments').where('documentID', '==', plant.plantId);

    const unsubscribe = commentQuery.onSnapshot((querySnapshot) => {
      const tempCommentData = querySnapshot.docs.map((doc) => doc.data());
      setCommentData(tempCommentData);
    });

    return () => {
      unsubscribe();
    };
  }, [plant.plantId]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const getImageToDisplay = () => {
    if (plantImages.length === 0) {
      return getDefaultImage();
    }

    if (selectedImage) {
      return selectedImage;
    }

    const favImgIndex = plantImages.findIndex((img) => img.favImg === true);

    if (favImgIndex !== -1) {
      return plantImages[favImgIndex];
    }

    return plantImages[0];
  };

  const getDefaultImage = () => {
    return {
      imgUrl:
        'https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2Fetg_placeHolder.png?alt=media&token=1233cb30-a991-408d-a5d9-e53c4a5b7a47',
      caption: 'User has not uploaded any images yet',
    };
  };

  const plantDetailDisplay = () => {
    if (plant.status === 'Vegetative') {
      return `${daysSince(plant)} days in veg`;
    } else if (plant.status === 'Flower') {
      return `${daysSince(plant)} days in flower`;
    } else {
      return `${timestampToDate(plant.harvestDate)} date harvested`;
    }
  };

  const handleCommentsClick = () => {
    setFooterDisplay((prevDisplay) => (prevDisplay === 'comments' ? null : 'comments'));
  };

  const handleViewMore = () => {
    navigate(`/grows/${getDisplayName(plant.plantOwnerUID)}/${plant.plantId}`);
  };

  const handleGrowLogClick = () => {
    setFooterDisplay((prevDisplay) => (prevDisplay === 'growLog' ? null : 'growLog'));
  };

  return (
    <div key={plant.plantId} className="grid grid-cols-1  gap-6 my-2 border-2 border-solid border-emerald-800 rounded-xl w-full">
      <div className="relative mx-auto w-full">
        <div className="shadow p-4 rounded-lg bg-white">
          <div className='my-4'>
            <h2 className="font-medium sm:text-base md:text-4xl text-gray-800 line-clamp-1" title="Plant Title or Strain">
              {(plant.socialTitle && plant.socialTitle !== '') ? plant.socialTitle : plant.strain}
            </h2>
            <p className="mt-2 text-sm text-gray-800 line-clamp-1">
              {plantDetailDisplay(plant)}
            </p>
          </div>
          <div className="flex flex-col justify-center relative rounded-lg overflow-hidden">
            <div className="bg-emerald-800/50 duration-500 w-full">
              {plantImages.length > 1 ? (
                <div className="flex flex-row gap-2 items-center overflow-auto">
                  {plantImages.map((image, index) => (
                    <img
                      key={index}
                      className={`max-h-[420px] p-2 rounded-xl self-center object-cover transition-transform transform ease-in-out ${selectedImage === image ? 'border-2 border-emerald-800' : ''}`}
                      src={image.imgUrl}
                      alt={image.caption}
                      onClick={() => handleImageClick(image)}
                    />
                  ))}
                </div>
              ) : (
                <div className="flex justify-center items-center p-2 gap-2 overflow-hidden">
                  <img
                    className={`max-h-[420px] rounded-xl self-center object-cover transition-transform transform ease-in-out ${selectedImage ? 'border-2 border-emerald-800' : ''}`}
                    src={getImageToDisplay().imgUrl}
                    alt={getImageToDisplay().caption}
                    onClick={() => handleImageClick(getImageToDisplay())}
                  />
                </div>
              )}
              <span className="absolute top-0 left-0 inline-flex mt-3 ml-3 px-3 py-2 rounded-lg z-10 gap-2 bg-emerald-800/50 text-sm font-medium text-white select-none">
                <div className="flex justify-center bottom-0 my-2">
                  <div className="flex bg-white px-4 py-1 space-x-5 rounded-lg overflow-hidden shadow">
                    <p className="flex items-center font-medium text-gray-800">
                      <BiImages />
                      {plant.imgArray.length}
                    </p>
                    <p className="flex items-center font-medium text-gray-800">
                      <BiNotepad />
                      {plant.noteLog.length}
                    </p>
                    <p className='text-black self-center'>{plant.plantCategory}</p>
                  </div>
                </div>
              </span>
            </div>
            <p className="etg-systemText text-black text-center my-2">
              {selectedImage ? selectedImage.caption : getImageToDisplay().caption}
            </p>
          </div>
          <div className="flex justify-center mt-8">
            <div className='flex flex-wrap justify-center'>
              <ETGButton
                onClickFunction={handleGrowLogClick}
                icon='description'
                btnText='Grow Log'
              />
              <ETGButton
                onClickFunction={handleCommentsClick}
                icon='chat'
                btnText='Comments'
              />
              <ETGButton
                onClickFunction={handleViewMore}
                icon='info'
                btnText='View More'
              />
            </div>
          </div>
          <div className="flex flex-col w-full items-center">
            {footerDisplay === 'comments' && (
              <div className={`flex-row text-black px-4 m-4 w-full mx-auto`}>
                <CommentSection
                  comments={commentData}
                  type='plant'
                  documentID={plant.plantId}
                  updateErrorData={updateErrorData}
                  getDisplayName={getDisplayName}
                  userData={userData}
                  contentID={plant.plantId}
                />
              </div>
            )}
            {footerDisplay === 'growLog' && (
              <div className={`flex-row text-black px-4 m-4 w-full mx-auto`}>
                <div className='flex flex-row text-center'>
                  <GrowLog
                    appData={appData} setAppData={null}
                    userPlantData={null}
                    updateErrorData={updateErrorData}
                    currentPlantData={plant} currentPhase={plant.status}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col justify-center items-center">
            <NavLink to={`/grows/${getDisplayName(plant.plantOwnerUID)}`} >
              <div className="rounded-full w-6 h-6 md:w-8 md:h-8 bg-gray-200 m-auto">
                <img className="rounded-full w-6 h-6 md:w-8 md:h-8" onClick={() => handleProfileClick(plant.plantOwnerUID)} src={getUserThumbnail(plant.plantOwnerUID, userData)} alt="Profile" />
                <span className="absolute top-0 right-0 inline-block w-3 h-3 bg-primary-red rounded-full"></span>
              </div>
              <p className="ml-2 text-gray-800" onClick={() => handleProfileClick(plant.plantOwnerUID)}>
                {getDisplayName(plant.plantOwnerUID)}
              </p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
