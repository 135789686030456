import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from './CustomForm'

export default function MailChimpFormContainer(  ) {
    const mailchimpFormURL = 'https://AFWebDev.us6.list-manage.com/subscribe/post?u=1ed5b5369f32370b2227db320&id=d9832f2128'
    

  return (<>
    <div className='p-2'>
            <MailchimpSubscribe
                url={mailchimpFormURL}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
  </>)
}



// following this tutorial
// https://dev.to/gedalyakrycer/create-an-email-list-with-react-mailchimp-965