import React from 'react'

export default function CategoryArticleDisplay({ title, link, source, index }) {
    
return (    
    <div>
        {/* output category title if first in map */}
        {(index === 0) && <p className='pt-1'>{title}</p>}
            <a href={link} target="_blank" rel='noreferrer'>                                
                <span className='border-b-2 border-black/20 md:hover:border-black/70 text-sm font-light italic'>
                    {source}
                </span>
            </a>
    </div>
    
  )
}
