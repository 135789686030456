import React, { useState, useEffect } from 'react';
import { gaEvent } from '../renderless/helpers';
import { GiFlowerPot, GiChecklist } from 'react-icons/gi';
import { TbRulerMeasure } from 'react-icons/tb';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { HiUserGroup } from 'react-icons/hi';
import { MdOutlineDeveloperMode } from 'react-icons/md';

export default function FeatureDisplay() {
  const [chosenFeature, setChosenFeature] = useState('Define Your Grow Space');
  const [imgSrc, setImgSrc] = useState('https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FHome_FeatureDisplayImages%2FEctorGrow_DesignGrowSpace.png?alt=media&token=a4541e2c-06f4-4b5f-b389-fc42eac0338c');   
  const [featureDescription, setFeatureDescription] = useState('');

  
  useEffect(() => {
    // Update the feature icon, description, and image source based on chosenFeature
    switch (chosenFeature) {
      case "GrowLogs for Every Plant":
        setImgSrc('https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FHome_FeatureDisplayImages%2FEctorGrow_GrowlowForEveryPlant.png?alt=media&token=df6116c8-c36e-4623-8697-ce5941fa0832');         
        setFeatureDescription({
          par1: "Every plant you add has a Growlog containing images and notes (some added automatically for you) that will detail the history of your plants' growth.",
          par2: "This helps you and the EctorGrow Community view, troubleshoot when needed, learn from and enjoy every plant you grow. See more info below.",
        });
        break;
      case "Define Your Grow Space":
        setImgSrc('https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FHome_FeatureDisplayImages%2FEctorGrow_DesignGrowSpace.png?alt=media&token=a4541e2c-06f4-4b5f-b389-fc42eac0338c');         
        setFeatureDescription({
          par1: "Get your grow space set up in less than a minute! Add a location, like 'Flower Tent' or 'Backyard' or whatever helps you describe the physical locations you will be growing in - same for the different positions.",
          par2: "Add a few positions to help keep your plants organized and EctorGrow makes it easy to look back and spot trends, diagnose issues and record changes.",
        });
        break;
      case "Custom Nutrient Schedules":
        setImgSrc('https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FHome_FeatureDisplayImages%2FEctorGrow_CustomNutrientSchedules.png?alt=media&token=a8806f9c-4316-40d7-8aed-efa2617ba1c0');         
        setFeatureDescription({
          par1: "EctorGrow is your go-to tool for cannabis feeding. Craft personalized nutrient schedules, or copy nutrient schedules shared by the EctorGrow Community.",
          par2: "You can then effortlessly track your plant's nutrient intake in the growlog, and EctorGrow will automatically email you when it's time for the next reset.",
        });
        break;
      case "Info and Support":
        setImgSrc('https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FHome_FeatureDisplayImages%2FEctorGrow_CannabisInfo.png?alt=media&token=769c0413-13ac-47fb-9836-07f55dbab53d');         
        setFeatureDescription({
          par1: "EctorGrow is here for you, whether you're a seasoned pro or just starting out. We're all about helping you succeed in your grow journey. We offer practical, stage-specific guidance to help you maintain the ideal conditions for your plants.",
          par2: "Plus, we provide easy access to useful resources for every step of your growing adventure. With EctorGrow, you'll have all the info you need to nurture your plants from seed to harvest, no matter your experience level.",
        });
        break;
      case "Community":
        setImgSrc('https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FHome_FeatureDisplayImages%2FEctorGrow_EctorGrowCommunity.png?alt=media&token=1a9f5c1d-6bb2-45c9-94c8-091cd1de45dd');         
        setFeatureDescription({
          par1: "Share your progress, seek guidance for any challenges, find the perfect nutrient schedule, or simply connect with the EctorGrow community. You can also share your journey with friends and family, even if they don't have an EctorGrow account (must be 18+ years old).",
          par2: "EctorGrow users can engage with each other's plants by offering advice, sharing congratulations, asking questions, and joining conversations about everything related to the growing community.",
          par3: "/Community"
        });
        break;
      case "EctorGrow Labels":
        setImgSrc('https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FHome_FeatureDisplayImages%2FEctorGrow_EctorGrowLabels.png?alt=media&token=a7a9b751-2dfe-464d-9b13-79942625fbe9');         
        setFeatureDescription({
          par1: "Introducing EctorGrow Labels — a fantastic solution for organizing your extensive harvest collection and seamlessly sharing it with friends and family.",
          par2: "With EctorGrow Labels, you can effortlessly connect your physical harvest to its digital history with EctorGrow. They work great while growing and even better when your plants are harvested.",
          par3: "/EctorGrowLabels"
        }); 
        break;
      default:
        setImgSrc('https://firebasestorage.googleapis.com/v0/b/ectorgrow.appspot.com/o/applicationImages%2FHome_FeatureDisplayImages%2FEctorGrow_DesignGrowSpace.png?alt=media&token=a4541e2c-06f4-4b5f-b389-fc42eac0338c');         
        setFeatureDescription(`<p className="mt-4 text-gray-300 tracking-wider">Every plant you add has a Growlog containing images and notes (some added automatically for you) that will detail the history of your plants growth.</p><p className="mt-4 text-gray-300 tracking-wider"> This helps you and the EctorGrow Community view, troubleshoot when needed, learn from and enjoy every plant you grow.</p>`);
    }
  }, [chosenFeature]); // Only re-run the effect if chosenFeature changes

  // Function to determine the icon based on the feature title
  const getIconForFeature = (featureTitle) => {
    switch(featureTitle) {
      case "GrowLogs for Every Plant":
        return <GiFlowerPot />;
      case "Define Your Grow Space":
        return <TbRulerMeasure />;
      case "Custom Nutrient Schedules":
        return <GiChecklist />;
      case "Info and Support":
        return <AiOutlineInfoCircle />;
      case "Community":
        return <HiUserGroup />;
      case "EctorGrow Labels":
        return <MdOutlineDeveloperMode />;
      default:
        return <GiFlowerPot />; // Default icon
    }
  };

  const handleFeatureSelect = (feature) => {
    gaEvent('HomePage Feature', 'User Feature Click', feature);
    setChosenFeature(feature);
  };

  const determineIfActive = (featureTitle) => {
    return featureTitle === chosenFeature ? 'underline underline-offset-4' : 'text-white';
  };

  

  return (
    <section>
      <div className="flex flex-col justify-center pt-10 pb-20 px-10 items-center">
          <div className="mx-auto max-w-lg text-center lg:mx-0 ltr:lg:text-left rtl:lg:text-right">
            <h2 className="font-bold text-xl text-slate-300 pb-2">Explore Ector Grow:</h2>
            <h2 className="text-5xl h-28 mb-4 font-bold sm:text-4xl">{chosenFeature}</h2>           
            <div className='flex flex-col mb-4 h-72 overflow-auto'>
              <p className="mt-4 text-gray-300 tracking-wider">{featureDescription.par1}</p>
              <p className="my-4 text-gray-300 tracking-wider">{featureDescription.par2}</p>
              { featureDescription.par3 ? <a href={featureDescription.par3} target='_blank' rel='noreferrer' className="my-4 text-blue-300 tracking-wider">Click Here To Learn More</a> : null }
            </div>                                     
          </div>
        <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
          <div>
            <img src={imgSrc} alt="EctorGrow - Getting Started with Cannabis Cultivation and EctorGrow" className='rounded-xl' />
          </div>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
            {['Define Your Grow Space', 'GrowLogs for Every Plant', 'Custom Nutrient Schedules', 'Info and Support', 'Community', 'EctorGrow Labels'].map(
              (featureTitle) => (
                <div
                  key={featureTitle}
                  className={`block justify-center items-center rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring ${determineIfActive(featureTitle)}`}
                  onClick={() => handleFeatureSelect(featureTitle)}
                >
                  <div className="flex flex-col justify-center items-center  rounded-2xl text-2xl text-center bg-emerald-800 p-3">{getIconForFeature(featureTitle)}</div>                
                  <h2 className="mt-2 font-bold text-center">{featureTitle}</h2>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
}


