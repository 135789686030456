import React, { useState } from 'react'
import DatePicker from 'react-date-picker'
import { BsPersonFill } from 'react-icons/bs'
import { GiNoseFront } from 'react-icons/gi'
import { GrScorecard } from 'react-icons/gr'
import ETGButton from '../../../../shared/global/ETGButton'


export default function NoteEdit({ newNote, setNewNote, handleAddNewNote }) {
    let today = new Date()
     
    const [ noteComment, setNoteComment ] = useState('')    
    const [ noteDate, setNoteDate ] = useState(today)
    const [ icon, setIcon ] = useState(<BsPersonFill />)
    const [ noteTitle, setNoteTitle ] = useState('Editing Your Note')
    const [selectedRating, setSelectedRating] = useState(1);
    const [ budSize, setBudSize ] = useState('Tiny')
   
    let noteCharCount = noteComment.length

    // handle comment going over 140 characters
    if (noteCharCount > 140) {    
        setNoteComment(noteComment.slice(0, -1))        
    }

    const handleRatingChange = (e) => {
        const newRating = parseInt(e.target.value); // Parse the selected value to an integer
        setSelectedRating(newRating); // Update the selectedRating state with the new rating
      };

    React.useEffect(() => {
        switch (newNote) {            
            case 'u':
                // user note
                setIcon(<BsPersonFill />)
                setNoteTitle('Add A Note')
                break;
            case 'aroma':
                // user note
                setIcon(<GiNoseFront />)
                setNoteTitle('Describe the plants aroma')
                break;
            case 'review':
                // user review
                setIcon(<GrScorecard />)
                setNoteTitle('Review Your Harvest')
                break;
            default:
                setIcon(<BsPersonFill />)
                setNoteTitle('Editing A Note')
                break;
        }
    }, [newNote])
        

  return (
    <>
        <div className='flex flex-col'>
            <div className='flex flex-wrap justify-around py-4 text-lg'>
                <span className='p-2 bg-emerald-800/50 rounded-full shadow-xl'>
                    {icon}
                </span>
                <p>{noteTitle}</p>
            </div>
            
            <div className='flex-col m-1 py-4'>                
                <textarea
                    className="bg-slate-300 w-full px-4 py-2 rounded-xl border-black"
                    value={noteComment}
                    onChange={(e) => setNoteComment(e.target.value)}
                    rows={4} // Set the number of rows you want to display                    
                    />
                <p className='text-sm pt-4'>{noteCharCount} of 140 characters</p>
                {newNote === 'aroma' ? <p className="etg-systemText px-4 pt-2">
                    common descriptors: fruity, earthy, piney, diesel, sweet, skunky, smokey or ashy, musky, lemony/citrusy
                </p> : null}
                {newNote === 'review' ? <p className="etg-systemText px-4 pt-2">
                    <span className='font-bold'>congratulations</span>, it's time to review your results with this plant, don't forget to include details like: 
                    taste, aroma, effect and potentcy</p> : null}
            </div>
            {/* plant rating & bud sizes, if user is reviewing plant */}
            { newNote === 'review' ? <>
                <div className='flex flex-col justify-around py-4 bg-slate-300 rounded-xl mx-4'>
                    {/* Label & Select */}
                    <div className='flex flex-wrap justify-around'>
                        <label className='text-lg'>Harvest Rating:</label>
                        <select
                            className="shadow-xl text-lg p-1 text-center rounded-xl"
                            value={selectedRating} // Bind the selectedRating state to the value of the select element
                            onChange={handleRatingChange} // Call the handleRatingChange function on change
                        >
                            {/* Use a loop to generate options 1 to 10 */}
                            {Array.from({ length: 10 }, (_, index) => index + 1).map((rating) => (
                            <option key={rating} value={rating}>
                                {rating}
                            </option>
                            ))}
                        </select>

                    </div>
                    {/* Rating Descroptions */}
                    <div className='flex flex-col justify-around'>
                        <p className='py-2'>Rating Descriptions:</p>
                        <div className='flex flex-col etg-systemText'> 
                            <p>1 - Would not Recommend</p>
                            <p>3 - Effective but not very enjoyable</p>
                            <p>5 - Average budget to mid shelf </p>
                            <p>7 - Better than average</p>
                            <p>10 - Best of the best</p>
                        </div>                                        
                    </div>

                </div>
                {/* Bud Size */}
                <div className='flex flex-col justify-around py-4'>
                    <label className='text-lg'>Bud Size:</label>
                    <select
                        className="shadow-xl text-lg p-1 text-center rounded-xl bg-slate-300"
                        value={budSize} // Bind the selectedRating state to the value of the select element
                        onChange={(e) => setBudSize(e.target.value)} // Call the handleRatingChange function on change
                    >   
                        <option value='Tiny'>Tiny</option>
                        <option value='Small'>Small</option>
                        <option value='Regular'>Regular</option>
                        <option value='Big'>Big</option>
                        <option value='Jumbo'>Jumbo</option>
                    </select>
                </div>
                    
            </> : null}
            <div className='flex-row pt-2'>
                <p>Note Date:</p>
                <DatePicker value={noteDate} onChange={(e) => setNoteDate(e)} />
            </div>
        </div>
        <div className='flex justify-around py-4 mb-6'>   
            { newNote === 'review' ? <> 
            <ETGButton
                btnText='Save Review'
                onClickFunction={() => handleAddNewNote( noteDate, noteComment, newNote, selectedRating, budSize)}
            />            
            </> : <>
            <ETGButton
                btnText='Save Note'
                onClickFunction={() => handleAddNewNote( noteDate, noteComment, newNote)}
            />            
            </>}
            <ETGButton
                btnText='Cancel'
                onClickFunction={() => setNewNote(false)}
            />
        </div>
    </>
  )
}
