import DefaultNavbar from '../components/DefaultNavbar';
import DefaultFooter from '../components/DefaultFooter';
import React, { useState } from 'react';
import ProcessingDiv from '../components/ProcessingDiv';
import { GApageView } from '../components/renderless/helpers';
import { ErrorHandilingContext } from '../context/ErrorHandilingContext';
import LoginForm from '../components/login/LoginForm';
import { useLogin } from '../hooks/useLogin';


export default function Login({ user }) {    
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    // const [ rememeberMe, setRememberMe ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState('')
    const { etgErrorDetected } = React.useContext(ErrorHandilingContext)
    const { login, errorMessageToDisplay } = useLogin();

    async function handleUserLogin() {        
        setIsLoading(true)
        await login(email, password)
        
        if (errorMessageToDisplay) {
            setErrorMessage(errorMessageToDisplay)
            // updateErrorData(errorMessageToDisplay, '')
        }
        setIsLoading(false)
    }

    
      

    
    React.useEffect(() => {                
        GApageView('Login')    
    }, [])

    
                    
    
    
    
    if (isLoading) {
       return <ProcessingDiv />
    }
    return (        
        <> { etgErrorDetected ? null : <>        
        
            <section className='flex flex-col h-screen justify-between'>

            <div className='relative w-full z-20'>
                <DefaultNavbar 
                    user={user} 
                    profileData={null} 
                />
            </div>

            <main className='w-10/12 my-10 mx-auto'>
                { isLoading ? <ProcessingDiv /> : 
                <LoginForm 
                email={email} setEmail={setEmail}
                password={password} setPassword={setPassword}                    
                handleUserLogin={handleUserLogin}
                errorMessage={errorMessage}
                />                
                }
            </main>
            <DefaultFooter />
            </section>
        </> } </>
    );
}
