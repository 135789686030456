import * as React from "react";
import { createPortal } from "react-dom";
import ETGIconBtn from "../../shared/global/ETGIconBtn";
import LoadingDiv from '../../LoadingDiv'
import GettingStartedView from "./GettingStartedView";
import AddPlantView from "./AddPlantView";
import EditPlantView from "./EditPlantView";
import DeletePlantView from "./DeletePlantView";
import VegToFlowerView from "./VegToFlowerView";
import FlowerToHarvestView from "./FlowerToHarvestView";
import HarvestToFlowerView from "./HarvestToFlowerView";
import FlowerToVegView from "./FlowerToVegView";
import PlantMovementView from "./PlantMovementView";
import PlantInfoView from "./PlantInfoView";
import NutrientScheduleView from "./NutrientScheduleView";




const GrowsModalMain = ({ growsModal, setGrowsModal, appData, currentPlantData, currentPhase, setCurrentPhase,
handleNewPlantSelected, setIsProcessing, 
setAppDisplayControl, appDisplayControl, updateErrorData, activeNutrientObj, setActiveNutrientObj, setNutrientScheduleData, nutrientScheduleData  }) => {  

    // function that takes in a name and returns the corresponding nutrient schedule object
    const getNutrientScheduleObj = (name) => {
        let nutrientScheduleObj = ''
        if (name === 'none') {
            return nutrientScheduleObj
        }
        nutrientScheduleData.vegNutrients.forEach((schedule) => {
            if (schedule.scheduleName === name) {
                nutrientScheduleObj = schedule
            }
        })
        nutrientScheduleData.flowerNutrients.forEach((schedule) => {
            if (schedule.scheduleName === name) {
                nutrientScheduleObj = schedule
            }
        })
        return nutrientScheduleObj.nutrientScheduleID
    }
    
    // function to find the name of the nutrient by matching the ID
    const getNutrientScheduleName = (id) => {
        let nutrientScheduleName = ''
        nutrientScheduleData.vegNutrients.forEach((schedule) => {
            if (schedule.nutrientScheduleID === id) {
                nutrientScheduleName = schedule.scheduleName
            }
        })
        nutrientScheduleData.flowerNutrients.forEach((schedule) => {
            if (schedule.nutrientScheduleID === id) {
                nutrientScheduleName = schedule.scheduleName
            }
        })
        return nutrientScheduleName
    }
    
    // Create lists to display users nutrient schedules, loaded from firestore using a 
    let vegScheduleOptions = []
    let flowerScheduleOptions = []
    if (appData.profileData.vegNutrients.length > 0) {        
        vegScheduleOptions = nutrientScheduleData.vegNutrients.map((schedule, index) => <option value={appData.profileData.vegNutrients.nutrientScheduleID} key={`schedule:${index}`}>{schedule.scheduleName}</option> )
    } else {
        vegScheduleOptions = <option value='none' key={`schedule:`}>none</option>
    }
    if (appData.profileData.flowerNutrients.length > 0) {        
        flowerScheduleOptions = nutrientScheduleData.flowerNutrients.map((schedule, index) => <option value={appData.profileData.flowerNutrients.nutrientScheduleID} key={`schedule:${index}`}>{schedule.scheduleName}</option>)        
    } else {
        flowerScheduleOptions = <option value='none' key={`schedule:`}>none</option>
    }
    // Create lists to display users locations and positions
    const locations = appData.profileData.growLocations.map((location, index) => <option key={`location:${index}`}>{location}</option>)
    const positions = appData.profileData.growPositions.map((position, index) => <option key={`position:${index}`}>{position}</option>)
    

    let modalDisplay = <LoadingDiv />        

    let title = 'Grows Modal'
    switch (growsModal) {
        case 'getting-started':
            title = 'Getting Started'
            modalDisplay = <GettingStartedView
                appData={appData} setGrowsModal={setGrowsModal}
            />
            break;
        case 'add-plant':
            title = 'Add a New Plant'
            modalDisplay = <AddPlantView 
                setGrowsModal={setGrowsModal} 
                appData={appData}
                handleNewPlantSelected={handleNewPlantSelected}
                setCurrentPhase={setCurrentPhase}
                setAppDisplayControl={setAppDisplayControl} appDisplayControl={appDisplayControl}
                setIsProcessing={setIsProcessing} updateErrorData={updateErrorData}
                locations={locations} positions={positions}
                vegScheduleOptions={vegScheduleOptions} flowerScheduleOptions={flowerScheduleOptions} getNutrientScheduleObj={getNutrientScheduleObj}
            />
            break;
        case 'edit-plant':
            title = `Editing ${currentPlantData.strain}`
            modalDisplay = <EditPlantView 
                currentPlantData={currentPlantData} currentPhase={currentPhase}
                setGrowsModal={setGrowsModal}
                appData={appData}
                handleNewPlantSelected={handleNewPlantSelected}
                setCurrentPhase={setCurrentPhase}
                vegScheduleOptions={vegScheduleOptions} flowerScheduleOptions={flowerScheduleOptions} 
                setIsProcessing={setIsProcessing} updateErrorData={updateErrorData}
                getNutrientScheduleObj={getNutrientScheduleObj}
                getNutrientScheduleName={getNutrientScheduleName}
            />
            break
        case 'delete-plant':
            title = `Deleting ${currentPlantData.strain}`
            modalDisplay = <DeletePlantView
                currentPlantData={currentPlantData}
                appData={appData}
                currentPhase={currentPhase}
                setGrowsModal={setGrowsModal}
                setCurrentPhase={setCurrentPhase}
                updateErrorData={updateErrorData}
                setIsProcessing={setIsProcessing}
            />
            break
        case 'veg-to-flower':
            title = `Transferring ${currentPlantData.strain} from Vegetative To Flower`
            modalDisplay = <VegToFlowerView
                setIsProccesing={setIsProcessing}
                locations={locations} positions={positions}
                currentPlantData={currentPlantData}
                setIsProcessing={setIsProcessing}
                appData={appData}
                setCurrentPhase={setCurrentPhase}
                setGrowsModal={setGrowsModal}
                updateErrorData={updateErrorData}
                handleNewPlantSelected={handleNewPlantSelected}
            />            
            break        
        case 'flower-to-harvest':
            title = `Harvesting ${currentPlantData.strain}`
            modalDisplay = <FlowerToHarvestView 
            currentPlantData={currentPlantData}
                setIsProcessing={setIsProcessing}
                appData={appData}
                setCurrentPhase={setCurrentPhase}
                setGrowsModal={setGrowsModal}
                updateErrorData={updateErrorData}
            />                
            break   
        case 'harvest-to-flower':
            title = `Undo Harvest and Move ${currentPlantData.strain} back to Flower`
            modalDisplay = <HarvestToFlowerView                
                currentPlantData={currentPlantData}
                setIsProcessing={setIsProcessing}
                appData={appData}
                setCurrentPhase={setCurrentPhase}
                setGrowsModal={setGrowsModal}
                updateErrorData={updateErrorData}
                handleNewPlantSelected={handleNewPlantSelected}
            />                
            break   
        case 'flower-to-veg':
            title = `Undo move to Flower for ${currentPlantData.strain}, back to Vegetative`
            modalDisplay = <FlowerToVegView                
                appData={appData}
                currentPlantData={currentPlantData}
                setIsProcessing={setIsProcessing}
                setCurrentPhase={setCurrentPhase}
                setGrowsModal={setGrowsModal} setIsProccesing={setIsProcessing}
                updateErrorData={updateErrorData}
                handleNewPlantSelected={handleNewPlantSelected}
            />                
            break   
        case 'lc-move':
            title = `Change the Location/Position for ${currentPlantData.strain}`
            modalDisplay = <PlantMovementView
                locations={locations} positions={positions}
                appData={appData}
                currentPlantData={currentPlantData}
                setIsProcessing={setIsProcessing}                
                setGrowsModal={setGrowsModal} setIsProccesing={setIsProcessing}
                updateErrorData={updateErrorData}
            />                
            break  
        case 'info':
            title = `Plant Information for ${currentPlantData.strain}`
            modalDisplay = <PlantInfoView
                currentPlantData={currentPlantData}
                currentPhase={currentPhase}
                setGrowsModal={setGrowsModal}
            />
            break
        case 'add_nutrient':
            title = `Add a New Nutrient Schedule`
            modalDisplay = <NutrientScheduleView 
                growsModal={growsModal} 
                setGrowsModal={setGrowsModal} 
                growerProfile={appData.profileData} 
                id={appData.id} 
                activeNutrientObj={activeNutrientObj} setActiveNutrientObj={setActiveNutrientObj}
                updateErrorData={updateErrorData} setNutrientScheduleData={setNutrientScheduleData}
            />
            break
        case 'edit_nutrient':
            title = `Editing a Nutrient Schedule`
            modalDisplay = <NutrientScheduleView 
                growsModal={growsModal} 
                setGrowsModal={setGrowsModal} 
                growerProfile={appData.profileData} 
                id={appData.id} 
                activeNutrientObj={activeNutrientObj} setActiveNutrientObj={setActiveNutrientObj}
                updateErrorData={updateErrorData} setNutrientScheduleData={setNutrientScheduleData}
            />
            break
        default:
            break;
    }


    // on initial load, use useEffect to scroll to the top of the page
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


  
  
  return growsModal ? createPortal(
        <section className="flex flex-col w-10/12 m-auto z-50 bg-white rounded-xl p-2 my-4">            
        {/* Close Btn */}
        <div className="flex justify-end m-2">
            <ETGIconBtn type='cancel' onClickFunction={() => setGrowsModal(!growsModal)} />
        </div>
          {/* Title */}
          <div className="pt-6 mx-4">
            <h2 className="text-black underline underline-offset-2 etg-heading text-center" >
                {title}
            </h2>
          </div>

          {/* Display Modal Content */}
            {modalDisplay}                    
          

        </section>,        
        document.getElementById('grows-modal'),)        
    : null;
};
export default GrowsModalMain;