import React, { useState } from 'react'
import { determineOldestActivePlant } from '../../../renderless/helpers'

export default function SocialStats({ appData }) {

  const [ growStats, setGrowStats ] = useState({
    numOfPlants: 0,
    avgYield: 0,
    harvestedTotal: 0,
    harvestedTotalOz: 0,
    shortestVegDays: Infinity,
    longestVegDays: 0,
    shortestFlowerDays: Infinity,
    longestFlowerDays: 0,
    avgVegDays: 0,
    avgFlowerDays: 0,
    shortestVegPlant: null,
    longestVegPlant: null,
    shortestFlowerPlant: null,
    longestFlowerPlant: null,
  })
      
      const vegetativePlants = appData.plantData.activePlants.filter((plant) => plant.status === 'Vegetative')
      const flowerPlants = appData.plantData.activePlants.filter((plant) => plant.status === 'Flower')
      const harvestedPlants = appData.plantData.harvestedPlants
      const archivedPlants = appData.plantData.archivedPlants
      
    
      function getMonthDifference(startDate, endDate) {
        return (
          endDate.getMonth() -
          startDate.getMonth() +
          12 * (endDate.getFullYear() - startDate.getFullYear())
        );
      }
      
      
    
      // Determine the # of  harvested plantes plants in the past 12 months, their total yield and calculate avg. yield per plant
      const determineHarvestedPlantStats = () => {
        let today = new Date()
        // if plants harvest date was within the last 12 months, from today, 
        // add their stats to the running total - same for achived plants
        harvestedPlants.forEach(plant => {
          if (getMonthDifference(plant.harvestDate.toDate(), today) <= 12) {
            // add the values to the running totals            
            setGrowStats( growStats => ({
              ...growStats, 
              numOfPlants: (growStats.numOfPlants + 1),
              harvestedTotal: (growStats.harvestedTotal + parseInt(plant.harvestYield))
            }))
          }
        })
        archivedPlants.forEach(plant => {          
          if (getMonthDifference(plant.harvestDate.toDate(), today) <= 12) {
            // add the values to the running totals     
            setGrowStats( growStats => ({
              ...growStats, 
              numOfPlants: (growStats.numOfPlants + 1),
              harvestedTotal: (growStats.harvestedTotal + parseInt(plant.harvestYield))
            }))
          }
        })
      }
    
      
      // function to load date, ran once when component loads
      React.useEffect(() => {
        if ((harvestedPlants.length > 0 || archivedPlants.length > 0) && (growStats.numOfPlants === 0)) {
          determineHarvestedPlantStats();
      
          let totalVegDays = 0, totalFlowerDays = 0;
          let vegCount = 0, flowerCount = 0;
          let shortestVegDays = Infinity, longestVegDays = 0;
          let shortestFlowerDays = Infinity, longestFlowerDays = 0;
          let shortestVegPlant = null, longestVegPlant = null;
          let shortestFlowerPlant = null, longestFlowerPlant = null;
      
          const allPlants = [...appData.plantData.activePlants, ...harvestedPlants, ...archivedPlants];
          allPlants.forEach(plant => {
            const vegDays = calculateVegDays(plant);
            const flowerDays = calculateFlowerDays(plant);
      
            if (plant.status === 'Flower' || plant.status === 'Harvested' || plant.status === 'Archived') {
              if (vegDays < shortestVegDays) {
                shortestVegDays = vegDays;
                shortestVegPlant = plant;
              }
              if (vegDays > longestVegDays) {
                longestVegDays = vegDays;
                longestVegPlant = plant;
              }
              totalVegDays += vegDays;
              vegCount++;
            }
      
            if (plant.status === 'Harvested' || plant.status === 'Archived') {
              if (flowerDays < shortestFlowerDays) {
                shortestFlowerDays = flowerDays;
                shortestFlowerPlant = plant;
              }
              if (flowerDays > longestFlowerDays) {
                longestFlowerDays = flowerDays;
                longestFlowerPlant = plant;
              }
              totalFlowerDays += flowerDays;
              flowerCount++;
            }
          });
      
          // Now, update the state just once with all the new values.
          setGrowStats(g => ({
            ...g,
            shortestVegDays,
            longestVegDays,
            shortestFlowerDays,
            longestFlowerDays,
            shortestVegPlant,
            longestVegPlant,
            shortestFlowerPlant,
            longestFlowerPlant,
            avgVegDays: vegCount > 0 ? totalVegDays / vegCount : 0,
            avgFlowerDays: flowerCount > 0 ? totalFlowerDays / flowerCount : 0,
            harvestedTotalOz: (g.harvestedTotal / 28).toFixed(2),
            avgYield: g.harvestedTotal === 0 ? 0 : (g.harvestedTotal / g.numOfPlants),
            avgHarvestedTotalOz: g.harvestedTotal === 0 ? 0 : (g.avgYield / 28).toFixed(2),
          }));
        }
      }, [harvestedPlants, archivedPlants]);
      
         


      console.log('growStats', growStats)

  return (
    <>
    {/* Display Stats for user */}
    <section className='flex flex-col justify-center items-center text-white text-center overflow-auto'>
      <h4 className="text-2xl md:text-4xl font-bold px-4 py-6">Results & Stats for {appData.profileData.displayName}'s grow</h4>
      <div className='flex flex-wrap justify-center items-center text-white text-center overflow-auto'>


          {/* Grower profile photo */}
          <div className='flex flex-col p-2'>
            <img  
              className='h-40 w-auto rounded-3xl shadow-xl mx-auto border-emerald-600 border-2' 
              src={appData.profileData.profileImg} 
              alt={`${appData.profileData.displayName}'s profile`} 
            />            
          </div>


          {/* Oldest Plant */}
          <div className='flex flex-col p-2'>
            {determineOldestActivePlant( vegetativePlants, flowerPlants )}
            <p className='text-sm text-gray-400 italic '>plant closest to harvest</p>            
          </div>

          {/* Grouped 12 month stats */}
          <div className='flex flex-col p-2'>
            {/* plant total */}
            <div className='flex flex-col text-center'>
              {/* avg yield & description */}
              <div className='flex flex-col p-2 gap-3 border-2 border-slate-300/50 rounded-t-xl'>
                <p>
                  <span className='text-lg font-medium'>{growStats.numOfPlants} </span> 
                  plants harvested                
                </p>
                <p className='mx-auto'>Avg. Yield:
                  <span className='text-lg font-medium'> {growStats.avgYield.toFixed(2)}  g | {(growStats.avgYield / 28).toFixed(2)} oz</span>
                </p>
                <p className='mx-auto'>Total Harvest: 
                  <span className='text-lg font-medium'> {growStats.harvestedTotal} g | {growStats.harvestedTotalOz} oz</span>
                </p>
              <p className='text-sm text-gray-400 italic'>harvest stats for previous 12 months</p>
              </div>
              {/* Shortest/Longest Plants */}
              <div className='flex flex-col p-2 gap-3 border-2 border-slate-300/50 rounded-b-xl'>
  <p className='pt-2'>Shortest | Longest | Average </p>
  <p className='text-sm text-gray-400 italic'>days in phase of growth</p>
  <p className='mx-auto'>Vegetative Phase:
    <span className='text-lg font-medium'>
      {growStats.shortestVegPlant && growStats.shortestVegPlant.plantId ? 
        <>
          <a href={`/grows/${appData.profileData.displayName}/${growStats.shortestVegPlant.plantId}`} className='underline' title="Link to shortest vegetative phase plant" target='_blank' rel='noreferrer'> {growStats.shortestVegDays.toFixed(0)}
          </a> | <a href={`/grows/${appData.profileData.displayName}/${growStats.longestVegPlant.plantId}`} className='underline' title="Link to longest vegetative phase plant"> {growStats.longestVegDays.toFixed(0)}
          </a> | {growStats.avgVegDays.toFixed(0)}
        </> : 'N/A'
      }
    </span> days
  </p>
  <p className='mx-auto'>Flower Phase: 
    <span className='text-lg font-medium'>
      {growStats.shortestFlowerPlant && growStats.shortestFlowerPlant.plantId ? 
        <>
          <a href={`/grows/${appData.profileData.displayName}/${growStats.shortestFlowerPlant.plantId}`} className='underline' title="Link to shortest flower phase plant"> {growStats.shortestFlowerDays.toFixed(0)}</a> | 
          <a href={`/grows/${appData.profileData.displayName}/${growStats.longestFlowerPlant.plantId}`} className='underline' title="Link to longest flower phase plant"> {growStats.longestFlowerDays.toFixed(0)}</a> | {growStats.avgFlowerDays.toFixed(0)}
        </> : 'N/A'
      }
    </span> days
  </p>
</div>


            </div>
          </div>


      </div>
    </section>
    </>
  )
}


function calculateVegDays(plant) {
  // Initialize variables to hold the start and end dates of the Veg stage.
  let vegStart = null;
  let vegEnd = null;

  // Iterate through the notelog array.
  plant.noteLog.forEach(note => {
    // Check for the Veg start note.
    if (note.comment === "Plant Started" && note.type === "lc-started") {
      // Convert Firebase timestamp to JavaScript Date object.
      vegStart = new Date(note.date.seconds * 1000);
    }

    // Check for the Veg end note.
    if (note.comment === "Transferred to Flower" && note.type === "lc-transfer") {
      // Convert Firebase timestamp to JavaScript Date object.
      vegEnd = new Date(note.date.seconds * 1000);
    }
  });

  // If either date is missing, or there is no "Transferred to Flower" note, return 0 as we can't calculate Veg days.
  if (!vegStart || !vegEnd) {
    return 0;
  }

  // Calculate the difference in days between the start and end of Veg.
  const diffTime = Math.abs(vegEnd - vegStart);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days.

  return diffDays;
}



function calculateFlowerDays(plant) {
  // Initialize variables to hold the start and end dates of the Flower stage.
  let flowerStart = null;
  let flowerEnd = null;

  // Iterate through the notelog array.
  plant.noteLog.forEach(note => {
    // Check for the Flower start note.
    if (note.comment === "Transferred to Flower" && note.type === "lc-transfer") {
      // Convert Firebase timestamp to JavaScript Date object.
      flowerStart = new Date(note.date.seconds * 1000);
    }

    // Check for the Flower end note (harvest).
    if (note.comment === "Plant Harvested" && note.type === "lc-harvest") {
      // Convert Firebase timestamp to JavaScript Date object.
      flowerEnd = new Date(note.date.seconds * 1000);
    }
  });

  // If either date is missing, return 0 as we can't calculate Flower days.
  if (!flowerStart || !flowerEnd) {
    return 0;
  }

  // Calculate the difference in days between the start and end of Flower.
  const diffTime = Math.abs(flowerEnd - flowerStart);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days.

  return diffDays;
}

