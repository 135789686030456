import prohibitedNameList from '../../../Data/prohibitedNameList'

export function testDesiredUsername( desiredDisplayName, userDisplayNames ) {
    let results = {
        isNameAvailable: false,
        currentDisplayName: ''
    }

    let isProhibited = false;
    let isValid = true;
    // convert to lower case
    desiredDisplayName = desiredDisplayName.toLowerCase();
    // set displayed name to user entered text  
    results.currentDisplayName = desiredDisplayName


    // VALIDATION CHECKS
    // Check username is at least 3 characters
    if (desiredDisplayName.length < 3 && isValid) {
        // set isValid to false
        isValid = false;
    }
    // Check username is 10 characters or less
    if (desiredDisplayName.length > 10 && isValid) {
        alert('A display name can only be 10 characters long');
        // set displayName to just first 10 characters        
        results.currentDisplayName = desiredDisplayName.slice(0, 10)
        isValid = false;
    }

    // Check username has only letter characters or numbers
    let hasLetter = /^[a-z0-9\d]*$/.test(desiredDisplayName);    
    if (!hasLetter && isValid) { isValid = false; }

    // verify exact displayName is not already in use 
    let usernameExists = userDisplayNames.includes(desiredDisplayName);
    // if usernameExists is false, verify desiredDisplayName does not contian                 
    // any prohibited words/phrases
    if (!usernameExists && !isProhibited) {
        prohibitedNameList.forEach(name => {
            isProhibited = desiredDisplayName.includes(name);
            // check if isProhibited was true
            if (isProhibited) {
                // alert to user about probited word and reset displayName                                                        
                results.currentDisplayName = ''
                alert('Something in your username matched a prohibited word - please try again');
            }
        });
    }
    // IF DISPLAYNAME IS AVAIALBLE
    if (isValid && !usernameExists && !isProhibited) {        
        results.isNameAvailable = true
    }
    // IF DISPLAYNAME IS UNAVAILABLE
    if (!isValid || usernameExists || isProhibited) {        
        results.isNameAvailable = false
    }    

    return results
}
  
  