import React from 'react'

export default function ETGIconButton({ 
    type = 'close', 
    onClickFunction, 
    icon = 'close',  
    textColor = 'black',
    rounded = 'rounded-full'
    }) {

        
    // types
    let buttonStyling = 'border-emerald-800 bg-emerald-700/50 md:hover:bg-emerald-700 active:translate-y-1 active:bg-emerald-900'
    // general (default) -> emerald color
    if (type === 'add') {
        buttonStyling = 'border-emerald-800 bg-emerald-700/50 md:hover:bg-emerald-700 active:translate-y-1 active:bg-emerald-900'
    }
    // caution -> yellowish amber color, used as a caution button
    if (type === 'caution') {
        buttonStyling = 'border-amber-800 bg-amber-400/80 md:hover:bg-amber-700 active:translate-y-1 active:bg-amber-900'
    }
    // delete -> red color, used when deleting something    
    if (type === 'delete' || type === 'close') {
        buttonStyling = 'border-red-800 bg-red-600/50 md:hover:bg-red-700 active:translate-y-1 active:bg-red-900'
    }
    // social -> blue color, used with social interactions
    if (type === 'social') {
        buttonStyling = 'border-sky-800 bg-sky-600/50 md:hover:bg-sky-700 active:translate-y-1 active:bg-sky-900'
    }
    




  return (
    <>
        {/* <div className={`flex justify-center md:justify-between px-2 text-${textColor}`} onClick={() => onClickFunction()} > */}
        <button 
            className={`border-2 ${rounded} text-${textColor} px-2 my-2 ${buttonStyling}`}
            onClick={onClickFunction}
        >
            <p className='flex justify-items-center gap-1 p-2'>                    
                <i className='material-icons'>{icon}</i>
            </p>
        </button>                
        
    
    </>
  )
}
