import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import ProcessingDiv from '../ProcessingDiv';

export default function CommunityFilterDashboardSection({
  phases,
  mediums,
  setPhases,
  setMediums,
  plantCategories,
  setPlantCategories,
  contentTypes,
  setContentTypes,
  postBeginDate, setPostBeginDate,
  postEndDate, setPostEndDate,
  socialPostCategories, setSocialPostCategories, isPageLoading
}) {
  const handlePhaseChange = (selectedPhase) => {
    if (phases.includes(selectedPhase)) {
      setPhases(phases.filter((phase) => phase !== selectedPhase));
    } else {
      setPhases([...phases, selectedPhase]);
    }
  };


  function handleSocialPostCategoryChange(selectedCategory) {
    if (socialPostCategories.includes(selectedCategory)) {
      setSocialPostCategories(socialPostCategories.filter((category) => category !== selectedCategory));
    } else {
      setSocialPostCategories([...socialPostCategories, selectedCategory]);
    }
  }

  function updatePlantCategories(category) {
    const tempCategories = [...plantCategories];
    const index = tempCategories.indexOf(category);

    if (index === -1) {
      tempCategories.push(category);
    } else {
      tempCategories.splice(index, 1);
    }

    setPlantCategories(tempCategories);
  }

  const handleContentTypeChange = (selectedContentType) => {
    const updatedContentTypes = contentTypes.includes(selectedContentType)
      ? contentTypes.filter((contentType) => contentType !== selectedContentType)
      : [...contentTypes, selectedContentType];
    setContentTypes(updatedContentTypes);
  };

  const handleFilterChange = (filter, option) => {
    if (filter === 'Medium') {
      const updatedMediums = mediums.includes(option)
        ? mediums.filter((medium) => medium !== option)
        : [...mediums, option];
      setMediums(updatedMediums);
    } else if (filter === 'Phase of Growth') {
      handlePhaseChange(option);
    } else if (filter === 'Plant Categories') {
      updatePlantCategories(option);
    } else if (filter === 'Content Types') {
      handleContentTypeChange(option);
    }
  };

  const handleDateUpdate = (beginDate = false, endDate = false) => {      
    // if beginDate is true, pass beginDate to setPostBeginDate and setPostEndDate to 7 days after beginDate
    if (beginDate) {
      if (beginDate > postEndDate) {
        const updatedEndDate = new Date(beginDate);
        updatedEndDate.setDate(updatedEndDate.getDate() + 7);        
        setPostEndDate(updatedEndDate);        
      }
      setPostBeginDate(beginDate);
    }
    // if endDate is true, pass endDate to setPostEndDate and setPostBeginDate to 7 days before endDate
    if (endDate) {
      if (postBeginDate > endDate) {
        const updatedBeginDate = new Date(endDate);
        updatedBeginDate.setDate(updatedBeginDate.getDate() - 7);        
        setPostBeginDate(updatedBeginDate);
      }
      setPostEndDate(endDate);
    }
  };
  
  

  const [isFilterOptionsVisible, setFilterOptionsVisible] = useState(false);

  const toggleFilterOptions = () => {
    setFilterOptionsVisible((prevState) => !prevState);
  };
  
  
  return (
    <>
      <div className="bg-slate-500 rounded-xl border-dashed p-2 my-4 relative">
        <button className="absolute top-2 right-2 text-white outline-1 outline-emerald-100 outline-dashed rounded-full p-2" onClick={toggleFilterOptions}>
          {isFilterOptionsVisible ? <AiOutlineUp /> : <AiOutlineDown />}
        </button>
        <h4 className="text-2xl text-center py-8 etg-systemText text-white">Community Filters</h4>

        <p className="text-md text-center">Customize these settings to filter Plants, Social Posts, and Nutrient Schedules</p>

        {isPageLoading ? (
          <ProcessingDiv message='Updating Feed...' />
        ) : (
          <>
            <section className={`flex flex-col border-b-2 mb-8 border-emerald-800 ${isFilterOptionsVisible ? '' : 'hidden'}`}>
              {/* Content Types */}
              <div className="flex flex-col pt-4 items-center justify-center w-full">
                <h2 className="text-2xl font-bold text-center text-white py-2 mr-2">Content Types</h2>
                <div className="flex flex-wrap items-center justify-center w-full">
                  {/* Plants */}
                  <div className="flex flex-wrap items-center">
                    <label className="text-xl text-center text-white">Plants</label>
                    <input
                      type="checkbox"
                      name="content-type-plants"
                      value="Plants"
                      onChange={() => handleFilterChange('Content Types', 'Plants')}
                      checked={contentTypes.includes('Plants')}
                      className="w-6 h-6 m-2"
                    />
                  </div>

                  {/* Social Posts */}
                  <div className="flex flex-wrap items-center">
                    <label className="text-xl text-center text-white">Social Posts</label>
                    <input
                      type="checkbox"
                      name="content-type-social-posts"
                      value="Social Posts"
                      onChange={() => handleFilterChange('Content Types', 'Social Posts')}
                      checked={contentTypes.includes('Social Posts')}
                      className="w-6 h-6 m-2"
                    />
                  </div>

                  {/* Nutrient Schedules */}
                  <div className="flex flex-wrap items-center">
                    <label className="text-xl text-center text-white">Nutrient Schedules</label>
                    <input
                      type="checkbox"
                      name="content-type-nutrient-schedules"
                      value="Nutrient Schedules"
                      onChange={() => handleFilterChange('Content Types', 'Nutrient Schedules')}
                      checked={contentTypes.includes('Nutrient Schedules')}
                      className="w-6 h-6 m-2"
                    />
                  </div>
                </div>
              </div>

              {contentTypes.includes('Nutrient Schedules') || contentTypes.includes('Plants') ? (
                <>
                  {/* Medium */}
                  <div className="flex flex-col pt-4 items-center justify-center w-full">
                    <h2 className="text-2xl font-bold text-center text-white py-2 mr-2">Medium</h2>
                    <div className="flex flex-wrap items-center justify-center w-full">
                      {/* Soil */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Soil</label>
                        <input
                          type="checkbox"
                          name="medium-soil"
                          value="Soil"
                          onChange={() => handleFilterChange('Medium', 'Soil')}
                          checked={mediums.includes('Soil')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Hydro */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Hydro</label>
                        <input
                          type="checkbox"
                          name="medium-hydro"
                          value="Hydro"
                          onChange={() => handleFilterChange('Medium', 'Hydro')}
                          checked={mediums.includes('Hydro')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Coco */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Coco</label>
                        <input
                          type="checkbox"
                          name="medium-coco"
                          value="Coco"
                          onChange={() => handleFilterChange('Medium', 'Coco')}
                          checked={mediums.includes('Coco')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Peat Moss */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Peat Moss</label>
                        <input
                          type="checkbox"
                          name="medium-peat-moss"
                          value="Peat Moss"
                          onChange={() => handleFilterChange('Medium', 'Peat Moss')}
                          checked={mediums.includes('Peat Moss')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Rockwool */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Rockwool</label>
                        <input
                          type="checkbox"
                          name="medium-rockwool"
                          value="Rockwool"
                          onChange={() => handleFilterChange('Medium', 'Rockwool')}
                          checked={mediums.includes('Rockwool')}
                          className="w-6 h-6 m-2"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Phase of Growth */}
                  <div className="flex flex-col pt-4 items-center justify-center w-full">
                    <h2 className="text-2xl font-bold text-center text-white py-2 mr-2">Phase of Growth</h2>
                    <div className="flex flex-wrap items-center justify-center w-full">
                      {/* Vegetative */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Vegetative</label>
                        <input
                          type="checkbox"
                          name="phase-vegetative"
                          value="Vegetative"
                          onChange={() => handleFilterChange('Phase of Growth', 'Vegetative')}
                          checked={phases.includes('Vegetative')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Flower */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Flower</label>
                        <input
                          type="checkbox"
                          name="phase-flower"
                          value="Flower"
                          onChange={() => handleFilterChange('Phase of Growth', 'Flower')}
                          checked={phases.includes('Flower')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Harvested */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Harvested</label>
                        <input
                          type="checkbox"
                          name="phase-harvested"
                          value="Harvested"
                          onChange={() => handleFilterChange('Phase of Growth', 'Harvested')}
                          checked={phases.includes('Harvested')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Archived */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Archived</label>
                        <input
                          type="checkbox"
                          name="phase-archived"
                          value="Archived"
                          onChange={() => handleFilterChange('Phase of Growth', 'Archived')}
                          checked={phases.includes('Archived')}
                          className="w-6 h-6 m-2"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {contentTypes.includes('Plants') ? (
                <>
                  {/* Plant Categories */}
                  <div className="flex flex-col pt-4 items-center justify-center w-full">
                    <h2 className="text-2xl font-bold text-center text-white py-2 mr-2">Plant Categories</h2>
                    <div className="flex flex-wrap items-center justify-center w-full">
                      {/* General */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">General</label>
                        <input
                          type="checkbox"
                          name="plant-category-general"
                          value="General"
                          onChange={() => handleFilterChange('Plant Categories', 'General')}
                          checked={plantCategories.includes('General')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Help */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Help</label>
                        <input
                          type="checkbox"
                          name="plant-category-help"
                          value="Help"
                          onChange={() => handleFilterChange('Plant Categories', 'Help')}
                          checked={plantCategories.includes('Help')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Controversial */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Controversial</label>
                        <input
                          type="checkbox"
                          name="plant-category-controversial"
                          value="Controversial"
                          onChange={() => handleFilterChange('Plant Categories', 'Controversial')}
                          checked={plantCategories.includes('Controversial')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Showoff */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Showoff</label>
                        <input
                          type="checkbox"
                          name="plant-category-showoff"
                          value="Showoff"
                          onChange={() => handleFilterChange('Plant Categories', 'Showoff')}
                          checked={plantCategories.includes('Showoff')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Mother */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Mother</label>
                        <input
                          type="checkbox"
                          name="plant-category-mother"
                          value="Mother"
                          onChange={() => handleFilterChange('Plant Categories', 'Mother')}
                          checked={plantCategories.includes('Mother')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Specialty */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Specialty</label>
                        <input
                          type="checkbox"
                          name="plant-category-specialty"
                          value="Specialty"
                          onChange={() => handleFilterChange('Plant Categories', 'Specialty')}
                          checked={plantCategories.includes('Specialty')}
                          className="w-6 h-6 m-2"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {contentTypes.includes('Social Posts') ? (
                <>
                  {/* Social Post Categories */}
                  <div className="flex flex-col pt-4 items-center justify-center w-full">
                    <h2 className="text-2xl font-bold text-center text-white py-2 mr-2">Social Post Categories</h2>
                    <div className="flex flex-wrap items-center justify-center w-full">
                      {/* General */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">General</label>
                        <input
                          type="checkbox"
                          name="social-category-general"
                          value="General"
                          onChange={() => handleSocialPostCategoryChange('General')}
                          checked={socialPostCategories.includes('General')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Question */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Question</label>
                        <input
                          type="checkbox"
                          name="social-category-question"
                          value="Question"
                          onChange={() => handleSocialPostCategoryChange('Question')}
                          checked={socialPostCategories.includes('Question')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Random */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Random</label>
                        <input
                          type="checkbox"
                          name="plant-category-Random"
                          value="Random"
                          onChange={() => handleSocialPostCategoryChange('Random')}
                          checked={socialPostCategories.includes('Random')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Hardware */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Hardware</label>
                        <input
                          type="checkbox"
                          name="plant-category-Hardware"
                          value="Hardware"
                          onChange={() => handleSocialPostCategoryChange('Hardware')}
                          checked={socialPostCategories.includes('Hardware')}
                          className="w-6 h-6 m-2"
                        />
                      </div>

                      {/* Other */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center text-white">Other</label>
                        <input
                          type="checkbox"
                          name="plant-category-Other"
                          value="Other"
                          onChange={() => handleSocialPostCategoryChange('Other')}
                          checked={socialPostCategories.includes('Other')}
                          className="w-6 h-6 m-2"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Post Date Range */}
                  <div className="flex flex-col pt-4 items-center my-2 justify-center bg-slate-600/50 rounded-xl">
                    <div className="flex flex-col items-center justify-center w-full">
                      {/* Begin Date */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center p-1">Begin Date</label>
                        <DatePicker
                          className="text-black z-100"
                          onChange={(date) => handleDateUpdate(date)}
                          value={postBeginDate}
                          clearIcon={null}
                          calendarIcon={null}
                          format="MM/dd/yyyy"
                        />
                      </div>

                      {/* End Date */}
                      <div className="flex flex-wrap items-center">
                        <label className="text-xl text-center p-1">End Date</label>
                        <DatePicker
                          className="text-black z-100"
                          onChange={(date) => handleDateUpdate(false, date)}
                          value={postEndDate}
                          clearIcon={null}
                          calendarIcon={null}
                          format="MM/dd/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </section>
          </>
        )}
      </div>
    </>
  );
}