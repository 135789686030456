import React from 'react'
import ETGButton from './shared/global/ETGButton'
import ETGLogo from '../img/etgLogoWhiteBG.svg'

export default function AgeVerificationPublic({ controlBoolean }) {
    
  return (
    <>  <section className='flex flex-col text-center justify-center text-black'>        
        <div className='flex flex-col'>
            <div>
                <img 
                    src={ETGLogo} 
                    alt='Ector Grow' 
                    className='px-5'    
                />
                <p className='mt-4 tracking-wide text-lg font-semi'>
                    Elevate Your Personal Cannabis Grow
                </p>
            </div>
        </div>
        <div className='py-4 mx-auto'>            
            <ETGButton
                btnText='I am 18+'
                type='caution'
                onClickFunction={() => controlBoolean(true)}
            />
        </div>        
    </section> </>
  )
}
